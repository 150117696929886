import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: 'ng-template[appType]'
})
export class TemplateDirective {
  // type is the select the which templete is render
  @Input() type: string;
  constructor(public template: TemplateRef<any>) {}
}
