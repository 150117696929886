import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appEnumberPrevent]'
})
export class AuPreventENumberDirective {
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Check if the key pressed is 'e' or 'E'
    if (event?.key === 'e' || event?.key === 'E' || event?.key === '.') {
      // Prevent the 'e' key from being typed
      event.preventDefault();
    }
  }
}
