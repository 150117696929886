import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appWorkFlowClickEvent]'
})
export class WorkFlowClickEventDirective {

  constructor() { }
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    console.log('button', btn, 'number of clicks:');
  }
}
