export const momArabicTranslation = {
  meetingCreate: 'Add new Meeting',
  meetingName: 'Meeting Name',
  mode: 'Mode',
  platform: 'Platform',
  addLogo: 'Add Logo',
  recurringMeeting: 'Recurring Meeting?',
  startDate: 'Start Date',
  repeatevery: 'Repeat Every',
  endDate: 'End Date',
  timeZone: 'Timezone',
  startTime: 'Start Time',
  endTime: 'End Time',
  allDay: 'All Day',
  setReminder: 'Set Reminder',
  description: 'Description',
  participents: 'Participants',
  add: 'Add',
  addParticipent: 'Add Participant',
  cancel: 'Cancel',
  email: 'Email',
  name: 'Name',
  designation: 'Designation',
  organisarion: 'Organisation',
  role: 'Role',
  addAgenda: 'Add Agenda',
  setAgendaNow: 'Set Agenda Now',
  setAgendaLater: 'Set Agenda Later',
  noAgenda: 'No Agenda',
  addMom: 'Add Minutes of Meeting',
  chooseMeeting: 'Choose Meeting',
  template: 'Template',
  templates: 'Templates',
  confirm: 'Confirm',
  saveAsDraft: 'Save as Draft',
  actions: 'Actions',
  task: 'Task',
  owner: 'Owner',
  dueDate: 'Due Date',
  moreDetails: 'More Details',
  stateofOrg: 'State of the Organisation',
  strategicD: 'Strategic Discussions',
  remindedMsg: 'You will be reminded',
  prior: 'prior',
  agenda: 'Agenda',
  agendaItems: 'Agenda Items',
  selectItem: 'Select Item',
  meetingLocation: 'Meeting Location',
  agendaToBeReviewed: 'Agenda to be reviewed',
  agendaToBeCreated: 'Agenda to be Created',
  agendaOwned: 'Agenda Owned',
  agendaCreationNeeded: 'Agenda Creation Needed',
  meetingId: 'MID',
  participants: 'Participants',
  createdOn: 'Created On',
  createdBy: 'Created By',
  meetingStartDate: 'Meeting Start Date',
  meetingStartTime: 'Meeting Start Time',
  meetingEndDate: 'Meeting End Date',
  meetingEndTime: 'Meeting End Time',
  dueDays: 'Due (Days)',
  timezone: 'Time Zone',
  addMeeting: 'Add Meeting',
  meetingManagement: 'Meeting Management',
  upcomingMyMeetings: 'Upcoming : My Meetings',
  upcomingAllMeetings: 'Upcoming : All Meetings',
  agendaToCreate: 'Action Required : Create Agenda',
  momToCreate: 'Action Required : Create MOM',
  momToApprove: 'Action Required : Approve MOM',
  actionRequired: 'Action Required',
  status: 'Status',
  mom: 'Minutes of Meeting',
  actionNeeded: 'Action Needed',
  momToBeCreated: 'Minutes of Meeting to be Created',
  momToBeReviewed: 'Minutes of Meeting to be Reviewed',
  momToBeApproved: 'Minutes of Meeting to be Approved',
  required: 'This field is required',
  completed: 'Completed',
  meetingcompleted: 'Meeting Completed',
  pending: 'Pending',
  location: 'Location',
  overview: 'Overview',
  actionList: 'Action List',
  actionItems: 'Action Items',
  actionId: 'Action ID',
  actionItemsOwned: 'Action Items Owned',
  actionItemsCompleted: 'Action Items Completed',
  actionItemsInProgress: 'Action Items In Progress',
  referenceLink: 'Reference Link',
  meetingSummary: 'Meeting Summary',
  descisionPoints: 'Decision Points',
  decisionMode: 'Decision Made',
  projectStatusUpdate: 'Project Status Updates',
  riskIdentified: 'Risks Identified',
  resolutions: 'Resolutions',
  motionPassed: 'Motion Passed',
  chooseTemplate: 'Choose Template',
  meetingTitle: 'Meeting Title',
  dateAndTime: 'Date and Time',
  venue: 'Venue',
  channel: 'Channel',
  discussionPoints: 'Discussion Points',
  additionalPoints: 'Additional Points',
  create: 'Create',
  templateStandard: 'Standard Template',
  templateTeams: 'Teams Meeting',
  templateClient: 'Client Meeting',
  teamUpdates: 'Team Updates',
  projectUpdates: 'Project Updates',
  brainStorming: 'Brain Storming',
  clientIntroduction: 'Client Introduction',
  projectOverview: 'Project Overview',
  clientConcerns: 'Client Concerns',
  projectStatusUpdates: 'Project Status Updates',
  nextStepAndDecisionPoints: 'Next Step and Decision Points',
  agendaItemName: 'Agenda Item Name',
  reference: 'Reference',
  due: 'Due',
  details: 'Details',
  feedbacks: 'Feedbacks',
  warningParticipationRate: 'Warning: Participation Rate Review Required',
  screenshot: 'Screenshot.png',
  sendMessage: 'Send Message',
  attachment: 'Attachments',
  maxSize5: 'You can upload maximum of 5 MB',
  remove: 'Remove',
  option: 'Option',
  break: 'Break',
  meetingManager: 'Meeting Manager',
  participantExist: 'User already exist',
  presentAtMeeting: 'Present at meeting',
  participentsPresentValidation: 'Present at meeting is required for all participants',
  meeting: 'Meeting',
  ondayRecurr: 'On Day ',
  on: 'On',
  repeatFrequency: 'Repeat Frequency',
  onFrequency: 'On Frequency',
  meetingDetails: 'Meeting Details',
  agendaDetails: 'Agenda Details',
  noData: 'No Data Available',
  enterHere: 'Enter Here',
  additionalDetails: 'Additional Details',
  addParticipents: 'Add Participants',
  taskId: 'TID',
  taskName: 'Task Name',
  platformDescription: 'Choose the online platform or tool where the meeting will be hosted',
  allDayDescription: 'Check this option to schedule a meeting that lasts the entire day',
  logoDescription: 'Upload an image or logo that will be displayed on top of the agenda or MOM Documents',
  referenceUrl: 'Reference URL',
  referenceUrlDescription: 'Use this field to provide relevant references or supporting documents',
  agendaItemDescription: 'These are the individual topics or points to be discussed during the meeting. Each agenda item can have an owner, description, and reference URL',
  momToBeApprovedNew: 'MOMs to be Approved',
  momToBeCreatedNew: 'MOMs to be Created',
  agendasToBeCreatedNew: 'Agendas to be Created',
  templatesPreview: 'Templates Preview',
  standard: 'Standard',
  teamsMeeting: 'Teams Meeting',
  clientMeeting: 'Client Meeting',
  approve: 'Approve',
  approved: 'Approved',
  editMeeting: 'Edit Meeting',
  sync: 'Sync',
  cantDeleteMeetingManager: 'Cannot delete meeting manager',
  update: 'Update',
  editParticipant: 'Edit Participant',
  online: 'Online',
  offline: 'Offline',
  meetingTime: 'Meeting Time',
  meetingDate: 'Meeting Date',
  remindIn: 'Remind In',
  teamsWarningNotCancel: 'Cannot cancel this meeting in Teams, Since you are not the organizer',
  teamsWarningNotRemove: 'Cannot remove this meeting in Teams, Since meeting is not found',
  teamsWarningNotUpdate: 'Cannot update this interview, Since meeting is not found',
  teamsNotificationSent: 'Notification is sent',
  upcoming: 'Upcoming',
  preview: 'Preview',
  recurringmeeting: 'Recurring Meeting',
  download: 'Download',
  export: 'export',
  userDiffCondition: 'Logged user doesnot match with the microsoft account',
  boardCreatePageDesc: 'Initiate the process of assembling a comprehensive collection of documents, agendas, and tasks essential for effective board meetings. This feature allows to compile and organize all necessary materials in preparation for upcoming meetings, ensuring that stakeholders have access to relevant information and resources.',
  createBoardPack: 'Create Board Pack',
  basicDetails: 'Basic Details',
  sectionAccess: 'Section Access',
  packCustom: 'Pack Customisation ',
  visibilityRules: 'Visibility Rules',
  boardPackTitle: 'Board Pack Title',
  boardPackIcon: 'Board Pack Icon',
  boardPackAdmin: 'Board Pack Admin',
  saveContinue: 'Save and Continue',
  exitTool: 'Exit Tool',
  user: 'User',
  userGroup: 'User Group',
  boardPack: 'Board Pack',
  meetingLogo: 'Meeting Logo',
  scheduledMeeting: 'Scheduled Meeting',
  meetingUrl: 'Meeting URL',
  microsoftTeams: 'Microsoft Teams',
  googleMeet: 'Google Meet',
  zoom: 'Zoom',
  systemNotification: 'System Notification',
  emailNotification: 'Email Notification',
  scheduleMeeting: 'Schedule Meeting',
  scheduleandcontinue: 'Schedule and Continue',
  scheduleandexit: 'Schedule and Exit',
  logoColor: 'Logo Color',
  visibilityDesc: 'Identify the users, user groups, or the organisational departments who can access this Board Pack. The Board Pack Admin will be automatically granted visibility.',
  boardpackBuilder: 'Board Pack Builder',
  headerCheckbox: 'Header Checkbox',
  generalFeedback: 'General Feedback',
  polls: 'Polls',
  logs: 'Logs',
  documents: 'Documents',
  document: 'Document',
  analytics: 'Analytics',
  creatorError: 'Board Pack creator cannot be removed',
  internaluser: 'Internal User',
  externaluser: 'External User',
  mailId: 'Mail ID',
  beforestarts: 'before the meeting starts!',
  pasteurldesc: 'Enter or Paste the URL of an already scheduled meeting in this field to sync all related information seamlessly',
  scheduleMeetingPageDesc: 'The Schedule a Meeting within the Board Pack Builder facilitates the seamless coordination of board meetings by setting dates, times, and locations for upcoming sessions. Empowering users to efficiently plan and manage meeting logistics, ensuring that all participants are informed and prepared to engage in productive discussions',
  noMeetingLinked: 'No meeting linked',
  meetingMode: 'Meeting Mode',
  organiser: 'Organiser',
  noteTaker: 'Note Taker',
  boardPacks: 'Board Packs',
  edit: 'Edit',
  cancelMeeting: 'Cancel Meeting',
  sendReminder: 'Send Reminder',
  delete: 'Delete',
  boardMeetingTool: 'Board Meeting Tool',
  boardMeeting: 'Board Meeting',
  time: 'Time:',
  to: 'to',
  meetingLink: 'Meeting Link:',
  meetingStartsIn: 'Meeting Starts In:',
  meetingEndsIn: 'Meeting Ends In:',
  doesnotrepeat: 'Does not repeat',
  daily: 'Day',
  weekly: 'Week',
  monthly: 'Month',
  yearly: 'Year',
  minutes: 'Minutes',
  hours: 'Hours',
  days: 'Days',
  rename: 'Rename',
  published: 'Published',
  draft: 'Draft',
  managers: 'Managers',
  members: 'Members',
  addNewCollection: 'Add New Collection',
  filters: 'Filters',
  noBoardPackAvailable: 'No board pack available',
  noCollectionAvailable: 'No collection available',
  createNew: 'Create New',
  expand: 'Expand',
  collapse: 'Collapse',
  meetingInprogress: 'Meeting In Progress',
  meetingUpcoming: 'Upcoming Meeting',
  momCompleted: 'MOM Completed',
  copied: 'Copied',
  userType: 'User Type',
  activityCenter: 'Activity Centre',
  momEditor: 'MoM Editor',
  momElements: 'MoM Elements',
  fields: 'Fields',
  meetingParticipants: 'Meeting Participants',
  platformOrVenue: 'Platform/Venue',
  schedule: 'Schedule',
  header: 'Header',
  footer: 'Footer',
  content: 'Content',
  dropHereToDelete: 'Drop here to delete',
  generateAsTask: 'Generate as Task',
  allowDownloads: 'Allow Downloads',
  actionName: 'Action Name',
  actionOwner: 'Action Owner',
  actionAlignment: 'Action Alignment',
  priority: 'Priority',
  permissions: 'Permissions',
  approver: 'Approver',
  noActionItemsAvailable: 'No action items available',
  momBuilder: 'MoM Builder',
  editMom: 'Edit MoM',
  addContent: 'Add Content',
  addActionItem: 'Add Action Item',
  boardmetrics: 'Board Metrics',
  meetingMetrics: 'Meeting Metrics',
  category: 'Category',
  count: 'Count',
  packManager: 'Pack Manager',
  sections: 'Sections',
  visibility: 'Visibility',
  totalmembers: 'Total Meeting Members',
  roles: 'Roles',
  recurringdetails: 'Recurring Details',
  totalDuration: 'Total meeting duration',
  apply: 'Apply',
  section: 'Section',
  wordCount: 'Word Count',
  sectionWordMetrics: 'Section Word Metrics',
  totalActionItems: 'Total Action Items : ',
  totalWordCount: 'Total word Count : ',
  agendaBuilder: 'Agenda Builder',
  platformVenue: 'Platform/Venue',
  agendaElements: 'Agenda Elements',
  agendaEditor: 'Agenda Editor',
  allowdownloads: 'Allow Downloads',
  agendaname: 'Agenda Name',
  breakname: 'Break Name',
  addsubagenda: 'Add Sub Agenda',
  addagenda: 'Add Agenda',
  addbreak: 'Add Break',
  agendaMetrics: 'Agenda Metrics',
  elements: 'Elements',
  agendaOwners: 'Agenda Owners',
  agendaDocuments: 'Agenda Documents',
  breaks: 'Breaks',
  bookMark: 'Bookmark',
  reset: 'Reset',
  paste: 'Paste',
  exitTheTool: 'Exit The Tool?',
  progressLost: 'Please note that all progress will be lost.',
  participant: 'Participant',
  removeParticipant: 'Remove Participant?',
  areYouSure: 'Are you sure you want to remove this participant from the meeting?',
  deleted: 'Deleted',
  agendaInfo: 'The Agenda Editor allows you to structure your meeting agenda with headers, footers, and detailed content, including agenda items and sub-agendas. The layout and details you configure here will directly reflect in your final meeting document.',
  subAgenda: 'Sub Agenda',
  next: 'Next',
  previous: 'Previous',
  organizedBy: 'Organized By',
  actionType: 'Action Type',
  upcomingActions: 'Upcoming Actions',
  upcomingTasks: 'Upcoming Tasks',
  upcomingBoardMeetings: 'Upcoming Board Meetings',
  refresh: 'Refresh',
  moveToDraft: 'Move to Draft',
  save: 'Save',
  editBoardPack: 'Edit Board Pack',
  momNotCreatedYet: 'Mom not created yet',
  publish: 'Publish',
  recentActivity: 'Recent Activity',
  calender: 'Calender',
  presence: 'Presence',
  present: 'Present',
  absent: 'Absent',
  movetoPublish: 'Move to Published',
  comments: 'Comments',
  activityLog: 'Activity Log',
  meetingStatus: 'Meeting Status',
  cancelMeetingDescription: 'Are you sure you want to cancel this meeting. Participants will be notified.',
  boardPackManager: 'Board Pack Manager'
};
