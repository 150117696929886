import { Directive, ElementRef, AfterViewInit, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[appDragHandle]'
})
export class DragHandleDirective implements AfterViewInit, OnChanges {
  private element: HTMLElement;
  @Input() currentX = 0;
  @Input() currentY = 0;
  constructor(public elementRef: ElementRef<HTMLElement>) { }
  ngAfterViewInit() {
    this.element = this.elementRef.nativeElement as HTMLElement;
    this.element.style.transform =
      'translate3d(' + this.currentX + 'px, ' + this.currentY + 'px, 0)';
  }
  ngOnChanges() {
    if (this.element) {
      this.element.style.transform =
        'translate3d(' + this.currentX + 'px, ' + this.currentY + 'px, 0)';
    }
  }
}
