import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse
} from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';
import { LoaderService } from '../service/loader/loader.service';
import { NavigationEnd, Router } from '@angular/router';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(private loaderService: LoaderService, private router: Router) {
    this.router.events.subscribe((val: NavigationEnd) => {
      if (val instanceof NavigationEnd && this.loaderService.showLoader) {
        this.loaderService.isLoading.next(false);
      }
    });
  }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    if (this.loaderService.showLoader) {
      this.loaderService.isLoading.next(this.requests.length > 0);
    }
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!req.url.includes('notification')) {
      this.requests.push(req);
      if (this.loaderService.showLoader) {
        this.loaderService.show();
        this.loaderService.isLoading.next(true);
      }
    }

    return new Observable((observer: Subscriber<HttpEvent<unknown>>) => {
      const subscription = next.handle(req)
        .subscribe({
          next: (event: HttpResponse<any>) => {
            if (event instanceof HttpResponse) {
              this.removeRequest(req);
              observer.next(event);
            }
          },
          error: (err: string) => {
            this.removeRequest(req);
            observer.error(err);
          },
          complete: () => {
            this.removeRequest(req);
            observer.complete();
          }
        });
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
}
