import { Directive, ElementRef, AfterViewInit, AfterViewChecked, Input } from '@angular/core';
import { CommonApiService } from '../service/common/common-api.service';

@Directive({
  selector: '[appAuFontColor]'
})
export class FontColorDirective implements AfterViewInit, AfterViewChecked {

  /** Input for arx view */
  @Input() arxView: boolean = false;

  /** Input for custom color */
  @Input() customColor: string = '#000000';

  public bgColor!: string;
  public textTheme: boolean = true;
  constructor(private el: ElementRef, private _commonAPI:CommonApiService ) {}

  ngAfterViewInit(){
    this.getTheme();
    this.bgColor = this.findClosestTdBackgroundColor(this.el.nativeElement);
    this.lightOrDark(this.bgColor);
  }

  lightOrDark(color: any) {
    let r, g, b, o;
    // Check the format of the color, HEX or RGB?
    if (color?.match(/^rgb/)) {

      // If HEX --> store the red, green, blue values in separate variables
      color = color?.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

      r = color[1];
      g = color[2];
      b = color[3];
      o = color[4] ?? 1;
    } else {
      color = +(`0x${color?.slice(1).replace(color?.length < 5 && /./g, '$&$&')}`
      );

      r = color >> 16;
      g = (color >> 8) & 255;
      b = color & 255;
    }

    const hsp=Math.round(((parseInt(r) * 299)+(parseInt(g) * 587)+(parseInt(b) * 114)) / 1000);
    if (hsp>153 || o < 0.5 || !this.bgColor) {
      this.el.nativeElement.style.color = (!this.bgColor && !this.textTheme) ? '#a5a5a5' : this.arxView ? '#242424' : this.textTheme ? this.customColor : '#000000';
    } else {
      this.el.nativeElement.style.color = '#fff';
    }
  }

  ngAfterViewChecked(): void {
    if (this.bgColor !== this.el.nativeElement.style.backgroundColor) {
      this.bgColor = this.findClosestTdBackgroundColor(this.el.nativeElement);
      this.lightOrDark(this.bgColor);
    }
  }

  /* To get theme and change color according to theme */
  getTheme() {
    this._commonAPI.changeInThemeBehaviour.subscribe((theme:string)=>{
      this.textTheme = (theme === 'light') ? true : false;
      this.bgColor = this.findClosestTdBackgroundColor(this.el?.nativeElement);
      this.lightOrDark(this.bgColor);
    });
  }

  // To get the background color of the element or nearest td element
  findClosestTdBackgroundColor(element: HTMLElement): string {
    const elementBgColor= element?.style?.backgroundColor;
    if (elementBgColor){
      return elementBgColor;
    }
    const spanElement = document?.querySelector('span[appaufontcolor]');
    if (spanElement && spanElement?.textContent?.trim()?.length > 0) {
      let currentElement = element?.parentElement;
      for (let i = 0; i < 5 && currentElement; i++) {
        if (currentElement?.tagName === 'TD') {
          return currentElement?.style?.backgroundColor;
        }
        currentElement = currentElement?.parentElement;
      }
    }
    return;
  }
}
