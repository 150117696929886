import { Directive, ElementRef, Renderer2, Input, OnInit, HostListener, OnChanges, SimpleChanges } from '@angular/core';
import { LoaderService } from 'src/app/core/service/loader/loader.service';

@Directive({
  selector: '[appDisableButtonApi]',
  standalone: true
})
export class DisableButtonApiDirective implements OnInit, OnChanges {
  // input for flag to check active appDisableButtonApi
  @Input() isDisableApi:boolean =true;
  // input to check if the button is disabled
  @Input()  disabledButton: boolean = false;
  public isLoading:boolean = false;
  constructor(private _loader: LoaderService, private renderer: Renderer2,
    private el: ElementRef) {}

  ngOnInit(){
    this._loader.isLoading.subscribe((res: boolean) => {
      if (res && this.isDisableApi) {
        this.isLoading = true;
        this.renderer.addClass(this.el?.nativeElement, 'ax-disabled');
        this.renderer.setAttribute(this.el?.nativeElement, 'disabled', 'true');
      } else if (this.disabledButton) {
        this.renderer.addClass(this.el?.nativeElement, 'ax-disabled');
        this.renderer.setAttribute(this.el?.nativeElement, 'disabled', 'true');
      } else {
        this.isLoading = false;
        this.renderer.removeAttribute(this.el?.nativeElement, 'disabled');
        this.renderer.removeClass(this.el?.nativeElement, 'ax-disabled');
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.isDisableApi && Object.keys(changes)?.length === 1) {
      if (changes?.disabledButton?.currentValue) {
        this.isLoading = true;
        this.renderer.addClass(this.el?.nativeElement, 'ax-disabled');
        this.renderer.setAttribute(this.el?.nativeElement, 'disabled', 'true');
      } else {
        this.isLoading = false;
        this.renderer.removeAttribute(this.el?.nativeElement, 'disabled');
        this.renderer.removeClass(this.el?.nativeElement, 'ax-disabled');
      }
    }
  }

  @HostListener('click')
  onClick() {
    if (this.isLoading && this.isDisableApi) {
      this.el.nativeElement.disabled = true;
    } else {
      this.el.nativeElement.disabled = false;
    }
  }
}
