import { riskArabicTranslation } from 'src/app/core/constant/language-translation/risk/risk-language';

export const apiEndpoints = {
  observationRisk: 'risk-program/observation/',
  RiskPlaningStages: 'program-schedule-activity-detail/',
  riskProgramAssessmentSchedule: 'risk-program-schedule/',
  riskAssessmentSchedule: 'program-schedule/',
  getRiskscheduleDetails: 'program-schedule-activity-detail/',
  getRiskscheduledynamicHeaders: 'program-schedule-dynamic-fields/',
  riskScheduleSubActivity: 'program-schedule-sub-activity-detail/',
  riskScheduleDynamicFields: 'program-schedule-dynamic-fields/?audit_object_id=',
  riskScheduleDocuments: 'program-schedule-sub-activity/',
  riskUpdate: 'risk-program/',
  riskSchedule: 'program_schedule',
  riskProgramObservationDashboardCharts: 'filter-charts/?request_for=issue_tracker&request_from=app&chart_type=risk_program_observation_dashboard&platform=web',
  RiskProgramDashboardGrid: 'risk-program/dashboard-observation/list/',
  filterApiForRiskProgramObservationDashboard: 'get_filters/?request_for=risk-program-observation&dynamic_fields=true',
  RiskProgramDashboardExportCSV: '/risk-program/risk_program_observation_dashboard_download_csv',
  RiskProgramDashboardExportXLS: '/risk-program/risk_program_observation_dashboard_download_xls',
  RPObservationDashboardData: 'risk-program/observation-dashboard-marices/',
  RPRcmFields: 'risk-program/rcm-list/?request_for=risk_program,observation',
  editRPRcmFields: 'risk-program-rcmfields/edit/?request_for=',
  rpObservationDashboardRcmExport: 'risk-program-observation-matrix/export/',
  historyForRiskUniverseDetail: 'risk-assessment/history-changes/',
  taggedKriControl: 'risk-control/tagged_kri_record_list/',
  taggedKriRisk: 'risk-assessment/tagged_kri_record_list/',
  allRiskGrid: 'risk-program/all-risk-table-dashboard/',
  topRisksGrid: 'risk-program/top-risk-table-dashboard/',
  riskDashboardCharts: 'filter-charts/?request_for=issue_tracker&request_from=app&chart_type=risk-dashboard-panel&platform=web',
  controlHistory: 'risk-assessment-details/history-changes/',
  riskRatingNewValue: 'risk-rating/',
  RiskDashboardExportCSV: '/risk-assessment/risk_dashboard_csv_download',
  RiskDashboardExportXLS: '/risk-assessment/risk_dashboard_xls_download',
  controlScoringModelsLIst: 'control-scoring-model/list?control_rating=true',
  controlDraftList: 'risk-assessment/details/draft-list/',
  filterApiForRiskDashboard: 'get_filters/?request_for=risk-dashboard&dynamic_fields=true',
  riskProgramName: 'get_filters/?request_for=risk-program&dynamic_fields=true&&dynamic_fields=True',
  RiskAppetiteV1: 'risk_category/',
  taggedRisk: 'audit-planning/workingpaper/',
  deleteRiskControl: 'working-paper/',
  ExtraDropdownBS: 'dropdown-list/business-strategy/',
  BusinessStrategyMultipleDropdownV1: 'dropdown-list/business-strategy/multiple_dropdown_v1/',
  BusinessStrategyMultipleDropdownV2: 'dropdown-list/business-strategy/multiple_dropdown_v2/',
  BusinessStrategyMultipleDropdownV3: 'dropdown-list/business-strategy/multiple_dropdown_v3/',
  BusinessStrategyMultipleDropdownV4: 'dropdown-list/business-strategy/multiple_dropdown_v4/',
  BusinessStrategyMultipleDropdownV5: 'dropdown-list/business-strategy/multiple_dropdown_v5/',
  BusinessStrategyExtraDropdownV1: 'dropdown-list/business-strategy/extra_dropdown_v1/',
  BusinessStrategyExtraDropdownV2: 'dropdown-list/business-strategy/extra_dropdown_v2/',
  BusinessStrategyExtraDropdownV3: 'dropdown-list/business-strategy/extra_dropdown_v3/',
  BusinessStrategyExtraDropdownV4: 'dropdown-list/business-strategy/extra_dropdown_v4/',
  BusinessStrategyExtraDropdownV5: 'dropdown-list/business-strategy/extra_dropdown_v5/',
  esgPlanStatus: 'dropdown-list/esg-strategy-plan/esg_business_plan_status',
  strategiesCategory: 'dropdown-list/business-strategy/strategy_category/',
  strategiesStatus: 'dropdown-list/business-strategy/strategy_status/',
  strategiespriority: 'dropdown-list/business-strategy/strategy_priority/',
  adminStrategyDetails: 'dropdown-list/business-strategy/',
  RiskControlMultipleDropdownV1: 'dropdown-list/risk-assessment-v1-details/multiple_dropdown_v1/',
  RiskControlMultipleDropdownV2: 'dropdown-list/risk-assessment-v1-details/multiple_dropdown_v2/',
  RiskControlMultipleDropdownV3: 'dropdown-list/risk-assessment-v1-details/multiple_dropdown_v3/',
  RiskControlMultipleDropdownV4: 'dropdown-list/risk-assessment-v1-details/multiple_dropdown_v4/',
  RiskControlMultipleDropdownV5: 'dropdown-list/risk-assessment-v1-details/multiple_dropdown_v5/',
  RiskControlExtraDropdownV1: 'dropdown-list/risk-assessment-v1-details/extra_dropdown_v1/',
  RiskControlExtraDropdownV2: 'dropdown-list/risk-assessment-v1-details/extra_dropdown_v2/',
  RiskControlExtraDropdownV3: 'dropdown-list/risk-assessment-v1-details/extra_dropdown_v3/',
  RiskControlExtraDropdownV4: 'dropdown-list/risk-assessment-v1-details/extra_dropdown_v4/',
  RiskControlExtraDropdownV5: 'dropdown-list/risk-assessment-v1-details/extra_dropdown_v5/',
  newFileUploader: 'media/',
  newFileUpload: 'audit-plan/media/',
  assessmentRiskControlList: 'risk-assessment/assessment/list/',
  assessmentControlList: 'risk-assessment/details/assessment/list/',
  riskUniverseGridTempList: 'risk-universe-template-select-list',
  riskUniverseGridTableDetail: 'risk-universe-template/',
  selectedActionControls: 'risk-assessment/controls/action/',
  RiskControlActionList: 'all-actions/list',
  riskControlIssueTrackerGraph: 'filter-charts/?request_for=risk_and_control_action&request_from=app&chart_type=risk_issue_tracker&platform=web',
  riskIssueTrackerIssueList: 'risk-universe-all-issue-list/',
  issueTrackerIssueChartsForRisk: 'filter-charts/?request_for=risk_and_control_issue',
  riskIssueDynamicField: 'risk-assessment/issue/dynamic-field/list/',
  filterApiRiskControlAction: 'get_filters/?request_for=risk-issue-tracker-action&dynamic_fields=True',
  riskInRiskList: 'risk/',
  riskControlIssueExport: 'risk-issue-dashboard/export',
  filterApiRiskControlIssue: 'get_filters/?request_for=risk-issue-tracker&dynamic_fields=True',
  riskControlActionExport: 'action-dashboard/export',
  riskIssueTrackerIssueExport: 'risk-program-all-issues/export',
  riskIssueTrackerActionsExport: 'risk-program/action/export',
  riskIssueTrackerRecommendationsExport: 'risk-program/issue-tracker/recomendation/export',
  riskIssueTrackerMRExport: 'risk_management-response/issue-tracker/export',
  riskResidualLikelyImpactDropdown: 'risk-dropdown-list/',
  riskProgramObservationExport: 'risk-program/observation/dashboard/export',
  riskControlExport: 'controls/export/',
  controlAssessmentCriteria: 'control/taxonomy/create_edit',
  controlAssessmentData: 'control/taxonomy/list',
  riskAssessmentData: 'control/taxonomy/list?parent_type=risk',
  riskUniverseExport: 'risk-assessment/export/',
  riskProgramCalender: 'risk-program-plan/calender/',
  riskProgramCalenderHeader: 'risk-program-plan/calender/header/',
  riskProgramCalenderList: 'risk-program-plan/calender/sub-activity/list/',
  residualCheck: 'risk-residual-update-alert/',
  riskConfigurationList: 'admin/control-methods/list/',
  submitConfiguration: 'admin/control-methods/',
  rcmFieldEdit: 'risk-assessment/rcmfields/edit/',
  rcmFieldList: 'risk-assessment/rcmfields/list/',
  AllRCMGrisList: 'risk-assessment/rcm-matrix/table/list/',
  riskUniverseRCMExport: 'risk-assessment/export/rcm',
  riskDashbaordExport: 'risk-dashboard/risk/export/',
  calculationList: 'dropdown-list/risk-assessment/e_calculation_method/',
  industriesDropdownList: 'library/industry-list/',
  approvalControl: 'risk-assessment/details/',
  approvalRisk: 'risk-assessment/header/',
  sumOfWeightage: 'risk-assessment/tagged-control/',
  sumOfWeightageRiskInControl: 'risk-assessment/details/tagged-risk/',
  RiskStateDropDown: 'dropdown-list/risk-assessment/state/',
  riskRegisterPlanList: 'risk/plan/dropdown-list/',
  getBowTiewView: 'risk/',
  riskDirection: 'risk-assessment/tagged-risk-self/',
  controlDirection: 'risk-assessment/tagged-control-self/',
  riskControlDirection: 'risk-assessment/tagged-control-direction/',
  controlRiskDirection: 'risk-assessment/tagged-risk-direction/',
  causeImpactDynamicFields: 'cause-impact/dynamic-fields/',
  genericTagType: 'generic-dropdown/list',
  createGenericDropdown: 'generic-dropdown/create',
  scoringMethods: 'dropdown-list/risk-assessment/scoring_method/',
  controlScoringMethods: 'dropdown-list/risk-assessment-v1-details/scoring_method/',
  controlDynamicScoringMethod: 'dropdown-list/risk-assessment-v1-details/scoring_method/?is_hide=True',
  riskSummaryChart: 'filter-charts/?request_from=app&request_for=risk_assessment&request_for_risk=risk_summary&',
  riskBoardRoomChart: 'filter-charts/?request_from=app&request_for=risk_assessment&request_for_risk=boardroom_risk_dashboard&',
  moduleList: 'list/',
  IssueTrackerDashboardCount: 'risk-program-issue-tracker-dashboard-count',
  rpIssuetrackerObservationList: 'risk-program/issue-tracker/observation/list/',
  dashboardIssueTrackerObservationGraph: 'filter-charts/?request_for=issue_tracker&chart_type=risk_program_observations_dashboard&platform=web',
  dashboardIssueTrackerIssueGraph: 'filter-charts/?request_for=issue_tracker&chart_type=risk_program_issues_dashboard&platform=web',
  dashboardIssueTrackerActionGraph: 'filter-charts/?request_for=issue_tracker&chart_type=risk_program_actions_dashboard&platform=web',
  issueMrTrackerDashboard: 'filter-charts/?request_for=issue_tracker&chart_type=risk_program_mr_dashboard&platform=web',
  riskIssueTrackerObservationExport: 'risk-program/observation/dashboard/export',
  riskDefaultTemplate: 'grid-customdefault-template/list',
  riskDashboard: 'risk-dashboard/risk/charts/?request_for=entity-by-residual-risk-chart,sector-by-residual-risk-chart,regions-by-residual-risk-chart,entity-by-risk-list,sector-by-risk-list,regions-by-risk-list,risk-by-type,risk-by-category,risk-by-control-effectiveness,risk-movement,risk-by-reported-year,risk-by-rating-inherent-risk-rating,risk-by-inherent-likelihood,risk-by-inherent-impact&',
  riskDashboardV2: 'filter-charts/?request_for=risk_dashboard&request_from=app&',
  riskDashboardV3: 'risk-dashboard/risk/charts/?request_for=functions-by-residual-risk-chart,departments-by-residual-risk-chart,section-by-residual-risk-chart,functions-by-risk-list,departments-by-risk-list,sections-by-risk-list&',
  riskDashboardV4: 'risk-dashboard/risk/charts/?request_for=risk-department-level3-by-residual-risk-chart,risk-department-level4-by-residual-risk-chart,department-level-3-by-risk-list,department-level-4-by-risk-list&',
  riskDashboardV5: 'risk-dashboard/risk/charts/?request_for=risk-by-strategy,risk-by-sector,risk-by-risk-mitigation-timeline&',
  riskDashboardV6: 'risk-dashboard/risk/charts/?request_for=risk-by-action-owner,risk-by-status,risk-by-entity&',
  riskDashboardV7: 'risk-dashboard/risk/charts/?request_for=risk-by-rating-residual-risk-rating,risk-by-residual-likelihood,risk-by-residual-impact&',
  gridCustomTemplate: 'grid-custom-template/',
  gridTempList: 'grid-custom-template-select-list',
  dashboardConfig: 'audit-reporting/issue-tracker/configuration/list',
  filterApiRiskProgram: 'get_filters/?request_for=risk-program',
  riskCategoryList: 'risk-category/admin/list/',
  categoryList: 'risk-category/list/',
  dropdownList: 'dropdown-list/risk-assessment/',
  implementationStatus: 'dropdown-list/risk-assessment-v1-details/implementation_status/',
  defaultCriteriaList: 'default-criteria/list/',
  defaultCriteriaEdit: 'default-criteria/edit/',
  controlAction: 'general/action/',
  actionsCreate: 'risk-assessment/action/',
  riskControlModal: 'risk-assessment/controls/',
  controlRiskModal: 'risk-assessment/details/tagged-risk/'
};

export const issueTrackerQueryParams = {
  multi_values: true,
  action_status: '',
  action_priority: '',
  action_department: '',
  issue_tracker_action_status: '',
  department: '',
  owner_action: '',
  owner: '',
  record_owner: '',
  plan: '',
  department_level1: '',
  department_level2: '',
  risk_program_name: '',
  compliance_action_status: '',
  compliance_action_status_all: '',
  compliance_action_department: '',
  compliance_action_priority: ''
};

export const tableConfig = {
  tableLimitOption: [5, 10, 25, 50, 100],
  pager: 10,
  tableLimit: 10
};

export const strategyPlanQueryParam = {
  business_strategy_by_status: '',
  multi_values: false
};

export const historyQueryParams = {
  limit: tableConfig.pager,
  offset: 0
};

export const auditPlaningQueryParams = {
  requested_by: '',
  method_of_audit: '',
  audit_areas: '',
  areas_audit: '',
  rating: '',
  status_of_audit: '',
  audit_manager: '',
  plan: '',
  audit_status: '',
  plan_status: '',
  manager: '',
  mega_process: '',
  multi_values: false,
  audit_date: '',
  department_level1: '',
  user_department_level1: '',
  program_plan_status: ''
};

export const riskUniverseQueryParams = {
  limit: tableConfig.pager,
  offset: 0,
  cursor: '',
  risk_category: '',
  residual_impact: '',
  inherent_impact: '',
  risk_owner: '',
  residual_likelihood: '',
  inherent_likelihood: '',
  risk_manager: '',
  risk_identifier: '',
  risk_velocity: '',
  multi_values: false,
  q: '',
  strategy_category: '',
  risk_plan: '',
  status: '',
  risk_department: '',
  mega_process_v1: '',
  major_process_v1: '',
  top_risk: '',
  risk_residual_rating: '',
  risk_inherent_rating: '',
  inherent_rating: '',
  residual_rating: '',
  erm_identifier: '',
  manager: '',
  erm_type: '',
  owner: '',
  mega_process: '',
  major_process: '',
  inherent: '',
  residual: '',
  risk_department_level1: '',
  risk_department_level2: '',
  mega_process_v2: '',
  major_process_v2: '',
  fiscal_year: '',
  risk_type: '',
  target_residual_risk: '',
  risk_within_appetite: '',
  risk_mitigation_required: '',
  risk_appetite: '',
  key_risk: ''
};

export const riskProgramQueryParams = {
  risk_program_requested_by: '',
  method_of_risk_program: '',
  audit_areas: '',
  areas_audit: '',
  rating: '',
  status_of_risk_program: '',
  audit_manager: '',
  plan: '',
  audit_status: '',
  plan_status: '',
  manager: '',
  mega_process: '',
  multi_values: false,
  program_date: ''
};

export const commonEvent = {
  edit: 'Edit',
  delete: 'Delete',
  update: 'Update',
  view: 'View',
  sendReminder: 'sendReminder',
  markAsException: 'markAsException',
  markAsFalsePositive: 'markAsFalsePositive',
  markAsOpen: 'markAsOpen',
  changePassword: 'changePassword',
  araModule: 'aramodule',
  dataSource: 'dataSource',
  dataFlush: 'dataFlush',
  dataProcess: 'dataProcess',
  jdoc: 'jdoc',
  id: 'id',
  status: 'status',
  objectId: 'objectId',
  subSectionObjectId: 'subSectionObjectId',
  back: 'back',
  download: 'download',
  cancel: 'cancel',
  import: 'import',
  info: 'Info',
  schedule: 'Schedule',
  remove: 'Remove',
  deleteDocument: 'deleteDocument',
  viewIssue: 'viewIssue',
  editactivity: 'editactivity',
  browseactivity: 'browseactivity',
  digitalReport: 'digitalReport',
  browse: 'browse',
  browsePlan: 'browsePlan',
  create: 'Create',
  withdraw: 'withdraw',
  loadMore: 'loadMore',
  deleteActivity: 'deleteActivity',
  viewPublicUrl: 'viewPublicUrl',
  sendEmail: 'sendEmail',
  generate: 'generate',
  upload: 'Upload'
};

export const dashboardIssueTrackerConstant = {
  actionStatus: 'actionStatus',
  actionType: 'actionType',
  actionsDue: 'actionsDue',
  actionOwner: 'actionOwner',
  dateFilter: 'dateFilter',
  dateRange: 'dateRange',
  group: 'group',
  allActions: 'all actions',
  all: 'all',
  DateFormat: 'DD MMM, YYYY',
  DateFormatFilter: 'MMM yy',
  limit: 'limit',
  offset: 'offset',
  category: 'category',
  others: 'Others',
  label: 'label',
  color: 'color',
  actionStatusParams: 'action_status',
  dateFilterParams: 'date_filter',
  allCaps: 'All',
  tbd: 'TBD',
  notClassified: 'Not Classified'
};

export const dropdownEvent = {
  sort: 'sort',
  filter: 'filter',
  hide: 'hide',
  edit: 'edit',
  delete: 'delete'
};

export const queryParamForDashboardSquareCards = {
  business_strategy_by_status: 'business_strategy_by_status',
  strategy_category: 'strategy_category'
};

export const renderType = {
  downloadRender: 'Download Render',
  date: 'Date',
  name: 'Name',
  class: 'Class',
  multipleRender: 'Multiple Render',
  icon: 'Icon',
  countText: 'Count Text',
  description: 'Description',
  multipleNameRender: 'Multiple Name Render',
  noView: 'noView',
  longText: 'Long Text',
  shortNumber: 'shortNumber',
  status: 'status',
  badge: 'badge',
  multiBadge: 'multiBadge',
  axLongText: 'longText',
  arxStatusBadge: 'arxStatusBadge',
  richText: 'richText',
  html: 'html'
};

export const assessmentErmConst = {
  category: 'category',
  risk: 'risk'
};

export const categoryConstants = {
  categoryName: 'category_name',
  description: 'description',
  assetColor: 'asset_color',
  selectedColor: 'selected_color'
};

export const doughnutConstant = {
  risks: 'Risks',
  pending: 'pending',
  active: 'active',
  multipleRender: 'Multiple Render',
  color: '#e1e1e1',
  risksByResidualImpact: 'Risks By Residual Impact',
  businessStrategyByStatus: 'Business Strategy by Status',
  audit: 'Audits',
  riskPrograms: 'Risk Programs'
};

export const riskcontrolConstant = 'risk-control';

export const tableSortFilter = {
  option: [
    {
      icon: 'au-icon au-icon--sort-za',
      class: '',
      label: 'Sort',
      event: dropdownEvent.sort
    },
    {
      icon: 'au-icon au-icon--filter-mob',
      class: '',
      label: 'Filter',
      event: dropdownEvent.filter
    }
  ]
};

export const moduleName = {
  risk: 'RiskAssessment',
  control: 'RiskAssessmentDetails',
  BusinessStrategy: 'BusinessStrategy',
  BusinessPlan: 'BusinessPlan',
  RiskRegister: 'RiskPlan',
  ERMRegister: 'ErmPlan'
};

export const recordDataSetConstant = {
  allRecords: 'All Records',
  myRecords: 'My Records',
  allRecord: 'all_records',
  myRecord: 'my_records',
  issueTrackerIssues: 'issueTrackerIssues',
  owner: 'owner',
  recommendationOwner: 'recommendation_owner',
  createdByMe: 'Created By Me',
  createdBy: 'created_by_me',
  actionOwner: 'owner_action',
  strategyOwner: 'strategy_owner',
  responseOwner: 'response_owner',
  asOnDate: 'As on Date'
};

export const issueTrackerActionRecords = [
  { label: 'All Records' },
  { label: 'My Records' },
  { label: 'Created By Me' }
];

export const businessStrategiesQueryParams = {
  q: '',
  limit: tableConfig.pager,
  offset: 0,
  strategy_category: '',
  plan: '',
  business_plan: '',
  strategy_owner: '',
  multi_values: false,
  strategy_status: '',
  department_level: '',
  department_level1: '',
  objective_level: '',
  objective_level1: '',
  mega_process: '',
  major_process: ''
};

export const userRole = {
  professional: 'PROFESSIONAL',
  contributor: 'CONTRIBUTOR',
  viewer: 'VIEWER',
  adminProfessional: 'ADMIN PROFESSIONAL'
};

export const dependencyList = {
  activities: 'Activities',
  issues: 'Issues',
  relatedIssues: 'Related Issues',
  updateDocumentEvent: 'updateDocumentEvent',
  updateActionEvent: 'updateActionEvent',
  actions: 'Actions',
  documents: 'Documents',
  updateRecommendationEvent: 'updateRecommendationEvent',
  updateAssessmentEvent: 'updateAssessmentEvent',
  audit: 'audit',
  recommendations: 'Recommendations',
  controls: 'Controls',
  risks: 'Risks',
  assessments: 'Assessments',
  analyticAutomation: 'Analytics Automation',
  history: 'History',
  observation: 'observation',
  observationTitle: 'Observation',
  updateControlEvent: 'updateControlEvent',
  auditObservation: 'Audit Observation',
  updateAuditEvent: 'updateAuditEvent',
  riskAssessment: 'risk_assessment',
  control: 'control',
  risk: 'risk',
  issue: 'issue',
  controlIssue: 'control-issue',
  observationActionDocument: 'risk-program-observation-action',
  issueTitle: 'Issue',
  riskAssessmentDetails: 'risk_assessment_details',
  observationRecommendation: 'observation_recommendation',
  auditOverview: 'auditOverview',
  createIssue: 'create_issue',
  observationAction: 'observation_action',
  controlAction: 'control-action',
  actionDocument: 'actionDocument',
  riskMovement: 'Risk Movements',
  controlMovement: 'Control Movements',
  updateIssueEvent: 'updateIssueEvent',
  recommendation: 'recommendation',
  auditRecommendation: 'Audit Recommendations',
  RiskRecommendation: 'auditRecommendation',
  auditAction: 'audit Actions',
  issueActions: 'Issue Actions',
  auditActionTitle: 'Audit Actions',
  incident: 'incident',
  workingPaper: 'working paper',
  businessStrategy: 'business_strategy',
  auditProgramActivity: 'working_paper',
  activity: 'activity',
  policyRequest: 'policy_request',
  updateAuditProgramEvent: 'updateAuditProgramEvent',
  complianceRequest: 'compliance_request',
  entityGridUpdateEvent: 'entityGridUpdateEvent',
  regionGridUpdateEvent: 'regionGridUpdateEvent',
  departmentGridUpdateEvent: 'departmentGridUpdateEvent',
  userGroupUpdateEvent: 'userGroupUpdateEvent',
  userUpdateEvent: 'userUpdateEvent',
  riskDataUpdateEvent: 'riskDataUpdateEvent',
  controlDataUpdateEvent: 'controlDataUpdateEvent',
  auditPlanningDataUpdateEvent: 'auditPlanningDataUpdateEvent',
  auditReportingDataUpdateEvent: 'auditReportingDataUpdateEvent',
  analyticsDataUpdateEvent: 'analyticsDataUpdateEvent',
  complianceDataUpdateEvent: 'complianceDataUpdateEvent',
  incidentDataUpdateEvent: 'incidentDataUpdateEvent',
  bcmDataUpdateEvent: 'bcmDataUpdateEvent',
  policyDataUpdateEvent: 'policyDataUpdateEvent',
  bugetDataUpdateEvent: 'bugetDataUpdateEvent',
  policy: 'Policy',
  compliance: 'Compliance',
  updatePolicyEvent: 'updatePolicyEvent',
  updateComplianceEvent: 'updateComplianceEvent',
  businessProcess: 'Business Process',
  updateDependenciesRiskEvent: 'updateDependenciesRiskEvent',
  updateDependenciesControlEvent: 'updateDependenciesControlEvent',
  updateDependenciesProcessEvent: 'updateDependenciesProcessEvent',
  updateDependenciesPolicyEvent: 'updateDependenciesPolicyEvent',
  annexure: 'Annexure',
  updateAnnexure: 'updateAnnexure',
  managementResponse: 'Management Responses',
  updateResponse: 'updateResponse',
  Response: 'management_response',
  riskIssue: 'Risk Issue',
  observationIssueDocument: 'risk-program-observation-issue',
  complianceRecommendation: 'compliance_recommendation',
  recommendationHistory: 'compliance_observation_recommendation',
  designationGridUpdateEvent: 'designationGridUpdateEvent',
  updateRiskEvent: 'updateRiskEvent',
  riskPrograms: 'Risk Programs',
  auditPrograms: 'Audit Programs',
  sections: 'Sections',
  department: 'Departments',
  network: 'Network',
  riskProgram: 'risk_program',
  riskProgramDependancy: 'riskProgramDependancy',
  workingPaperTitle: 'Working Paper',
  updateWorkingPaperEvent: 'updateWorkingPaperEvent',
  observations: 'observations',
  associatedRisk: 'Risks',
  riskMap: 'Risk Map',
  riskErm: 'riskErm',
  enterprise_risk: 'enterprise_risk',
  riskSchedule: 'riskSchedule',
  businessStrategyV1: 'business-strategy',
  enterpriseRiskV1: 'enterprise-risk',
  riskWorkingPaper: 'risk-program-working-paper-risk',
  controlWorkingPaper: 'risk-program-working-paper-control',
  manualWorkingPaper: 'risk-program-working-paper',
  esg: 'esg'
};

export const dependencyLists = {
  auditRetalClient: 'Audit Observations',
  kri: 'KRI'
};

export const actionSlug = {
  risk: 'risk-assessment',
  control: 'risk-assessment-details'
};

export const tableFilterOnly = {
  option: [
    {
      icon: 'au-icon au-icon--filter-mob',
      class: '',
      label: 'Filter',
      event: dropdownEvent.filter
    }
  ]
};

export const tableSortOnly = {
  option: [
    {
      icon: 'au-icon au-icon--sort-za',
      class: '',
      label: 'Sort',
      event: dropdownEvent.sort
    }
  ]
};

export const createDropdownFields = {
  riskType: 'Risk type',
  riskIdentifier: 'Risk Identifier',
  riskCategory: 'Risk Category',
  riskSubCategory: 'Subcategory',
  status: 'Status',
  controlType: 'Control Type',
  controlAutomation: 'Control Automation',
  controlRating: 'Control Rating',
  controlFrequency: 'Control Frequency',
  process: 'Process',
  subProcess: 'Sub Process',
  keyControl: 'Key Control',
  easeofImplementation: 'Ease of implementation',
  easeofMaintain: 'Ease of maintain',
  costtoImplementMaintain: 'Cost to implement maintain',
  reducesLikelihood: 'Reduces Likelihood',
  reducesImpact: 'Reduces Impact',
  controlDesignEvaluation: 'Control design evaluation',
  commentsActionStatus: 'Action Status',
  incidentType: 'Incident Type',
  areasAudit: 'Audit Areas',
  approvers: 'Approver',
  reviewer: 'Reviewer',
  requestedBy: 'Requested by',
  auditMethod: 'Source of Audit',
  riskEntity: 'Risk entity',
  megaProcess: 'Mega process',
  majorProcess: 'Major process',
  treatment: 'Treatment',
  category: 'Category',
  strategyStatus: 'Strategy status',
  complianceCategory: 'Compliance category',
  complianceOccurance: 'Compliance occurance',
  incident_type: 'Incident type',
  location: 'Location',
  assetType: 'Asset type',
  criticality: 'Criticality',
  expenseCategory: 'ExpenseCategory',
  strategyCategory: 'Strategy category',
  businessProcessStatus: 'Business process status',
  assetCriticalityRating: 'Asset criticality rating',
  entity: 'entity',
  businessStrategy: 'businessStrategy',
  riskManger: 'riskManager',
  riskOwner: 'riskOwner',
  department: 'department',
  approver: 'approver',
  businessProcessDuration: 'business_process_duration',
  projectType: 'projectType',
  project: 'project',
  task: 'task',
  expenceFor: 'expenceFor',
  audit: 'audit',
  assetManagement: 'asset-management',
  businessProcess: 'business-process',
  compliance: 'compliance',
  risk: 'risk',
  analytics: 'audit-analytics',
  control: 'control',
  surveyType: 'Survey type',
  surveyCategory: 'Survey category',
  survey: 'survey',
  policyType: 'policy-type',
  policyOwner: 'policyOwner',
  policyTeam: 'policyTeam',
  policyReviewer: 'policyReviewer',
  policyApprover: 'policyApprover',
  policyUser: 'policyUser',
  stakeholder: 'stakeholder',
  policy: 'policy',
  scenarioCriticalityRating: 'Scenario Criticality Rating',
  calculatedField: 'Calculated Field',
  exceptionCriteria: 'Exception Criteria',
  analyticsManager: 'Analytics Manager',
  analyticsEffectiveness: 'Analytics Effectiveness',
  auditee: 'Auditee',
  analyticsProcess: 'Analytics Process',
  analyticsSubProcess: 'Analytics Sub Process',
  actionPriority: 'Action Priority',
  actionStatus: 'Action Status',
  tagAssessment: 'Tag Assessment',
  incidentRating: 'Incident Rating',
  assignee: 'Assignee',
  actionEntity: 'action Entity',
  policyRating: 'policyRating',
  policyStatus: 'policyStatus',
  policyRequest: 'policyRequest',
  region: 'region',
  scoring: 'scoring',
  issues: 'issues',
  register: 'RiskRegister',
  incidentStatus: 'incidentStatus',
  policyExperts: 'policyExperts',
  policyLegal: 'policyLegal',
  policyArea: 'policyArea',
  previousVersions: 'previousVersions',
  strategyPlan: 'strategyPlan',
  complianceType: 'complianceType',
  complianceArea: 'complianceArea',
  complianceStatus: 'complianceStatus',
  complianceRequest: 'complianceRequest',
  complianceRating: 'complianceRating',
  complianceOwner: 'complianceOwner',
  complianceTeam: 'complianceTeam',
  complianceReviewer: 'complianceReviewer',
  complianceApprover: 'complianceApprover',
  complianceUser: 'complianceUser',
  complianceExperts: 'complianceExperts',
  complianceLegal: 'complianceLegal',
  complianceAuditOccurance: 'complianceOccurance',
  complianceAudit: 'complianceaudit',
  recommendationStatus: 'recommendationStatus',
  recommendationPriority: 'recommendationPriority',
  fraudRelated: 'Fraud Related',
  decisionMaker: 'Decision Maker',
  riskMitigationStrategyResponsibility: 'Risk Mitigation Strategy Responsibility V1',
  riskMitigationStrategyEffectiveness: 'Risk Mitigation Strategy Effectiveness',
  activityAssignee: 'Activity Assignee',
  activityStakeholder: 'Activity Stakeholder',
  activityStatus: 'Activity Status',
  observationDepartment: 'Observation Department',
  observationAuditArea: 'Observation Audit Area',
  observationRating: 'Observation Rating',
  observationManagementStatus: 'Observation Management Status',
  observationImplementationOwner: 'Observation Implementation Owner',
  observationStakeholder: 'Observation Stakeholder',
  issue: 'issue',
  billable: 'billable',
  auditPlan: 'Audit Plan',
  sector: 'Sector',
  ermRisk: 'ermrisk',
  ermType: 'Erm type',
  ermIdentifier: 'Erm identifier',
  keyRisk: 'Key Risk',
  topRisk: 'Top Risk',
  riskSource: 'Risk Source',
  riskDepartment: 'Department',
  riskAppetite: 'Risk Appetite',
  riskWithinAppetite: 'Risk Within Appetite',
  targetResidualRisk: 'Target Residual Risk',
  riskMigrationRequired: 'Risk Mitigation Required',
  additionalDepartment: 'Additional Department',
  riskExtraDropdown: 'Risk Extra Dropdown',
  riskAdditionalDropdown: 'Risk Additional Dropdown',
  riskNewDropdown: 'Risk New Dropdown',
  riskAuxiliaryDropdown: 'Risk Auxiliary Dropdown',
  riskSubsidiaryDropdown: 'Risk Subsidiary Dropdown',
  extraDropdownRisk: 'Extra Dropdown Risk',
  additionalDropdownRisk: 'Additional Dropdown Risk',
  additionalMajorProcess: 'Additional Major Process',
  newDropdown: 'New Dropdown Risk',
  duration: 'duration',
  additionalDropDown1: 'additionalDropDown1',
  additionalDropDown2: 'additionalDropDown2',
  additionalDropDown3: 'additionalDropDown3',
  additionalDropDown4: 'additionalDropDown4',
  additionalMultiDropDown1: 'additionalMultiDropDown1',
  additionalMultiDropDown2: 'additionalMultiDropDown2',
  additionalMultiDropDown3: 'additionalMultiDropDown3',
  additionalMultiDropDown4: 'additionalMultiDropDown4',
  additionalDesignation: 'additionalDesignation',
  requestType: 'Request Type',
  departmentRequestedBy: 'Department Requested By',
  priority: 'Priority',
  toBeWrittenBy: 'To Be Written By',
  oversight: 'Oversight',
  statusComplianceRequest: 'Status Compliance Request',
  departments: 'Department',
  level1: 'level1',
  level2: 'level2',
  level3: 'level3',
  level4: 'level4',
  departmentLevel1: 'departmentLevel1',
  departmentLevel2: 'departmentLevel2',
  departmentLevel3: 'departmentLevel3',
  departmentLevel4: 'departmentLevel4',
  type: 'type',
  observationUser: 'Observation User',
  observationDesignation: 'ObservationDesignation',
  observationDropdownV1: 'ObservationDropdownV1',
  observationDropdownV2: 'ObservationDropdownV2',
  observationDropdownV3: 'ObservationDropdownV3',
  observationDropdownV4: 'ObservationDropdownV4',
  observationMultiDropdownV1: 'ObservationMultiDropdownV1',
  observationMultiDropdownV2: 'ObservationMultiDropdownV2',
  observationMultiDropdownV3: 'ObservationMultiDropdownV3',
  observationMultiDropdownV4: 'ObservationMultiDropdownV4',
  fiscalYear: 'Fiscal Year',
  manager: 'Manager',
  parent: 'Parent',
  objectiveLevel1: 'objectiveLevel1',
  objectiveLevel2: 'objectiveLevel2',
  objectiveLevel3: 'objectiveLevel3',
  objectiveLevel4: 'objectiveLevel4',
  objectiveLevel5: 'objectiveLevel5',
  objectiveLevel6: 'objectiveLevel6',
  objectiveParent1: 'objectiveParent1',
  objectiveParent2: 'objectiveParent2',
  objectiveParent3: 'objectiveParent3',
  objectiveParent4: 'objectiveParent4',
  objectiveParent5: 'objectiveParent5',
  dropdownv1: 'dropdownv1',
  dropdownv2: 'dropdownv2',
  dropdownv3: 'dropdownv3',
  dropdownv4: 'dropdownv4',
  multidropdownv1: 'multidropdownv1',
  multidropdownv2: 'multidropdownv2',
  multidropdownv3: 'multidropdownv3',
  multidropdownv4: 'multidropdownv4',
  additionalDropDownV1: 'additionalDropDownV1',
  additionalDropDownV2: 'additionalDropDownV2',
  additionalDropDownV3: 'additionalDropDownV3',
  additionalDropDownV4: 'additionalDropDownV4',
  additionalMultiDropDownV1: 'additionalMultiDropDownV1',
  additionalMultiDropDownV2: 'additionalMultiDropDownV2',
  additionalMultiDropDownV3: 'additionalMultiDropDownV3',
  additionalMultiDropDownV4: 'additionalMultiDropDownV4',
  riskProgram: 'risk_program',
  riskProgramRequestedBy: 'risk_program_requested_by',
  methodofRiskProgram: 'method_of_risk_program',
  departmentRequestBy: 'department_requested_by',
  toBeWrittenByDropdown: 'toBeWrittenByDropdown',
  user: 'user',
  statusPolicyRequest: 'Status Policy Request',
  userDepartments: 'userDepartments',
  userlevel2: 'userlevel2',
  userlevel3: 'userlevel3',
  userlevel4: 'userlevel4',
  question: 'question',
  assessmentPlan: 'assessmentPlan',
  rcm: 'rcm'
};

export const dynamicConfigForAuditObservationActionIssues = {
  'field_name': 'issue',
  'default_field_name': {
    'ar': 'Issue',
    'en-us': 'Issue'
  },
  'language': {
    'ar': 'Issue',
    'en-us': 'Issue'
  },
  'is_mandatory': false,
  'is_shown': true,
  'description_required': false,
  'description': null,
  'configuration': {
    'show': true,
    'mandatory': false,
    'description': false
  },
  'is_editable': false,
  'permission_status': true
};

export const tabConstants = {
  notSupporting: 'Selected file format is not supported',
  fileExceeds: 'Selected file size exceeds the limit, file should be less than 100mb',
  reviewer: 'reviewer',
  file: 'file',
  yes: 'Yes',
  no: 'No',
  risk: 'risk',
  control: 'control',
  riskUniverse: 'riskUniverse',
  riskControl: 'riskControl',
  riskAssessment: 'risk_assessment',
  riskAssessmentDetails: 'risk-assessment-details',
  araModule: 'aramodule',
  incidentDetails: 'incident_reporting',
  auditRecommendation: 'auditRecommendation',
  auditProgramActivity: 'audit_program_activity',
  process: 'Process',
  auditObservations: 'auditObservations',
  auditActivity: 'auditActivity',
  auditPlanning: 'auditPlanning',
  businessStrategy: 'business_strategy',
  auditsDependency: 'auditsDependency',
  observation: 'observation',
  recommendation: 'auditRecommendation',
  issue: 'issue',
  actionDocument: 'actionDocument',
  complianceObervation: 'complianceObservationDependency',
  complianceRecommendation: 'complianceObservationRecommendation',
  riskIssue: 'Risk Issue',
  riskProgram: 'risk_program',
  riskProgramDependancy: 'riskProgramDependancy',
  associatedRisk: 'associatedRisk',
  programSchedule: 'program_schedule',
  auditReporting: 'audit_reporting',
  riskAssessmentDetailsV1: 'risk-assessment-v1-details',
  riskAssessmentV1: 'risk-assessment',
  programScheduleV1: 'risk-program-schedule',
  programWorkingPaper: 'risk-program-working-paper',
  programActvity: 'audit-program-activity',
  programWorkingPaperActivity: 'risk-program-wp-activity',
  programObservation: 'risk-program-observation',
  riskAction: 'risk-action',
  riskIssues: 'risk-issue',
  observationIssueDocument: 'risk-program-observation-issue',
  controlIssue: 'control-issue',
  observationActionDocument: 'risk-program-observation-action',
  programRecommendation: 'risk_program_observation_recommendation',
  managementResponse: 'risk-program-mr'
};

export const permissionTable = {
  'Edit': true,
  'Delete': true,
  'Upload Document': true
};

export const idConstants = {
  auditId: 'audit_id',
  programId: 'program_id',
  issueId: 'issue_id',
  id: 'id',
  observationId: 'observation_id',
  identifier: 'identifier',
  slug: 'slug',
  results: 'results',
  planId: 'plan_id',
  actionDocument: 'actionDocument',
  managementResponse: 'management_response',
  manual: 'Manual',
  responseId: 'response_id'
};

export const riskIssueQueryParams = {
  limit: tableConfig.pager,
  offset: 0
};

export const auditIssueConstant = {
  auditIssue: 'auditIssue',
  issueTrackerIssue: 'issueTrackerIssue',
  auditPlanning: 'Audit Planning',
  riskAssessment: 'Risk Assessment',
  riskProgramWorkingPaper: 'riskProgramWorkingPaper',
  riskProgram: 'Risk Program'
};

export const assessmentList = {
  createdDate: '_created_date',
  createdUser: '_created_user'
};

export const assessmentTakeFormDataConstants = {
  requestFor: 'request_for',
  objectId: 'object_id',
  submit: 'submit'
};

export const auditApprovalConstants = {
  pending: 'pending',
  rejected: 'rejected',
  approved: 'Approved',
  delete: 'delete',
  edit: 'edit',
  create: 'create',
  reviewer: 'Reviewer',
  approver: 'Approver',
  reviewed: 'Reviewed',
  review: 'Review',
  open: 'Open',
  completed: 'Completed',
  removed: 'removed',
  complete: 'completed',
  withdrew: 'withdrew'
};

export const riskDraftTable = {
  risk: 'risk',
  draft: 'draft',
  takeAssessment: 'Take Assessment',
  viewDetails: 'View Details',
  questionnaire: 'questionnaire',
  assessments: 'assessments',
  assessmentPlan: 'assessmentPlan',
  inActive: 'inActive'
};

export const riskRegister = {
  delete: 'delete',
  browsePlan: 'browsePlan',
  edit: 'edit',
  totalRegister: 'total_register',
  risk: 'Risks',
  true: 'True',
  false: 'False'
};

export const languageSelected = {
  language: 'en-us'
};

export const inherentChartConstant = {
  inherentImpact: 'Inherent Impact',
  inherentLikelihood: 'Inherent Likelihood',
  inherentRating: 'Inherent Rating'
};

export const issueActionData = {
  actionDocument: 'actionDocument',
  id: 'id',
  auditId: 'audit_id',
  riskId: 'risk_id',
  controlId: 'control_id',
  issueId: 'issue_id',
  observationId: 'observation_id',
  auditReporting: 'audit-reporting',
  actionStatus: 'actionstatus/',
  commentText: 'comment_text',
  file: 'file',
  parent: 'parent',
  replies: 'replies',
  userDetails: 'userDetails',
  result: 'result',
  approvalId: 'approval_id',
  riskIssue: 'Risk Issue'
};

export const renderTypes = {
  downloadRender: 'Download Render',
  date: 'Date',
  name: 'Name',
  class: 'Class',
  multipleRender: 'Multiple Render',
  icon: 'Icon',
  countText: 'Count Text',
  countTextLink: 'Count Text Link',
  description: 'Description',
  multipleNameRender: 'Multiple Name Render',
  noView: 'noView',
  longText: 'Long Text',
  shortNumber: 'shortNumber',
  fullText: 'fullText',
  auSelect: 'AuSelect',
  approval: 'approval',
  richText: 'richText',
  status: 'status',
  badge: 'badge',
  multiBadge: 'multiBadge'
};

export const residualChartConstant = {
  residualImpact: 'Residual Impact',
  residualLikelihood: 'Residual Likelihood',
  riskResidualRating: 'Risk Residual Rating'
};

export const riskGridConstant = {
  import: 'import',
  export: 'export',
  download: 'download',
  importFromLibrary: 'importFromLibrary',
  genericImport: 'genericImport'
};

export const dashboardIssueTrackerTableConstant = {
  pointer: 'au-table-option',
  custom: 'custom',
  messageIcon: 'au-icon au-icon--mail',
  icon: 'icon',
  tooltip: 'tooltip',
  iconItem: 'iconitem',
  sendReminder: 'send reminder',
  action: 'Action',
  tableId: 'table_id',
  route: 'route',
  priority: 'Priority',
  status: 'Status',
  age: 'age',
  text: 'text',
  owner: 'Owner',
  dueDate: 'Due Date',
  title: 'title',
  risk: 'Risk',
  control: 'Control',
  audit: 'Audit',
  issue: 'issue',
  label: 'label',
  riskProgram: 'Risk Program'
};

export const list = {
  risk: 'risk-assessment',
  control: 'risk-assessment-v1-details',
  audit: 'audit-planning',
  riskName: 'risk_name',
  controlName: 'control_name',
  auditName: 'audit_name',
  policy: 'policy-management',
  policyName: 'policy_name',
  compliance: 'compliance-audit',
  complianceName: 'compliance_title',
  bcm: 'business-process',
  processName: 'process_title',
  riskData: 'Risk',
  controlData: 'Control',
  auditData: 'Audit',
  policyData: 'Policy',
  complianceData: 'Compliance',
  process: 'Business Continuity',
  admin: 'Admin'
};

export const rightSidebarNetworkConstants = {
  userTemplate: 'user-template'
};

export const colourForm = {
  color: ['#115caa', '#8559b4', '#cf4fa3', '#ff537c', '#ff764b', '#ffa600', '#003B6D', '#4AAFFF', '#66bb66', '#9ecc6c', '#d0dc7a', '#ffec8f', '#f9b859', '#f27e3a', '#e53535', '#0878bc', '#2a94cb', '#4fb0d7', '#77cae3', '#a0e4f0', '#cafeff']
};

export const dropdownConstant = {
  color: '#E9F0F7',
  auditArea: 'audit_areas',
  areasAudit: 'areas_audit'
};

export const riskActionWidgetFormConstants = {
  control_tag: 'control_tag',
  control_id: 'control_id',
  control_rating: 'control_rating',
  risk: 'risk'
};

export const ermAssessmentQueryParams = {
  division: '',
  multi_values: false,
  assessment_status: ''
};

export const ermAssessmentConstants = {
  likelihoodRating: 'likelihood_rating',
  overallRating: 'overall_rating',
  impactRating: 'impact_rating'
};

export const tableFilter = {
  option: [
    {
      icon: 'au-icon au-icon--filter-mob',
      class: '',
      label: 'Filter',
      event: dropdownEvent.filter
    }
  ]
};

export const riskDetailsConstant = {
  riskManagement: 'Risk Management',
  strategyOverview: 'Strategy Overview',
  observationrecommendation: 'observationrecommendation',
  issue: 'issue',
  observation: 'observation',
  auditActivity: 'auditprogramactivity',
  audit: 'auditplan&request_for_auditplandetail=auditplandetail',
  riskControl: 'Risk Control'
};

export const riskControlQueryParams = {
  limit: tableConfig.pager,
  offset: 0,
  control_type: '',
  control_rating: '',
  control_frequency: '',
  control_owner: '',
  control_manager: '',
  control_automation: '',
  multi_values: false,
  q: '',
  mega_process: '',
  major_process: '',
  department_level1: ''
};

export const riskControlTab = {
  controlType: 'control_type'
};

export const strategyPlanDetailQueryParam = {
  strategy_category: '',
  order_by_field: '',
  table_filters: ''
};

export const searchFilterkeyRisk = ['field_name', 'dynamic_data'];

export const matrixNoData =[
  {
    'row': 0,
    'column': 0,
    'impact': 'i1',
    'likelihood': 'l1',
    'count': '',
    'color': '#00A94E'
  },
  {
    'row': 0,
    'column': 1,
    'impact': 'i1',
    'likelihood': 'l2',
    'count': '',
    'color': '#00A94E'
  },
  {
    'row': 0,
    'column': 2,
    'impact': 'i1',
    'likelihood': 'l3',
    'count': '',
    'color': '#F7F700'
  },
  {
    'row': 0,
    'column': 3,
    'impact': 'i1',
    'likelihood': 'l4',
    'count': '',
    'color': '#F7F700'
  },
  {
    'row': 1,
    'column': 0,
    'impact': 'i2',
    'likelihood': 'l1',
    'count': '',
    'color': '#00A94E'
  },
  {
    'row': 1,
    'column': 1,
    'impact': 'i2',
    'likelihood': 'l2',
    'count': '',
    'color': '#F7F700'
  },
  {
    'row': 1,
    'column': 2,
    'impact': 'i2',
    'likelihood': 'l3',
    'count': '',
    'color': '#F7B900'
  },
  {
    'row': 1,
    'column': 3,
    'impact': 'i2',
    'likelihood': 'l4',
    'count': '',
    'color': '#F7B900'
  },
  {
    'row': 2,
    'column': 0,
    'impact': 'i3',
    'likelihood': 'l1',
    'count': '',
    'color': '#F7F700'
  },
  {
    'row': 2,
    'column': 1,
    'impact': 'i3',
    'likelihood': 'l2',
    'count': '',
    'color': '#F7B900'
  },
  {
    'row': 2,
    'column': 2,
    'impact': 'i3',
    'likelihood': 'l3',
    'count': '',
    'color': '#F7B900'
  },
  {
    'row': 2,
    'column': 3, 'impact':
    'i3', 'likelihood': 'l4',
    'count': '',
    'color': '#F70000'
  },
  {
    'row': 3,
    'column': 0,
    'impact': 'i4',
    'likelihood': 'l1',
    'count': '',
    'color': '#F7F700'
  },
  {
    'row': 3,
    'column': 1,
    'impact': 'i4',
    'likelihood': 'l2',
    'count': '',
    'color': '#F7B900'
  },
  {
    'row': 3,
    'column': 2,
    'impact': 'i4',
    'likelihood': 'l3',
    'count': '',
    'color': '#F70000'
  },
  {
    'row': 3,
    'column': 3,
    'impact': 'i4',
    'likelihood': 'l4',
    'count': '',
    'color': '#F70000'
  }
];

export const matrixSALData =[
  {
    'row': 0,
    'column': 0,
    'impact': 'i1',
    'likelihood': 'l1',
    'count': '',
    'color': '#66BB66'
  },
  {
    'row': 0,
    'column': 1,
    'impact': 'i1',
    'likelihood': 'l2',
    'count': '',
    'color': '#66BB66'
  },
  {
    'row': 0,
    'column': 2,
    'impact': 'i1',
    'likelihood': 'l3',
    'count': '',
    'color': '#66BB66'
  },
  {
    'row': 0,
    'column': 3,
    'impact': 'i1',
    'likelihood': 'l4',
    'count': '',
    'color': '#FFC000'
  },
  {
    'row': 0,
    'column': 4,
    'impact': 'i1',
    'likelihood': 'l5',
    'count': '',
    'color': '#FFC000'
  },
  {
    'row': 1,
    'column': 0,
    'impact': 'i2',
    'likelihood': 'l1',
    'count': '',
    'color': '#66BB66'
  },
  {
    'row': 1,
    'column': 1,
    'impact': 'i2',
    'likelihood': 'l2',
    'count': '',
    'color': '#66BB66'
  },
  {
    'row': 1,
    'column': 2,
    'impact': 'i2',
    'likelihood': 'l3',
    'count': '',
    'color': '#FFC000'
  },
  {
    'row': 1,
    'column': 3,
    'impact': 'i2',
    'likelihood': 'l4',
    'count': '',
    'color': '#FFC000'
  },
  {
    'row': 1,
    'column': 4,
    'impact': 'i2',
    'likelihood': 'l5',
    'count': '',
    'color': '#FFC000'
  },
  {
    'row': 2,
    'column': 0,
    'impact': 'i3',
    'likelihood': 'l1',
    'count': '',
    'color': '#66BB66'
  },
  {
    'row': 2,
    'column': 1,
    'impact': 'i3',
    'likelihood': 'l2',
    'count': '',
    'color': '#FFC000'
  },
  {
    'row': 2,
    'column': 2,
    'impact': 'i3',
    'likelihood': 'l3',
    'count': '',
    'color': '#FFC000'
  },
  {
    'row': 2,
    'column': 3, 'impact':
    'i3', 'likelihood': 'l4',
    'count': '',
    'color': '#FFC000'
  },
  {
    'row': 2,
    'column': 4, 'impact':
    'i3', 'likelihood': 'l5',
    'count': '',
    'color': '#e53535'
  },
  {
    'row': 3,
    'column': 0,
    'impact': 'i4',
    'likelihood': 'l1',
    'count': '',
    'color': '#FFC000'
  },
  {
    'row': 3,
    'column': 1,
    'impact': 'i4',
    'likelihood': 'l2',
    'count': '',
    'color': '#FFC000'
  },
  {
    'row': 3,
    'column': 2,
    'impact': 'i4',
    'likelihood': 'l3',
    'count': '',
    'color': '#FFC000'
  },
  {
    'row': 3,
    'column': 3,
    'impact': 'i4',
    'likelihood': 'l4',
    'count': '',
    'color': '#e53535'
  },
  {
    'row': 3,
    'column': 4,
    'impact': 'i4',
    'likelihood': 'l5',
    'count': '',
    'color': '#e53535'
  },
  {
    'row': 4,
    'column': 0,
    'impact': 'i5',
    'likelihood': 'l1',
    'count': '',
    'color': '#FFC000'
  },
  {
    'row': 4,
    'column': 1,
    'impact': 'i5',
    'likelihood': 'l2',
    'count': '',
    'color': '#FFC000'
  },
  {
    'row': 4,
    'column': 2,
    'impact': 'i5',
    'likelihood': 'l3',
    'count': '',
    'color': '#e53535'
  },
  {
    'row': 4,
    'column': 3,
    'impact': 'i5',
    'likelihood': 'l4',
    'count': '',
    'color': '#e53535'
  },
  {
    'row': 4,
    'column': 4,
    'impact': 'i5',
    'likelihood': 'l5',
    'count': '',
    'color': '#e53535'
  }
];

export const riskImpactScoringGridData = [
  {
    risk_level_score: '1',
    criterion: {
      value: 'Low',
      color: '#66BB66'
    },
    financial_impact: '< 0.5',
    time_impact: '1',
    perfomance_impact: 'Low impact on performance'
  },
  {
    risk_level_score: '2',
    criterion: {
      value: 'Medium',
      color: '#f2b753'
    },
    financial_impact: '0.5 to 2',
    time_impact: '2',
    perfomance_impact: 'Medium impact on performance'
  },
  {
    risk_level_score: '3',
    criterion: {
      value: 'High',
      color: '#e87c34'
    },
    financial_impact: '2 to 10',
    time_impact: '5',
    perfomance_impact: 'High impact on performance'
  },
  {
    risk_level_score: '4',
    criterion: {
      value: 'Critical',
      color: '#e53535'
    },
    financial_impact: '10 to 50',
    time_impact: '> 5',
    perfomance_impact: 'Critical impact on performance'
  },
  {
    risk_level_score: '5',
    criterion: {
      value: 'Catastrophic',
      color: '#dd529e'
    },
    financial_impact: '> 50',
    time_impact: 'Unable to deliver',
    perfomance_impact: 'Mission cannot be accomplished'
  }
];

export const auScheduleApprovalProcessing = {
  headText: 'Unable to complete the action as approval is in process'
};

export const auSuccesswStagesDeleteMsg = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to delete this Stage? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel',
  success: 'Stage Deleted Successfully'
};

export const statusDropdown = [
  { id: 'Completed', name: 'Completed'},
  { id: 'In Progress', name: 'In Progress' },
  { id: 'Not Commenced', name: 'Not Commenced' }
];

export const riskLikelyhoodScoringGridData = [
  {
    risk_level_score: '1',
    range: '0 - 20',
    criterion: {
      value: 'Rare',
      color: '#66BB66'
    },
    description: 'Rare – Event may occur only in exceptional circumstances'
  },
  {
    risk_level_score: '2',
    range: '20 - 40',
    criterion: {
      value: 'Unlikely',
      color: '#f2b753'
    },
    description: 'Unlikely – Event could occur at sometime'

  },
  {
    risk_level_score: '3',
    range: '40 - 60',
    criterion: {
      value: 'Moderate',
      color: '#e87c34'
    },
    description: 'Moderate – Event should occur at sometime'
  },
  {
    risk_level_score: '4',
    range: '60 - 80',
    criterion: {
      value: 'Likely',
      color: '#e53535'
    },
    description: 'Likely – Event will probably occur in most circumstances'
  },
  {
    risk_level_score: '5',
    range: '80 - 100',
    criterion: {
      value: 'Almost Certain',
      color: '#dd529e'
    },
    description: 'Almost Certain – Event is expected to occur in most circumstances'
  }
];

export const riskCategoryDefinition = [
  {
    risk_category: 'Strategic Risk',
    definition: 'Risk that business objectives may not be achieved as per management and shareholders intent.'
  },
  {
    risk_category: 'Governance Risk',
    definition: 'The risk that the company’s governance processes do not comply with legal requirements or stakeholder expectations and that the board of directors fails to provide adequate monitoring and oversight of executive management activities'
  },
  {
    risk_category: 'Reputation Risk',
    definition: 'The risk that results from negative public opinion that endangers the company to continue serving existing and potential customers. The risk arises as a result of the Company’s processes failing to meet customer expectations.'
  },
  {
    risk_category: 'Operational Risk',
    definition: 'Operational risk comprises all hazards to which a company is exposed as a result of being in business and of doing business. It is the risk of loss resulting from inadequate or failed internal processes, people, and systems.'
  },
  {
    risk_category: 'Regulatory Risk',
    definition: 'The risk of regulatory sanctions, material financial loss or loss to reputation that a company may suffer as a result of its failure to comply with applicable laws, standards, rules and regulations.'
  },
  {
    risk_category: 'Legal Risk',
    definition: 'The risk that a company’s transactions, contractual agreements and specific strategies and activities are not enforceable under applicable law.'
  },
  {
    risk_category: 'Customer / Client Wants Risk',
    definition: 'The company is not aware that customer needs and wants change. Such needs and wants may apply to desired quality, willingness to pay and/or speed of execution.'
  },
  {
    risk_category: 'Information Technology Risk',
    definition: 'The risk that the information technologies used in the business are not efficiently and effectively supporting the current and future needs of the business or threaten the company’s ability to sustain the operation of critical business processes.'
  },
  {
    risk_category: 'Infrastructure Risk',
    definition: 'The risk that the company does not have an effective information technology infrastructure (e.g., hardware, networks, software, people and processes) to effectively support the current and future needs of the business in an efficient, cost-effective and well-controlled fashion.'
  },
  {
    risk_category: 'Catastrophic Loss Risk',
    definition: 'The inability to sustain operations, provide essential products and services, or recover operating costs as a result of a major disaster.'
  },
  {
    risk_category: 'Succession Planning Risk',
    definition: 'Leadership talent within the company is not sufficiently developed to provide for orderly succession in the future.'
  }
];

export const ratingDefinition = [
  {
    risk_level: {
      value: 'High',
      color: '#da3032'
    },
    criteria: 'Significant impact on cost, revenue, resources, customer service, regulatory compliance or reputation could result, affecting the achievement of business objectives.'
  },
  {
    risk_level: {
      value: 'Medium',
      color: '#f2b753'
    },
    criteria: 'Limited impact on cost, revenue, resources, customer service or reputation and could turn severe if not addressed.'
  },
  {
    risk_level: {
      value: 'Low',
      color: '#66BB66'
    },
    criteria: 'Low impact on cost, revenue or resources with scope for improvements to increase efficiency and meet sound business practices.'
  }
];

export const financeDefinition = [
  {
    risk_level: {
      value: 'Severe',
      color: '#da3032'
    },
    criteria: '- Loss of Revenue by 25%\n- Loss of EBIT Profit by 20%\n- Loss of Net Assets by 10%'
  },
  {
    risk_level: {
      value: 'Major',
      color: '#e87c34'
    },
    criteria: '- Loss of Revenue by 20%\n- Loss of EBIT Profit by 16%\n- Loss of Net Assets by 8%'
  },
  {
    risk_level: {
      value: 'Significant',
      color: '#f2b753'
    },
    criteria: '- Loss of Revenue by 15%\n- Loss of EBIT Profit by 12%\n- Loss of Net Assets by 6%'
  },
  {
    risk_level: {
      value: 'Important',
      color: '#66BB66'
    },
    criteria: '- Loss of Revenue by 10%\n- Loss of EBIT Profit by 8%\n- Loss of Net Assets by 4%'
  },
  {
    risk_level: {
      value: 'Minor',
      color: '#88d57a'
    },
    criteria: '- Loss of Revenue by 5%\n- Loss of EBIT Profit by 4%\n- Loss of Net Assets by 2%'
  }
];

export const operationalDefinition = [
  {
    risk_level: {
      value: 'Severe',
      color: '#da3032'
    },
    criteria: '- Annual Airside revenue (SAR/Tonnage) less than 25% of yearly target.\n- landside revenue (SAR/Tonnage) sales are less than 40 % of yearly target.\n- Undelivered 5% of total import AWB’s\n- 70:30 Ratio of Landside revenue to Airside revenue\n- 125 number of unresolved complaints by customer service.\n- 10% of mandatory trainings not conducted in a calendar year.\n\n- 13 and above of employee turnover.\n- 20% of downtime hours of total number of facility equipment.\n- 90 + number of days delay in completion of capital projects.\n- 35 + PO not circulated on time\n- 10% of negative surveys obtained by dissatisfied vendors after completion of project(s)\n- 10 non backed up critical vendors.\n- Loss or theft of 1 confidential document .\n\n- 4% of monthly Unresolved lost cargo shipments\n- 20/month of incident reporting of damage to cargo\n- 10/weekly unresolved complaints by tracing department.\n- 10 missing stamped /signed documents.\n- 10% /month OTP of flight delay vs STD\n- 10% leadership attrition\n- Absent/Outdated 10% Documented Policy & Procedure for critical process as defined for BCMS Parameters\n- 25% of departmental KPI unachieved'
  },
  {
    risk_level: {
      value: 'Major',
      color: '#e87c34'
    },
    criteria: '- Annual Airside revenue (SAR/Tonnage) less than 20% of yearly target.\n- landside revenue (SAR/Tonnage) sales are less than 35 % of yearly target.\n- Undelivered 4% of total import AWB’s\n- 65:35 Ratio of Landside revenue to Airside revenue\n- 100 number of unresolved complaints by customer service.\n- 8% of mandatory trainings not conducted in a calendar year.\n\n- 10 to 12% of employee turnover.\n- 15% of downtime hours for facility equipment.\n- 75 - 89 number of days delay in completion of capital projects.\n- 30 -34 PO not circulated on time.\n- 8% of negative surveys obtained by dissatisfied vendors after completion of project(s)\n- 8 non backed up critical vendors.\n\n- 3% of monthly Unresolved lost cargo shipments\n- 15/month of incident reporting of damage to cargo\n- 7/weekly unresolved complaints by tracing department.\n- 8 missing stamped /signed documents.\n- 7% /month OTP of flight delay vs STD\n- 8% leadership attrition\n- Absent/Outdated 8% Documented Policy & Procedure for critical process as defined for BCMS Parameters\n- Between 20% to 24% of departmental KPI unachieved'
  },
  {
    risk_level: {
      value: 'Significant',
      color: '#f2b753'
    },
    criteria: '- Annual Airside revenue (SAR/Tonnage) less than 15% of yearly target.\n- landside revenue (SAR/Tonnage) sales are less than 20 % of yearly target.\n- Undelivered 3% of total import AWB’s\n- 60:40 Ratio of Landside revenue to Airside revenue\n- 75 number of unresolved complaints by customer service.\n- 6% of mandatory trainings not conducted in a calendar year.\n\n- 7 to 9% of employee turnover.\n- 10% of downtime hours for facility equipment.\n- 60 -74 number of days delay in completion of capital projects.\n- 25 – 29 PO not circulated on time\n- 5% of negative surveys obtained by dissatisfied vendors after completion of project(s)\n- 5 non backed up critical vendors.\n\n- 2% of monthly Unresolved lost cargo shipments\n- 8/month of incident reporting of damage to cargo\n- 5/weekly unresolved complaints by tracing department.\n- 6 missing stamped /signed documents.\n- 5% /month OTP of flight delay vs STD\n- 6% leadership attrition\n- Absent/Outdated 5% Documented Policy & Procedure for critical process as defined for BCMS Parameters\n- Between 15% to 19% of departmental KPI unachieved'
  },
  {
    risk_level: {
      value: 'Important',
      color: '#66BB66'
    },
    criteria: '- Annual Airside revenue (SAR/Tonnage) less than 10% of yearly target.\n- landside revenue (SAR/Tonnage) sales are less than 15 % of yearly target.\n- Undelivered 2% of total import AWB’s\n- 55:45 Ratio of Landside revenue to Airside revenue\n- 60 number of unresolved complaints by customer service.\n- 4% of mandatory trainings not conducted in a calendar year.\n\n- 4 to 6% of employee turnover.\n- 5% of downtime hours for facility equipment.\n- 45 -59 number of days delay in completion of capital projects.\n- 20 -24 PO not circulated on time\n- 3% of negative surveys obtained by dissatisfied vendors after completion of project(s)\n- 3 non backed up critical vendors\n\n- 1.5% of monthly Unresolved lost cargo shipments\n- 5/month of incident reporting of damage to cargo\n- 3/weekly unresolved complaints by tracing department.\n- 3 missing stamped /signed documents.\n- 3% /month OTP of flight delay vs STD\n- 4% leadership attrition\n- Absent/Outdated 3% Documented Policy & Procedure for critical process as defined for BCMS Parameters\n- Between 10% to 14% of departmental KPI unachieved'
  },
  {
    risk_level: {
      value: 'Minor',
      color: '#88d57a'
    },
    criteria: '- Annual Airside revenue (SAR/Tonnage) less than 5% of yearly target.\n- landside revenue (SAR/Tonnage) sales are less than 10 % of yearly target.\n- Undelivered 1% of total import AWB’s\n- 50:50 Ratio of Landside revenue to Airside revenue\n- 30 number of unresolved complaints by customer service.\n- 2% of mandatory trainings not conducted in a calendar year.\n\n- 1 to 3% of employee turnover.\n- >5% of downtime hours for facility equipment.\n- 30 -44 number of days delay in completion of capital projects.\n- 10 -9 PO not circulated on time\n- >3 % of negative surveys obtained by dissatisfied vendors after completion of project(s)\n- >3 % non backed up critical vendors\n\n- 1% of monthly Unresolved lost cargo shipments\n- 2/month of incident reporting of damage to cargo\n- 1/weekly unresolved complaints by tracing department.\n- 1 missing stamped /signed documents.\n- 1% /month OTP of flight delay vs STD\n- 2% leadership attrition\n- Absent/Outdated 1% Documented Policy & Procedure for critical process as defined for BCMS Parameters\n- Less than 10% of departmental KPI unachieved'
  }
];

export const reputationalDefinition = [
  {
    risk_level: {
      value: 'Severe',
      color: '#da3032'
    },
    criteria: '- Extended national / international adverse media coverage and/or Significant loss of confidence by internal and external stakeholders.\n- Negative publicity which may lead to loss of confidence / reputation with more than 6 customers or investors'
  },
  {
    risk_level: {
      value: 'Major',
      color: '#e87c34'
    },
    criteria: '- Major damage to reputational image and brand image and possible negative attention / action from government agencies.\n- Negative publicity which may lead to loss of confidence / reputation with more than 4 customers or investors.'
  },
  {
    risk_level: {
      value: 'Significant',
      color: '#f2b753'
    },
    criteria: '- Negative publicity which may lead to loss of confidence / reputation with more than 3 customers or investors.'
  },
  {
    risk_level: {
      value: 'Important',
      color: '#66BB66'
    },
    criteria: '- Negative publicity which may lead to loss of confidence / reputation with 1 or 2 customers or investor.'
  },
  {
    risk_level: {
      value: 'Minor',
      color: '#88d57a'
    },
    criteria: '- Limited damage to reputational image and brand image which is resolved by management action.'
  }
];

export const strategyDefinition = [
  {
    risk_level: {
      value: 'Severe',
      color: '#da3032'
    },
    criteria: '- Total completion 85% of the entire balanced scorecard\n- Market share loss by 5%\n- 4 Significant changes to strategic plan\n- 10% Customer attrition'
  },
  {
    risk_level: {
      value: 'Major',
      color: '#e87c34'
    },
    criteria: '- Total completion 88% of the entire balanced scorecard\n- Market share loss by 4%\n- 3 Significant changes to strategic plan\n- 8% Customer attrition'
  },
  {
    risk_level: {
      value: 'Significant',
      color: '#f2b753'
    },
    criteria: '- Total completion 90% of the entire balanced scorecard\n- Market share loss by 3%\n- 2 Significant changes to strategic plan\n- 6% Customer attrition'
  },
  {
    risk_level: {
      value: 'Important',
      color: '#66BB66'
    },
    criteria: '- Total completion 93% of the entire balanced scorecard\n- Market share loss by 2 %\n- 1 Significant changes to strategic plan\n- 4% Customer attrition'
  },
  {
    risk_level: {
      value: 'Minor',
      color: '#88d57a'
    },
    criteria: '- Total completion 95% of the entire balanced scorecard\n- Market share loss by 1%\n- Significant changes to strategic plan\n- 2% Customer attrition'
  }
];

export const healthDefinition = [
  {
    risk_level: {
      value: 'Severe',
      color: '#da3032'
    },
    criteria: '- Death, permanent total disability\n- Equipment totally lost or damage equal to or more than SAR 80M\n- Irreversible significant environmental impact, pandemic'
  },
  {
    risk_level: {
      value: 'Major',
      color: '#e87c34'
    },
    criteria: '- Could result in one or more of the following: permanent partial disability, injuries, or occupational illness that may result in hospitalization over equal or more than 3 days of absence\n- Equipment damage equal to or less than SAR 30M\n- Reversible significant environmental impact'
  },
  {
    risk_level: {
      value: 'Significant',
      color: '#f2b753'
    },
    criteria: '- Could result in one or more of the following: injury or occupational illness resulting in one or > 3 lost workday(s).\n- Equipment damage equal to or less than SAR 5M\n- Reversible moderate environmental impact'
  },
  {
    risk_level: {
      value: 'Important',
      color: '#66BB66'
    },
    criteria: '- Temporary minor injury/illness/first aid treatment needed.\n- Equipment damage equal to or less than SAR 999K'
  },
  {
    risk_level: {
      value: 'Minor',
      color: '#88d57a'
    },
    criteria: '- Minor injury not requiring first aid or no apparent injury/adverse outcome, near miss.\n- Equipment damage equal to or less than SAR 99K'
  }
];

export const complianceDefinition = [
  {
    risk_level: {
      value: 'Severe',
      color: '#da3032'
    },
    criteria: '- Significant prosecution and fines; very serious litigation.\n- Imprisonment of one or more executives or directors.\n- 1 Significant non-compliance with regulations leading to suspension of license\n- <35% of critical cases filed in courts.\n- 5 number of code of conduct cases reported in quarter bases\n- 1 final court judgment resulted in major financial loss/ reputation infringement'
  },
  {
    risk_level: {
      value: 'Major',
      color: '#e87c34'
    },
    criteria: '- Imprisonment of one or more employees .\n- Serious breach of contract or regulation leading to investigation or major fine or operational hindrances.\n - <25% of critical cases filed in courts.\n- 4 number of code of conduct cases reported in quarter bases'
  },
  {
    risk_level: {
      value: 'Significant',
      color: '#f2b753'
    },
    criteria: '- Prolonged and continued contractual or regulatory non-compliances with significant fines or operational hindrances.\n- <20% of critical cases filed in courts.\n- 3 number of code of conduct cases reported in quarter bases'
  },
  {
    risk_level: {
      value: 'Important',
      color: '#66BB66'
    },
    criteria: '- Repeated contractual or regulatory non-compliance with fines\n- <15% of critical cases filed in courts.\n- 2 number of code of conduct cases reported in quarter bases'
  },
  {
    risk_level: {
      value: 'Minor',
      color: '#88d57a'
    },
    criteria: '- Repeated contractual or regulatory non-compliance with fines\n- <15% of critical cases filed in courts.\n- 2 number of code of conduct cases reported in quarter bases'
  }
];

export const securityDefinition = [
  {
    risk_level: {
      value: 'Severe',
      color: '#da3032'
    },
    criteria: '- Screening equipment destroyed or temporary unavailability to screen cargo/people (more than 01 hour)\n- Breach of security in the facility/ramp area\n- CCTV outage for 2 Hours\n- SAF Cat 1 placed on the airline\n- Falsifying/Changing security Forms without permission.\n- Absents of Security representative more than 30 Min\n- Theft of 1 Shipments goods or business equipment\n- Detection of a prohibited article in cargo/aircraft/facility'
  },
  {
    risk_level: {
      value: 'Major',
      color: '#e87c34'
    },
    criteria: '- Screening equipment temporary unavailability to screen cargo/people (up to 30 mis)\n- Security breach impacting on procedures.\n- CCTV outage for 1 Hours\n- SAF Cat 2 placed on the airline'
  },
  {
    risk_level: {
      value: 'Significant',
      color: '#f2b753'
    },
    criteria: '- CCTV outage for 30 Minutes'
  }
];

export const informationDefinition = [
  {
    risk_level: {
      value: 'Severe',
      color: '#da3032'
    },
    criteria: '- Up to 3 days of critical IT systems downtime\n- Up to 2 weeks of non - critical IT systems downtime'
  },
  {
    risk_level: {
      value: 'Major',
      color: '#e87c34'
    },
    criteria: '- 2 days of critical IT systems downtime.\n- 5 days of non - critical IT systems downtime.'
  },
  {
    risk_level: {
      value: 'Significant',
      color: '#f2b753'
    },
    criteria: '- 24 hours of critical IT systems downtime.\n- 3 days of non - critical IT systems downtime'
  },
  {
    risk_level: {
      value: 'Important',
      color: '#66BB66'
    },
    criteria: '- 10 hours of critical IT systems downtime.\n- 2 days of non - critical IT systems downtime'
  },
  {
    risk_level: {
      value: 'Minor',
      color: '#88d57a'
    },
    criteria: '- More than 06 hours of critical IT systems downtime.\n- More than 01 days of non - critical IT systems downtime'
  }
];

export const cyberDefinition = [
  {
    risk_level: {
      value: 'Severe',
      color: '#da3032'
    },
    criteria: '- Receiving a phishing email to more than 200 users\n- Failure of Firewall for more than 24 hours\n- Malware/Virus found in 30 or more user machines or in multiple servers\n- Unauthorized Privileged access in multiple sites'
  },
  {
    risk_level: {
      value: 'Major',
      color: '#e87c34'
    },
    criteria: '- Receiving a phishing email to more than 100 users\n- Failure of Firewall for more than 12 hours\n- Malware/Virus found in 20 user machines or in a server\n- Unauthorized Privileged access in one site'
  },
  {
    risk_level: {
      value: 'Significant',
      color: '#f2b753'
    },
    criteria: '- Receiving a phishing email to more than 50 users\n- Failure of Firewall for more than 6 hours\n- Malware/Virus found in 10 user machines or more'
  },
  {
    risk_level: {
      value: 'Important',
      color: '#66BB66'
    },
    criteria: '- Receiving a phishing email to more than 10 users\n- Failure of Firewall for 3 hours or less\n- Malware/Virus found in 5 or less user machines'
  },
  {
    risk_level: {
      value: 'Minor',
      color: '#88d57a'
    },
    criteria: '- Receiving a phishing email to 5 users or less\n- Failure of Firewall for 1 hour or less\n- Malware/Virus found in 1 user machine'
  }
];

export const riskAppetiteStatement = [
  {
    risk_category: 'Financial',
    risk_appetite: {
      value: 'Low',
      color: '#71bb63'
    },
    risk_appetite_statement: 'Company has Low appetite for financial risks. Company is not prepared to take any risks which may jeopardize its financial stability.'
  },
  {
    risk_category: 'Operational',
    risk_appetite: {
      value: 'Low',
      color: '#71bb63'
    },
    risk_appetite_statement: 'Company aims to provide secure and pleasant transport, logistics and cargo services to all its customers & clients. Company has Low appetite for any threat that impacts cargo or ground handling operations, commercial and business continuity. SAL is committed to attracting the best talent in the market, and retain its existing best performing talent. Therefore, company has Low appetite to risk in Operational area'
  },
  {
    risk_category: 'Strategy',
    risk_appetite: {
      value: 'Low',
      color: '#71bb63'
    },
    risk_appetite_statement: 'Company has Low appetite for any strategical damage or loss which may impact its market share regionally or globally.'
  },
  {
    risk_category: 'Reputational',
    risk_appetite: {
      value: 'Low',
      color: '#71bb63'
    },
    risk_appetite_statement: 'Company has Low appetite for risks impacting the brand and image.'
  },
  {
    risk_category: 'Health, Safety & Environment',
    risk_appetite: {
      value: 'Zero',
      color: '#da3032'
    },
    risk_appetite_statement: 'Company is committed to the prevention of staff injuries, environment protection, promoting safe operations and healthy lifestyle for all its customers and clients. Therefore, Company has Zero appetite for any Health, Safety and Environment related issues or incidents.'
  },
  {
    risk_category: 'Regulatory, Legal and Compliance',
    risk_appetite: {
      value: 'Low',
      color: '#71bb63'
    },
    risk_appetite_statement: 'Company has Low risk appetite for regulatory and compliance violations or breaches which may expose the company to unwarranted legal and regulatory hassles.'
  },
  {
    risk_category: 'Security',
    risk_appetite: {
      value: 'Zero',
      color: '#da3032'
    },
    risk_appetite_statement: 'SAL has Zero tolerance in the area of security and is applying a Risk-based approach with the primary aim of preventing terrorism and criminal activity through cargo. To achieve this SAL will work to industry best working practices and follow guidance from national/international agencies.'
  },
  {
    risk_category: 'Information Technology',
    risk_appetite: {
      value: 'Low',
      color: '#71bb63'
    },
    risk_appetite_statement: 'Company has Low appetite for risks impacting its Information system, IT Security (and/or) Cybersecurity and infrastructure to ensure minimum downtime and challenges in operations.'
  },
  {
    risk_category: 'Cybersecurity',
    risk_appetite: {
      value: 'Low',
      color: '#71bb63'
    },
    risk_appetite_statement: 'Company has Low appetite for risks impacting Cybersecurity and infrastructure to ensure minimum downtime and challenges in operations.'
  }

];

export const riskImpactScoring = [
  {
    impact_score: {
      value: '5',
      color: '#da3032'
    },
    performance_impact: 'Low impact on performance.'
  },
  {
    impact_score: {
      value: '4',
      color: '#e87c34'
    },
    performance_impact: 'Medium impact on performance.'
  },
  {
    impact_score: {
      value: '3',
      color: '#f2b753'
    },
    performance_impact: 'High impact on performance.'
  },
  {
    impact_score: {
      value: '2',
      color: '#66BB66'
    },
    performance_impact: 'Critical impact on performance.'
  },
  {
    impact_score: {
      value: '1',
      color: '#88d57a'
    },
    performance_impact: 'Mission cannot be accomplished.'
  }

];

export const riskRating = [
  {
    rating: {
      value: 'NONE / WEAK',
      color: '#da3032'
    },
    action: 'Critical improvement opportunity.',
    discription: 'Controls and/or management activities are non-existent or have major deficiencies and don’t operate as intended.',
    control_assessment_score: '1'
  },
  {
    rating: {
      value: 'NEED IMPROVEMENT',
      color: '#e87c34'
    },
    action: 'Significant improvement opportunity.',
    discription: 'Limited controls and/or management activities are 0.75 in place, high level of risk remains.',
    control_assessment_score: '0.75'
  },
  {
    rating: {
      value: 'ADEQUATE',
      color: '#f2b753'
    },
    action: 'Moderate improvement opportunity.',
    discription: 'Controls and/or management activities are in 0.5 place, with opportunities for improvement identified.',
    control_assessment_score: '0.5'
  },
  {
    rating: {
      value: 'EFFECTIVE',
      color: '#66BB66'
    },
    action: 'Limited improvement opportunity.',
    discription: 'Controls and/or management activities are properly 0.25 designed and operating, with limited opportunities for improvement identified.',
    control_assessment_score: '0.25'
  },
  {
    rating: {
      value: 'STRONG',
      color: '#88d57a'
    },
    action: 'No improvement required.',
    discription: 'Controls and/or management activities are properly 0.01 designed and operating as intended.',
    control_assessment_score: '0.01'
  }

];

export const riskLikelihoodScoring = [
  {
    likelihood_score: {
      value: '5',
      color: '#d5eaff'
    },
    performance_impact: 'Low impact on performance.'
  },
  {
    likelihood_score: {
      value: '4',
      color: '#d5eaff'
    },
    performance_impact: 'Medium impact on performance.'
  },
  {
    likelihood_score: {
      value: '3',
      color: '#d5eaff'
    },
    performance_impact: 'High impact on performance.'
  },
  {
    likelihood_score: {
      value: '2',
      color: '#d5eaff'
    },
    performance_impact: 'Critical impact on performance.'
  },
  {
    likelihood_score: {
      value: '1',
      color: '#d5eaff'
    },
    performance_impact: 'Mission cannot be accomplished.'
  }
];

export const riskRange = [
  {
    low: {
      value: '1 – 3.9 Low',
      color: '#71bb63'
    },
    moderate: {
      value: '4 – 8.9 = Moderate',
      color: '#f2b753'
    },
    high: {
      value: '9 - 25 = High',
      color: '#da3032'
    },
    key_high: {
      value: '15- 25 = High',
      color: '#da3032'
    }
  }
];

export enum Toastsmessage{
  zeroSeverity = 'Severity cannot be zero.',
  scheduleStageLocked = 'Unable to perform the action as the schedule is locked'
  }

export const moduleFields = {
  risk_id: 'risk_id',
  risk_name: 'risk_name',
  risk_department_level1: 'risk_department_level1',
  risk_department_level2: 'risk_department_level2',
  major_process_v1: 'major_process_v1',
  mega_process_v1: 'mega_process_v1',
  entity: 'entity',
  sector: 'sector',
  residual_risk_details: 'residual_risk_details',
  inherent_risk_details: 'inherent_risk_details',
  status: 'status'
};

export const riskQueryParams = {
  date_filter: null,
  date_range: '',
  group: '',
  category: '',
  project_type: ''
};

export const riskConstants = {
  year: 'year'
};

export const riskDashboardConstants = {
  riskDepartment: 'Risk by Department Level1',
  riskDepartmentLevel2: 'Risk by Department Level2',
  majorProcess: 'Risk by Major Process',
  megaProcess: 'Risk by Mega Process',
  category: 'Risk by Category',
  riskByStatus: 'Risks by Status',
  chartTopRisk: 'Top Risks',
  riskBySector: 'Risks by Sectors',
  riskByEntities: 'Risks by Entities',
  topRisks: 'topRisks',
  allRisks: 'allRisks',
  top_risk: ''
};

export const riskChartData = {
  department: 'department',
  department_level_2: 'department_level_2',
  major_process: 'major_process',
  mega_process: 'mega_process',
  category: 'category',
  status_by_inherent: 'status_by_inherent',
  status_by_residual: 'status_by_residual',
  top_risk_by_inherent: 'top_risk_by_inherent',
  top_risk_by_residual: 'top_risk_by_residual',
  sector_inherent: 'sector_inherent',
  sector_residual: 'sector_residual',
  entity_inherent: 'entity_inherent',
  entity_residual: 'entity_residual'
};

export const observationDashboardParams = {
  observation_status: '',
  management_status: '',
  status_of_audit: '',
  observation_department: '',
  multi_values: false,
  stakeholder: '',
  megaprocess: '',
  observation_business_user: '',
  mega_process: '',
  department: '',
  audit__status_of_audit: '',
  rating: '',
  management_user: '',
  created_by: '',
  major_process: '',
  audit: '',
  date_filter: '',
  date_range: '',
  department_level1: '',
  department_level2: '',
  type: '',
  category: '',
  department_l1: '',
  compliance: '',
  compliance_status: '',
  observation_type: '',
  risk_program__status_of_risk_program: '',
  risk_program: ''
};

export const activeClicked = {
  all: 'all',
  label: 'label',
  color: 'color',
  limit: 'limit',
  offset: 'offset',
  category: 'category',
  Others: 'Others'
};

export enum LanguageConstants {
  arabic = 'ar',
  department = 'Risks by Department Level1',
  departmentLevel2= 'Risks by Department Level2',
  majorProcess = 'Risks by Major Process',
  megaProcess = 'Risks by Mega Process',
  category = 'Risks by Category',
  inherent = 'Risks by Status',
  topRisk = 'Top Risks',
  sector = 'Risks by Sectors',
  entity = 'Risks by Entities'
  }

export const riskDashboardParams = {
  risk_department_level1: '',
  risk_department_level2: '',
  major_process_v2: '',
  mega_process_v2: '',
  category: '',
  date_filter: '',
  date_range: '',
  multi_values: false,
  status_by_inherent: '',
  status_by_residual: '',
  top_risk_by_inherent: '',
  top_risk_by_residual: '',
  sector_inherent: '',
  sector_residual: '',
  entity_inherent: '',
  entity_residual: '',
  fiscal_year: '',
  sector: '',
  entity: '',
  risk_plan: '',
  risk_program_name: '',
  method_of_risk_program: ''
};

export const dependencyAttribute = {
  bowTie: 'bowTie',
  networkChart: 'networkChart',
  controls: 'Controls',
  incidents: 'Incidents',
  audit_and_risk_program_ob: 'Audit_and_Risk_Program_OB',
  compliance_observations: 'ComplianceOB',
  documents: 'Documents',
  history: 'History',
  risks: 'Risks',
  analytics_automation: 'AnalyticsAutomation',
  observation: 'Observation',
  assessments: 'Assessments',
  actions: 'Actions',
  issues: 'Issues',
  requests: 'Requests',
  kri: 'KRI',
  historycontrolmovements: 'HistoryControlMovements',
  riskMap: 'RiskMap',
  control: 'Control',
  incident: 'Incident',
  auditandRiskProgramObservations: 'AuditandRiskProgramObservations',
  complianceObservation: 'ComplianceOb',
  riskMovements: 'RiskMovements',
  causes: 'Causes',
  impacts: 'Impacts',
  mitigationplan: 'MitigationPlan'
};

export const documentEvents = {
  limit: 'limit',
  edit: 'edit',
  delete: 'delete',
  search: 'search',
  pageChange: 'pageChange',
  save: 'save',
  upload: 'upload',
  refresh: 'refresh',
  scroll: 'scroll'
};

export const tableKeys = {
  risk_id: 'risk_id',
  risk_name: 'risk_name',
  inherent_risk_rating: 'inherent_risk_rating',
  residual_risk_rating: 'residual_risk_rating',
  entity: 'entity',
  multi_entity: 'multi_entity',
  sector: 'sector',
  mega_process_v2: 'mega_process_v2',
  major_process_v2: 'major_process_v2',
  risk_department_level1: 'risk_department_level1',
  risk_department_level2: 'risk_department_level2'
};

export enum ApiStatus {
  ok = 200,
  created = 201,
  noContent = 204,
  badRequest = 400,
  unAuthorized = 401,
  forbidden = 403,
  notFound = 404,
  notAcceptable = 406,
  conflict = 409,
  unprocessableEntity = 422,
  failedDependency = 424,
  serverDown = 500,
  timeOut = 504,
  securityStatus = 307
  }

export const documentWidgetFormConstants = {
  description: 'description',
  title: 'title',
  file: 'file',
  data: 'data',
  auditWorkflow: 'workflow'
};

export const validationRule = {
  emailMinLength: 5,
  rpoMaxLength: 6,
  emailMaxLength: 70,
  phoneNumberMinLength: 10,
  phoneNumberMaxLength: 15,
  textAreaMinLength: 0,
  textAreaMaxLength: 65535,
  nameMinLength: 2,
  nameMaxLength: 100,
  passwordMinLength: 8,
  passwordMaxLength: 25,
  mobileMinLength: 10,
  mobileMaxLength: 20,
  textMinLength: 0,
  zipMaxLength: 15,
  textMaxLength: 255,
  string: 'string',
  titleTextBoxMaxLength: 500,
  descriptionTextAreaMaxLength: 1100,
  titleTextBoxMaxLength255: 255,
  assetTitleBox: 500,
  incidentTitleTextBoxMaxLength: 50,
  auditCustomField: 70,
  requestFormTitle: 50,
  tierMaxLength: 10,
  titleTextBoxMaxLength200: 200,
  colorcodeMaxlen: 7,
  costSavingMaxLength: 2147483647,
  numberOfExceptions: 100000000000000000,
  observationTitleLength: 300,
  documentUploadSizeLimit: 26214400,
  dataSourceDocumentLimit: 73400320,
  multipleDocumentUploadSizeLimit: 314572800,
  integerLimit: 2147483647,
  integerLimitNegative: -2147483647
};

export const auUserRole = {
  professional: 'PROFESSIONAL',
  contributor: 'CONTRIBUTOR',
  viewer: 'VIEWER',
  adminProfessional: 'ADMIN PROFESSIONAL',
  administrator: 'ADMINISTRATOR'
};

export const riskAppetiteConstant = [{
  color: '#C00000',
  label: 'Zero',
  optional: '1 -'
},
{
  color: '#70AD47',
  label: 'Low',
  optional: '2 -'
},
{
  color: '#FFFF00',
  label: 'Medium',
  optional: '3 -'
},
{
  color: '#FFC000',
  label: 'High',
  optional: '4 -'
},
{
  color: '#ED7D31',
  label: 'Very High',
  optional: '≥ 5 -'
}
];

export const auWarningMsgRemoveRisk = {
  headText: 'You are About to Remove a Risk!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Remove',
  buttonOpt_2: 'No, don’t remove'
};
export const auWarningMsgRemoveControl = {
  headText: 'You are About to Remove a Control!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Remove',
  buttonOpt_2: 'No, don’t remove'
};

export const riskDashboardOrganisational = {
  multi_values: false,
  risk_type: '',
  top_risk: '',
  domain: '',
  risk_category1: '',
  risk_business_strategy: '',
  sector: '',
  risk_mitigation_timeline: '',
  action_owner: '',
  status: '',
  multi_entity: '',
  risk_extra_dropdown: '',
  risk_department_level1: '',
  risk_department_level2: '',
  risk_dashboard_risk_department_level4_by_residual_risk_chart: '',
  risk_department_level4: '',
  risk_dashboard_risk_department_level3_by_residual_risk_chart: '',
  risk_department_level3: '',
  risk_dashboard_section_by_residual_risk_chart: '',
  departments_by_risk_list: '',
  risk_dashboard_departments_by_residual_risk_chart: '',
  functions: '',
  risk_dashboard_functions_by_residual_risk_chart: '',
  regions: '',
  risk_dashboard_regions_by_residual_risk_chart: '',
  sector_by_risk_list: '',
  risk_dashboard_sector_by_residual_risk_chart: '',
  entity_by_risk_list: '',
  risk_dashboard_entity_by_residual_risk_chart: '',
  inherent_impact: '',
  residual_impact: '',
  residual_likelihood: '',
  inherent_risk_rating: '',
  residual_risk_rating: '',
  inherent_likelihood: '',
  previous_risk_rating: '',
  current_risk_rating: ''
};

export enum AuRiskConstants {
  Preventive = 'Preventive',
  Detective = 'Detective',
  Corrective = 'Corrective',
  riskId = 'Risk ID',
  riskName = 'Risk Name',
  riskManager = 'Risk Manager',
  riskOwner = 'Risk Owner',
  riskVelocity = 'Risk Velocity',
  budget_utilization = 'Budget Utilization',
  residual_risk_rating = 'Residual Risk Rating',
  residual_impact = 'Residual Impact',
  residual_likelihood = 'Residual Likelihood',
  inherent_risk_rating = 'Inherent Risk Rating',
  inherent_impact = 'Inherent Impact',
  inherent_likelihood = 'Inherent Likelihood',
  risk_category = 'Risk Category',
  riskType = 'Risk Type',
  riskAutomation = 'Risk Automation',
  riskRatings = 'Risk Rating',
  risk_rating = 'risk_rating',
  risk_owner = 'risk_owner',
  mega_process = 'Mega Process',
  major_process = 'Major Process',
  riskVulnerability = 'Vulnerability',
  inherantScore = 'Inherent Score',
  residualScore = 'Residual Score',
  createdBy = 'Created by',
  controlCount = 'Controls',
  keyRisk = 'Key Risk',
  topRisk = 'Top Risk',
  riskSource = 'Risk Source',
  department = 'Department',
  riskAppetite = 'Risk Appetite',
  createdDate = 'Created Date',
  updatedBy = 'Updated by',
  updatedDate = 'Updated Date',
  riskDescription = 'Risk Description',
  targetResidualRisk = 'Target Residual Risk',
  manager = 'Manager',
  businessStrategy = 'Business Strategy',
  status = 'Status',
  entity = 'Entity',
  riskRegisters = 'Risk Register',
  scoringModel = 'Scoring Model',
  approvedBy = 'Approved By'
}

export const auWarningMsgCPRiskGridPublish = {
  headText: 'Successful!',
  subText: 'Risk Grid Template is created successfully',
  buttonOpt_1: 'OK',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgCPRiskTemplateSaveasDraft = {
  headText: 'Your Draft is Saved',
  subText: 'The Risk Grid Template has been saved as a draft. Do you want to continue editing?',
  buttonOpt_1: 'Continue editing',
  buttonOpt_2: 'Exit'
};

export const cpriskGridTemplateWizardEvent =  {
  fields: 'Fields',
  finish: 'Finish'
};

export const cpriskGridTemplateItemsNormal =  [
  {
    name: 'Fields',
    event: cpriskGridTemplateWizardEvent.fields
  },
  {
    name: 'Finish',
    event: cpriskGridTemplateWizardEvent.finish
  }
];

export const cpRiskUniverseCreateTemplatePageInfo = [
  {
    title: 'Home',
    url: '/dashboard/home'
  },
  {
    title: 'Admin',
    url: '/controlpanel/admin-users'
  },
  {
    title: 'Risk Management',
    url: '/controlpanel/admin-panel/object-manager/risk-management/overview/risk-universe-controlpanel'
  },
  {
    title: 'Grid',
    url: '/controlpanel/risk-management/templates/risk-grid'
  },
  {
    title: 'Default Risk Universe Template Editor',
    url: ''
  }
];

export const auditOverviewInputTextareaWidgetConstants = {
  auditName: 'Audit Name',
  auditUniverse: 'Audit Universe',
  sourceOfAudit: 'Source of Audit',
  requestedBy: 'Requested by',
  auditScope: 'Audit Scope',
  auditObjective: 'Audit Objective',
  conclusion: 'Conclusion',
  riskProgram: 'risk_program'
};

export enum CommentsTask {
  addComment = 'addComment',
  editComment = 'editComment',
  deleteComment = 'deleteComment',
  addReply = 'addreply',
  viewReplies = 'viewReplies',
  commentText = 'comment_text',
  file = 'file',
  parent = 'parent'
}

export enum EditedAuditConstants {
  auditName = 'Audit Name',
  auditUniverse = 'Audit Universe',
  entity = 'Entity',
  auditArea = 'Audit Area',
  source = 'Source of Audit',
  requested = 'Requested by',
  scope = 'Audit Scope',
  objective = 'Audit Objective',
  conclusion = 'Conclusion'
}

export const languageConstants = {
  language: 'en-us'
};

export enum LocalStorageKey {
  kriDetails = 'kriDetails',
  AssetEdit = 'AssetEdit',
  userLogin = 'token',
  expDetails = 'expDetails',
  riskDetails = 'riskDetails',
  scoringDropdown = 'scoringDropdown',
  riskControl = 'riskControlDetails',
  auditDraft = 'auditDraft',
  auditEdit = 'auditEdit',
  auditAction = 'auditActionDetails',
  auditPlanDetail = 'auditPlanDetail',
  auditReportDetail = 'auditReportDetail',
  observationDetail = 'observationDetail',
  budgetDetail = 'budgetDetail',
  ComplianceUpload = 'complianceupload',
  timeDetails = 'timeDetails',
  universalFilter = 'filterDetails',
  LikelihoodData = 'likelihoodData',
  impactData = 'impactData',
  velocityData = 'velocityData',
  scoringModelData = 'scoringModel',
  vulnerabilityData = 'vulnerabilityData',
  riskOverview = 'riskOverview',
  riskScoring = 'riskScoring',
  riskScoringDetails = 'riskScoringDetails',
  templateDetail = 'templateDetail',
  actionDetail = 'actionDetail',
  experienceDetail = 'experienceDetail',
  educationDetail = 'educationDetail',
  certificationDetail = 'certificationDetail',
  allFilterApplied = 'allFilter',
  userDetails = 'userDetails',
  membersUserDetails = 'membersUserDetails',
  entityCount = 'entityCount',
  sessionId = 'sso-sessionid',
  riskScoringUpdated = 'riskScoringUpdated',
  auditPlanDetails = 'auditPlanDetails',
  compliancePlanDetail = 'compliancePlanDetail',
  auditObservationDetail = 'auditObservationDetail',
  recommendationDetail = 'recommendationDetail',
  mappedData = 'mappedData',
  fieldArray = 'fieldArray',
  connectionHistoryName = 'connectionHistoryName',
  validatedData = 'validatedData',
  warningIndex = 'warningIndex',
  warningIndexDate = 'warningIndexDate',
  warningIndexInt = 'warningIndexInt',
  warningIndexDecimal = 'warningIndexDecimal',
  updatedIndex = 'updatedIndex',
  tableName = 'tableName',
  subModuleFields = 'subModuleFields',
  connectionMethod = 'connectionMethod',
  additionalFields = 'additionalFields',
  observationRecommendationDetail = 'observationRecommendationDetail',
  auditActionDetail = 'auditActionDetail',
  editScenarioData = 'editScenarioData',
  existingTemplate = 'existingTemplate',
  usersDetails = 'userDetail',
  queryBuilder = 'queryBuilder',
  builderChanged = 'builderChanged',
  auditRecommendationDetail = 'auditRecommendationDetail',
  userDashboardMenu = 'userDashboardMenu',
  gridDataPreview = 'gridDataPreview',
  issueDetails = 'issueDetails',
  controlDetails = 'controlDetails',
  connectionHistoryObject = 'connectionHistoryObject',
  analyticsUpdateData = 'analyticsUpdateData',
  analyticsScheduleDetails = 'analyticsScheduleDetails',
  isUpdate = 'isUpdate',
  notificationId = 'notificationId',
  userType = 'userType',
  customField = 'customField',
  datatype = 'datatype',
  formChanges = 'formChanges',
  riskControls = 'riskControl',
  requestId = 'requestId',
  policyOverview = 'policyOverview',
  isLoadMore = 'isLoadMore',
  isTabClick = 'isTabClick',
  riskAssessmentList = 'riskAssessmentList',
  deletedAssessment = 'deletedAssessment',
  ermOverallRatingGraph = 'ermOverallRatingGraph',
  sectorCount = 'sectorCount',
  universalSectorFilter = 'sectorFilterDetails',
  allSectorFilterApplied = 'allSectorFilter',
  filterCount = 'filterCount',
  isDraftProgram = 'isDraftProgram',
  riskProgramEditFromDetail = 'riskProgramEditFromDetail',
  riskRatingData = 'riskRatingData',
  riskProgramDetails = 'riskProgramDetails',
  documentDetailText = 'Maximum file upload size (PDF - 16 MB, Images - 200 KB, Other documents 10 MB)',
  emailDocumentDetailText = 'Maximum file upload size is 10 MB',
  IncidentEdit = 'IncidentEdit',
  toDisplay = 'toDisplay',
  permissions = 'permissions',
  assessmentBreadcrumbData = 'assessmentBreadcrumbData'
}

export enum AuApproveOrReject {
  approved = 'approve',
  rejected = 'reject',
  requestType = 'request_type',
  actionType = 'action_type',
  id = 'id',
  requestId = 'request_id',
  slug = 'slug',
  audit = 'audit-planning',
  create = 'create',
  false = 'False',
  recordId = 'record_id',
  transitionId = 'transition_id',
  comment = 'comment_text',
  approverType = 'approver_type',
  reviewerReject = 'Reviewer Reject'
  }

export const auAuditIssueParams = {
  audit_issues: '',
  issue_owner: '',
  issue_status: '',
  issue_rating: ''
};

export const observationList = {
  issues: 'Issues',
  control: 'Control',
  assessment: 'Assessment',
  workingPaper: 'Working Paper',
  risk: 'Risk'
};

export enum ScoringConstants {
  active = 'active',
  title = 'title',
  riskAssessment = 'risk-assessment',
  likelihood = 'likelihood',
  impact = 'impact',
  velocity = 'risk_velocity',
  vulnerability = 'vulnerability',
  inherentImpact = 'inherent_impact',
  inherentLikelihood = 'inherent_likelihood',
  scoring = 'scoring',
  summary = 'summary',
  overView = 'overView',
  dependency = 'dependency',
  assessment = 'assessment',
  residual_rating = 'residual_rating',
  inherent_rating = 'inherent_rating',
  risk_category = 'risk_category'
}

export const documentEventTypeConstants = {
  type1: 'type1',
  type2: 'type2'
};

export const riskControlWidgetMoreOptionConstant = {
  browseControl: 'browseControl',
  editControl: 'editControl',
  removeControl: 'removeControl'
};

export enum AuditProgramChart {
  activityChart = 'activityChart',
  issueChart = 'issueChart',
  auditProgramChart = 'auditProgramChart',
  testingStatus = 'testing_status',
  issueStatus = 'issue_status',
  activityStatus = 'activity_status',
  limit = 'limit',
  offset = 'offset',
  category = 'category',
  others = 'Others',
  all = 'all'
  }

export const auditActionQueryParams = {
  multi_values: false,
  action_status: '',
  action_priority: '',
  action_department: '',
  type: '',
  department: '',
  owner_action: '',
  owner: '',
  record_owner: '',
  plan: '',
  department_level1: '',
  department_level2: '',
  tilecount: ''
};
export const riskProgramQueryParamsFilter = {
  limit: tableConfig.pager,
  cursor: '',
  q: '',
  count: true,
  offset: '',
  order_by_field: '',
  table_filters: ''
};

export const auditOverdueTab = {
  overdue: 'Overdue',
  overdues: 'overdue',
  actionStatus: 'action_status',
  issueStatus: 'issue_status',
  recommendationStatus: 'recommendation_status',
  managementStatus: 'management_status',
  observation: ''
};

export const auditDateRecords = [
  { label: riskArabicTranslation.as_on_date, param: 'as_on_date' }
];

export enum AuditRequestTable {
  audit = 'audit',
  request = 'request',
  pendingRequest = 'pendingRequest',
  pendingApproval = 'pendingApproval',
  calendar = 'calendar'
  }

export const controlEffectivenessLikelihoodReduction = [
  {
    rating: 'Missing',
    score: {
      value: '5',
      color: '#e53535'
    },
    description: 'Controls reducing the likelihood of the risk occurring are missing or not in place'
  },
  {
    rating: 'Minimal',
    score: {
      value: '4',
      color: '#f27e3a'
    },
    description: 'Severe weakness to the controls reducing the likelihood of the risk occurring and the controls are likely to fail'
  },
  {
    rating: 'Partial',
    score: {
      value: '3',
      color: '#f9b859'
    },
    description: 'Identified weaknesses or flaws to the controls reducing the likelihood of the risk occurring that are likely to fail to cover targeted risk'
  },
  {
    rating: 'Substantial',
    score: {
      value: '2',
      color: '#d0dc7a'
    },
    description: 'Controls reducing the likelihood of the risk occurring are in place but with some weaknesses or inefficiency'
  },
  {
    rating: 'Complete',
    score: {
      value: '1',
      color: '#66bb66'
    },
    description: 'Demonstrably effective controls that reduce the likelihood of the risk occurring with very little risk of failure'
  }
];

export const controlEffectivenessImpactReduction = [
  {
    rating: 'Missing',
    score: {
      value: '5',
      color: '#e53535'
    },
    description: 'Controls reducing the impact of the risk occurring are missing or not in place'
  },
  {
    rating: 'Minimal',
    score: {
      value: '4',
      color: '#f27e3a'
    },
    description: 'Severe weakness to the controls reducing the impact of the risk occurring and the controls are likely to fail'
  },
  {
    rating: 'Partial',
    score: {
      value: '3',
      color: '#f9b859'
    },
    description: 'Identified weaknesses or flaws to the controls reducing the impact of the risk occurring that are likely to fail to cover targeted risk'
  },
  {
    rating: 'Substantial',
    score: {
      value: '2',
      color: '#d0dc7a'
    },
    description: 'Controls reducing the impact of the risk occurring are in place but with some weaknesses or inefficiency'
  },
  {
    rating: 'Complete',
    score: {
      value: '1',
      color: '#66bb66'
    },
    description: 'Demonstrably effective controls that reduce the impact of the risk occurring with very little risk of failure'
  }
];

export const riskScoringCriteriaLikelihood = [
  {
    rating: 'Rare',
    score: {
      value: '1',
      color: '#66bb66'
    },
    measure: '0.15'
  },
  {
    rating: 'Unlikely',
    score: {
      value: '2',
      color: '#d0dc7a'
    },
    measure: '0.35'
  },
  {
    rating: 'Possible',
    score: {
      value: '3',
      color: '#f9b859'
    },
    measure: '0.5'
  },
  {
    rating: 'Likely',
    score: {
      value: '4',
      color: '#f27e3a'
    },
    measure: '0.65'
  },
  {
    rating: 'Almost Certain',
    score: {
      value: '5',
      color: '#e53535'
    },
    measure: '0.85'
  }
];

export const riskScoringCriteriaImpact = [
  {
    rating: 'Low',
    score: {
      value: '1',
      color: '#66bb66'
    }
  },
  {
    rating: 'Minor',
    score: {
      value: '2',
      color: '#d0dc7a'
    }
  },
  {
    rating: 'Moderate',
    score: {
      value: '3',
      color: '#f9b859'
    }
  },
  {
    rating: 'Significant',
    score: {
      value: '4',
      color: '#f27e3a'
    }
  },
  {
    rating: 'Critical',
    score: {
      value: '5',
      color: '#e53535'
    }
  }
];

export const riskTaxonomyStrategic = [
  {
    risk: 'Business Model & Strategy',
    criteria: '1. Absence or ineffective corporate strategy and objectives.\n 2. Ineffective or inefficient implemtation of reorganization structure'
  },
  {
    risk: 'Organization Structure',
    criteria: '1. Lack of clarity about responsibilities\n2. Poor decision-making authority\n3. Complex organizational structure\n4. Insufficient funding to support the structure'
  },
  {
    risk: 'Corporate & Organisational Governance',
    criteria: '1. Board composition and effectiveness\n 2. Inadequate management oversight'
  },
  {
    risk: 'Control Environment',
    criteria: '1. Leadership and management risks\n 2. Employee integrity risks\n 3. Culture risks\n 4.Third-party risks'
  },
  {
    risk: 'Competition & Market Share',
    criteria: '1. Losing market share\n 2. Market monopoly or dependability\n 3. Regulatory hurdles new markets\n 4. Market competition for existing players in new markets'
  },
  {
    risk: 'Stakeholder Relations',
    criteria: '1. Lack of transparency\n 2. Poor communication with stakeholders\n 3. Failure to consider the interests of key stakeholders'
  },
  {
    risk: 'Pricing',
    criteria: '1. Increased competition\n 2. Shifts in consumer preferences\n 3. Changes in market conditions'
  },
  {
    risk: 'Reputation',
    criteria: '1. Negative publiciy / media coverage\n 2. Customer complaints\n 3. Loss of credibility / trust in the brand'
  },
  {
    risk: 'Land / Location Management',
    criteria: '-'
  }
];

export const riskTaxonomyFinancial = [
  {
    risk: 'Budgeting & Capital Management',
    criteria: '1. Inaccurate forecasting\n 2. Unanticipated expenditures\n 3. Dependence on external funding\n 4. Over-investment in low-return projects\n 5. Under-investment in high-return projects'
  },
  {
    risk: 'Existing Credit Agreements',
    criteria: '1. Absence, invalid or insufficient financial guarantees in custody\n 2. Breach of credit agreement\n 3. Fail in assign correct credit limits, periods'
  },
  {
    risk: 'Receivables and Doubtful Debt',
    criteria: '1. Counter party default risk (customer default)\n 2. Absence or insufficient provisions for bad debts\n 3. Absence, incorrect, or delay of customer balance confirmations'
  },
  {
    risk: 'Payables',
    criteria: '1. Suppliers / vendors not receive payment on time\n 2. Supplier / vendor go bankrupt\n 3. Submit false or fraudulent invoices'
  },
  {
    risk: 'Interest Rate Changes',
    criteria: '1. Changes in borrowing costs\n 2. Declining returns on investments'
  },
  {
    risk: 'Market Risk',
    criteria: '-'
  },
  {
    risk: 'Equity Risk',
    criteria: '1. Decline in equity\n 2. Investor unable to sell equity investment if needed'
  },
  {
    risk: 'Fixed Assets',
    criteria: '1. Damage or loss to the fixed asset\n 2. Obsolescence\n 3. Value of the fixed asset will decline\n 4. Depreciation risk due to emerging technologies\n 5. Fixed asset will require costly maintenance or repairs'
  },
  {
    risk: 'VAT and Zakat',
    criteria: '1. Errors in tax reporting'
  },
  {
    risk: 'Financial Crime',
    criteria: '1. Intentional misstatements\n 2. Embezzlement\n 3. Bribery and corruption'
  },
  {
    risk: 'Financial Reporting',
    criteria: '1. Errors in financial reporting\n 2. Non-compliance with international accounting standards\n 3. Disregard financial reports by operations\n 4. Absence, incorrect, or delay of related party balance confirmations\n 5. Misapplication of accounting standards\n 6. Data quality and reliability\n 7. False or inflated financial statements'
  }
];

export const riskTaxonomyCoreOperations = [
  {
    risk: 'Renting Operations',
    criteria: '1. Vehicles theft\n 2. Accident risks\n 3. Poor service performance to customers'
  },
  {
    risk: 'Sales Operations',
    criteria: '-'
  },
  {
    risk: 'Weem Application',
    criteria: '-'
  },
  {
    risk: 'Branch Management',
    criteria: '-'
  },
  {
    risk: 'Fleet Management (Logistics & Warehousing)',
    criteria: '1. Fleet utilization risk\n 2. Overcapacity\n 3. Underutilization'
  },
  {
    risk: 'Car Availability',
    criteria: '1. Variety ditribution risk \n 2. Unavailable vehicles for rent\n 3. Models / type / category availability'
  },
  {
    risk: 'Car Maintenance',
    criteria: '1. Long outstanding open jobs (WIPS)'
  },
  {
    risk: 'Car Collection',
    criteria: '-'
  },
  {
    risk: 'Car Insurance',
    criteria: '-'
  },
  {
    risk: 'Analysis & Reporting',
    criteria: '-'
  }
];

export const riskTaxonomyOperational = [
  {
    risk: 'Processes and Systems',
    criteria: '1. Inefficient processes\n 2. Lengthy decision-making processes\n 3. Lack of standardization across units'
  },
  {
    risk: 'People',
    criteria: '1. Lack of skilled personnel\n 2. High turnover\n 3. Difficulty attracting and retaining top talent\n 4. Resistance to change\n 5. Inadequate resources allocation\n 6. Poor resources capabilities'
  },
  {
    risk: 'Performance and Incentive',
    criteria: '1. Conflicting goals between different units\n 2. Misaligned incentives'
  },
  {
    risk: 'Housing Management',
    criteria: '-'
  },
  {
    risk: 'Suppliers / Third Party Management',
    criteria: '1. Non-performing third parties\n 2. Not commited to non-disclosure agreement\n 3. Lack of due-dilligence of companies assets'
  },
  {
    risk: 'Marketing & Communication',
    criteria: '-'
  },
  {
    risk: 'Facility Management',
    criteria: '-'
  },
  {
    risk: 'Information Technology & Cyber',
    criteria: '-'
  },
  {
    risk: 'Business Continuity',
    criteria: '-'
  },
  {
    risk: 'External Factors (Economic, Environmental, Political and Social Factors)',
    criteria: '-'
  }
];

export const riskTaxonomyCustomer = [
  {
    risk: 'Concentration of Customers',
    criteria: '-'
  },
  {
    risk: 'Contract Management',
    criteria: '-'
  },
  {
    risk: 'Customer Experience & Satisfaction',
    criteria: 'Customer dissatisfaction'
  }
];

export const riskTaxonomyLegal = [
  {
    risk: 'Regulatory Compliance & Changes',
    criteria: '1. Government regulations\n 2. Industry standards\n 3. Environmental regulations'
  },
  {
    risk: 'Regulatory Compliance (Other)',
    criteria: '1. Contractual compliance\n 2. Corporate Social Resposability'
  },
  {
    risk: 'Internal Compliance',
    criteria: '1. Company bylaws\n 2. Process gaps\n 3. Human error\n 4. Employee misconduct\n 5. Lack of awareness\n 6. Inadequate training'
  },
  {
    risk: 'Legal Affairs',
    criteria: 'Litigation risk'
  }
];

export const keyControlConstants = {
  'keyControl': 'User to select the key control',
  'weightageInput': 'Weightage input by user'
};

export const axTableEvents = {
  filter: 'filter',
  refresh: 'refresh',
  export: 'export',
  import: 'import'
};

export const historySlug = {
  risk: 'risk-assessment',
  control: 'risk-assessment-details',
  risk_action: 'risk-action',
  risk_issue: 'risk-issue',
  risk_program: 'risk-program',
  risk_schedule: 'risk-program-schedule',
  risk_working_paper: 'risk-program-working-paper',
  risk_activity: 'risk-program-activity',
  risk_observation: 'risk-program-observation',
  risk_program_issue: 'risk-program-issue',
  risk_recommendation: 'risk-program-recommendation',
  risk_response: 'risk-program-management-response',
  control_issue: 'risk-control-issue',
  enterprise_risk: 'enterprise-risk',
  risk_universe_issue: 'risk-universe-issue'
};

export const tabsUrl =  {
  'Risk Program Overview': {route: '/risk-management/risk-program/risk-program-overview'},
  'Risk Plan': {route: '/risk-management/risk-program/risk-program-plan'},
  'Risk Program Calendar': {route: '/risk-management/risk-program/risk-program-calendar'}
};

export const riskTabs =  [
  {
    'id': 1,
    'status': true,
    'tab_name': riskArabicTranslation.Risk_Program_Overview
  },
  {
    'id': 2,
    'status': true,
    'tab_name': riskArabicTranslation.Risk_Plan
  },
  {
    'id': 3,
    'status': true,
    'tab_name': riskArabicTranslation.Risk_Program_Calendar
  }
];

export const tabsUrlRiskIssue =  {
  'Issues': {route: '/risk-management/risk-issue-tracker/issues'},
  'Actions': {route: '/risk-management/risk-issue-tracker/actions'}
};

export const riskIssueTabs =  [
  {
    'id': 1,
    'status': true,
    'tab_name': riskArabicTranslation.issues
  },
  {
    'id': 2,
    'status': true,
    'tab_name': riskArabicTranslation.actions
  }
];

export const tabsUrlRiskProgramData =  {
  'Issues': {route: '/dashboards/risk-program-issue-tracker/issues'},
  'Actions': {route: '/dashboards/risk-program-issue-tracker/actions'},
  'Recommendations': {route: '/dashboards/risk-program-issue-tracker/recommendations'},
  'Management Responses': {route: '/dashboards/risk-program-issue-tracker/management-responses'}
};

export const riskProgramTabs =  [
  {
    'id': 1,
    'status': true,
    'tab_name': riskArabicTranslation.issues
  },
  {
    'id': 2,
    'status': true,
    'tab_name': riskArabicTranslation.actions
  },
  {
    'id': 3,
    'status': true,
    'tab_name': riskArabicTranslation.recommendations
  },
  {
    'id': 4,
    'status': true,
    'tab_name': riskArabicTranslation.Management_Responses
  }
];

export const scoringmethods = {
  manual: 'manual',
  aggregate: 'aggregate',
  dynamic: 'dynamic'
};

export const riskSummaryQueryParams = {
  risk_category: '',
  risk_category1: '',
  residual_impact: '',
  inherent_impact: '',
  risk_owner: '',
  residual_likelihood: '',
  inherent_likelihood: '',
  risk_manager: '',
  risk_identifier: '',
  risk_velocity: '',
  multi_values: false,
  strategy_category: '',
  risk_plan: '',
  status: '',
  risk_department: '',
  mega_process_v1: '',
  major_process_v1: '',
  mega_process_v2: '',
  major_process_v2: '',
  top_risk: '',
  risk_residual_rating: '',
  risk_inherent_rating: '',
  inherent_rating: '',
  residual_rating: '',
  category: '',
  overall_rating: '',
  key_risk: '',
  risk_appetite: '',
  risk_mitigation_required: '',
  risk_type: '',
  risk_within_appetite: '',
  target_residual_risk: '',
  fiscal_year: '',
  risk_department_level1: '',
  risk_department_level2: '',
  department_level1: '',
  department_level2: '',
  control_type: '',
  control_rating: '',
  risk_department_chart: ''
};

export const organizationFields = {
  risk_department_level1: 'department_level1',
  risk_department_level2: 'department_level2',
  risk_department_level3: 'department_level3',
  risk_department_level4: 'department_level4'
};

export const dashboardIssueTrackerIssueQueryParams = {
  multi_values: false,
  status: '',
  department: '',
  by_time: 'month',
  time: '',
  aging: '',
  owner_name: '',
  due_date_filter: '',
  by_time_filter: '',
  by_due_date: 'month',
  due_date: '',
  plan: '',
  department_level1: '',
  department_level2: '',
  mega_process: '',
  major_process: '',
  rating: '',
  owner: ''
};

export const dashboardIssueTrackerMrQueryParams = {
  multi_values: false,
  status: '',
  department: '',
  by_time: 'month',
  time: '',
  aging: '',
  owner_name: '',
  due_date_filter: '',
  by_time_filter: '',
  by_due_date: 'month',
  due_date: '',
  plan: '',
  department_level1: '',
  department_level2: '',
  mega_process: '',
  major_process: '',
  owner: ''
};

export const dashboardIssueTrackerActionQueryParams = {
  multi_values: false,
  status: '',
  department: '',
  by_time: 'month',
  time: '',
  aging: '',
  owner_name: '',
  due_date_filter: '',
  by_time_filter: '',
  by_due_date: 'month',
  due_date: '',
  plan: '',
  risk_program_name: '',
  overdue: '',
  department_level1: '',
  department_level2: '',
  mega_process: '',
  major_process: '',
  owner: ''
};

export const exportModalWithSocketConstants = {
  open: 'open',
  loading: 'loading',
  closed: 'closed'
};

export const dashboardIssueTrackerObservationQueryParams = {
  multi_values: false,
  status: '',
  department: '',
  by_time: 'month',
  time: '',
  aging: '',
  owner_name: '',
  due_date_filter: '',
  by_time_filter: '',
  by_due_date: 'month',
  due_date: '',
  plan: '',
  overdue: '',
  department_level1: '',
  department_level2: '',
  mega_process: '',
  major_process: '',
  owner: '',
  risk_program_name: ''
};
