import { Dependencies, GridParams, AppliedGlobalFilter, ApprovalCreateData, CommentDataForSubject, CommentStatus, CreateDropdownItem, CreateDropdownItemResponse, GlobalFilterRequest, PermissionListForAuditManagement, ApprovalWidgetData, OrganizationPermissionData, GenericReminderHistory, ScheduleDynamicConfig, UserDetails, RcmTableListQueryParams, AnnexureDynamicFields, GenericSliderData } from 'src/app/core/interface/common.interface';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { HttpService } from 'src/app/core/service/http/http.service';
import { EventEmitter, Injectable } from '@angular/core';
import { ApiEndpoints, ApiStatus, LocalStorageKey, riskUniverseQueryParams } from 'src/app/core/constants';
import { BehaviorSubject, shareReplay, Subject } from 'rxjs';
import { FunctionService } from 'src/app/core/helper/function.helper.service';
import { ActivatedRoute } from '@angular/router';
import { TimelineCaledarHeader, TimelineCalendarData } from 'src/app/core/interface/ax-timeline.interface';
import { SubSink } from 'subsink';
import { AdminUserService } from 'src/app/core/service/control-panel/admin-user.service';
import { StorageService } from 'src/app/core/helper/storage.service';
import { PermissionSocketService } from 'src/app/core/service/socket/permission-socket.service';
import { complianceModuleApiEndpoints } from 'src/app/core/constant/compliance.constant';
@Injectable({
  providedIn: 'root'
})
export class CommonApiService {
  // types may varry for scoring calculation
  public updatedScoringData:any;
  public error:EventEmitter<void> = new EventEmitter<void>();
  public navigationButtonClick:EventEmitter<void> = new EventEmitter<void>();
  public sliderSubmit:EventEmitter<void> = new EventEmitter<void>();
  public sliderAddionalPayload:EventEmitter<Record<string, string | number | boolean>> = new EventEmitter<Record<string, string | number | boolean>>();
  public commentSubmit:EventEmitter<void> = new EventEmitter<void>();
  public sharedValueChange:EventEmitter<boolean> = new EventEmitter<boolean>();
  public timelineReload:EventEmitter<string> = new EventEmitter<string>();
  public dynamicCardChangeSubject:{slug: string, accessList: boolean} | any = new BehaviorSubject<{slug: string, accessList: boolean}>(null);
  public dynamicCardChange:{slug: string, accessList: boolean} = this.dynamicCardChangeSubject.asObservable();
  public redirectionEmit:EventEmitter<boolean> = new EventEmitter<boolean>();
  public isValueSubmit: boolean = false;
  public useCustomVariable: boolean;
  public customVariableToShowHide: boolean;
  public duedateCheckBehaviour: boolean= null;
  public changeInSpanOptionBehaviour:boolean | any = new BehaviorSubject<boolean>(null);
  public changeInThemeBehaviour:boolean | any = new BehaviorSubject<boolean>(null);
  public dropDownId: number;
  public showCommentWidgetSidebar: boolean = false;
  private showSettingsSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showSettings$: Observable<boolean> = this.showSettingsSubject.asObservable();
  // Due to multiple response any as a type need to be added
  public permissionData: OrganizationPermissionData[] |any;
  public customModulePermission!: Record<string, string>[];
  public permissionDataUpdate: EventEmitter<any> = new EventEmitter<any>();
  public customModulePermissionUpdate: EventEmitter<void> = new EventEmitter<void>();
  public showPage: boolean = true;
  public permissionUploadMR: boolean = true;
  public userDetails!:UserDetails;
  public moduleSlug!: string;
  public permissionAddComment: boolean = true;
  public permissionViewComment: boolean = true;
  public enabledFilter: boolean = false;
  public permissionSocketUrl: string = 'permission_update_response';
  public permissionEditComment: boolean = true;
  public permissionDeleteComment: boolean = true;
  public permissionTag: boolean = true;
  public permissionDownload: boolean = true;
  public permissionUpload: boolean = true;
  public permissionDelete: boolean = true;
  public permissionView: boolean = true;
  public permissionModule: string | any;
  private transitionEvent$: string | any = new Subject<number>();
  private commentData$: string | any = new Subject<CommentDataForSubject>();
  // variable used for subscribe the comment changes, value will differ hence use any
  private axCommentData$: string | any = new Subject<CommentDataForSubject>();
  public sidebarStatus: boolean;
  public showNLPsearch: boolean;
  private sidebarStatusCheck$: string | any = new Subject<any>();
  public cudCommentEvent:any = new EventEmitter<any>();
  public commentSidebarCloseEvent:EventEmitter<void> = new EventEmitter<void>();
  // variable to store comment filter label
  public commentFilterLabel: string = '';
  public axCudCommentEvent:any = new EventEmitter<any>();
  public replyComponentChange: any = new EventEmitter<any>();
  // replay eventemitter for ax comment
  public axReplyComponentChange: any = new EventEmitter<any>();
  public languageChange: any = new EventEmitter<any>();
  private commentStatus$: string | any = new Subject<any>();
  public approvalStatus: EventEmitter<string|any> = new EventEmitter<string|any>();
  public riskApprovalDelete: boolean = false;
  public valueStatusChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public countStatus: EventEmitter<void> = new EventEmitter<void>();
  public calculationUpdate: EventEmitter<void> = new EventEmitter<void>();
  public scoringFinalUpdate: EventEmitter<void> = new EventEmitter<void>();
  public scoringcalulationUpdate: EventEmitter<void> = new EventEmitter<void>();

  // events may varry
  public documentUpdate: EventEmitter<any> = new EventEmitter<any>();
  public statusChangePermission: boolean = true;
  public statusChangeWarning: string = '';
  public socketToken!: string;
  public accessList: PermissionListForAuditManagement = {};
  public approvalLoadData!: ApprovalWidgetData;
  public defaultUrlExplorer!: string;
  // hide tab for print
  public hideTab:boolean = false;
  public sliderTitleNew!:string;

  /** sidebar values changes on license type */
  public sidebarChange: any = new EventEmitter<any>();

  /** response values changes on hence use any */
  public languageValueChange: any = new EventEmitter<any>();
  // history values changes on component base
  public requestHistory!: any;
  public reminderData!: GenericReminderHistory;
  // value varies so any given
  public permissionAccess: any = new BehaviorSubject({});
  // value varies so any given
  public updatedPermissionSlug: any = new BehaviorSubject({});
  // value varies so any given
  public menuCustomAccess: any = new BehaviorSubject({});
  public redirectToHome: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // value varies so any given
  public permissionOldValue: any = {};
  public scheduleHeaderDetails!: TimelineCaledarHeader;
  public complianceScheduleData!: {results?:TimelineCalendarData[], next_cursor?:string, count?:number};
  public assignedToDynamicDetails!: ScheduleDynamicConfig;
  public generalApprovalStatus!: Record<string, boolean>;
  public recallSideMenuApi: EventEmitter<void> = new EventEmitter<void>();
  private subs: SubSink = new SubSink();

  public libraryInstance: boolean = false;
  public libraryTarget: string = '';
  public auditStageDynamicDetails!: ScheduleDynamicConfig;
  public startDateDynamicDetails!: ScheduleDynamicConfig;
  public endDateDynamicDetails!: ScheduleDynamicConfig;
  public approversDynamicDetails!: ScheduleDynamicConfig;
  public teamDynamicDetails!: ScheduleDynamicConfig;
  public contributorMRPermission: boolean = false;
  public contributorImprovementPermission: boolean = false;
  public disableAdd:boolean = false;
  public isStractScoreCard: boolean = false;
  public isStractClass!:string;

  /* Variable used to store data. Any format can be use because its used for common  */
  public storeData:any = [];
  private sliderData$: Observable<GenericSliderData> | null = null;
  private sliderDataDependency$: Observable<GenericSliderData> | null = null;
  private sliderDataStatus$: Observable<GenericSliderData> | null = null;
  private sliderDataComment$: Observable<GenericSliderData> | null = null;
  private sliderDataPermissionList$: Observable<GenericSliderData> | null = null;
  public apiUrlSlider!: string;
  public apiSliderParams!: string;

  // value varies so any given
  public selectedLanguage$: any = new BehaviorSubject({});
  public activeChildDependencyIndex: number = 0;
  public esgMetricsPermission:boolean = true;
  public strategyMetricsPermission:boolean = true;
  public isNameValidating: boolean = false;
  public annexureDynamicFields!: AnnexureDynamicFields;
  public userProfileDetailsUpdate: EventEmitter<void> = new EventEmitter<void>();
  // value varies according to the depdendency submodule
  public customDependencySliderEvent: EventEmitter<any> = new EventEmitter<any>();

  /** to recall API in Issue card */
  public recallDetailApi: EventEmitter<void> = new EventEmitter<void>();

  /* value varies hence adding 'any' */
  private formActionSubject:any = new Subject<void>();
  public action$:any = this.formActionSubject.asObservable();
  constructor(private _http: HttpService, private _httpClient: HttpClient, private _helperService: FunctionService, private _admin: AdminUserService,   private _storage: StorageService, private _function:FunctionService,
    private _permissionSocket: PermissionSocketService) { }

  /* Common functions */

  /**  @description Function used to sort data by keyname. Any is used because function used for common for all modules*/
  sortData(data:Record<string, any>[], keyname:string, replaceData:string='', keyTop:string=null) {
    const sortconv = this.splitSortKey(keyname);
    const sortType = sortconv?.type;
    const columnname = sortconv?.columnName;
    const isDescending = sortType === 'dsc';
    return data?.sort((a:Record<string, any>, b:Record<string, any>)=>{
      const valueA =replaceData? a?.[columnname]?.replace(replaceData, ''):a?.[columnname];
      const valueB =replaceData? b?.[columnname]?.replace(replaceData, ''):b?.[columnname];
      if (keyTop && keyTop === valueA) {
        return isDescending ? 1 : -1;
      }

      if (keyTop && keyTop === valueB) {
        return isDescending ? -1 : 1;
      }

      if (valueA === undefined || valueA === null || valueA === '') {
        return isDescending ? -1 : 1;
      }

      if (valueB === undefined || valueB === null || valueB === '') {
        return isDescending ? 1 : -1;
      }

      const isNumericA = !isNaN(parseFloat(valueA));
      const isNumericB = !isNaN(parseFloat(valueB));
      if (isNumericA && isNumericB) {
        const numericValueA = parseFloat(valueA);
        const numericValueB = parseFloat(valueB);
        return isDescending ? numericValueB - numericValueA : numericValueA - numericValueB;

      }
      return isDescending ? valueB.localeCompare(valueA) : valueA.localeCompare(valueB);
    });
  }

  /**  @description Function used to split sort keyname.*/
  splitSortKey(key:string) {
    const splitData = key?.split('_');
    const sortType = splitData?.[0];
    splitData?.splice(0, 1);
    const columnName = splitData?.join('_');
    return {
      type: sortType,
      columnName: columnName
    };
  }


  /* Common functions */
  applyGlobalFilter(data: GlobalFilterRequest) {
    return this._http.post(ApiEndpoints.GlobalFilter, data);
  }

  addToDropdownItem(data: GlobalFilterRequest) {
    return this._http.post(ApiEndpoints.GlobalFilter, data);
  }

  getAppliedFilter(): Observable<AppliedGlobalFilter> {
    return this._http.get(ApiEndpoints.GetGlobalFilter);
  }

  createDropdownItem(data: CreateDropdownItem): Observable<CreateDropdownItemResponse> {
    return this._http.post(ApiEndpoints.CreateDropdownItem, data);
  }

  createCategoryItem(data: FormData|any): Observable<CreateDropdownItemResponse> {
    return this._http.post(ApiEndpoints.categoryCreate, data);
  }

  // For create asset_type
  createAssetTypeItem(data: Record<string, string>): Observable<CreateDropdownItemResponse> {
    return this._http.post(ApiEndpoints.bcmassetcontrolpaneltypecreate, data);
  }

  selectLanguage(data: FormData|any) {
    return this._http.put(ApiEndpoints.languageselector, data);
  }

  getTableauTicket() {
    return this._http.get(ApiEndpoints.tableauTicket);
  }

  suggestionList(field: string, module: string) {
    return this._http.get(`${ApiEndpoints.suggestionList}field=${field}&request_for=${module}`);
  }

  public setCommentData(val: CommentDataForSubject) {
    this.commentData$.next(val);
  }

  get getCommentData() {
    return this.commentData$.asObservable();
  }

  // function to subscribe comment data
  get getAxCommentData() {
    return this.axCommentData$.asObservable();
  }

  setSideBarStatusCheckEvent(val:CommentDataForSubject|any) {
    this.sidebarStatusCheck$.next(val);
  }

  get getSideBarStatusCheckEvent() {
    return this.sidebarStatusCheck$.asObservable();
  }

  getSocketToken() {
    return this._http.get(ApiEndpoints.generateToken);
  }

  public setTransitionValue(data: number) {
    this.transitionEvent$.next(data);
  }

  get getTransitionValue() {
    return this.transitionEvent$.asObservable();
  }

  createDropdownMegaMajor(data: FormData|any): Observable<CreateDropdownItemResponse> {
    return this._http.post(ApiEndpoints.megaMajorCreate, data);
  }

  // get user include client
  allUserList() {
    return this._http.get(`${ApiEndpoints.adminUserGrid}?exclude_client=false`);
  }

  /* common function for RCM Table that takes dynamic api as input */
  getAllRCMListDynamic(apiEndpoint:string, params:RcmTableListQueryParams = riskUniverseQueryParams) {
    let query = '&';
    query += this._function.queryParamsToString(params);
    return this._http.get(`${apiEndpoint}?${query}`);
  }


  /** *to get the all user list including explorer, professional and contributor*/
  allActiveUserList() {
    return this._http.get(`${ApiEndpoints.adminActiveUserGrid}`);
  }

  approvalStakeholderList() {
    return this._http.get(`${ApiEndpoints.stakeholderUsers}?exclude_client=false`);
  }

  fileDownload(url: string): Observable<any | any[]>{
    return  this._httpClient.get(environment.baseUrl+url, {responseType: 'blob'});
  }

  documentDownload(url: string): Observable<any | any[]>{
    return  this._httpClient.get(url, {responseType: 'blob'});
  }

  public setCommentStatus(val: CommentStatus | any) {
    this.commentStatus$.next(val);
  }

  get getCommentStatus() {
    return this.commentStatus$.asObservable();
  }

  // To get the approvel data list
  getApprovalList(url?: string, gridParams?: GridParams | any, requestParams?: string) {
    const approvalList = this._helperService?.queryParamsToString(gridParams);
    if (requestParams !== undefined){
      return this._http.get(`${url}?${approvalList}&${requestParams}`);
    }
    return this._http.get(`${url}?${approvalList}`);
  }

  getGroupApprovalDetail(objId: string, params: Record<string, string>) {
    const requestParams = this._helperService?.queryParamsToString(params);
    return this._http.get(`${ApiEndpoints.groupApprove}${objId}/detail/?${requestParams}`);
  }

  getApprovalTimelineList(gridParams?: GridParams, requestParams?: string) {
    const approvalList = this._helperService?.queryParamsToString(gridParams);
    if (requestParams !== undefined){
      return this._http.get(`${ApiEndpoints.getApprovalTimeline}?${approvalList}&${requestParams}`);
    }
  }

  // To Create the approvel data list
  getCreateApproval(apiUrl: string, data: FormData) {
    return this._http.post(`${apiUrl}`, data);
  }

  // To Create the approvel data list
  getLockRevise(gridParams: ApprovalCreateData | any) {
    const keys: any = Object.keys(gridParams);
    const value: any = Object.values(gridParams);
    let query = '?';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(`${ApiEndpoints.approverLockRevise}${query}`);
  }

  // get approver List
  getApproverList() {
    return this._http.get(`${ApiEndpoints.approverList}`);
  }

  // TO Approve actions
  Approver(objId: string, action: string, data?:FormData, isGroup: boolean = false) {
    return this._http.post(`${isGroup? ApiEndpoints.groupApprove : ApiEndpoints.approve}${objId}/${action}`, data);
  }

  getFieldSecurityList(module: string) {
    return this._http.get(`${ApiEndpoints.fieldSecurityList}?slug=${module}`);
  }

  getChangeInSpanOptionsBehaviour(): Observable<boolean> {
    return this.changeInSpanOptionBehaviour.asObservable();
  }

  // this method is added for handling au-select closing from parent .setting changeInSpanOptionBehaviour true in will be leads to closing of au-select. Importantly set as false after the use
  setChangeInSpanOptionsBehaviour(data: boolean) {
    this.changeInSpanOptionBehaviour.next(data);
  }

  validateName(module: string, name: string) {
    return this._http.get(`${ApiEndpoints.validateName}?slug=${module}&name=${name}`);
  }

  // To check if name is already existing
  nameValidate(module: string, name: Record<string, string>) {
    return this._http.post(`${ApiEndpoints.validateName}?slug=${module}`, name);
  }

  getApprovalType(content_type: number) {
    return this._http.get(`${ApiEndpoints.getApprovalType}?content_type=${content_type}`);
  }

  getDynamicDependencies(apiUrl: string) {
    return this._http.get(`${apiUrl}`);
  }

  updateDynamicDependencies(apiUrl: string, data: Dependencies[] | Dependencies) {
    return this._http.put(`${apiUrl}`, data);
  }

  // To check whether query param exist or not
  isParamExist(router: ActivatedRoute) {
    if ((Object?.keys(router?.snapshot?.queryParams).length === 0) || (Object?.keys(router?.snapshot?.queryParams).length==1 && Object?.keys(router?.snapshot?.queryParams)[0]=== 'multi_values')) {
      return true;
    }
    return false;
  }

  // to get reminder history
  genericReminderHistory(objectID:string, gridParams:GridParams, slug:string) {
    const sectionQueryParam = this._helperService?.queryParamsToString(gridParams);
    return this._http.get(`${ApiEndpoints.genericReminderHistory}${objectID}/list/?slug=${slug}&${sectionQueryParam}`);
  }

  /* function to know the button click event from parent to child */
  setIsValueSubmit(value: boolean) {
    this.isValueSubmit = value;
    this.sharedValueChange.emit(this.isValueSubmit);
  }

  /* function to know the button click event from parent to child, type varying hence any */
  setIsValueChange(value: string, accessList?: boolean) {
    this.dynamicCardChangeSubject.next({slug: value, accessList: accessList});
  }

  /* function to know the redirection click event from parent to child */
  redirectionEmits(value: boolean = false) {
    this.redirectionEmit.emit(value);
  }

  /**
   * @description returning the required permission according to slug
   */
  getAllPermissionInOne(slug: string, withKey: boolean = false){
    const permission = !withKey ? this.permissionOldValue?.[slug]?.permission?.[slug] : this.permissionOldValue?.[slug]?.permission;
    return permission;
  }

  // returning the required permission according to slug for modules not in permission guard, this function is used in document archive for reference
  getPermissionSingleApi(slug: string, withKey: boolean = false, isSocket: boolean = false) {
    // res value varies so any given
    return new Promise<boolean>((resolve: any) => {
      if (this.permissionOldValue?.[slug]?.permission && !isSocket) {
        const permission = !withKey ? this.permissionOldValue?.[slug]?.permission?.[slug] : this.permissionOldValue?.[slug]?.permission;
        resolve(permission);
      } else {
        // res value varies so any given
        this._admin.getPermissionAccess(slug).subscribe((res: any) => {
          const result = {};
          result[slug] = {permission: res, status: true};
          this.permissionOldValue = {...this.permissionOldValue, ...result};
          const permission = !withKey ? this.permissionOldValue?.[slug]?.permission?.[slug] : this.permissionOldValue?.[slug]?.permission;
          resolve(permission);
        });
      }
    });
  }

  // Function used for the group response permission with the help of request for
  getPermissionGroupApi(slug: string, reqFor: string, reqForApiSlug:string, is_socket: boolean = false) {
    // res value varies so any given
    return new Promise<boolean>((resolve: any) => {
      if (this.permissionOldValue?.[`${reqFor}_group`] && !is_socket) {
        const permission =  this.permissionOldValue?.[`${reqFor}_group`];
        resolve(permission);
      } else {
        // res value varies so any given
        this._admin.getPermissionGroupAccess(slug, reqForApiSlug).subscribe((res: any) => {
          const result = {};
          result[`${reqFor}_group`] = res;
          this.permissionOldValue = {...this.permissionOldValue, ...result};
          const permission = this.permissionOldValue?.[`${reqFor}_group`];
          resolve(permission);
        });
      }
    });
  }

  /**
   * @description returning the required permission according to slug
   */
  approvalFeatureStatus(){
    return this._http.get(`${ApiEndpoints.generalApprovalStatus}`);
  }

  /**
   * @description returning the required permission according to slug
   * @reason any used beacuse its a general api and curently full data not added the response will change with data
   */
  getApprovalFeatureStatus(moduleKey: string){
    return new Promise<boolean>((resolve: any) => {
      if (!this.generalApprovalStatus){
        this.subs.add(
          this.approvalFeatureStatus().subscribe((res: any) => {
            if (res?.['status'] === ApiStatus.ok) {
              this.generalApprovalStatus = res;
              resolve(this.generalApprovalStatus[moduleKey]);
            }
          })
        );
      } else {
        resolve(this.generalApprovalStatus[moduleKey]);
      }
    });
  }

  // To update the theme id
  addActiveTheme(data:{theme_mode:number}) {
    return this._http.put(ApiEndpoints.currentThemeId, data);
  }

  // To get theme list
  getThemeList() {
    return this._http.get(ApiEndpoints.themeList);
  }

  // To Get table Group common api data for grid
  getcommonTableGroup(url: string, params:GridParams) {
    let query = '';
    query += this._helperService.queryParamsToString(params);
    return this._http.get(`${url}?${query}`);
  }

  // To create generic tag any is required used as common api
  createGenerateTag(data: FormData|any): Observable<CreateDropdownItemResponse> {
    return this._http.post(ApiEndpoints.genericTagCreate, data);
  }

  // To get dropdown data for organization fields
  getOrganizationDataList(component: string|number, data: {parents?: number[]}) {
    return this._http.post(`${ApiEndpoints.organizationDataList}?component=${component}`, data);
  }

  /*  To get dropdown data for organization fields in public url */
  getOrganizationDataListPublic(url:string, component: string|number, data: {parents?: number[]}) {
    return this._httpClient.post(`${url}${ApiEndpoints.organizationDataList}?component=${component}`, data);
  }

  /** *to get the all user list including explorer, professional and contributor and all user license*/
  allUsersActiveList() {
    return this._http.get(`${ApiEndpoints.allActiveUsersIncludeAllLicense}`);
  }

  // to get groups list
  getWorkflowGroups() {
    return this._http.get(`${ApiEndpoints.workflowGroups}`);
  }

  /* API call function to update the lock status of a particular field */
  updateLockDataMR(data: {lock_fields: Record<string, boolean>}, id: string, requestFor: string) {
    return this._http.put(`${ApiEndpoints.managementResponse + id}/lock/edit/?request_for=${requestFor}`, data);
  }

  public setSelectedLanguage(data: number) {
    this.selectedLanguage$.next(data);
  }

  get getSelectedLanguage() {
    return this.selectedLanguage$.asObservable();
  }

  // to get the dynamic listing by passing the api url from slider
  dynamicListApi(apiUrl: string, flag: any, callListApi: boolean): Observable<GenericSliderData> {
    if (this.apiUrlSlider!==flag || this.apiSliderParams !== apiUrl ){
      this.resetSliderData();
    }
    if (!this.sliderData$) {
      if (flag) {
        this.apiUrlSlider = flag;
      }
      if (apiUrl) {
        this.apiSliderParams = apiUrl;
      }
      if (!callListApi) {
        this.sliderData$ = this._http.get(apiUrl).pipe(
          // Share and replay the latest value
          shareReplay(1)
        );
      } else {
        this.sliderData$ = this._http.get(apiUrl);
      }
    }
    return this.sliderData$;
  }

  // to get the dynamic listing by passing the api url from slider
  dynamicCommentPermissionListApi(apiUrl: string, flag: string, callListApi: boolean): Observable<GenericSliderData> {
    if (this.apiUrlSlider!==flag){
      this.resetSliderData();
    }
    if (!this.sliderDataPermissionList$) {
      if (flag){
        this.apiUrlSlider = flag;
      }
      if (callListApi) {
        this.sliderDataPermissionList$ = this._http.get(apiUrl);
      } else {
        this.sliderDataPermissionList$ = this._http.get(apiUrl).pipe(
          // Share and replay the latest value
          shareReplay(1)
        );
      }
    }
    return this.sliderDataPermissionList$;
  }

  // to get the dynamic listing by passing the api url from slider
  dynamicListApiLoadDependency(apiUrl: string, flag: any): Observable<GenericSliderData> {
    if (this.apiUrlSlider!==flag){
      this.resetSliderData();
    }
    if (!this.sliderDataDependency$) {
      if (flag){
        this.apiUrlSlider = flag;
      }
      this.sliderDataDependency$ = this._http.get(apiUrl).pipe(
        // Share and replay the latest value
        shareReplay(1)
      );
    }
    return this.sliderDataDependency$;
  }

  // to get the dynamic listing by passing the api url from slider
  dynamicListApiStatus(apiUrl: string, flag: string ): Observable<GenericSliderData> {
    if (this.apiUrlSlider!==flag){
      this.resetSliderData();
    }
    if (!this.sliderDataStatus$) {
      if (flag){
        this.apiUrlSlider = flag;
      }
      this.sliderDataStatus$ = this._http.get(apiUrl).pipe(
        // Share and replay the latest value
        shareReplay(1)
      );
    }
    return this.sliderDataStatus$;
  }

  // Optionally, you can implement a reset function if needed
  resetSliderData() {
    this.sliderData$ = null;
    this.sliderDataStatus$ = null;
    this.sliderDataDependency$ = null;
    this.sliderDataComment$=null;
    this.sliderDataPermissionList$=null;
  }

  // to get the dynamic listing by passing the api url from slider
  dynamicGetCommentApi(apiUrl: string, resolveFilter: string, flag: string, apiCallFlag: boolean) {
    if (this.apiUrlSlider!==flag){
      this.resetSliderData();
    }
    if (!this.sliderDataStatus$) {
      if (flag){
        this.apiUrlSlider = flag;
      }
      const params = {
        resolve_filter: resolveFilter
      };
      const queryParams = this._helperService?.queryParamsToString(params);
      if (!apiCallFlag) {
        this.sliderDataComment$ = this._http.get(`${apiUrl}&${queryParams}`).pipe(
          // Share and replay the latest value
          shareReplay(1)
        );
      } else {
        this.sliderDataComment$ = this._http.get(`${apiUrl}&${queryParams}`);
      }
    }
    return this.sliderDataComment$;
  }

  // to get the dynamic listing by passing the api url from slider
  dynamicCommentResolveApi(apiUrl: string) {
    return this._http.put(apiUrl);
  }

  // to get the dynamic listing by passing the api url from slider
  getDropdownList(apiUrl: string) {
    return this._http.get(apiUrl);
  }

  // to get the dynamic listing by passing the api url from slider - post
  getDropdownListPost(apiUrl: string, data: Record<string, number[]>) {
    return this._http.post(apiUrl, data);
  }

  // to save side bar form
  saveSideBarForm(apiUrl: string, data: any) {
    return this._http.put(apiUrl, data);
  }

  /* get Selected List  Data is varying */
  getSelectedList(name: string, limit: number = 20, cursor: string | null = null, params:HttpParams, data: any) {
    let queryParams:string = '';
    if (params !== undefined) {
      delete params?.['limit'];
      delete params?.['count'];
      delete params?.['cursor'];
      queryParams = this._helperService?.queryParamsToString(params);
    }
    return this._http.post(`${ApiEndpoints.selectedItemList}?request_for=${name}&limit=${limit}&count=true&${cursor !== null ? (`cursor=${cursor}&`) : ''}${queryParams}`, data);
  }

  /* put data into bulk edit   Data can vary so given any */
  setBulkEdit(name: string, contentType: number, data: any, params: HttpParams) {
    let queryParams:string = '';
    if (params !== undefined) {
      delete params?.['limit'];
      delete params?.['count'];
      queryParams = this._helperService?.queryParamsToString(params);
    }
    if (queryParams?.substring(queryParams?.length - 1) === '&') {
      queryParams = queryParams.substring(0, queryParams?.length - 1);
    }
    return this._http.post(`${ApiEndpoints.dynamicBulkEdit}${name}/${contentType}/edit/?${queryParams}`, data);
  }

  /* get Selected List */
  getDynamicInputsBulkEdit(moduleName: string, content_type: number) {
    return this._http.get(`${ApiEndpoints.dynamicInputList}${moduleName}/${content_type}/list/`);
  }

  dynamicCreateCommentApi(apiUrl: string, data: FormData) {
    return this._http.post(apiUrl, data);
  }

  // to get the dynamic listing by passing the api url from slider
  dynamicEditCommentApi(apiUrl: string, data: FormData) {
    return this._http.put(apiUrl, data);
  }

  // to get the dynamic listing by passing the api url from slider
  dynamicDeleteCommentApi(apiUrl: string) {
    return this._http.delete(apiUrl);
  }

  // function for implementing permission socket connection
  openPermissionSocket() {
    this.userDetails = this._storage.getLocalStorage(LocalStorageKey?.userDetails);
    if (this.socketToken) {
      this._permissionSocket.connection(this.socketToken, this.permissionSocketUrl).subscribe((data: {module_slug?: string, users_list?: number[]}) => {
        if (data?.['module_slug'] && (data?.['users_list']?.includes(this.userDetails?.user_id?.id) || data?.['users_list']===null)) {
          this.updatedPermissionSlug?.next(data);
        }
      });
    } else {
      this.getSocketToken().subscribe((data: {
                token: string
                status: number
              }) => {
        this.socketToken = data?.token;
        this.openPermissionSocket();
      });
    }
  }

  /** To get the network chart nodes */
  getNetworkChartNodes(objId: string, slug: string, params?: Record<string, string>) {
    const requestParams = this._helperService?.queryParamsToString(params);
    return this._http.get(`${ApiEndpoints.networkChartGetNodes}${slug}${objId}/?${requestParams}`);
  }

  /* to get audit grid template **/
  getGridTableList(objectId: string, slug: string) {
    return this._http.get(`${ApiEndpoints.gridCustomTemplate}${objectId}/details?slug=${slug}`);
  }

  /* to get grid template list **/
  getGridTemplateList(slug: string) {
    return this._http.get(`${ApiEndpoints.gridTempList}?slug=${slug}`);
  }

  /* To get default template **/
  getDefaultTemplate(slug: string) {
    return this._http.get(`${ApiEndpoints.auditDefaultTemplate}?slug=${slug}`);
  }

  /* to update default template **/
  updateDefaultTemplate(data:number, slug: string) {
    return this._http.post(`${ApiEndpoints.auditDefaultTemplate}?slug=${slug}&template_id=${data}`);
  }

  /**
   * @description Function used to get comment text
   * @author Ratheesh K
   * @date 16/05/2024
   */
  getCommentsText(objectId:string) {
    return this._http.get(`${ApiEndpoints.comment}${objectId}/detail/`);
  }

  /* to get module dependencies **/
  getModuleDependencies(slug:string) {
    return this._http.get(`${ApiEndpoints.moduleDependencies}/${slug}/`);
  }

  /* to get module dependencies **/
  getDependenciesForModule(slug:string) {
    return this._http.get(`${ApiEndpoints.dependenciesModule}/${slug}/`);
  }

  /* to set module dependencies **/
  setImportForFileUploadWithDependencies(formData: FormData, slug: string){
    return this._http.post(`${ApiEndpoints.importDependencies}/${slug}/`, formData);
  }

  // function to list Documents
  getDocumentList(objectId:string, contentType:string,  params: GridParams, listApi?: string) {
    const queryValue = `${this._function.queryParamsToString(params)}`;
    if (listApi) {
      return this._http.get(`${listApi}?${queryValue}`);
    } else if (!listApi) {
      return this._http.get(`${ApiEndpoints.fileUplad}${contentType}/${objectId}/list/?${queryValue}`);
    }
  }

  // Upload api for the document
  uploadDocument(file:FormData, id:string, reqFor:string){
    return this._http.postWithProgress(`${ApiEndpoints.newFileUpload + id}/upload?request_from=app&request_for=${reqFor}`, file);
  }

  // Delete api for the document
  documentsDelete(reqFor:string, objId:string, id:string, deleteApi?: string) {
    if (!deleteApi) {
      return this._http.delete(`${ApiEndpoints.newFileUpload}${objId}/${id}/delete?request_for=${reqFor}`);
    } else if (deleteApi)  {
      return this._http.delete(`${deleteApi}`);
    }
  }

  /* To delete annexure **/
  dynamicDeleteAnnexure(apiUrl:string) {
    return this._http.delete(`${apiUrl}`);
  }

  /**
  * @description  update api for the document ,type varies here, so using any
  */
  updateDocument( formData: FormData|any, reqFor:string, objId:string, id:string) {
    return this._http.put(`${ApiEndpoints.newFileUpload}${objId}/${id}/edit?request_from=app&request_for=${reqFor}`, formData);

  }

  /* To create annexure, any used because values doesn't exist on FormData **/
  createAnnexure(reqFor:string, data: FormData|any, apiUrl?:string) {
    let formDataHasFile = false;
    for (const value of data?.values()) {
      if (value instanceof File) {
        formDataHasFile = true;
      }
    }
    if (formDataHasFile) {
      return this._http.postWithProgress(`${ApiEndpoints.createGenericDocument}?request_for=${reqFor}`, data);
    }

    if (apiUrl) {
      return this._http.post(`${apiUrl}`, data);
    }
    return this._http.post(`${ApiEndpoints.createGenericDocument}?request_for=${reqFor}`, data);
  }

  /* To update annexure , any used because values doesn't exist on FormData**/
  updateAnnexure(objID:string, reqFor:string, data: FormData|any) {
    let formDataHasFile = false;
    for (const value of data?.values()) {
      if (value instanceof File) {
        formDataHasFile = true;
      }
    }
    if (formDataHasFile) {
      return this._http.putWithProgress(`${ApiEndpoints.updateGenericDocument}${objID}/?request_for=${reqFor}`, data);
    }
    return this._http.put(`${ApiEndpoints.updateGenericDocument}${objID}/?request_for=${reqFor}`, data);
  }

  /* To get annexure list **/
  getAnnexureList(params:Record<string, string|boolean|number>) {
    let query = '?';
    query += this._helperService.queryParamsToString(params);
    return this._http.get(`${`${ApiEndpoints.genericDocumentList}`}${query}`);
  }

  /* To get annexure detail **/
  getAnnexureDetail(objID: string) {
    return this._http.get(`${ApiEndpoints.genericDocumentDetail}${objID}`);
  }

  /* To get annexure dynamic field lists **/
  getAnnexureDynamicFields(parent: string) {
    return this._http.get(`${parent}${ApiEndpoints.annexureDynamicList}`);
  }

  /* To delete annexure **/
  deleteAnnexure(objectId:string, params:Record<string, string>) {
    let query = '?';
    query += this._helperService.queryParamsToString(params);
    return this._http.delete(`${ApiEndpoints.genericDocumentDelete}${objectId}/${query}`);
  }


  /* To attach annexure template**/
  attachAnnexureTemplate(params:Record<string, string>, objId:string, data: FormData) {
    let query = '?';
    query += this._helperService.queryParamsToString(params);
    return this._http.post(`${ApiEndpoints.tagGenericDocument}${objId}/${query}`, data);
  }

  /* function to set slider submit*/
  setSliderSubmit() {
    this.sliderSubmit.emit();
  }

  /**
     * @description API call function for getting scheudule activity details.
    */
  getComplianceAuditScheduleCalendarActivity(params: {plan?:number, q?:string, cursor?:string, limit?:number, count?:boolean, request_for?: string} = {}, objectId:string) {
    let query = '';
    const convqueryparams = this._function.queryParamsToString(params);
    if (convqueryparams) {
      query = '?';
      query += convqueryparams;
    }
    return this._http.get(`${complianceModuleApiEndpoints.complianceScheduleCalendarActivity}${objectId}/${query}`);
  }

  /* function to set slider payload*/
  setAdditionalPayload(value: Record<string, string | number | boolean>) {
    this.sliderAddionalPayload.emit(value);
  }

  /* function to know navigation click*/
  setNavigationClick() {
    this.showSettingsSubject.next(!this.showSettingsSubject.value);

  }

  /* function to set comment submit*/
  setCommentSubmit() {
    this.commentSubmit.emit();
  }

  /* To get risk scoring tab List **/
  getRiskTabList() {
    return this._http.get(ApiEndpoints.riskTabList);
  }

  /* To get risk scoring tab Table Values **/
  getRiskScoringTableValues(gridParams:{overall_rating?: boolean; module_slug?: string; team_id?: number}) {
    let query = '?';
    query += this._helperService.queryParamsToString(gridParams);
    return this._http.get(`${ApiEndpoints.getscoringTable}${query}`);
  }

  /* To get risk scoring logic fields **/
  getRiskScoringLogicFields(gridParams:{
    overall_rating: boolean;
    module_slug: string;
    team_id: number;
}) {
    let query = '?';
    query += this._helperService.queryParamsToString(gridParams);
    return this._http.get(`${ApiEndpoints.logicFieldList}${query}`);
  }

  /* To update risk scoring logic fields **/
  updateLogicFields(gridParams:GridParams, formData:FormData | any) {
    let query = '?';
    query += this._helperService.queryParamsToString(gridParams);
    return this._http.post(`${ApiEndpoints.updateLogic}${query}`, formData);
  }

  // Edit Risk Scorinng Range, data may vary so using any
  editRiskScoreRange( data : FormData | any) {
    return this._http.post(ApiEndpoints.updateRange, data);
  }

  /* function to know dynamic form submission */
  triggerFormSubmission() {
    this.formActionSubject.next();
  }

  /* To delete issue **/
  dynamicDelete(apiUrl:string) {
    return this._http.put(`${apiUrl}`);
  }
}
