import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { apiEndPoints, varibaleName} from '../constant/jwt.constants';
import {Buffer} from 'buffer';
import { HttpService } from '../service/http/http.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class JwtTokenService {

  private refreshTokenTimeout!:  ReturnType<typeof setTimeout>;
  public worker!: Worker;
  constructor(private _http: HttpService, private _cookieService :CookieService ) { }
  // USED to refresh token
  public startRefreshTokenTimer(refreshToken: string) {
    if (this.worker) {
      this.worker.terminate();
    }
    try {
      this.worker = new Worker(new URL('src/app/core/workers/background-timer.worker.ts', import.meta.url));
      this.refreshWorker(refreshToken);
    } catch {
      this.refereshTimeout(refreshToken);
    }
  }

  // call webworker for background task
  refreshWorker(refreshToken: string) {
    if (this.experTime !== -1) {
      if (typeof Worker !== 'undefined') {
        this.worker.onmessage = ({data}: {time: number, token: string}| any) => {
          if (this.getToken !== '') {
            this.refreshToken(data.token).subscribe((res: {access: string, refresh: string}) => {
              if (this.getToken !== '') {
                this.setToken(res?.access,  res?.refresh);
                if (this.experTime !== -1) {
                  this.worker.postMessage( {time: this.experTime, token: res?.refresh});
                }
              } else if ( this.worker ){
                this.worker.terminate();
              }

            });
          } else if ( this.worker ){
            this.worker.terminate();
          }
        };
        this.worker.postMessage({time: this.experTime, token: refreshToken});
      } else {
        this.refereshTimeout(refreshToken);
      }
    }

  }

  // used to get expery time
  get experTime(): number  {
    const jwtToken = this.getTokenData;
    if (Object.values(jwtToken)?.length  !==  0) {
      const expires = new Date(jwtToken.exp * 1000);
      const timeout = expires.getTime() - Date.now() - (60 * 1000);
      if ( timeout >= 0) {
        return timeout;
      }
      return -1;
    }
    return -1;
  }

  // used to refresh using normal timer
  refereshTimeout(refreshToken: string) {
    if (this.experTime !== -1) {
      this.refreshTokenTimeout = setTimeout(() => this.refreshToken(refreshToken).subscribe((res: {access: string, refresh: string}) => {
        this.setToken(res?.access, res?.refresh);
        this.refereshTimeout(res?.refresh);
      }), this.experTime);
    }
  }

  // refresh api
  refreshToken(refreshToken: string) {
    return this._http.post(apiEndPoints.refresh, {refresh: refreshToken});
  }

  // stop refresh api call
  public stopRefreshTokenTimer() {

    if ( this.worker ){
      this.worker.terminate();
    }
    if (this.refreshTokenTimeout) {
      clearTimeout(this.refreshTokenTimeout);
    }
  }



  // set token to cookies
  public setToken(acess: string, refersh : string) {
    // Checking production variable for setting values
    if (environment.production && environment.protocol === 'https://') {
      document.cookie = `${varibaleName.token}=${acess};path=/;Domain=.${environment.cookieDomain};secure`;
      document.cookie = `${varibaleName.refreshToken}=${refersh};path=/;Domain=.${environment.cookieDomain};secure`;
    }   else if (environment.production && environment.protocol === 'http://') {
      document.cookie = `${varibaleName.token}=${acess};path=/;`;
      document.cookie = `${varibaleName.refreshToken}=${refersh};path=/;`;
    } else {
      document.cookie = `${varibaleName.token}=${acess};path=/;`;
      document.cookie = `${varibaleName.refreshToken}=${refersh};path=/;`;
    }
  }

  // delete token from cookies
  public deleteToken() {
    this._cookieService.delete(varibaleName.token);
    this._cookieService.delete(varibaleName.refreshToken);
  }


  // get  token from cookies
  public get getToken(): string{
    if (this._cookieService.get(varibaleName.token)) {
      return this._cookieService.get(varibaleName.token);
    }
    return '';
  }

  // get  refresh token from cookies
  public get getRefreshToken(): string{
    if (this._cookieService.get(varibaleName.refreshToken)) {
      return this._cookieService.get(varibaleName.refreshToken);
    }
    return '';
  }


  // get data from cookies
  public get getTokenData(): Record<string, string> | any{
    try {
      if (this._cookieService.get(varibaleName.token)) {
        return  this.getTokenValues(this._cookieService.get(varibaleName.token));
      }
      return {};
    } catch {
      return {};
    }
  }

  // convert token
  public  getTokenValues(token: string) {
    try {
      const jsonPayload = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
      return jsonPayload;
    } catch {
      return {};
    }
  }
}
