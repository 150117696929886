import { Directive, ElementRef, Input, HostListener, OnChanges } from '@angular/core';

@Directive({
  selector: '[appNumberonly]'
})
export class NumberonlyDirective implements OnChanges {
  @Input() min!: number;
  @Input() max!: number;
  @Input() decimal!: boolean;
  // flag for shift focus to next field on tabkey press
  @Input() shiftFocusOnTabKey: boolean = false;

  /* Input used for enable/disable the pipe */
  @Input() numberOnlyEnable: boolean = true;
  private regex: RegExp = new RegExp('^[0-9]*$');
  private specialKeys: string[] = ['Backspace', 'ArrowLeft', 'ArrowRight'];
  constructor(private elementRef: ElementRef) {

  }

  /**
   * Key board action
   * @param event
   */
  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if (this.numberOnlyEnable) {
    // shift focus to next field if user presses tab key (for accessibility)
      if (event?.key === 'Tab') {
        return this.shiftFocusOnTabKey;
      }
      if (this.specialKeys.includes(event?.key)) {
        return;
      }
      const inputValue: string = this.elementRef.nativeElement?.value?.concat(event?.key);
      if (inputValue && !String(inputValue).match(this.regex)) {
        event?.preventDefault();
      }
      if (this.min && inputValue) {
        if (+inputValue < this.min) {
          event?.preventDefault();
        }
      }

      /**  @description To limit the maximum value in the input. Consider no limit if the max variable has no value.  */
      if (this.max && inputValue) {
        if (+inputValue > this.max) {
          event?.preventDefault();
        }
      }
      return;
    }
    return;
  }

  /**
   * Copy Paste action
   * @param event
   */
  @HostListener('paste', ['$event']) onPaste(event: { originalEvent: any; preventDefault: () => void; }) {
    if (this.numberOnlyEnable) {
      const clipboardData = (event?.originalEvent || event)?.clipboardData?.getData('text/plain');
      if (clipboardData) {
        const regEx = this.regex;
        if (!regEx.test(clipboardData)) {
          event?.preventDefault();
        }
      }
      return;
    }
    return;
  }

  ngOnChanges(): void {
    if (this.decimal) {
      this.regex = new RegExp('^[0-9.]*$');
    }
  }
}
