
import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { concat, filter, first, interval } from 'rxjs';
import { SweetAlertService } from '../helper/sweet-alert.service';
import { warningSwLatest } from '../warning-messages';

@Injectable({
  providedIn: 'root'
})
export class ServiceWorkerHandlerService {

  constructor(
    private _updates: SwUpdate,
    private _sweetAlert: SweetAlertService,
    private _appRef: ApplicationRef
  ) {

  }

  // used to check latest version of application and reload
  checkForUpdate() {
    this._updates.versionUpdates
      .pipe(filter((evt: VersionReadyEvent): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(async () => {
        const submit =  await this._sweetAlert.confirm(warningSwLatest.headText, warningSwLatest.subText,
          warningSwLatest.buttonOpt_1, warningSwLatest.buttonOpt_2);
        if (submit) {
          document.location.reload();
        }
      });
  }

  // used to check latest version of on check navigation every one hour
  // for more information https://angular.io/guide/service-worker-communications
  updateCheckOnNavigation() {
    const appIsStable$ = this._appRef.isStable.pipe(first((isStable: boolean) => isStable === true));
    const everyOneHours$ = interval(1 * 60 * 60 * 1000);
    const everyOneHoursOnceAppIsStable$ = concat(appIsStable$, everyOneHours$);

    everyOneHoursOnceAppIsStable$.subscribe(async () => {
      try {
        const updateFound = await this._updates.checkForUpdate();
      } catch (err) {
        // cache
      }
    });
  }
}
