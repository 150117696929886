export const mfaAPI=  {
  mfaQRCode: 'mfa-qrcode/',
  statusCheck: 'mfa-status/',
  mfaBackupKey: 'mfa-get-backup/',
  mfaVerifyToken: 'mfa-qrcode-verify/',
  mfaStatusUpdate: 'admin/features/',
  mfaStatusUpdateParams: '/mfa_edit?feature_slug=two-factor-authentication',
  mfaStatus: 'admin/features/list?slug=two-factor-authentication',
  mfaEmail: 'mfa-email/',
  mfaSms: 'mfa-sms/',
  emailOtpResend: 'mfa-email-otp/'
};
