import { BrowserCheckService } from './core/service/BrowserCheck/browser-check.service';
import { Component, Renderer2} from '@angular/core';
import { ServiceWorkerHandlerService } from './core/service/service-worker-handler.service';
import { JwtTokenService } from 'src/app/core/helper/jwt-token.service';
import { MfaValidateService } from 'src/app/core/service/mfa-validate.service';
import { AccessibilityService } from 'src/app/core/service/accessibility.service';
import { StorageService } from 'src/app/core/helper/storage.service';
import { CommonApiService } from 'src/app/core/service/common/common-api.service';
import { themePreference } from 'src/app/core/constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public activeTheme!: string;
  public activeFont!: string;
  public activeStyle!: Record<string, string>;
  constructor(
    private _sw: ServiceWorkerHandlerService,
    private _browserCheck: BrowserCheckService,
    private  _jwtService : JwtTokenService,
    private _mfaService: MfaValidateService,
    private _accessibilityService: AccessibilityService,
    private _renderer: Renderer2,
    private _storage: StorageService,
    private _commonApi: CommonApiService
  ) {
    this._sw.checkForUpdate();
    this._sw.updateCheckOnNavigation();
    this._browserCheck.checkForUpdate();
    this._accessibilityService.addClassOnTabPress();
    const user = _jwtService.getTokenData;
    themePreference['ui_preference'] = user?.['ui_preference'];
    if (this._mfaService.getRedirectionStatus === 'true') {
      this._mfaService.mfaAuth = false;
    } else if (this._mfaService.getRedirectionStatus === 'false') {
      this._mfaService.mfaAuth = true;
    } else if (user?.mfa_status && user?.mfa_mandatory) {
      this._mfaService.mfaAuth = true;
    } else {
      this._mfaService.mfaAuth = false;
    }
    const refreshToken = this. _jwtService.getRefreshToken;
    if (refreshToken !== '') {
      this._jwtService.startRefreshTokenTimer(refreshToken);
    }

    this.removeThemeClass();
    this.activeStyle = this._storage.getLocalStorage('activeStyle');
    const mode = user?.theme_mode;
    this.activeTheme = this.activeStyle?.activeTheme ? this.activeStyle.activeTheme : mode === 'Dark Mode' ?'dark' :mode === 'Light Mode' ?'light':'';
    this.activeFont = this.activeStyle?.activeFont ? this.activeStyle.activeFont : 'medium';
    const defaultTheme = this.activeTheme?this.activeTheme:window.matchMedia?.('(prefers-color-scheme: dark)').matches?'dark':'light';
    this._commonApi?.changeInThemeBehaviour.next(defaultTheme);
    this._storage.setLocalStorage('activeStyle', {activeFont: this.activeFont, activeTheme: this.activeTheme});

    if (this.activeTheme === 'dark' || (this.activeTheme !== 'light' && window.matchMedia?.('(prefers-color-scheme: dark)').matches)) {
      this._renderer.addClass(document.getElementsByTagName( 'html' )[0], 'ax-dark-mode');
    } else {
      this._renderer.addClass(document.getElementsByTagName( 'html' )[0], 'ax-light-mode');
    }
  }

  // To remove class according to theme
  removeThemeClass() {
    this._renderer.removeClass(document.getElementsByTagName( 'html' )[0], 'ax-dark-mode');
    this._renderer.removeClass(document.getElementsByTagName( 'html' )[0], 'ax-light-mode');
  }
}
