import { Directive } from '@angular/core';
import { Validator, NG_VALIDATORS, ValidatorFn, UntypedFormControl } from '@angular/forms';

@Directive({
  selector: '[appTextInputvalidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useClass: TextInputvalidatorDirective,
      multi: true
    }
  ]
})
export class TextInputvalidatorDirective implements Validator {

  validator: ValidatorFn;
  constructor() {
    this.validator = this.textinputvalidator();
  }

  validate(c: UntypedFormControl) {
    return this.validator(c);
  }

  textinputvalidator(): ValidatorFn {
    return (control: UntypedFormControl) => {
      if (control?.value != null && control?.value !== '') {
        const testPattern1 = /<(?:(?:(?:(script|style|object|embed|applet|noframes|noscript|noembed)(?:\s+(?:"[\S\s]*?"|'[\S\s]*?'|(?:(?!\/>)[^>])?)+)?\s*>)[\S\s]*?<\/\1\s*(?=>))|(?:\/?[\w:]+\s*\/?)|(?:[\w:]+\s+(?:"[\S\s]*?"|'[\S\s]*?'|[^>]?)+\s*\/?)|\?[\S\s]*?\?|(?:!(?:(?:DOCTYPE[\S\s]*?)|(?:\[CDATA\[[\S\s]*?\]\])|(?:--[\S\s]*?--)|(?:ATTLIST[\S\s]*?)|(?:ENTITY[\S\s]*?)|(?:ELEMENT[\S\s]*?))))>/g;
        const validTags1 = testPattern1?.test(control?.value);
        const isValid1 = !validTags1;
        if (isValid1) {
          const testPattern2 = /^[a-zA-Z0-9||\u0600-\u06FF\u0750-\u077F]/;
          const validTags2 = testPattern2?.test(control?.value);
          const validTags3 = control?.value?.startsWith('|');
          const isValid2 = validTags2;
          return isValid2 && !validTags3 ? null : { invalidFormat: { message: 'Input cannot start with special character or white space' } };
        }
        return {
          invalidFormat: { message: 'Invalid input' }
        };
      }
      return null;
    };
  }
}
