import { Directive, Input, EventEmitter, Output, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appScrollSpy]',
  standalone: true
})
export class ScrollSpyDirective {

    /** input used for tags passed */
    @Input() public spiedTags: string[] = [];

    /** Output used for change section */
    @Output() public sectionChange: EventEmitter<number> = new EventEmitter<number>();
    private currentSection: string;
    private dataIndex!: number;

    constructor(private _el: ElementRef) {}

    @HostListener('scroll', ['$event'])

    /** Function event change hence use any */
    onScroll(event: any) {
      let currentSection: string;
      const children = this._el?.nativeElement?.querySelectorAll('.arx-create-form__body-content');
      const scrollTop = event?.target?.scrollTop;
      const parentOffset = event?.target?.offsetTop;

      for (let i = 0; i < children?.length; i++) {
        const element = children[i];
        if (this.spiedTags?.some((spiedTag: string) => spiedTag === element?.tagName) && element?.classList.contains('arx-create-form__body-content')) {
          if ((element.offsetTop - parentOffset) <= scrollTop) {
            currentSection = element?.id;
            this.dataIndex = element?.dataset?.index;
          }
        }
      }
      if (currentSection !== this.currentSection) {
        this.currentSection = currentSection;
        this.sectionChange.emit(this.dataIndex);
      }
    }


}
