import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  apiUrl: string = environment.baseUrl + environment.baseVersion + environment.apiVersion;
  public downloadApiUrl: string = environment.baseUrl + environment.baseVersion;
  httpOptions: {
    headers: HttpHeaders;
  } = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': environment.baseUrl
      // 'Accept': 'text/plain',
      // 'X-Requested-With': 'XMLHttpRequest',
      })
    };

  constructor(
    private http: HttpClient) { }

  post(url: string, data: any = {}, options: any = {}): Observable<any | any[]> {
    return this.http.post(this.apiUrl + url, data, options);
  }

  fileUpload(url: string, data: any = {}): Observable<any | any[]> {
    return this.http.post(this.apiUrl + url, data);
  }

  put(url: string, data: any = {}): Observable<any | any[]> {
    return this.http.put(this.apiUrl + url, data);
  }

  patch(url: string, data: any = {}): Observable<any | any[]> {
    return this.http.patch(this.apiUrl + url, data);
  }

  get(url: string): Observable<any | any[]> {
    return this.http.get(this.apiUrl + url);
  }

  delete(url: string): Observable<any | any[]> {
    return this.http.delete(this.apiUrl + url);
  }

  fileDownload(url: string): Observable<any | any[]>{
    return  this.http.get(this.downloadApiUrl + url, {responseType: 'blob'});
  }

  // Similar to http get request but the api version in the header is not passed
  getWithoutApiURL(url: string): Observable<any | any[]> {
    return this.http.get(this.downloadApiUrl + url);
  }

  // This is similar to http post request but has reportProgress set to true which will give info about the transfer size updates
  postWithProgress(url: string, data: any = {}): Observable<any | any[]> {
    return this.http.post(this.addNGSWBypassParam(this.apiUrl + url), data, {
      reportProgress: true
    });
  }

  // This is similar to http put request but has reportProgress set to true which will give info about the transfer size updates
  putWithProgress(url: string, data: any = {}): Observable<any | any[]> {
    return this.http.put(this.addNGSWBypassParam(this.apiUrl + url), data, {
      reportProgress: true
    });
  }

  /**
   * @description Function to add the param ngsw-bypass in order to bypass the service worker for getting proper transfer size updates
   * @author Sreejith T
   * @date 27/02/2023
   */
  addNGSWBypassParam(url: string) {
    if (url?.[url?.length - 1] === '&') {
      return url = `${url}ngsw-bypass`;
    } else if (url?.includes('?')) {
      // Check if url already have query param
      return url = `${url}&ngsw-bypass`;
    }
    // If no query params added
    return url = `${url}?ngsw-bypass`;
  }

}
