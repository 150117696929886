import { commonLanguage } from 'src/app/core/constant/language-translation/common-language';
import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { ToastService } from 'src/app/core/helper/toast.helper.service';

@Directive({
  selector: '[appCopyToClipboard]'
})
export class CopyToClipboardDirective {

  // Input of copied text
  @Input('appCopyToClipboard') text!: string;
  public commonTranslation:Record<string, string> = commonLanguage;

  constructor(
    private el: ElementRef,
    private _toaster: ToastService
  ) {}

  // Event trigerred on mouse click
  @HostListener('click')
  onClick() {
    if (this.text){
      if (navigator.clipboard) {
        navigator.clipboard.writeText(this.text)
          .then(() => {
            this._toaster.success(commonLanguage.copyToClipboard);
          })
          .catch(() => {
            this._toaster.error(commonLanguage.error_to_copy);
          });
      } else {
        this._toaster.error(commonLanguage.error_to_copy);
      }
    }
  }
}
