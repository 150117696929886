import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appDragNDrop]'
})
export class DragNDropDirective {

  // folders or files can be dropped so any given
  @Output() fileDropped: EventEmitter<any> = new EventEmitter<any>();

  // Input for folder upload
  @Input() isFolder: boolean = false;

  @HostBinding('class') private activeClass:string = '';

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.activeClass= 'ax-file-upload__drag-over';
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.activeClass= '';
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.activeClass= 'dragovers';
    const files = this.isFolder ? evt?.dataTransfer?.items : evt?.dataTransfer?.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }
}
