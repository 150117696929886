export const kriArabicTranslation = {
  compliance_indicators: 'Compliance Indicators',
  by_rating: 'by Rating',
  kpis: 'KPIs',
  by_department: 'by Department',
  by_trend: 'by Trend',
  scoring_method: 'Scoring Method',
  on_day: 'ON DAY',
  on_the: 'ON THE',
  choose_option: 'Please choose an option',
  scoring_signal_exposure:
    'scoring to get an early signal of increasing risk exposure.',
  provide_range_for: 'Provide a range for',
  to_calculate_the_rating: 'to calculate the rating.',
  configure_threshold: 'Configure Threshold',
  provide_risk_threshold:
    'Provide the lower and upper acceptable risk limits or thresholds for',
  lower_upper_threshold: 'Lower threshold cannot be less than upper threshold',
  lower_threshold_less: 'Lower threshold cannot be less than min value',
  lower_threshold_greater_max:
    'Lower threshold cannot be greater than max value',
  lower_threshold_greater: 'Lower threshold cannot be greater than min value',
  upper_lower_threshold: 'Upper threshold cannot be less than lower threshold',
  upper_threshold_greater: 'Upper threshold cannot be greater than max value',
  upper_threshold_less: 'Upper threshold cannot be less than max value',
  periodic_assessment_scoring:
    'periodic assessment shall be input as text if the scoring method is custom.',
  ratings_manually_adjusted:
    'Ratings and thresholds will need to be manually adjusted.',
  maximum_length_100: 'The maximum length of this field is 100 characters',
  classification_regards_rating: 'classification with regards to the rating.',
  threshold_mitigate_risks: 'threshold limits to mitigate risks.',
  trend_analysis: 'Trend Analysis',
  actions_by_status: 'Actions by Status',
  score_overview: 'Score Overview',
  add_score: 'Add Score',
  update_score: 'Update Score',
  date_less_start_date: 'Date cannot be less than  start date of',
  date_greater_end_date: 'Date cannot be greater than  end date of',
  score_exceed_max: 'Score cannot exceed max value of',
  score_less_min: 'Score cannot be less than  min value of',
  score_less_max: 'Score cannot be less than  max value of',
  score_exceed_min: 'Score cannot exceed min value of',
  want_to_edit_kri: 'Are you sure you want to edit this KRI?',
  edit_kri: 'Edit KRI',
  associated_entity_kri:
    'Only those risks, controls and processes associated with entity will be listed for selection in future changes to the KRI.',
  auto_rated_kri_sytem:
    'When adding a new rating value, it is necessary to update the scoring method of each KRI to ensure that auto-rated KRIs accurately reflect the new rating system.',
  auto_rated_risk_appetite_sytem:
    'When adding a new rating value, it is necessary to update the scoring method of each Risk Appetite to ensure that auto-rated Risk Appetites accurately reflect the new rating system.',
  delete_auto_rated_kri_sytem:
    'When deleting a new rating value, it is necessary to update the scoring method of each KRI to ensure that auto-rated KRIs accurately reflect the new rating system.',
  delete_auto_rated_risk_appetite_sytem:
    'When deleting a new rating value, it is necessary to update the scoring method of each Risk Appetite to ensure that auto-rated Risk Appetites accurately reflect the new rating system.',
  delete_auto_rated_metrics_sytem:
    'When deleting a new rating value, it is necessary to update the scoring method of each Metrics to ensure that auto-rated Metrics accurately reflect the new rating system.',
  edit_auto_rated_kri_sytem:
    'When editing a rating value, it is necessary to update the scoring method of each KRI to ensure that auto-rated KRIs accurately reflect the new rating system.',
  edit_auto_rated_risk_appetite_sytem:
    'When editing a rating value, it is necessary to update the scoring method of each Risk Appetite to ensure that auto-rated Risk Appetites accurately reflect the new rating system.',
  not_authorized_tag_kri: 'You are not authorized to Tag in KRI',
  not_authorized_untag_kri: 'You are not authorized to UnTag in KRI',
  not_authorized_tag_risk_appetite:
    'You are not authorized to Tag in Risk Appetite',
  not_authorized_untag_risk_appetite:
    'You are not authorized to UnTag in Risk Appetite',
  max_value_greater: 'Max value can not be less than Min value',
  set_up_goal: 'Set up Goal',
  latest_scores: 'Latest Scores',
  esgPlan: 'ESG Plans',
  threshold_range: 'Threshold Range',
  preview: 'Preview',
  history: 'History',
  esgObjectEditor: 'ESG Object Editor',
  esg_Strategy_Overview: 'Strategy Overview',
  strategy_plan: 'Strategy Plan',
  esgplanCP: 'ESG plan',
  strategyManagement: 'Strategy Management',
  esgManagement: 'Esg Management',
  strategyID: 'Strategy ID',
  objectiveID: 'Objective ID',
  goalID: 'Goal ID',
  subgoalID: 'Sub Goal ID',
  addMetrics: 'Add Metrics',
  esg: 'ESG',
  strategyCategory: 'Strategies by Sub Category',
  objectiveCategory: 'Objectives by Sub Category',
  goalCategory: 'Goals by Sub Category',
  subGoalCategory: 'Sub Goals by Sub Category',
  to_edit_strategy_dynamic: 'You are about to edit the',
  perspective: 'Perspective',
  goal: 'Goal',
  kpi_name: 'KPI Name',
  kpi_name_placeholder: 'Enter KPI Name',
  objective: 'Objective',
  objective_placeholder: 'Enter Objective',
  more_details: 'MORE DETAILS',
  score_type: 'Score Type',
  data_type: 'Data Type',
  decimal_precision: 'Decimal precision',
  score_goal: 'Score Goal',
  minimum_value: 'Minimum value',
  threshold1: 'Threshold 1',
  threshold2: 'Threshold 2',
  threshold3: 'Threshold 3',
  threshold4: 'Threshold 4',
  threshold5: 'Threshold 5',
  maximum_value: 'Maximum value',
  Lower_is_better: 'Lower is better',
  enable_score_normalization: 'Enable score normalization',
  display_name: 'Display Name',
  equivalent_value: 'Equivalent value',
  time_period: 'Time Period',
  start_date_input: 'Start date (OPTIONAL)',
  end_date: 'End date (OPTIONAL)',
  same_as_performance_plan: 'Same as performance plan',
  next_reminder_on: 'Next Reminder on',
  reminder_description:
    'Reminder is calculated from the last reminder day or the starting day of the reminder by adding 1 to it.',
  grace_period: 'Grace Period to update (DAYS)',
  import_historical_data_description:
    'Past performance data can be imported by uploading a table file (excel, csv). The date values in the Time Period column should be in proper formatting (as per selected KPI Time Period) and on a regular interval to import the historical KPI values. The data in the KPI Value column must also be in the same data type as the KPI.',
  download_template_description: 'Generate and download template',
  select_worksheet: 'Select Worksheet',
  select_time_period_column: 'Select Time Period Column',
  select_current_value_column: 'Select Current Value Column',
  default_period_label: 'Set default value for missing period',
  role_designation_description: 'Specify the key stakeholder for this KPI',
  kpi_owner: 'KPI OWNER',
  goal_owner: 'GOAL OWNER',
  kpi_reviewers: 'KPI REVIEWERS (OPTIONAL)',
  goal_reviewers: 'GOAL REVIEWERS (OPTIONAL)',
  visibility_rules_description:
    'Identify the users, user groups, or the organisational departments who can access this KPI. The KPI Owner and the KPI Reviewer will be automatically granted visibility.',
  assign_role_to: 'ASSIGN ROLE TO',
  recipient: 'Recipient',
  kpi_measurement: 'KPI Measurement',
  back_to_perspective_btn: 'Back to Perspective',
  basic_details: 'Basic Details',
  list_items: 'List Items',
  check_in: 'Check-in',
  import_historical_data: 'Import Historical data',
  roledesignation: 'Role Designation',
  visibility_rules: 'Visibility Rules',
  set_kpi: 'Set KPI',
  add_from_library: 'Add from Library',
  add_more: 'Add More',
  steps: 'Steps',
  visualization: 'Vizualisation',
  smart_suggestions: 'Smart Suggestions',
  users: 'Users',
  department: 'Department',
  total_visibility: 'Total Users with Visibility',
  total_users_designated: 'Total Users Designated',
  equation: 'Equation',
  lower_is_better_tooltip_message:
    'Inverts the performance indicator for the KPI, reflecting that lower scores are more desirable. In case of Balanced KPis (2 color and 3 color balanced), this setting is not applicable.',
  kpi_owner_tooltip_template:
    'KPI Owners are responsible for updating the KPI score periodically. They will recieve a reminder automatically if a check-in date is configured.',
  goal_owner_tooltip_template:
    'Goal Owners are responsible for updating the Goal score periodically. They will recieve a reminder automatically if a check-in date is configured.',
  pastMonth: 'Past Month',
  thisMonth: 'This Month',
  lastUpdate: 'Last updated on',
  target: 'Target',
  previousMonth: 'Previous month',
  red: 'Red',
  readmore: 'Read more',
  myTask: 'My Tasks',
  recents: 'Recents',
  view: 'View',
  myBoard: 'My Board',
  collection1: 'Collection 1',
  collection2: 'Collection 2',
  addNewCollection: 'Add New Collection',
  cac: 'Customer Acquisition Cost (CAC)',
  nps: 'Net Promoter Score (NPS)',
  checkIn: 'Check-in',
  currentScore: 'Current score under review',
  kpiName: 'KPI name',
  details: 'Details',
  description: 'Description',
  kpiOwner: 'KPI Owner',
  kpiReviewers: 'KPI Reviewers',
  actual: 'Actual',
  status: 'Status',
  owner: 'Owner',
  goals: 'Goals',
  risk: 'Risk',
  scorecards: 'Balance Scorecard',
  financialPerspective: 'Financial Perspective',
  visionandStrategy: 'Vision and Strategy',
  perspectives: 'Perspectives',
  maximizeCustomerValue: 'Maximize Customer Value',
  customerLifetimeValue: 'Customer Lifetime value',
  frequency: 'Frequency',
  score: 'Score',
  pastPerfomance: 'Past Performance',
  ensureFinancialSustainability: 'Ensure Financial Sustainability',
  addMoreKPI: 'Add More KPI',
  automaticallyIncludeKPIAdmin: 'Automatically include KPI Admin',
  automaticallyIncludeGoalAdmin: 'Automatically include GOAL Admin',
  goalName: 'GOAL Name',
  moreDescription: 'More Description',
  MeasurementMethod: 'Measurement',
  MeasurementMethodDescription: 'Measurement Method',
  TableofKPIs: 'Table of KPIs',
  weightage: 'Weightage',
  kpiAdmin: 'KPI Admin',
  goalAdmin: 'GOAL ADMIN',
  setGoal: 'Set Goal',
  editGoal: 'Edit Goal',
  goalsList: 'Goals List',
  createNewGoal: 'Create New Goal',
  filter: 'Filter',
  hideCompleted: 'Hide completed tasks',
  showPreviously: 'Show previously hidden',
  column: 'Column',
  log: 'Log',
  plan_timeline: 'Plan Timeline',
  checkinDetails: 'This view enables KPI Owners and Reviewers to access their task list. KPI Owners have the capability to check-in, as well as to view a history of check-ins. KPI Reviewers can review and approve check-in values submitted by the KPI Owners.',
  checkinPreview: 'Preview unreviewed score',
  checkInTask: 'Check-in Task',
  checkInHistory: 'Check-in History',
  manualCheckIn: 'Manual Check-in',
  updatedDate: 'Update Date',
  attachment: 'Attachment',
  chooseFile: 'Choose Files',
  noFiles: 'No files chosen',
  reviewTask: 'Review Task',
  backCheckIN: 'Back to Check-In',
  historyCheckin: 'History of Check-In',
  auto_update_status_to: 'Auto-update status to',
  calculate_end_date_based_on: 'Calculate end date based on ',
  performance_paln_message: 'The performance plan will run for 365 days starting from 1/1/2023 to 1/1/2024',
  create_new_performance_plan: 'Create New Performance Plan',
  enable_score_tooltip: 'Instead of indicating the exact score of a KPI, this feature normalizes the score display. The pointer aligns broadly with the defined range (e.g, low, medium, high) on the score gague. for instance, if the score falls with the low range, the needle will center with in the range,rather than pinpointing the precise score value',
  kpi_reviewer_tooltip: 'KPI Reviewers review check-ins made by KPI Owners, offering insights and approvals',
  same_as_performace_tooltip: 'Match the duration of the associated Performance Plan.',
  grace_period_tooltip: 'Grace Period allows additional time beyond the check-in date for KPI reporting',
  performance_plan_owner: 'Performance Plan Owner',
  performance_create_page_title: 'Performance Planning Tool',
  performance_create_page_description: 'Performance Plans serve as the foundational blueprint for organizational success',
  worst_value: 'Worst Value',
  sample_socre: 'SAMPLE SCORE',
  inclusion_rule: 'Inclusion Rule',
  exclusion_rule: 'Exclusion Rule',
  performance_plans: 'Performance Plans',
  create_plan: 'Create Plan',
  kpi_min_value_validation: 'Minimum value should be less than Threshold 1',
  kpi_threshold1_validation: 'Threshold 1 should be greater than Minimum value',
  kpi_threshold2_validation: 'Threshold 2 should be greater than Threshold 1',
  kpi_threshold3_validation: 'Threshold 3 should be greater than Threshold 2',
  kpi_threshold4_validation: 'Threshold 4 should be greater than Threshold 3',
  kpi_threshold5_validation: 'Threshold 5 should be greater than Threshold 4',
  kpi_max_value_validation: 'Maximum value should be greater than last Threshold value',
  endDate: 'End Date',
  perfomancePlanwillRunfor: 'The performance plan will run for',
  daysStartingfrom: 'days starting from',
  refresh: 'refresh',
  back_to_set_performance_plan: 'Back to Set Performance  Plan',
  manage_goals: 'Manage Goals',
  added_from_library: 'Added from Library',
  choose_logo: 'Choose a Logo',
  change: 'Change',
  perspective_name: 'Perspective Name',
  perspective_logo: 'Perspective Logo',
  role_description: 'By default, any user with access to the perspective will be able to see all the goals and metrics being tracked.',
  perpsective_admin: 'Perpsective Admin',
  perspective_visibility_rules_description: 'Identify the users, user groups, or the organisational departments who can access this Perspective. The Perspective Admin will be automatically granted visibility.',
  save_and_exit: 'Save and Exit',
  next_set_goal: 'Next: Set Goal',
  create_new_perspective: 'Create New Perspective',
  kpi: 'KPI',
  score_validation: 'Score should be between minimum and maximum value',
  overall: 'Overall'
};
