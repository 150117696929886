import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, delay, filter } from 'rxjs';
import { SubSink } from 'subsink';

interface IntersectionSubjectData {
  entry: IntersectionObserverEntry,
  observer: IntersectionObserver
}


@Directive({
  selector: '[appVisibilityObserver]',
  standalone: true
})
export class VisiblityObserverDirective implements OnDestroy, OnInit, AfterViewInit {

    /**
     * @Description Time in milli seconds for observer to observe elements after element visible in screen
     */
    @Input() debounceTime: number = 0;

    /**
     * @Description Percentage of element visible in screen the callback should be executed, 0 to 1
     */
    @Input() threshold: number = 1;

    /**
     * @Description Event emitter to emit when element visibility found
     */
    @Output() visible: EventEmitter<boolean> = new EventEmitter<boolean>();

    private subs: SubSink = new SubSink();

    private observer: IntersectionObserver | undefined;
    private subject$: Subject<IntersectionSubjectData> = new Subject<IntersectionSubjectData>();

    constructor(private element: ElementRef) { }

    ngOnInit(): void {
      this.createObserver();
    }

    ngAfterViewInit(): void {
      this.startObservingElements();
    }

    ngOnDestroy(): void {
      if (this.observer) {
        this.observer?.disconnect();
        this.observer = undefined;
      }
      this.subs.unsubscribe();

      this.subject$.next(null);
      this.subject$.complete();
    }

    /**
     * @Description Function to check element is visible in the screen
     * @Author Mohammed Rasik K
     * @Date 06/09/2023
     */

    private isVisible(element: HTMLElement): Promise<boolean> {
      return new Promise<boolean>((resolve: (value: boolean)=> void) => {
        const observer = new IntersectionObserver(([entry]: IntersectionObserverEntry[]) => {
          resolve(entry?.intersectionRatio === 1);
          observer.disconnect();
        });
        observer.observe(element);
      });
    }

    /**
     * @Description Function to create an observer to observe element
     * @Author Mohammed Rasik K
     * @Date 06/09/2023
     */

    private createObserver() {
      const options = {
        rootMargin: '0px',
        threshold: this.threshold
      };

      const isIntersecting = (entry: IntersectionObserverEntry) => entry.isIntersecting || entry.intersectionRatio > 0;

      this.observer = new IntersectionObserver((entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
        entries?.forEach((entry: IntersectionObserverEntry) => {
          if (isIntersecting(entry)) {
            this.subject$.next({ entry, observer });
          }
        });
      }, options);
    }

    /**
     * @Description Function to start observing element using created observer
     * @Author Mohammed Rasik K
     * @Date 06/09/2023
     */

    private startObservingElements() {
      if (!this.observer) {
        return;
      }

      this.observer?.observe(this.element?.nativeElement);
      this.subs.add(
        this.subject$
          .pipe(delay(this.debounceTime), filter(Boolean))
          .subscribe(async ({ entry, observer }: IntersectionSubjectData) => {
            const target = entry?.target as HTMLElement;
            if (target){
              const isStillVisible = await this.isVisible(target);
              if (isStillVisible) {
                this.visible.emit(true);
                observer.unobserve(target);
              }
            }
          })
      );
    }
}
