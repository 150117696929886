import { Injectable } from '@angular/core';
import { ApiEndpoints } from '../../constants';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class SetupWizardService {

  public isWizard: boolean = false;
  constructor(
    private _http: HttpService
  ) { }

  getSetupWizardDetails() {
    return this._http.get(ApiEndpoints.setupWizard);
  }

  checkSetupWizardStatus() {
    return this._http.get(ApiEndpoints.setupWizardStatus);
  }

  dismissSetupWizard() {
    return this._http.put(ApiEndpoints.setupWizardStatus);
  }
}
