export const complianceModuleApiEndpoints = {
  complianceAuditObservationActionDashboardExportCSV: '/compliance-management/compliance_observation_action_issue_tracker/csv_download',
  complianceAuditObservationActionDashboardExportXLS: '/compliance-management/compliance_observation_action_issue_tracker/xls_download',
  complianceAuditReminderHistory: '/compliance-observation/management-response',
  complianceMrReminderHistory: '/compliance-audit/',
  getCompliancetMrCriticality: 'dropdown-list/compliance-audit-management-response/mr_criticality/',
  CompliancePlanfileUpload: 'audit-plan/media/',
  compliancePlan: 'compliance-audit-plan/',
  taggedItemCount: 'tagged-items-count',
  auditReportGrid: 'document-report-list/',
  ComplianceDigitalReport: 'digital-report-compliance/',
  digitalReportSignoff: 'digital-report-compliance-signoff/',
  complianceAuditCalendar: 'compliance-audit/calendar/list',
  complianceAuditCalendarSubmodule: 'compliance-audit/sub-activity/calendar/list',
  complianceAuditCalendarHeader: 'compliance-audit/calendar/header',
  complianceIssueTrackerExport: 'compliance-management/issue-trcker/issue-export/',
  complianceIssueTrackerObservationExport: 'compliance-management/issue-tracker/observation-export/',
  complianceActionIssueTrackerExport: 'compliance-management/issue-trcker/action-export/',
  complianceMRIssueTrackerExport: 'compliance-management/issue-trcker/management-response-export/',
  complianceRecommendationIssueTrackerExport: 'compliance-management/issue-trcker/recommendation-export/',
  dashboardConfig: 'audit-reporting/issue-tracker/configuration/list',
  complianceScheduleCalendarActivity: 'audit-schedule/calender/',
  complianceScheduleCalendarSubActivity: 'audit-schedule/calender/sub-activity/list/',
  complianceScheduleCalendarHeader: 'audit-schedule/calender/header/',
  complianceScheduleBoardView: 'compliance-audit-schedule-activity-board-view-detail/',
  complianceSoaControlsByTag: 'compliance/tag/soa/list/',
  complianceControlList: 'compliance/soa/control/add-list/',
  complianceRiskList: 'compliance-management/compliance-audit/soa/risk/add-list/',
  riskBasedControlList: 'compliance-management/compliance-audit/soa/control/add-list/',
  relatedRiskList: 'compliance-management/compliance-audit/soa/related-risk/list/',
  generalTags: 'generic-dropdown/list?field=generic_tag',
  genericTagType: 'generic-dropdown/list',
  selectedOption: 'dropdown-list/compliance-audit/selection/',
  reasonForSelectionList: 'dropdown-list/compliance-audit/reason_for_selection/',
  reasonForSelectionCreate: 'generic-dropdown/create',
  getSoaListCompliance: 'compliance/#compliance_id#/soa/list/',
  getSoaListComplianceExpand: 'compliance/#compliance_id#/soa/list/?expand_view=true',
  getAuditProgramListCompliance: 'compliance/#compliance_id#/auditprogram/list/',
  getSoaListComplianceDetail: 'compliance/#compliance_id#/soa/detail-list/',
  getSoaListComplianceDetailExpand: 'compliance/#compliance_id#/soa/detail-list/?expand_view=true',
  deleteSoaCompliance: 'compliance/#compliance_id#/soa/delete/',
  getDefaultStatus: 'compliance/auditprogram/default-status/',
  getComplianceScoringChart: 'compliance/#compliance_id#/scoring/chart/',
  dynamicSoaControls: 'control/soa/dynamic-header/',
  previousAuditTaggedList: 'tagged-related-compliance-audit/',
  auditTaggableList: 'all-related-compliance-audit/',
  auditTaggingApi: 'tag-related-compliance-audit/',
  removeTaggedPreviousAudit: 'untagged-releated-compliance-audit/',
  scoringMethodList: 'dropdown-list/compliance-audit/compliance_scoring_method/',
  moduleList: 'list/',
  dyanamicparent: 'action/#parentslug#/parent-dynamic/list/',
  actionParentDetails: 'action/#object_id#/parent/details/',
  dropDownCreate: 'dropdown/create/',
  dropDownUpdate: 'dropdown/#id#/edit/',
  dropDownList: 'dropdown-list/',
  parentLevelList: 'control-panel/dropdown/parent-levels/',
  controlPanelSubchild: 'control-panel/dropdown-field/sub-child/list/',
  dashboardIssueTrackerActionGraph: 'filter-charts/?request_for=compliance_issue_tracker&call_for=action&',
  dashboardIssueTrackerObservationGraph: 'filter-charts/?request_for=compliance_issue_tracker&call_for=observation&',
  issueIssueTrackerDashboard: 'filter-charts/?request_for=compliance_issue_tracker&call_for=issue&',
  issueMrTrackerDashboard: 'filter-charts/?request_for=compliance_issue_tracker&call_for=management_response&',
  IssueTrackerDashboardCount: 'audit-issue-tracker-dashboard-count',
  complianceDependencyList: 'compliance-audit-details/',
  complianceSoaSave: 'compliance-management/soa/'
};

export const clientUrls = {
  galadari: 'http://galadari-staging.auraa.com'
};

export const permissionTags = {
  add: 'Add',
  addComment: 'Add Comment',
  admin: 'Admin',
  approval: 'Approval',
  delete: 'Delete',
  deleteComment: 'Delete Comment',
  deleteDocument: 'Delete Document',
  download: 'Download',
  downloadDocument: 'Download Document',
  edit: 'Edit',
  editComment: 'Edit Comment',
  editDocument: 'Edit Document',
  export: 'Export',
  import: 'Import',
  tag: 'Tag',
  upload: 'Upload',
  uploadDocument: 'Upload Document',
  view: 'View',
  ViewComment: 'View Comment',
  status: 'Status'
};

export const complianceTitles = {
  sal: 'Compliance Assessment',
  other: 'Compliance Audit'
};
export const complianceGridTitle = {
  sal: ' Assessment',
  other: ' Audit'
};
export const auScheduleApprovalProcessing = {
  headText: 'Unable to complete the action as approval is in process'
};

export const staticExportParamsObservation = {
  export: 'audit-planning',
  request_for: 'compliance_issue_tracker',
  chart_type: 'audit_observation_issue_tracker'
};

export const staticExportParams = {
  export: 'audit-planning',
  request_for: 'compliance_issue_tracker',
  chart_type: 'issuetracker_dashboard'
};

export const initiateAction=[
  {is_active: true, checked: true, title: '', due_date: new Date(), description: '', remarks: '', action_owner: [], department_level1: [], department_level2: [], department_level3: [], department_level4: [], mega_process: [], major_process: []}
];

export const tableConfig = {
  tableLimitOption: [5, 10, 25, 50, 100],
  pager: 10,
  tableLimit: 10
};

export const complianDetailsParams = {
  limit: tableConfig.pager,
  offset: 0,
  compliance_action_status: '',
  multi_values: false,
  q: ''
};
export const requestForComplianceModules = {
  requestForAuditPlan: 'compliance-plan',
  requestforPlanDocument: 'compliance-audit'
};

export const sourceType = {
  scheduleActivity: 'Compliance Schedule Activity',
  compliance: 'Compliance'
};


export enum ApiEndPointsCm {
  historyForCompliance = 'action/history-changes/',
  documentUpload = 'media/',
  documentEdit = 'audit-plan/media/',
  complianceObservationDashboardExport = 'compliance/dashboard-observation-export/'

}

export const issueTrackerTabMenuPath = {
  Observations: {route: '/compliance-management/compliance-dashboard/observations'},
  Issues: {route: '/compliance-management/compliance-dashboard/issues'},
  Actions: {route: '/compliance-management/compliance-dashboard/actions'},
  Recommendations: {route: '/compliance-management/compliance-dashboard/recommendation'},
  'Management Responses': {route: '/compliance-management/compliance-dashboard/management-response'}
};


export const complianceTableDropDown = {

  view: {
    icon: 'au-icon au-icon--view',
    class: '',
    label: 'Browse',
    event: 'browse'
  },
  edit: {
    icon: 'au-icon au-icon--edit',
    class: '',
    label: 'Edit',
    event: 'edit'
  },
  clone: {
    icon: 'au-icon au-icon--copy',
    class: '',
    label: 'Clone',
    event: 'clone',
    automation: 'clone'
  },
  delete: {
    icon: 'au-icon au-icon--delete',
    class: 'au-color--red',
    label: 'Delete',
    event: 'delete'
  },
  approval: {
    icon: 'au-icon au-icon--file-history',
    class: '',
    label: 'Send for Approval',
    event: 'Approval'
  }
};


export const historySlug = {
  cmAudit: 'compliance-audit',
  cmObservation: 'compliance-observation',
  cmMr: 'compliance-audit-management-response',
  bcp: 'business-continuity-plan',
  cmAction: 'compliance-action',
  bcpAction: 'bcp-action',
  cmRecommendation: 'compliance-recommendation',
  complianceWorkingPaper: 'compliance-working-paper',
  complianceSchedule: 'compliance-schedule',
  cmIssue: 'compliance-issue',
  cmObsAction: 'compliance-observation-action',
  asset: 'asset-management',
  assetAction: 'asset-management-action'
};


export const complianceOverviewTab = {
  overview: 'Overview',
  soa: 'Statement of Applicability',
  auditprogram: 'Audit Program',
  schedule: 'Schedule',
  finish: 'Finish',
  dependency_soa: 'Statement of Applicability',
  dependency_audit_program: 'Audit Program',
  dependency_documents: 'Documents',
  dependency_workflow: 'Workflows'

};

export const tableHeaderEvent = {
  attachDocument: 'attachDocument',
  addComment: 'addComment',
  markAsReviewed: 'markAsReviewed',
  undoMarkAsReviewed: 'undoMarkAsReviewed',
  sendReminder: 'sendReminder',
  markAsException: 'markAsException',
  markAsFalsePositive: 'markAsFalsePositive',
  markAsOpen: 'markAsOpen',
  export: 'export',
  filter: 'filter',
  refresh: 'refresh',
  sort: 'sort',
  remove: 'remove',
  dropdownSelect: 'dropdown_select',
  close: 'close',
  submit: 'submit',
  delete: 'delete',
  group: 'group',
  highLightEvent: 'highlight_content',
  edit: 'edit'
};

export const filterOptionConstant  = [
  {
    label: 'contains',
    value: 'icontains'
  },
  {
    label: 'is',
    value: 'iexact'
  },
  {
    label: 'is not',
    value: '_isnot_iexact'
  },
  {
    label: 'doesn\'t contains',
    value: '_isnot_icontains'
  },
  {
    label: 'start with',
    value: 'istartswith'
  },
  {
    label: 'ends with',
    value: 'iendswith'
  },
  {
    label: 'greater than',
    value: 'gt'
  },
  {
    label: 'less than',
    value: 'lt'
  },
  {
    label: 'greater than or equal to',
    value: 'gte'
  },
  {
    label: 'less than or equal to',
    value: 'lte'
  }
];


export const commonDropdown = {
  generic_tag: 'generic_tag',
  selectionValue: 'yes'
};

export const complianceCreateToaster = {
  controlNoDataSelect: 'Please select any data.',
  selectAtleatRisk: 'Select at least one risk',
  selectOneRecord: 'Select at least one record'
};

export const complianceMultiDropDowns =  [
  'entity',
  'compliance_mega_process',
  'compliance_major_process',
  'owner',
  'team',
  'users',
  'stakeholder_new',
  'escalation_points',
  'previous_versions',
  'fiscal_year',
  'functions',
  'compliance_department_l1',
  'compliance_department_l2',
  'compliance_department_l3',
  'compliance_department_l4',
  'compliance_additional_multi_dropdown_v1',
  'compliance_additional_multi_dropdown_v2',
  'compliance_additional_multi_dropdown_v3',
  'compliance_additional_multi_dropdown_v4',
  'compliance_additional_user_v1',
  'compliance_additional_user_v2',
  'compliance_additional_user_v3',
  'compliance_additional_user_v4',
  'generic_tag'
];


export const complianceSingleDropDowns = [
  'compliance_type',
  'requested_by',
  'compliance_status',
  'compliance_rating',
  'compliance_area',
  'compliance_occurance',
  'compliance_duration',
  'sector',
  'compliance_additional_designation_v1',
  'compliance_additional_dropdown_v1',
  'compliance_additional_dropdown_v2',
  'compliance_additional_dropdown_v3',
  'compliance_additional_dropdown_v4',
  'plan'
];

export const complianceDateFields = [
  'valid_from',
  'valid_to'
];

export const complianceDataKeyChange = {
  mega_process: 'compliance_mega_process',
  major_process: 'compliance_major_process',
  department_level1: 'compliance_department_l1',
  department_level2: 'compliance_department_l2',
  department_level3: 'compliance_department_l3',
  department_level4: 'compliance_department_l4'
};

export const parentSlugsAction = {
  compliance: 'compliance-audit',
  observation: 'compliance-observation'
};

export const parentInputTypes = {
  input: 'input',
  badge: 'badge'
};

export const organizationFields = {
  compliance_department_l1: 'department_level1',
  compliance_department_l2: 'department_level2',
  compliance_department_l3: 'department_level3',
  compliance_department_l4: 'department_level4'
};

export const organizationFieldsBP = {
  compliance_department_l1: 'department_level_1',
  compliance_department_l2: 'department_level_2',
  compliance_department_l3: 'department_level_3',
  compliance_department_l4: 'department_level_4'
};

export const organizationFieldsObs = {
  compliance_department_l1: 'department_l1',
  compliance_department_l2: 'department_l2',
  compliance_department_l3: 'department_l3',
  compliance_department_l4: 'department_l4'
};

export const organizationFieldsAsset = {
  compliance_department_l1: 'department_level1',
  compliance_department_l2: 'department_level2',
  compliance_department_l3: 'department_level3',
  compliance_department_l4: 'department_level4'
};


