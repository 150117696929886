import { Injectable } from '@angular/core';
import { AdminUserService } from '../service/control-panel/admin-user.service';

@Injectable({
  providedIn: 'root'
})

export class ResolveTabGuard {
  constructor(private _admin: AdminUserService) {}
  resolve() {
    return this._admin.getAdminPermission();
  }
}
