import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AxActionService {
  public selectDropdown: HTMLElement | null = null;
  public selectDropdownExpansion: HTMLElement | null = null;
  public selectDropdownOldData: Record<string, object | boolean |any>={};
  public closeEvent: Subject<boolean>=new Subject<boolean>();
  public selectDropdownPrevious: HTMLElement | null = null;

  closeDropdown(close: boolean){
    this.closeEvent.next(close);
  }
}
