import { Directive } from '@angular/core';
import { Validator, NG_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[appNoWhitespaceStart]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useClass: NoWhitespaceStartDirective,
      multi: true
    }
  ]
})
export class NoWhitespaceStartDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    if (typeof control?.value === 'string') {
      if (control?.value === ''){
        return null;
      }
      const validTag = control?.value?.toString();
      const validTags = validTag?.replace(/&nbsp;/g, '');
      const isValid = validTags.toString().trim();
      return isValid ? null : { invalidFormat: { message: 'Input cannot set with white space' } };
    }
    return null;
  }
}
