import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appCalendarDate]'
})
export class AuCalendarDateDirective {
  @Input() appCalendarDate!: string;
  private specialKeys: string[] = ['-', '.'];
  constructor(private el: ElementRef) { }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const current: string = this.el?.nativeElement?.value;
    const next: string = current?.concat(event?.key);
    const februaryMonthData = ['Month 2', 'February'];
    const alternateMonths = ['April', 'June', 'September', 'November'];
    const nonAlternateMonths = ['January', 'March', 'May', 'July', 'August', 'October', 'December', 'Month 1', 'Month 3'];

    /**
   * @description To get input date and add validation
   * @author Hazeena Sara Jacob
   * @date 01/06/2023
   */
    if (this.specialKeys?.includes(event?.key)) {
      event?.preventDefault();
      return;
    }
    if (next && !isNaN(Number(next))) {
      const inputValue = Number(next);
      if (februaryMonthData?.includes(this.appCalendarDate)) {
        if (inputValue > 29 || inputValue < 1) {
          event?.preventDefault();
          return;
        }
      } else if (alternateMonths?.includes(this.appCalendarDate)) {
        if (inputValue > 30 || inputValue < 1) {
          event?.preventDefault();
          return;
        }
      } else if (nonAlternateMonths?.includes(this.appCalendarDate)) {
        if (inputValue > 31 || inputValue < 1) {
          event?.preventDefault();
          return;
        }
      } else if (inputValue > 31 || inputValue < 1) {
        event?.preventDefault();
        return;
      }
    }

  }
}
