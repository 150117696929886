import { riskArabicTranslation } from "./constant/language-translation/risk/risk-language";
import { kriArabicTranslation } from 'src/app/core/constant/language-translation/kri/kri-language';
import { commonLanguage } from "./constant/language-translation/common-language";
import { policyTranslation } from 'src/app/core/constant/language-translation/compliance/policy-translation';
import { translateKeys } from "./constant/language-translation/audit/audit-translation";
import { analyticsTranslation } from "src/app/analytics/analytics.translation";
import { stractArabicTranslation } from "src/app/core/constant/language-translation/stract/stract-language";
import { momArabicTranslation } from "src/app/core/constant/language-translation/mom/mom-language";
import { arxRiskWarning } from "src/app/core/constant/language-translation/risk/arx-translation";

export const toastMessages = {
  deleted: 'Deleted',
  success: 'Success',
  updated: 'Updated',
  created: 'Created',
  duplicated: 'Duplicated',
  formError: 'There are items that require your attention',
  nextPageFormError: 'There are items that require your attention in the session of Design Assessment',
  validationError: 'Please fill all the mandatory fields',
  validationErrorAllField: 'Please fill all the fields',
  invalidDate: 'Please select valid date',
  invalidYear: 'End year cannot be earlier than start year',
  selectModel: 'Please select any universe model',
  checkCriteria: 'The selected audit universe model does not contain any criteria. Please review your selection',
  selectDate: 'Please select a date',
  selectStatus: 'Please select status',
  invalidTime: 'Please enter valid time',
  invalidEmail: 'Invalid email',
  noLocalPath: 'No local path is added',
  typeSomething: 'Please add a comment',
  selectProjectType: 'Please select a Project Type',
  selectProject: 'Please select a Project',
  typeSomeResponse: 'Please add a response',
  pleaseSelectOneItem: 'Please select at least one item',
  duplicateTableRelation: 'Duplicate table, Please edit the relation',
  fieldDisabled: 'This field is disabled',
  fieldNameRequired: 'Field Name is required.',
  noFieldFoundInExpression: 'No field is found in this expression',
  noChartSelected: 'Select a chart type',
  noDashboardTitle: 'Please provide dashboard title',
  noGraphCreated: 'Please add at least one chart',
  warningForGoBack: 'Changes you made may not be saved.',
  invalidExpression: 'Please enter a valid expression',
  noExpression: 'Expression field cannot be empty.',
  measureNotSelected: 'Please select atleast one dimension same as the measure',
  diemnsionTwoSelected: 'Please select two dimensions and a measure',
  diemnsionOneSelected: 'Please select one dimension and a measure',
  selectExistingTemplate: 'Please select an exisiting template.',
  riskNotTagged: riskArabicTranslation?.risk_tagged_to_audit,
  controlNotTagged: riskArabicTranslation?.control_tagged_to_audit,
  selectSameSlug: 'Please select same module',
  selectMeasureOrDimension: 'Please select measure or dimension first',
  expressionNullError: 'Please enter expression.',
  fieldNameExists: 'The field name can\'t be duplicate or same as the calculation functions',
  duplicateValue: 'This field already exist',
  limitExceeds: 'The maximum length of this field is 70 characters',
  emptyField: 'You can\'t save without a value',
  duplicateCustomValue: 'duplicate custom  field exist',
  limitExceed: 'The maximum length of custom field is 70 characters',
  savCustomField: 'please save the custom fields',
  checkCustom: 'please check  custom fields validation and fill all the mandatory fields',
  noSpecialCharacter: 'You have entered an invalid value',
  permissionRevokedDownload: 'You are not allowed to download',
  browsePermissionMessage: 'You are not allowed to view this record',
  noSignerSelected: 'Please select the signer',
  onlyImage: 'Please select (png,jpg,jpeg) file',
  permissionTagAudit: 'You are not authorized to Tag in Audit Planning',
  permissionTagKri: kriArabicTranslation?.not_authorized_tag_kri,
  permissionTagRiskAppetite: kriArabicTranslation?.not_authorized_tag_risk_appetite,
  permissionUnTagKri: kriArabicTranslation?.not_authorized_untag_kri,
  permissionUnTagRiskApetite: kriArabicTranslation?.not_authorized_untag_risk_appetite,
  permissionTagRiskProgram: riskArabicTranslation?.not_tag_risk_program,
  permissionUnTagAudit: 'You are not authorized to UnTag in Audit Planning',
  approvalAssessment: 'You are not authorized to remove the assessment. It has been sent for approval to the reviewer',
  permissionUnTagRiskProgram: riskArabicTranslation?.not_untag_risk_program,
  reviewerPermission: 'You are not authorized to Review/Reject this audit',
  approverPermission: 'You are not authorized to Approve/Reject this audit',
  approverViewPermission: 'You are not authorized to view this audit for Approve/Reject ',
  pleaseSelectField: 'Please select a field',
  deleteDocument: 'You are not authorized to delete the document',
  permissionTagObservation: 'You are not authorized to Tag in Observation',
  permissionUnTagObservation: 'You are not authorized to UnTag in Observation',
  approverEditPermission: 'You are not authorized to edit this audit',
  noChange: 'This action cannot be performed as there are no changes',
  negativeValue: 'Negative values are not allowed',
  choiceUpdated: 'Dropdown choices updated',
  timeExceeds: 'Time exceeds 24 hour limit',
  validTime: 'Please enter a valid Time',
  minuteExceeds: 'Time exceeds 59 minute limit',
  eDateAndSignRequiredForAllSigners: 'Please add atleast one date and sign for each signers',
  addOptionForAllSigners: 'Please add option for all the signers selected',
  reminderInvalidTime: 'Time should be atleast 10 minutes ahead of current time',
  multiDocumentNotAllowed: 'You cannot add multiple documents',
  requiredAuditWorkflow: 'Please add the required workflow',
  selectAssignee: 'Please select an assignee',
  auditNotFound: 'Audit not found',
  dashboardEmpty: 'Cards with no data are not allowed',
  selectFolder: 'Please select a folder',
  wrongFormat: 'Switching to selected type is not possible. Please deselect the current item',
  deletedMessage: 'The record you are trying to access has been deleted.',
  statusDeletiondenied: 'You don’t have the permission to change status',
  sameDocument: 'Document with the same title already exists.',
  documentUpload: 'Document(s) uploaded.',
  documentDelete: 'Document deleted.',
  selectAtleastOneRiskOrControl:riskArabicTranslation?.select_one_riskOrControl,
  permissionUnTagEsg: 'You are not authorized to UnTag in Strategy Management',
  permissionTagEsg: 'You are not authorized to Tag in Strategy Management',
  saySomething: 'Say something! Blank comments are not allowed.',
  moved: 'Question moved ',
  one_participant_already_added: 'Participant is already added',
  approvalAssessmentRemove: 'Unable to delete the assessment as it is under approval',
  choiceAdded: 'Dropdown choices added',
  editPermissionAssessment: 'You do not have permission to submit the assessment.',
  directionToaster: 'Please fill the direction , direction cannot be empty',
  pleaseSelectOneRecommendation: 'Please select at least one recommendation',
  selectObservation: 'Please select at least one observation',
  universeCreated: 'Audit Universe has been generated ',
  universeUpdated: 'Audit Universe has been updated'
};

export const warningMsgDelete = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to delete this item? You can’t undo this action.',
  subTextApprovalDelete: 'Do you want to delete this record, upon delete this will send for approval',
  subTextAnalytics: 'You are About to Delete Data Source',
  subTextAnalyticsWithData: 'You are About to Delete Data Source with data',
  subTextAudit: 'Are you sure you want to remove this item? You can’t undo this action.',
  buttonOpt_audit: 'Remove',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const warningMsgSurveyDelete = {
  headText: commonLanguage.are_you_sure,
  subText: commonLanguage.sure_to_delete_survey,
  buttonOpt_1: commonLanguage.delete,
  buttonOpt_2: commonLanguage.cancel
};

export const warningMsgGeneralModulesDelete = {
  headText: commonLanguage.delete_item,
  subText: commonLanguage.this_will_permanently_delete_the_item,
  buttonOpt_1: commonLanguage.delete,
  buttonOpt_2: commonLanguage.cancel
};


export const warningMsgSurveyResponseDelete = {
  headText: commonLanguage.are_you_sure,
  subText: commonLanguage.sure_to_delete_survey_response,
  buttonOpt_1: commonLanguage.delete,
  buttonOpt_2: commonLanguage.cancel
};

export const warningMsgTemplateDelete = {
  headText: commonLanguage.are_you_sure,
  subText: commonLanguage.sure_to_delete_template,
  buttonOpt_1: commonLanguage.delete,
  buttonOpt_2: commonLanguage.cancel
};

export const warningMsgParticipantDelete = {
  headText: commonLanguage.are_you_sure,
  subText: commonLanguage.sure_to_delete_participant,
  buttonOpt_1: commonLanguage.delete,
  buttonOpt_2: commonLanguage.cancel
};

export const warningMsgDeleteRisk = {
  headText: riskArabicTranslation?.to_remove_risk,
  subTextAudit: commonLanguage?.undo_this_action,
  buttonOpt_audit: commonLanguage?.yes_confirm,
  buttonOpt_2: commonLanguage?.no_cancel
};

export const warningMsgDeleteControlAudit = {
  headText: riskArabicTranslation?.to_remove_control,
  subTextAudit: commonLanguage?.undo_this_action,
  buttonOpt_audit: commonLanguage?.yes_confirm,
  buttonOpt_2: commonLanguage?.no_cancel
};

export const warningDeleteMsg = {
  headText: 'Are You Sure You Want to Delete this Item?',
  subText: 'You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const warningMsgRiskprogramSaveAsDraft = {
  headText: commonLanguage?.save_draft,
  subText: commonLanguage?.please_confirm,
  buttonOpt_1: commonLanguage?.yes_confirm,
  buttonOpt_2: commonLanguage?.no_cancel,
  automationButton_1: 'yesconfirm',
  automationButton_2: 'nocancel'
};

export const warningMsgAfterRiskProgramSaveAsDraft = {
  headText: commonLanguage?.draft_is_saved,
  subText: riskArabicTranslation?.risk_program_saved_draft_editing,
  buttonOpt_1: commonLanguage?.continue_editing,
  buttonOpt_2: commonLanguage?.exit,
  automationButton_1: 'continueediting',
  automationButton_2: 'exit'
};

export const warningDeleteUserMsg = {
  headText: 'Are You Sure You Want to Delete this Item?',
  subText: 'Are you sure? You cannot undo this action',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const warningMsgDeleteEsg = {
  headText: 'You are about to delete the',
  subText: 'Are you sure you want to delete this item? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const warningMsgDeleteGoal = {
  headText: 'You are about to delete the goal',
  subText: 'Are you sure you want to delete this item? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};


export const warningMsgDeleteSubGoal = {
  headText: 'You are about to delete the sub goal',
  subText: 'Are you sure you want to delete this item? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const warningMsgDeleteObjective = {
  headText: 'You are about to delete the objective',
  subText: 'Are you sure you want to delete this item? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const warningAuditPlanDeleteUserMsg = {
  headText:translateKeys.deleteUniverseModel,
  subText: 'Are you sure? You cannot undo this action',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const assessmentWarningMsgDelete = {
  headText: 'You are About to Delete the Template!',
  subText: 'Are you sure? You cannot undo this action',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const warningMsgDeleteDocument = {
  headText: 'You are About to Delete the Document!',
  subText: 'Are you sure you want to delete this item? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const warningMessageClone = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to clone this item? Copy of this item will be created as a new item',
  buttonOpt_1: 'Clone',
  buttonOpt_2: 'Cancel'
};

export const warningMessageWithdraw = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to withdraw this request? Request will be withdrawing from reviewer',
  buttonOpt_1: 'Withdraw',
  buttonOpt_2: 'Cancel'
};

export const warningMessageWidrow = {
  headText: 'Do you want to withdraw the previous request?',
  subText: 'There is a pending edit request which you already created for this same record. You have to withdraw that for a new request.',
  buttonOpt_1: 'Continue',
  buttonOpt_2: 'Cancel'
};
export const warningRequestManagement = {
  headText: 'Proceed to Request Management Response?',
  subText: 'Management responses for the 30 observations will be requested from John Doe..',
  buttonOpt_1: 'Proceed ',
  buttonOpt_2: 'No, don’t proceed'
};
export const warningApproval = {
  headText: 'Deactivate the Approval System?',
  subText: 'While acting, existing edit and delete requests will be discarded. Create requests will move to drafts. Do you want to proceed?',
  buttonOpt_1: 'Proceed ',
  buttonOpt_2: 'Cancel'
};
export const multipleApproval = {
  headText: 'Deactivate Unanimous Approval?',
  subText: 'Existing records having at least one response will skip the level. Do you want to proceed?',
  buttonOpt_1: 'Proceed ',
  buttonOpt_2: 'Cancel'
};
export const singleApproval = {
  headText: 'Activate Unanimous Approval?',
  subText: 'Each person should approve before the approval goes through. Pending approvals will require unanimous consent.',
  buttonOpt_1: 'Proceed ',
  buttonOpt_2: 'Cancel'
};
export const approvalDraft = {
  headText: 'Successful!',
  buttonOpt_1: 'Ok',
  buttonOpt_2: 'View Draft'
};
export const successRequestManagement = {
  headText: 'Successful!',
  subText: 'Bulk management responses are requested from John Doe.',
  buttonOpt_1: 'Ok'
};
export const successActivation = {
  headText: 'Account activated!',
  subText: 'An email has been sent with a link to log in to the account.',
  buttonOpt_1: 'Ok'
};
export const warningMsgResend = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to reactivate this user? You can’t undo this action.',
  buttonOpt_1: 'Reactivate',
  buttonOpt_2: 'Cancel'
};

export const warningMsgDeleteUser = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to delete this user? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const deleteUserWarning = {
  headText: 'You are About to Delete the user!',
  subText: 'Are you sure? You cannot undo this action',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const warningMsgRemove = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to remove this item? You can’t undo this action.',
  subTextAudit: 'Are you sure you want to remove this item? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};
export const warningMsgDeleteScenario = {
  headText: 'You are About to Delete a Scenario',
  subText: 'Are you sure? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};
export const warningMsgDeleteReport = {
  headText: 'You are About to Delete a Report',
  subText: 'Are you sure? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const warningMsgDeleteDocumentPolicy = {
  headText: 'You are About to Delete a Document',
  subText: 'Are you sure? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel',
  automationButton_1: 'yes',
  automationButton_2: 'no',
};

export const warningMsgDeactivate = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to deactivate this user? You can’t undo this action.',
  buttonOpt_1: 'Deactivate',
  buttonOpt_2: 'Cancel'
};

export const warningMsgTimeDropEdit = {
  headText: 'Confirm',
  subText: 'Are you sure you want to edit this item?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel'
};


export const auWarningMsgEdit = {
  headText: commonLanguage?.areYouSure,
  subText: commonLanguage?.sure,
  buttonOpt_1: commonLanguage?.yes,
  buttonOpt_2: commonLanguage?.cancel
};

export const editWarning = {
  headText: 'Please Confirm that You Want to Save the Changes.',
  subText: ' ',
  buttonOpt_1: 'Save',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgEditStrategyPlan = {
  headText: riskArabicTranslation?.to_edit_strategy_plan,
  subText: 'Edit Are you sure?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel changes'
};

export const auWarningMsgEditESGPlan = {
  headText: 'You are about to edit the strategy plan',
  headTextESG: 'You are about to edit the ESG plan',
  subText: 'Edit Are you sure?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel changes'
};

export const auWarningMsgDeleteESGPlan = {
  headText: 'You are about to delete the strategy plan',
  headTextESG: 'You are about to delete the ESG plan',
  subText: 'Are you sure you want to delete this item? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgDeleteStrategyPlan = {
  headText: riskArabicTranslation?.delete_strategy_plan,
  subText: 'Are you sure you want to delete this item? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgCancel = {
  headText: 'Are You Sure?',
  subText: 'Please confirm that you want to discard the changes.',
  buttonOpt_1: 'Discard',
  buttonOpt_2: 'Cancel'
};
export const warningMsgCancel = {
  headText: 'You are About to Cancel the Uploaded Data',
  subText: 'Are You Sure?',
  buttonOpt_1: 'Proceed',
  buttonOpt_2: 'Cancel'
};

export const warningMsgRiskErmApproval = {
  headText: commonLanguage?.submitForApprovalQuestion,
  subText: commonLanguage?.submitApprovalLockConfirm,
  buttonOpt_1: commonLanguage?.submitForApproval,
  buttonOpt_2: commonLanguage?.cancel
};

export const warningMsgRiskErmApprovalForEdit = {
  headText: commonLanguage?.submitForApprovalQuestion,
  subText: commonLanguage?.submitApprovalLockConfirmEdit,
  buttonOpt_1: commonLanguage?.submitForApproval,
  buttonOpt_2: commonLanguage?.cancel
};

export const assistedModellingWarningMsgCancel = {
  headText: 'You are About to Cancel the Assisted Modeling',
  subText: 'Are You Sure?',
  buttonOpt_1: 'Proceed',
  buttonOpt_2: 'Cancel'
};

export const cancelWarningMsg = {
  headText: 'Please Confirm that You Want to Save the Changes.',
  subText: ' ',
  buttonOpt_1: 'Save',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgComplianceEdit = {
  headText: 'Edit Compliance',
  subText: 'Are you sure you want to edit this compliance?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel'
};
export const assetFormControlPanel = {
  headText: 'Update Asset Form',
  subText: 'Are you sure you want to update this Asset Form?',
  buttonOpt_1: 'Update',
  buttonOpt_2: 'Cancel'
};
export const incidentFormControlPanel = {
  headText: 'Edit Incident Form',
  subText: 'Are you sure you want to edit this Incident Form?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel'
};
export const araCpEdit = {
  headText: 'You are About to Edit a Scenario!',
  subText: 'Are you sure you?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel'
};
export const araCpProcess = {
  headText: 'Edit Process',
  subText: 'Are you sure you want to edit this Process?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel'
};
export const araCpSubProcess = {
  headText: 'Edit Sub Process',
  subText: 'Are you sure you want to edit this Sub Process?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgIncidentEdit = {
  headText: 'Edit Incident',
  subText: 'Are you sure you want to edit this  incident?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgAssetEdit = {
  headText: 'Edit Asset',
  subText: 'Are you sure you want to edit this  asset?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgScenarioDetailEdit = {
  headText: 'You are About to Edit a Scenario',
  subText: 'Edit Are you sure?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgComplianceCreate = {
  headText: 'Create a new Compliance',
  subText: 'Are you sure you want to create a new compliance?',
  buttonOpt_1: 'Create',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgCreate = {
  headText: 'Add a new expense',
  subText: 'Are you sure you want to add a new expense?',
  buttonOpt_1: 'Add',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgRiskDraft = {
  headText: commonLanguage?.proceed_to_assessment,
  subText: riskArabicTranslation?.proceed_risk_assessment,
  buttonOpt_1: commonLanguage?.confirm,
  buttonOpt_2: commonLanguage?.cancel
};
export const auWarningMsgRiskSaveAsDraft = {
  headText: commonLanguage?.save_draft,
  subText: commonLanguage?.please_confirm,
  buttonOpt_1: commonLanguage?.yes_confirm,
  buttonOpt_2: commonLanguage?.no_cancel,
  automationButton_1: 'yesconfirm',
  automationButton_2: 'nocancel'
};

export const auWarningMsgAfterRiskSaveAsDraft = {
  headText: commonLanguage?.draft_is_saved,
  subText: riskArabicTranslation?.risk__saved_draft_editing,
  buttonOpt_1: commonLanguage?.continue_editing,
  buttonOpt_2: commonLanguage?.exit,
  automationButton_1: 'continueediting',
  automationButton_2: 'exit'
};
export const auWarningMsgAuditEdit = {
  headText: 'Edit Audit',
  subText: 'Are you sure you want to edit this Audit?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgRiskProgramEdit = {
  headText: riskArabicTranslation?.Edit_Risk_Program,
  subText: riskArabicTranslation?.to_edit_Risk_Program,
  buttonOpt_1: commonLanguage?.yes,
  buttonOpt_2: commonLanguage?.cancel
};

export const auWarningMsgKriEdit = {
  headText: kriArabicTranslation?.edit_kri,
  subText: kriArabicTranslation?. want_to_edit_kri,
  buttonOpt_1: commonLanguage?.yes,
  buttonOpt_2: commonLanguage?.cancel
};
export const auWarningMsgAuditProgramEdit = {
  headText: 'Edit Audit Program',
  subText: 'Are you sure you want to edit this Audit Program?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgStrategyEdit = {
  headText: riskArabicTranslation?.to_edit_strategy,
  subText: commonLanguage?.are_you_sure,
  buttonOpt_1: commonLanguage?.yes_edit,
  buttonOpt_2: commonLanguage?.cancel_changes
};

export const auWarningMsgStrategyDynamicEdit = {
  headText: kriArabicTranslation?.to_edit_strategy_dynamic,
  subText: commonLanguage?.are_you_sure,
  buttonOpt_1: commonLanguage?.yes_edit,
  buttonOpt_2: commonLanguage?.cancel_changes
};

export const auWarningMsgStrategyDelete = {
  headText: riskArabicTranslation?.delete_strategy,
  subText: riskArabicTranslation?.undo_action,
  buttonOpt_1: commonLanguage?.yes_delete_forever,
  buttonOpt_2: commonLanguage?.no_dont_delete
};

export const auWarningMsgRiskEdit = {
  headText: riskArabicTranslation?.Edit_Risk,
  subText: riskArabicTranslation?.to_edit_risk,
  buttonOpt_1: commonLanguage?.yes,
  buttonOpt_2: commonLanguage?.cancel
};

export const auWarningMsgControlCreate = {
  headText:riskArabicTranslation?.Create_new_Control,
  subText: riskArabicTranslation?.to_create_new_risk_control,
  buttonOpt_1: commonLanguage?.yes,
  buttonOpt_2: commonLanguage?.cancel
};

export const auWarningMsgControlEdit = {
  headText: riskArabicTranslation?.Edit_Control,
  subText: riskArabicTranslation?.to_edit_risk_control,
  buttonOpt_1: commonLanguage?.yes,
  buttonOpt_2: commonLanguage?.cancel
};

export const auWarningMsgConfirm = {
  headText: 'Are you sure?',
  subText: 'Please Confirm the details',
  buttonOpt_1: 'Confirm',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgAnalyticsCalculatedFieldEdit = {
  headText: 'Edit Field',
  subText: 'Are you sure you want to edit this field? This field is used for another calculated field creation.',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgScenarioCreate = {
  headText: 'Create Scenario',
  subText: 'Are you sure you want to create a scenario?',
  buttonOpt_1: 'Create',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgScenarioEdit = {
  headText: 'You are About to Edit a Scenario',
  subText: 'Edit Are you sure?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgSaveChanges = {
  headText: 'Save Changes',
  subText: 'Do you want to save the changes?',
  buttonOpt_1: 'Save',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgSaveChangesOnExit = {
  headText: 'Exit without saving',
  subText: 'Are you sure you want to exit without saving the changes?',
  buttonOpt_1: 'Exit',
  buttonOpt_2: 'Cancel'
};
export const dataProcess = {
  headText: 'Data Process',
  subText: 'This will Process all Records. Are you sure?',
  buttonOpt_1: 'Proceed',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgDataFlush = {
  headText: 'Flush data source ?',
  subText: 'This will flush the data from the data source.',
  buttonOpt_1: 'Flush',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgDataFlushOverview = {
  headText: 'All exceptions will be flushed & you should run each scenario individually.',
  subText: 'Do you want to proceed?',
  buttonOpt_1: 'Flush Data',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgDataFlushDetails = {
  headText: 'All exceptions will be flushed & scenario will run again.',
  subText: 'Do you want to proceed?',
  buttonOpt_1: 'Flush Data',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgDataFlushWithScenarios = {
  headText: 'You are About to Flush the Data to this Data Source',
  subText: 'Are you sure?',
  subText_1: 'scenario is created on this data source. Are you sure you want to flush the data?',
  subText_2: 'scenarios are created on this data source. Are you sure you want to flush the data?',
  buttonOpt_1: 'Flush Data',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgDataAddAfterFlush = {
  headText: 'You are About to Add Data to this Data Source',
  subText: 'Are you sure?',
  buttonOpt_1: 'Add',
  buttonOpt_2: 'Cancel'
};

export const warningMsgLoadMoreTextData = {
  headText: 'You are About to Add More Data to this Data Source',
  subText: 'Are you sure?',
  buttonOpt_1: 'Add',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgException = {
  warning: 'Mark as exception ?',
  headText: 'This might change the rating of the following control(s):',
  subText: 'will be changed. Do you want to proceed?',
  buttonOpt_1: 'Mark as exception',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgClientCreate = {
  headText: 'Create a new Client',
  subText: 'Are you sure you want to create a new client?',
  buttonOpt_1: 'Create',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgClientEdit = {
  headText: 'Edit a Client',
  subText: 'Are you sure you want to edit client details?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel'
};
export const warningMsgDeleteClient = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to delete this client? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};
export const warningMsgActivateClient = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to activate this client?',
  buttonOpt_1: 'Activate Client',
  buttonOpt_2: 'Cancel'
};
export const warningMsgDeactivateClient = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to deactivate this client?',
  buttonOpt_1: 'Deactivate Client',
  buttonOpt_2: 'Cancel'
};
export const warningMsgInstanceClient = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to create instance for this client?',
  buttonOpt_1: 'Create Instance',
  buttonOpt_2: 'Cancel'
};

export const warningMsgCreatePolicy = {
  headText: policyTranslation?.policyCreate,
  subText: policyTranslation?.createPolicy,
  buttonOpt_1: commonLanguage?.yes,
  buttonOpt_2: commonLanguage?.cancel
};

export const warningMsgCreatePolicyAsDraft = {
  headText: commonLanguage?.saveAs,
  subText: commonLanguage?.plzCnfm,
  buttonOpt_1: commonLanguage?.yes_confirm,
  buttonOpt_2: commonLanguage?.no_cancel
};

export const warningMsgRestorePolicy = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to restore policy?',
  buttonOpt_1: 'Restore',
  buttonOpt_2: 'Cancel'
};
export const warningMsgEditPolicy = {
  headText: policyTranslation?.editPolicy,
  subText: policyTranslation?.areYouSure,
  buttonOpt_1: commonLanguage?.yes,
  buttonOpt_2: commonLanguage?.cancel
};

export const warningMsgTabSwitchPolicy = {
  headText: commonLanguage?.discard_changes,
  subText: policyTranslation?.loseChanges,
  buttonOpt_1: commonLanguage?.keepEditing,
  buttonOpt_2: commonLanguage?.discard
};

export const warningMsgDeletePolicy = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to delete this policy? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel',
  automationButton_1: 'delete',
  automationButton_2: 'cancel'
};

export const warningMsgDeleteSection = {
  headText: commonLanguage?.are_you_sure,
  subText: 'You are about to delete this section. This will delete all the subsections below this section. Do you want to proceed? You cannot undo this action.',
  headDeleteTxt: 'Delete section',
  SubDeleteTxt: 'This will permanently delete the section and subsections',
  buttonOpt_1: commonLanguage?.delete,
  buttonOpt_2: commonLanguage?.cancel,
}

export const policyRequestFormControlPanel = {
  headText: 'Update Request Details Form',
  subText: 'Are you sure you want to update this Request Details Form?',
  buttonOpt_1: 'Update',
  buttonOpt_2: 'Cancel'
};

export const policyRequestApprove = {
  headText: 'Approve Request',
  subText: 'Are you sure you want to approve this request?',
  buttonOpt_1: 'Approve',
  buttonOpt_2: 'Cancel'
};
export const complianceRequestApprove = {
  headText: 'Approve Request',
  subText: 'Are you sure you want to approve this request?',
  buttonOpt_1: 'Approve',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgPolicyRequestEdit = {
  headText: 'Edit Policy Request',
  subText: 'Are you sure you want to edit this policy request?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgPolicyRequestCreate = {
  headText: 'Create a New Policy Request',
  subText: 'Are you sure you want to create a new Policy Request?',
  buttonOpt_1: 'Create',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgPolicyRequestCreateAsDraft = {
  headText: 'Save as Draft ?',
  subText: 'Please confirm',
  buttonOpt_1: 'Confirm',
  buttonOpt_2: 'Cancel'
};

export const warningMsgDeletePolicyRequest = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to delete this policy request? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};
export const warningMsgDeleteComplianceRequest = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to delete this compliance request? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgReviewPolicy = {
  headText: 'Review Policy',
  subText: 'Are you sure you want to review this policy?',
  buttonOpt_1: 'Review',
  buttonOpt_2: 'Cancel'
};

export const warningMsgEditCompliance = {
  headText: 'Edit a',
  subText: 'Are you sure you want to edit the',
  subTextAddOn:' ?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel'
};

export const warningMsgDeleteComplianceAudit = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to delete this ',
  subTextAddOn:' ? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const warningMsgCloneComplianceProgram = {
  caption: commonLanguage?.copyOfComlpliance,
  captionForPlan: commonLanguage?.copyOfComlpliancePlan,
  subText: commonLanguage?.to_go_ahead,
  buttonOpt_1: commonLanguage?.clone,
  buttonOpt_2: commonLanguage?.cancel
}

export const warningMsgDeleteTimeLog = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to delete this time log? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const warningMsgCreateComplianceAudit = {
  headText: 'Create a new ',
  subText: 'Are you sure you want to create a new ' ,
  subTextAddOn: ' ?',
  buttonOpt_1: 'Create',
  buttonOpt_2: 'Cancel'
};
export const warningMsgCreateComplianceAuditAsDraft = {
  headText: 'Save as Draft ?',
  subText: 'Please confirm',
  buttonOpt_1: 'Confirm',
  buttonOpt_2: 'Cancel'
};
export const warningMsgCompliancRequestCreate = {
  headText: 'Create a new Compliance Request',
  subText: 'Are you sure you want to create a new Compliance Request?',
  buttonOpt_1: 'Create',
  buttonOpt_2: 'Cancel'
};

export const warningMsgCompliancRequestCreateAsDraft = {
  headText: 'Save as Draft ?',
  subText: 'Please confirm',
  buttonOpt_1: 'Confirm',
  buttonOpt_2: 'Cancel'
};

export const warningMsgRestoreComplianceAudit = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to restore this citation?',
  buttonOpt_1: 'Restore',
  buttonOpt_2: 'Cancel'
};

export const warningMsgReviewComplianceAudit = {
  headText: 'Review Citation',
  subText: 'Are you sure you want to review this citation?',
  buttonOpt_1: 'Review',
  buttonOpt_2: 'Cancel'
};

export const warningMsgDeleteCompliance = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to delete this compliance? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const warningMsgComplianceRequestEdit = {
  headText: 'Edit Compliance Request',
  subText: 'Are you sure you want to edit this compliance request?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel'
};

export const warningMsgRequestCreate = {
  headText: 'Create a New Request',
  subText: 'Are you sure you want to create a new Request?',
  buttonOpt_1: 'Create',
  buttonOpt_2: 'Cancel'
};

export const warningMsgRequestCreateAsDraft = {
  headText: 'Save as Draft?',
  subText: 'Please confirm',
  buttonOpt_1: 'Confirm',
  buttonOpt_2: 'Cancel'
};


export const warningMsgRequestEdit = {
  headText: 'Edit Request',
  subText: 'Are you sure you want to edit this request?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel'
};

export const warningMsgDeleteRequest = {
  headText: 'Remove this Request!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Confirm',
  buttonOpt_2: 'Cancel'
};


export const warningMsgAddNewDependency = {
  headText: commonLanguage?.addAnewDependency,
  subText: commonLanguage?.areYouSureUwantToAddDependency,
  buttonOpt_1: commonLanguage?.yes,
  buttonOpt_2: commonLanguage?.cancel
};

export const warningMsgAddNewDependencies = {
  headText: 'Add new dependencies',
  subText: 'Are you sure you want to add new dependencies?',
  buttonOpt_1: 'Add',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgCreateRequestPolicy = {
  headText: 'Are you sure?',
  subText: 'You are leaving the policy creation wizard to create a request. Please confirm your action.',
  buttonOpt_1: 'Proceed',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgCreateRequestCompliance = {
  headText: 'Are you sure?',
  subText: 'You are leaving the compliance creation wizard to create a request. Please confirm your action.',
  buttonOpt_1: 'Proceed',
  buttonOpt_2: 'Cancel'
};

export const warningMsgTimeLogApprove = {
  headText: 'Approve Time Log',
  subText: 'Approve this time log?',
  buttonOpt_1: 'Approve',
  buttonOpt_2: 'Cancel'
};

export const warningMsgTimeLogRequestCreate = {
  headText: 'Create a new Time Log Request',
  subText: 'create time request?',
  buttonOpt_1: 'Create',
  buttonOpt_2: 'Cancel'
};
export const warningMsgEditBusinessProcess = {
  headText: 'Edit a business process',
  subText: 'Edit Are you sure?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel'
};

export const warningMsgTimeLogReject = {
  headText: 'Reject Time Log',
  subText: 'Are you sure you want to reject this time log?',
  buttonOpt_1: 'Reject',
  buttonOpt_2: 'Cancel'
};
export const warningMsgCreateBusinessProcess = {
  headText: 'Create a business process',
  subText: 'Are you sure?',
  buttonOpt_1: 'Create',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgAssetCreate = {
  headText: 'Create Asset',
  subText: 'Are you sure you want to create this asset?',
  buttonOpt_1: 'Create',
  buttonOpt_2: 'Cancel'
};

export const warningMsgCreateAssetAsDraft = {
 headText: 'Save as Draft ?',
  subText: 'Please confirm',
  buttonOpt_1: 'Confirm',
  buttonOpt_2: 'Cancel'
};

export const warningMsgCreateBusinessProcessAsDraft = {
  headText: 'Save as Draft ?',
  subText: 'Please confirm',
  buttonOpt_1: 'Confirm',
  buttonOpt_2: 'Cancel'
};

export const warningMessageRedirectToObservationWizard = {
  headText: 'You are About to Edit an Observation.',
  subText: commonLanguage.alertSubText,
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel'
};
export const observationWarning = {
  headText: commonLanguage.edit,
  subText: 'You will be directed to editing wizard where you can modify the',
  subtText2: 'details and the dependencies. Do you want to proceed?'

}

export const auWarningMsgMassReAssign = {
  headText: 'Proceed to Mass Reassign?',
  subText: 'You are about to replace the selected user on CountOfRecords records. Do you want to proceed?',
  buttonOpt_1: 'Proceed',
  buttonOpt_2: 'No, don’t proceed',
  subText_1: 'You are about to replace the selected user on',
  subText_2: 'records. Do you want to proceed?',
  showCancelButton: true,
  icon: 'warning'
};

export const warningMsgErmAssessment = {
  headText: commonLanguage.proceed_to_assign_assessment,
  subText: commonLanguage.about_to_assign_the_assessment,
  buttonOpt_1: commonLanguage.yesProceed,
  buttonOpt_2: commonLanguage.no_cancel,
  subText_1: commonLanguage.about_to_assign_the_assessment,
  subText_2: commonLanguage.want_to_proceed,
  showCancelButton: true,
  icon: 'warning',
  automationButton_1: 'yesproceed',
  automationButton_2: 'nocancel'
};

export const askForMassReassign = {
  headText: 'Proceed to Mass Reassign?',
  subText: 'The User has dependencies with other data. Reassign data to another user and then try deleting it again',
  buttonOpt_1: 'Proceed',
  buttonOpt_2: 'Cancel'
};

export const notifyMassReassign = {
  headText: 'Mass Reassign is in Progress',
  subText: 'This will take a while. You will be notified once the action is complete.',
  buttonOpt_1: 'Ok',
  buttonOpt_2: 'Cancel',
  showCancelButton: false,
  icon: 'success'
};

export const askForRecoverUser = {
  headText: 'Proceed to Recover User?',
  subText: 'This is a deleted user which already having a history with your team. Do you want to proceed to recover this user?',
  buttonOpt_1: 'Recover',
  buttonOpt_2: 'Cancel',
  showCancelButton: true,
  icon: 'warning'
};

export const warningSwLatest = {
  headText: 'Latest build is available',
  subText: 'Please click the reload button to load changes.',
  buttonOpt_1: 'Reload now',
  buttonOpt_2: 'I\'ll do it later'
};

export const warningBulkReminderCreate = {
  headText: 'Proceed to Submit Bulk Reminder?',
  subText: 'You are about to proceed with Bulk reminders for the selected records. Do you want to proceed?',
  buttonOpt_1: 'Proceed',
  buttonOpt_2: 'No, don’t proceed',
  subText_1: 'You are about to proceed with Bulk reminders for the',
  subText_2: 'selected records. Do you want to proceed?',
  subText_3: 'selected record. Do you want to proceed?',
  showCancelButton: true,
  icon: 'warning'
};

export const confirmReminder = {
  headText: 'Successful',
  subText: 'Reminder has been scheduled',
  buttonOpt_1: 'Ok',
  buttonOpt_2: 'Cancel',
  showCancelButton: false,
  icon: 'success'
};

export const sendBulkReminderEmail = {
  headText: 'Email Sending is in Progress',
  subText: 'This will take a while. You will be notified once the action is completed.',
  buttonOpt_1: 'Ok',
  buttonOpt_2: 'Cancel',
  showCancelButton: false,
  icon: 'success'
};

export const warningMsgDeleteControl = {
  headText: riskArabicTranslation?.to_delete_control,
  subText: riskArabicTranslation?.undo_action,
  buttonOpt_1: commonLanguage?.yes_delete_forever,
  buttonOpt_2: commonLanguage?.no_dont_delete
};
export const warningMessageCloneControl = {
  headText: riskArabicTranslation?.copy_of_control,
  subText: commonLanguage?.to_go_ahead,
  buttonOpt_1: commonLanguage?.clone,
  buttonOpt_2: commonLanguage?.cancel
};
export const auWarningMsgControlEditConfirmation = {
  headText: riskArabicTranslation?.to_edit_the_risk_control,
  subText: commonLanguage?.are_you_sure,
  buttonOpt_1: commonLanguage?.yes_edit,
  buttonOpt_2: commonLanguage?.cancel_changes
};
export const auWarningMsgControlCreateConfirmation = {
  headText: riskArabicTranslation?.create_a_new_risk_control,
  subText: commonLanguage?.to_go_ahead,
  buttonOpt_1: commonLanguage?.yes_create,
  buttonOpt_2: commonLanguage?.cancel,
  automationButton_1: 'yescreate',
  automationButton_2: 'cancel'
};
export const warningMsgControlCreateAsDraft = {
  headText: commonLanguage.save_draft,
  subText: commonLanguage.please_confirm,
  buttonOpt_1: commonLanguage.yes_confirm,
  buttonOpt_2: commonLanguage.no_cancel,
  automationButton_1: 'yesconfirm',
  automationButton_2: 'nocancel'
};
export const auWarningMsgDeleteTaggedRisk = {
  headText: riskArabicTranslation?.to_remove_the_risk,
  subText: commonLanguage?.undo_this_action,
  buttonOpt_1: commonLanguage?.yes_confirm,
  buttonOpt_2: commonLanguage?.no_cancel
};
export const auWarningMsgDeleteTaggedObservation = {
  headText: 'You are About to Remove the Observation!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Remove',
  buttonOpt_2: 'Cancel'
};
export const warningMsgDeleteAssessment = {
  headText: commonLanguage?.remove_assessment,
  subText: commonLanguage?.undo_this_action,
  buttonOpt_1:  commonLanguage?.yes_confirm,
  buttonOpt_2: commonLanguage?.no_cancel
};
export const warningMsgDeleteIncident = {
  headText: 'You are About to Remove the Incident!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Remove',
  buttonOpt_2: 'Cancel'
};
export const warningMsgDeleteAction = {
  headText: 'You are About to Delete Action!',
  subText: 'Are you sure you want to delete this item? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgDeleteIssue = {
  headText: 'You are About to Delete Issue!',
  subText: 'Are you sure you want to delete this item? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgActionEditConfirmation = {
  headText: 'You are About to Edit the Action!',
  subText: 'Edit Are you sure?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgImprovementEditConfirmation = {
  headText: 'You are About to Edit the Improvement!',
  subText: 'Edit Are you sure?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel changes'
};
export const auWarningMsgImprovementNoFieldsConfirmation = {
  headText: 'No inputs are given to the form',
  subText: 'Do you want to proceed?',
  buttonOpt_1: 'Proceed',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgIssueEditConfirmation = {
  headText: 'You are About to Edit the ',
  headTextAddon:' !',
  subText: 'Edit Are you sure?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel changes'
};
export const auWarningMessageCloneStrategy = {
  headText: riskArabicTranslation?.clone_strategy,
  subText: riskArabicTranslation?.continue_ahead,
  buttonOpt_1: commonLanguage?.clone,
  buttonOpt_2: commonLanguage?.cancel
};
export const auWarningMssgRiskCreate = {
  headText: riskArabicTranslation?.to_create_new_risk,
  subText: commonLanguage?.are_you_sure,
  buttonOpt_1: commonLanguage?.yes_create,
  buttonOpt_2: commonLanguage?.cancel_changes
};
export const auWarningMssgObservationCreate = {
  headText: commonLanguage?.warning_msg_obsrvtn_create,
  subText: commonLanguage?.are_you_sure,
  buttonOpt_1: commonLanguage?.yes_create,
  buttonOpt_2: commonLanguage?.cancel
};
export const auWarningMessageCloneRisk = {
  headText: riskArabicTranslation?.clone_risk,
  subText: commonLanguage?.to_go_ahead,
  buttonOpt_1: commonLanguage?.clone,
  buttonOpt_2: commonLanguage?.cancel
};
export const auWarningMsgDeleteRisk = {
  headText: riskArabicTranslation?.to_delete_risk,
  subText: commonLanguage?.undo_this_action,
  buttonOpt_1: commonLanguage?.yes_delete_forever,
  buttonOpt_2: commonLanguage?.no_dont_delete
};
export const auWarningMsgUpdateRisk = {
  headText: riskArabicTranslation?.about_to_edit_risk,
  subText: commonLanguage?.are_you_sure,
  buttonOpt_1: commonLanguage?.yes_edit,
  buttonOpt_2: commonLanguage?.cancel_changes,
  automationButton_1: 'yesedit',
  automationButton_2: 'cancelchanges'
};
export const auWarningMsgUpdatePlanModel = {
  headText: translateKeys.universeModelEdit,
  subText: 'Edit Are you sure?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel changes'
};
export const auWarningMsgRemoveTaggedControl = {
  headText: riskArabicTranslation?.to_remove_control,
  subText: commonLanguage?.undo_this_action,
  buttonOpt_1: commonLanguage?.yes_confirm,
  buttonOpt_2: commonLanguage?.no_cancel
};
export const auWarningMsgRemoveTaggedIncident = {
  headText: 'You are About to Remove an Incident!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Remove',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgRemoveTaggedRecommendation = {
  headText: riskArabicTranslation?.about_to_remove_recommendation,
  subText: commonLanguage?.undo_this_action,
  buttonOpt_1: commonLanguage?.yes_remove,
  buttonOpt_2: commonLanguage?.no_cancel
};
export const auWarningMsgRiskRegisterEdit = {
  headText: riskArabicTranslation?.To_Edit_Risk_Register,
  subText: commonLanguage?.are_you_sure,
  buttonOpt_1: commonLanguage?.yes_edit,
  buttonOpt_2: commonLanguage?.cancel_changes
};
export const auWarningMsgRiskRegisterDelete = {
  headText: riskArabicTranslation?.Delete_Risk_Register,
  subText: riskArabicTranslation?.undo_action,
  buttonOpt_1: commonLanguage?.yes_delete_forever,
  buttonOpt_2: commonLanguage?.no_dont_delete
};
export const auWarningMsgErmRiskRegisterDelete = {
  headText: riskArabicTranslation?.Delete_ERM_Register,
  subText: commonLanguage?.undo_this_action,
  buttonOpt_1: commonLanguage?.yes_delete_forever,
  buttonOpt_2: commonLanguage?.no_dont_delete
};
export const auWarningMsgErmRiskRegisterEdit = {
  headText: riskArabicTranslation?.To_Edit_ERM_Register,
  subText: commonLanguage?.are_you_sure,
  buttonOpt_1: commonLanguage?.yes_edit,
  buttonOpt_2: commonLanguage?.cancel_changes
};
export const warningMsgWithdrawReminder = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to withdraw this reminder? You can’t undo this action.',
  buttonOpt_1: 'Withdraw',
  buttonOpt_2: 'Cancel',
  showCancelButton: true,
  icon: 'warning'
};
export const warningIncidentReporting = {
  headText: 'Thank You for Submitting the Incident Report',
  subText: ' ',
  buttonOpt_1: 'Ok',
  buttonOpt_2: ' ',
  subText_1: 'Use tracking ID:',
  subText_2: 'to track the incident report status.',
  showCancelButton: false,
  icon: 'success'
};

export const warningWhistleblowReporting = {
  headText: 'Thank You for Submitting the Whistleblower',
  subText: ' ',
  buttonOpt_1: 'Ok',
  buttonOpt_2: ' ',
  subText_1: 'Please take a note of your ticket ID: \n',
  subText_2: 'for future reference.',
  showCancelButton: false,
  icon: 'success'
};
export const auWarningMsgRiskCategoryEdit = {
  headText: riskArabicTranslation?.edit_category,
  subText: commonLanguage?.are_you_sure,
  buttonOpt_1: commonLanguage?.yes_edit,
  buttonOpt_2: commonLanguage?.cancel_changes
};
export const auWarningMsgRiskCategoryDelete = {
  headText: riskArabicTranslation?.delete_category,
  subText: riskArabicTranslation?.undo_action,
  buttonOpt_1: commonLanguage?.yes_delete_forever,
  buttonOpt_2: commonLanguage?.no_dont_delete
};
export const auWarningMessageCloneEnterpriseRisk = {
  headText: riskArabicTranslation?.clone_ERM,
  subText: commonLanguage?.to_go_ahead,
  buttonOpt_1: commonLanguage?.clone,
  buttonOpt_2: commonLanguage?.cancel
};
export const auWarningMsgDeleteAssessmentQuestionnaire = {
  headText: riskArabicTranslation?.delete_question,
  subText: riskArabicTranslation?.undo_action,
  buttonOpt_1: commonLanguage?.yes_delete_forever,
  buttonOpt_2: commonLanguage?.no_dont_delete
};
export const auWarningMsgDeleteAssessments = {
  headText: riskArabicTranslation?.Delete_an_Assessment,
  subText: riskArabicTranslation?.undo_action,
  buttonOpt_1: commonLanguage?.yes_delete_forever,
  buttonOpt_2: commonLanguage?.no_dont_delete
};
export const auWarningMsgDeleteAssessmentPlan = {
  headText: riskArabicTranslation?.Delete_an_Assessment_Plan,
  subText: riskArabicTranslation?.undo_action,
  buttonOpt_1: commonLanguage?.yes_delete_forever,
  buttonOpt_2: commonLanguage?.no_dont_delete
};

export const auWarningMsgEditAssessmentPlan = {
  headText: riskArabicTranslation?.Edit_an_Assessment_Plan,
  subText: commonLanguage?.are_you_sure,
  buttonOpt_1: commonLanguage?.yes_edit,
  buttonOpt_2: commonLanguage?.cancel_changes
};
export const auWarningMsgDeleteEnterpriseRisk = {
  headText: riskArabicTranslation?.To_Delete_ERM,
  subText: riskArabicTranslation?.undo_action,
  buttonOpt_1: commonLanguage?.yes_delete_forever,
  buttonOpt_2:  commonLanguage?.no_dont_delete
};
export const auWarningMsgEnterpriseRiskSaveAsDraft = {
  headText: commonLanguage?.save_draft,
  subText: commonLanguage?.please_confirm,
  buttonOpt_1: commonLanguage?.yes_confirm,
  buttonOpt_2: commonLanguage?.no_cancel,
  automationButton_1: 'yesconfirm',
  automationButton_2: 'nocancel'
};
export const auWarningMsgAfterEnterpriseRiskSaveAsDraft = {
  headText: commonLanguage?.draft_is_saved,
  subText: riskArabicTranslation?.Continue_Editing_ERM,
  buttonOpt_1: commonLanguage?.continue_editing,
  buttonOpt_2: commonLanguage?.exit,
  automationButton_1: 'continueediting',
  automationButton_2: 'exit'
};
export const auWarningMsgUpdateEnterpriseRisk = {
  headText: riskArabicTranslation?.To_Edit_ERM,
  subText: commonLanguage?.are_you_sure,
  buttonOpt_1: commonLanguage?.yes_edit,
  buttonOpt_2: commonLanguage?.cancel_changes
};
export const auWarningMsgEnterpriseRiskCreate = {
  headText: riskArabicTranslation?.Create_New_ERM,
  subText: commonLanguage?.are_you_sure,
  buttonOpt_1: commonLanguage?.yes_create,
  buttonOpt_2: commonLanguage?.cancel_changes,
  automationButton_1: 'yescreate',
  automationButton_2: 'cancelchanges'
};
export const auWarningMsgControlPanelDelete = {
  headText: 'Are You Sure You Want to Delete this Item?',
  subText: 'You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgControlPanelEdit = {
  headText: 'Please Confirm that You Want to Save the Changes.',
  headTextAlter: commonLanguage?.confirm_changes,
  subText: 'Edit Are you sure?',
  subTextAlter: commonLanguage?.mr_exclude_change,
  buttonOpt_1: 'Save',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgControlPanelSeverityEdit = {
  headText: 'Editing the Scoring Factor will Affect the Rating Calculations in the Existing Scoring Models',
  subText: 'Do you want to proceed?',
  buttonOpt_1: 'Proceed',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgControlPanelSeverityDetailEdit = {
  headText: 'Editing the Scoring Factor will Affect the Rating Calculations in the Scoring Model',
  subText: 'Do you want to proceed?',
  buttonOpt_1: 'Proceed',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgControlPanelRedirectToScoringModelEdit = {
  headText: 'You will be Redirected to the Existing Scoring Models Page',
  subText: 'Do you want to proceed?',
  buttonOpt_1: 'Proceed',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgControlPanelRedirectToObservationScoringModelEdit = {
  headText: 'You will be Redirected to Edit the Existing Scoring Model',
  subText: 'Do you want to proceed?',
  buttonOpt_1: 'Proceed',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgOngoingAudit = {
  headText: 'There are no On-going audits associated with the selected Risks/Controls.',
  subText: ' ',
  buttonOpt_1: 'Continue',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgOngoingRiskProgram = {
  headText: riskArabicTranslation?.ongoing_rp_selected_risks_controls,
  subText: ' ',
  buttonOpt_1: commonLanguage?.continue,
  buttonOpt_2: commonLanguage?.cancel
};
export const successOngGoingAudits = {
  headText: 'Successful!',
  subText: 'Selected Risks/Controls are tagged to the Audit.',
  buttonOpt_1: 'OK'
};
export const warningMsgUpdateResidualRiskRating = {
  headText: riskArabicTranslation?.to_edit_residual_risk_rating,
  subText: commonLanguage?.are_you_sure,
  buttonOpt_1: commonLanguage?.yesProceed,
  buttonOpt_2: commonLanguage?.no_cancel
};

export const warningMsgEditEmailTemplate = {
  headText: 'You are About to Edit the Template!',
  subText: 'Edit Are you sure?',
  buttonOpt_1: 'Proceed',
  buttonOpt_2: 'Cancel'
};

export const warningMsgEditEmailSignature = {
  headText: 'You are About to Edit the Signature!',
  subText: 'Edit Are you sure?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel'
};

export const auditEntityValueChanged = {
  headText: 'Entity Changed !',
  subText: 'Risks, Controls and Assessments will be fetched based on the latest entity values. Previously tagged dependencies will be removed from Audit.',
  buttonOpt_1: 'OK'
};

export const warningMsgAuditAsDraft = {
  headText: 'Your Draft is Saved',
  subText: 'The audit has been saved as a draft. Do you want to continue editing?',
  buttonOpt_1: 'Continue editing',
  buttonOpt_2: 'Exit'
};

export const auWarningMsgEditAudit = {
  headText: 'You are About to Edit an Audit!',
  subText: 'Edit Are you sure?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgDeleteAudit = {
  headText: 'You are about to delete this audit',
  subText: 'Are you sure you want to delete this item? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgDeleteAuditMessage = {
  headText: 'You are about to Delete an Audit!',
  subText: 'Do you want to proceed?',
  buttonOpt_1: 'Proceed',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgDeleteAuditUniverse = {
  headText: 'You are about to Delete an Audit Universe!',
  subText: 'Are you sure you want to delete this item? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgDeleteAuditPlan = {
  headText: 'You are about to Delete an Audit Plan!',
  subText: 'Are you sure you want to delete this item? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgEditAuditUniverse = {
  headText: 'You are About to Edit an Audit Universe!',
  headTextV2: 'You are About to Edit an Audit Plan!',
  subText: 'Edit Are you sure?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel changes'
};

export const auWarningMsgEditAuditProgram = {
  headText: 'You are about to Edit an Audit Program!',
  subText: 'Edit Are you sure?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel changes'
};

export const auWarningMsgEditAuditComment = {
  headText: 'You are about to edit an auditors comment',
  subText: 'Edit Are you sure?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel changes'
};

export const auWarningMsgEditAuditorComment = {
  headText: 'You are about to Edit an Auditor Comment!',
  subText: 'Edit Are you sure?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel changes'
};

export const auWarningMsgDeleteAuditProgram = {
  headText: 'You are about to Delete an Audit Program!',
  subText: 'Are you sure you want to delete this item? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgEditActivity = {
  headText: 'You are about to Edit an Activity!',
  subText: 'Edit Are you sure?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel changes'
};
export const auWarningMsgDeleteActivity = {
  headText: 'You are about to Delete an Activity!',
  subText: 'Are you sure you want to delete this item? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgAuditDraft = {
  headText: 'You are About to Delete an Audit Draft!',
  subText: 'Are you sure you want to delete this item? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgCommentDelete = {
  headText: 'You are About to Delete the Comment!',
  subText: 'Are you sure you want to delete this item? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgIssueDelete = {
  headText: 'You are About to Delete the ',
  headTextAddon:' !',
  subText: 'Are you sure you want to delete this item? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgEditAuditRecommendation = {
  headText: 'You are About to Edit a Recommendation!',
  subText: 'Edit Are you sure?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel changes'
};
export const auWarningMsgEditRiskProgramRecommendation = {
  headText: riskArabicTranslation?.to_edit_rp_recommendation,
  subText: commonLanguage?.are_you_sure,
  buttonOpt_1: commonLanguage?.yes_edit,
  buttonOpt_2: commonLanguage?.cancel_changes
};
export const auWarningMsgDeleteAuditRecommendation = {
  headText: 'You are About to Delete a Recommendation!',
  subText: 'Are you sure you want to delete this item? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgDeleteRiskProgramRecommendation = {
  headText: riskArabicTranslation?.to_delete_rp_recommendation,
  subText: commonLanguage?.undo_this_action,
  buttonOpt_1: commonLanguage?.yes_delete_forever,
  buttonOpt_2: commonLanguage?.no_dont_delete
};
export const auWarningMsgRemoveIssue = {
  headText: 'You are about to remove an ',
  headTextAddon:' !',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Remove',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgRemoveAssessment = {
  headText:  commonLanguage?.remove_assessment,
  subText: commonLanguage?.undo_this_action,
  buttonOpt_1: commonLanguage?.yes_confirm,
  buttonOpt_2:  commonLanguage?.no_cancel
};
export const auWarningMsgRemoveWorkingPaper = {
  headText: commonLanguage?.remove_wp,
  subText: commonLanguage?.undo_this_action,
  buttonOpt_1: commonLanguage?.yes_confirm,
  buttonOpt_2:  commonLanguage?.no_cancel
};
export const auWarningMsgRiskDelete = {
  headText: riskArabicTranslation?.to_remove_risk,
  subText: commonLanguage?.undo_this_action,
  buttonOpt_1: commonLanguage?.yes_confirm,
  buttonOpt_2: commonLanguage?.no_cancel
};
export const auWarningMsgControlDelete = {
  headText: riskArabicTranslation?.to_remove_control,
  subText: commonLanguage?.undo_this_action,
  buttonOpt_1: commonLanguage?.yes_confirm,
  buttonOpt_2: commonLanguage?.no_cancel
};
export const auWarningMsgDeleteManagementResponse = {
  headText: riskArabicTranslation?.to_delete_MR,
  subText: commonLanguage?.undo_this_action,
  buttonOpt_1: commonLanguage?.yes_delete_forever,
  buttonOpt_2: commonLanguage?.no_dont_delete
};
export const auWarningMsgEditObservation = {
  headText: commonLanguage?.editObserv,
  headText_2: commonLanguage?.editObservSave,
  subText: commonLanguage?.are_you_sure,
  buttonOpt_1: commonLanguage?.yes_edit,
  buttonOpt_2: commonLanguage?.cancel_changes,
  buttonOpt_3: commonLanguage?.save,
  buttonOpt_4: commonLanguage?.cancel
};
export const auWarningMsgDeleteObservation = {
  headText: commonLanguage?.deleteObserv,
  subText: commonLanguage?.undoAction,
  buttonOpt_1: commonLanguage?.delete_forever,
  buttonOpt_2: commonLanguage?.no_delete
};
export const auWarningMsgEditImprovement = {
  headText: commonLanguage?.editImprov,
  subText: commonLanguage?.are_you_sure,
  buttonOpt_1: commonLanguage?.yes_edit,
  buttonOpt_2: commonLanguage?.cancel_changes
};
export const auWarningMsgDeleteImprovement = {
  headText: commonLanguage?.deleteImprov,
  subText: commonLanguage?.undoAction,
  buttonOpt_1: commonLanguage?.delete_forever,
  buttonOpt_2: commonLanguage?.no_delete
};
export const auWarningMsgDeleteReport = {
  headText: commonLanguage?.deleteReport,
  subText: commonLanguage?.undoAction,
  buttonOpt_1: commonLanguage?.delete_forever,
  buttonOpt_2: commonLanguage?.no_delete
};
export const auWarningMessageCloneAudit = {
  headText: commonLanguage?.copyOfAudit,
  subText: commonLanguage?.to_go_ahead,
  buttonOpt_1: commonLanguage?.clone,
  buttonOpt_2: commonLanguage?.cancel
};

export const auWarningMessageCloneObservation = {
  headText: commonLanguage?.duplicateObserv,
  subText: commonLanguage?.newlyCreatedObserv,
  buttonOpt_1: commonLanguage?.duplicate,
  buttonOpt_2: commonLanguage?.cancel
};

export const warningMessageEntity = {
  headText: commonLanguage?.editExistingScoring,
  subText: commonLanguage?.want_to_proceed,
  buttonOpt_1: commonLanguage?.yesProceed,
  buttonOpt_2: commonLanguage?.no_cancel
};

export const warningMessageEditInherentRating = {
  headText: commonLanguage?.want_to_proceed,
  subText: riskArabicTranslation?.change_inherent_risk_rating_values,
  buttonOpt_1: commonLanguage?.yesProceed,
  buttonOpt_2: commonLanguage?.no_cancel
};

export const warningMessageEditResidualRating = {
  headText: commonLanguage?.want_to_proceed,
  subText: riskArabicTranslation?.change_residual_risk_rating_values,
  buttonOpt_1: commonLanguage?.yesProceed,
  buttonOpt_2: commonLanguage?.no_cancel
};

export const warningMessageEditResidualRatingNew = {
  headText: commonLanguage?.want_to_proceed,
  subText: ' ',
  subText_1: riskArabicTranslation?.change_residual_risk_rating_values,
  subText_2: riskArabicTranslation?.there_are_currently,
  subText_3: riskArabicTranslation?.tagged_risks_associated_rating,
  buttonOpt_1: commonLanguage?.yes_update,
  buttonOpt_2: commonLanguage?.no_dont_update,
  buttonOpt_3: commonLanguage?.cancel,
};

export const warningMessageEditInherentRatingNew = {
  headText: commonLanguage?.want_to_proceed,
  subText: ' ',
  subText_1: riskArabicTranslation?.change_inherent_risk_rating_values,
  subText_2: riskArabicTranslation?.there_are_currently,
  subText_3: riskArabicTranslation?.tagged_risks_associated_rating,
  buttonOpt_1: commonLanguage?.yes_update,
  buttonOpt_2: commonLanguage?.no_dont_update,
  buttonOpt_3: commonLanguage?.cancel,
};

export const warningMessageAddKriRating = {
  headText: commonLanguage?.are_you_sure,
  subText: kriArabicTranslation?.auto_rated_kri_sytem,
  buttonOpt_1: commonLanguage?.yesProceed,
  buttonOpt_2: commonLanguage?.no_cancel
};

export const warningMessageAddRiskAppetiteRating = {
  headText: commonLanguage?.are_you_sure,
  subText: kriArabicTranslation?.auto_rated_risk_appetite_sytem,
  buttonOpt_1: commonLanguage?.yesProceed,
  buttonOpt_2: commonLanguage?.no_cancel
};

export const warningMessageDeleteKriRating = {
  headText: commonLanguage?.are_you_sure,
  subText: kriArabicTranslation?.delete_auto_rated_kri_sytem,
  buttonOpt_1: commonLanguage?.yesProceed,
  buttonOpt_2: commonLanguage?.no_cancel
};

export const warningMessageDeleteRiskApetiteRating = {
  headText: commonLanguage?.are_you_sure,
  subText:  kriArabicTranslation?.delete_auto_rated_risk_appetite_sytem,
  buttonOpt_1: commonLanguage?.yesProceed,
  buttonOpt_2: commonLanguage?.no_cancel
};

export const warningMessageDeleteMetricsRating = {
  headText: commonLanguage?.are_you_sure,
  subText: kriArabicTranslation?.delete_auto_rated_metrics_sytem,
  buttonOpt_1: commonLanguage?.yesProceed,
  buttonOpt_2: commonLanguage?.no_cancel
};

export const warningMessageEditKriRating = {
  headText: commonLanguage?.are_you_sure,
  subText: kriArabicTranslation?.edit_auto_rated_kri_sytem,
  buttonOpt_1: commonLanguage?.yesProceed,
  buttonOpt_2: commonLanguage?.no_cancel
};

export const warningMessageEditRiskAppetiteRating = {
  headText: commonLanguage?.are_you_sure,
  subText: kriArabicTranslation?.edit_auto_rated_risk_appetite_sytem,
  buttonOpt_1: commonLanguage?.yesProceed,
  buttonOpt_2: commonLanguage?.no_cancel
};

export const approvalDraftSuccessForAudit = {
  headText: 'Successful!',
  buttonOpt_1: 'Ok',
  buttonOpt_2: 'View Draft',
  htmlContent: ''
};

export const auWarningMsgProgramEdit = {
  headText: riskArabicTranslation?.Edit_Risk_Program,
  subText: riskArabicTranslation?.to_edit_Risk_Program,
  buttonOpt_1: commonLanguage?.yes,
  buttonOpt_2: commonLanguage?.cancel
};

export const warningAuditDelete = {
  headText: 'Warning!',
  subText: ' ',
  buttonOpt_1: 'Ok',
  buttonOpt_2: ' ',
  showCancelButton: false,
  icon: 'warning'
};
export const warningMsgRemoveTaggedRiskInStrategy = {
  headText: riskArabicTranslation?.to_remove_risk,
  subText: riskArabicTranslation?.undo_action,
  buttonOpt_1: commonLanguage?.yes_confirm,
  buttonOpt_2: commonLanguage?.no_cancel
};

export const warningMsgRemoveTaggedControlInStrategy = {
  headText: 'You are About to Remove a Control!',
  subText: 'Undo Action',
  buttonOpt_1: 'Remove',
  buttonOpt_2: 'Cancel',
};

export const warningMsgRemoveTaggedMetricsInStrategy = {
  headText: 'You are About to Remove a Metrics!',
  subText: 'Undo Action',
  buttonOpt_1: 'Remove',
  buttonOpt_2: 'Cancel',
};

export const warningMsgAuditSaveAsDraft = {
  headText: 'Save as Draft?',
  subText: 'Please confirm',
  buttonOpt_1: 'Confirm',
  buttonOpt_2: 'Cancel'
};

export const warningAfterMsgAuditSaveAsDraft = {
  headText: 'Your Draft is Saved',
  subText: 'The Audit Planning has been saved as a draft. Do you want to continue editing?',
  buttonOpt_1: 'Continue editing',
  buttonOpt_2: 'Exit'
};
export const warningMsgPublishFlow = {
  headText: 'You are about to publish the flow',
  subText: 'Are you sure?',
  buttonOpt_1: 'Publish',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgEditMenu = {
  headText: 'You are About to Edit Menu Items!',
  subText: 'Edit Are you sure?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgDataprocess = {
  headText: 'Data is processing please wait',
  subText: 'Data is processing please wait, generating the data',
  buttonOpt_1: 'Ok',
  buttonOpt_2: 'Cancel',
  showCancelButton: false,
  icon: 'warning'
};

export const auWarningMsgDeleteEmailTemplate = {
  headText: 'You are About to Delete a Template!',
  subText: 'Are you sure you want to delete this item? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const templateRemovalWarningMessage = {
  headText: commonLanguage?.remove_template,
  subText: commonLanguage?.additonalQuestion,
  buttonOpt_1: commonLanguage?.removeTemplate,
  buttonOpt_2:  commonLanguage?.template_removal
}

export const auWarningMsgDeleteTaggedControl = {
  headText: riskArabicTranslation?.to_remove_the_control,
  subText: commonLanguage?.undo_this_action,
  buttonOpt_1: commonLanguage?.yes_confirm,
  buttonOpt_2: commonLanguage?.no_cancel
};
export const warningMessageAddDynamicControlRating = {
  headText: commonLanguage?.want_to_proceed,
  subText: riskArabicTranslation?.to_add_dynamic_rating_factor,
  buttonOpt_1: commonLanguage?.yesProceed,
  buttonOpt_2: commonLanguage?.no_cancel
};

export const warningMessageAddComplianceObservationRating = {
  headText: 'Do You Want to Proceed?',
  subText: 'You are about to add an Observation Rating. If you modify, please review the observation rating calculation in Dynamic Rating.',
  buttonOpt_1: 'Proceed',
  buttonOpt_2: 'Cancel'
};

export const warningMessageEditDynamicControlRating = {
  headText: commonLanguage?.want_to_proceed,
  subText: riskArabicTranslation?.to_change_dynamic_rating_factor,
  buttonOpt_1: commonLanguage?.yesProceed,
  buttonOpt_2: commonLanguage?.no_cancel
};

export const warningMeassageEntityChanged = {
  headText: riskArabicTranslation?.update_entity,
  subText: riskArabicTranslation?.risks_and_controls_associated_entity,
  buttonOpt_1: commonLanguage?.proceed,
  buttonOpt_2: commonLanguage?.undo
}

export const warningMeassageEntityChangedKri = {
  headText: commonLanguage?.update_entity,
  subText: kriArabicTranslation?.associated_entity_kri,
  buttonOpt_1: commonLanguage?.proceed,
  buttonOpt_2: commonLanguage?.undo
}

export const warningMessageForAuditSurveyContributor = {
  headText: 'Changes cannot be made after submission.',
  subText: 'Do you want to continue?',
  buttonOpt_1: 'Proceed',
  buttonOpt_2: 'Cancel'
}

export const warningMessageForObservationRecommendation = {
  headText: riskArabicTranslation?.observation_created,
  content_1: commonLanguage?.observation,
  content_2: riskArabicTranslation?.want_create_recommendation,
  subText: '',
  buttonOpt_1: riskArabicTranslation?.create_recommendation,
  buttonOpt_2: commonLanguage?.exit,
};

export const warningMessageForObservationRecommendationWithPerm = {
  headText: riskArabicTranslation?.observation_created,
  content_1: commonLanguage?.observation,
  subText: '',
  buttonOpt_2: commonLanguage?.exit,
};

export const warningMessageRecommendationRepeat = {
  headText: 'Recommendation created.',
  content_1: 'Recommendation',
  content_2: 'Do you want to create more?',
  subText: '',
  buttonOpt_1: 'Create recommendation',
  buttonOpt_2: 'Exit',
};

export const warningDownloadXls = {
  headText: 'Warning',
  subText: 'The maximum character limit for a single cell in Excel is 32,767 characters. If the data in a cell exceeds this limit, it will be cropped when exporting. Please ensure that the data being exported is within the character limit for each cell to avoid any data loss.',
  buttonOpt_1: 'Proceed',
  buttonOpt_2: 'Cancel'
};

export const warningMsgDeleteException = {
  headText: 'Delete exception ?',
  subText: 'This will permanently delete the exception.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const WarningMsgDeleteProject = {
  headText: 'You are about to delete a project!',
  subText: 'Are you sure? You cannot undo this action',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const warningMsgMultipleDeleteExceptions = {
  headText: 'Delete exceptions ?',
  subText: 'This will permanently delete the exceptions.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const WarningMsgDeleteProjects = {
  headText: 'You are about to delete projects!',
  subText: 'Are you sure? You cannot undo this action',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const securityDeleteWarningMsg = {
  headText: 'Are you sure?',
  subText: 'Are you certain that you want to delete the security control? Please note that all restrictions associated with the control will be permanently removed.',
  buttonOpt_1: 'Confirm',
  buttonOpt_2: 'Cancel'
};

export const observationStatusChangeMsg = {
  headText: 'Management Response has been updated',
  subText: 'Do you want to update the observation?',
  buttonOpt_1: 'Go To Observation',
  buttonOpt_2: 'Close'
};

export const improvementStatusChangeMsg = {
  headText: 'Management Response has been updated',
  subText: 'Do you want to update the improvement?',
  buttonOpt_1: 'Go to improvement',
  buttonOpt_2: 'Close'
};

export const warningMeassageEntityChangedStrategy = {
  headText: commonLanguage?.update_entity,
  subTextStrategy: commonLanguage?.associated_entity_strategy,
  subTextObjective: commonLanguage?.associated_entity_objective,
  subTextGoal: commonLanguage?.associated_entity_goal,
  subTextSubGoal: commonLanguage?.associated_entity_sub_goal,
  buttonOpt_1: commonLanguage?.proceed,
  buttonOpt_2: commonLanguage?.undo
}

export const customWarningMessage = {
  headText: 'You are About to Delete the Attachment!',
  subText: 'Are you sure you want to delete this item? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
}

export const warningMsgResidualCheck = {
  headText: riskArabicTranslation?.residual_check,
  subText: commonLanguage?.want_to_proceed,
  buttonOpt_1: commonLanguage?.yesProceed,
  buttonOpt_2: commonLanguage?.no_cancel
};

export const warningMsgApproval = {
  headText: commonLanguage.are_you_sure,
  subText: commonLanguage.approval_alert,
  buttonOpt_1: commonLanguage.yesProceed,
  buttonOpt_2: commonLanguage.no_cancel,
  automationButton_1: 'yesconfirm',
  automationButton_2: 'nocancel'
};

export const confirmMsgMRDueDate = {
  headText: commonLanguage.changeImplementationDate,
  subText: commonLanguage.youWantToProceed,
  buttonOpt_1: commonLanguage.yesProceed,
  buttonOpt_2: commonLanguage.no_cancel,
  automationButton_1: 'yesconfirm',
  automationButton_2: 'nocancel'
};

export const warningMsgDeleteSoaCompliance = {
  headText: 'You are about to remove SOA!',
  subText: 'Are you sure? You cannot undo this action',
  buttonOpt_1: 'Remove',
  buttonOpt_2: 'No, don\'t remove'
};

export const warningMsgEditTemplate = {
  headText:policyTranslation?.editTemplate,
  subText:policyTranslation?.areYouSureTemplate,
  buttonOpt_1: commonLanguage?.yes,
  buttonOpt_2: commonLanguage?.cancel
}

export const warningMsgMRResponseTextLockAfterSubmit = {
  headText: commonLanguage.submitMR,
  buttonOpt_1: commonLanguage.submit,
  buttonOpt_2: commonLanguage.cancel
}
export const warningMsgMRResponseTextApprovalAfterSubmit = {
  headText: commonLanguage.submit_mr_for_approval,
  buttonOpt_1: commonLanguage.submitForApproval,
  buttonOpt_2: commonLanguage.cancel
}

export const warningMsgDashboardDelete = {
  headText: analyticsTranslation.folder,
  subText: analyticsTranslation.folderSub,
  buttonOpt_1: commonLanguage.delete,
  buttonOpt_2: commonLanguage.cancel
};

export const warningMsgControlDelete = {
  headText: analyticsTranslation.control,
  subText: analyticsTranslation.controlSub,
  buttonOpt_1: commonLanguage.remove,
  buttonOpt_2: commonLanguage.cancel
};

export const warningMsgReportRemove = {
  headText: analyticsTranslation.grid,
  subText: analyticsTranslation.gridSub,
  buttonOpt_1: commonLanguage.remove,
  buttonOpt_2: commonLanguage.cancel
};

export const exitToolConfirmationMessage = {
  headText: stractArabicTranslation?.exit_tool_confirmation_header,
  subText: stractArabicTranslation?.exit_tool_confirmation_sub,
  buttonOpt_1: stractArabicTranslation?.exit_tool_confirmation_cancel,
  buttonOpt_2: stractArabicTranslation?.exit_tool_confirmation_exit,
  buttonOpt_3: stractArabicTranslation?.exit_tool_confirmation_save
};

export const auWarningMessageDependencies = {
  headText: commonLanguage?.delete,
  subText: commonLanguage?.deleteConfirmationDependency,
  buttonOpt_1: commonLanguage?.delete,
  buttonOpt_2: commonLanguage?.cancel
};

export const warningMultipleDeleteScenario = {
  headText1: analyticsTranslation.deleteScenario,
  headText2: analyticsTranslation.deleteScenarios,
  subText1: analyticsTranslation.permantentlyDelete,
  subText2: analyticsTranslation.permantentlyDeletes,
  buttonOpt_1: commonLanguage.delete,
  buttonOpt_2: commonLanguage.cancel
};

export const exitTheToolMessage = {
  headText: momArabicTranslation?.exitTheTool,
  subText: momArabicTranslation?.progressLost,
  buttonOpt_1: momArabicTranslation?.confirm,
  buttonOpt_2: momArabicTranslation?.cancel
};

export const removeParticipant = {
  headText: momArabicTranslation?.removeParticipant,
  subText: momArabicTranslation?.areYouSure,
  buttonOpt_1: momArabicTranslation?.remove,
  buttonOpt_2: momArabicTranslation?.cancel
};

export const editControlWarning = {
  headText: arxRiskWarning?.withoutRatingControl,
  subText: arxRiskWarning?.noScoreControl,
  buttonOpt_1: arxRiskWarning?.keepEditing,
  buttonOpt_2: arxRiskWarning?.discard
};

export const editRiskWarning = {
  headText: arxRiskWarning?.withoutRatingRisk,
  subText: arxRiskWarning?.noScoreRisk,
  buttonOpt_1: arxRiskWarning?.keepEditing,
  buttonOpt_2: arxRiskWarning?.discard
};
