import { ResolveTabGuard } from './core/guard/resolvetab.guard';

import { HttpUrlInterceptor } from './core/interceptor/http-request-url.interceptor';
import { LoaderInterceptor } from './core/interceptor/loader.interceptor';
import { AuthHeaderInterceptor } from './core/interceptor/auth-header.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { appRoutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { StatusCheckInterceptor } from './core/interceptor/status-check.interceptor';
import { ErrorInterceptor } from './core/interceptor/error.interceptor';


import { ResolveGuard } from './core/guard/resolve.guard';
import { ResolveSetupWizardGuard } from './core/guard/resolve-setup-wizard.guard';
import { ResolveContributorGuard } from './core/guard/resolve-contributor.guard';
import { ResolveAdminGuard } from './core/guard/resolveadmin.guard';
import { ToastrModule } from 'ngx-toastr';
import { RemoveUnderScoreCapitalizePipe } from './core/pipe/remove-under-score-capitalize.pipe';
import { UserDatePipe } from './core/pipe/user-date.pipe';
import { MfaValidateService } from 'src/app/core/service/mfa-validate.service';
import { AccessibilityService } from 'src/app/core/service/accessibility.service';
import { BadgeService } from 'src/app/core/service/badge.service';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, { useHash: false, scrollPositionRestoration: 'enabled' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({ preventDuplicates: true }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    MonacoEditorModule.forRoot()
  ],

  providers: [
    ResolveGuard, ResolveTabGuard, ResolveAdminGuard, ResolveContributorGuard, ResolveSetupWizardGuard,
    DatePipe, RemoveUnderScoreCapitalizePipe, UserDatePipe, MfaValidateService, AccessibilityService, BadgeService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: StatusCheckInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpUrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, `${environment.baseUrl}/v1/api/v6/language-translation/`, '/');
}
