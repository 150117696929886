import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appAlphabetsOnly]'
})
export class AlphabetsOnlyDirective {
  private regex: RegExp = new RegExp('^[a-zA-Z ]+$');
  private specialKeys: Array<string> = ['Backspace', 'ArrowLeft', 'ArrowRight'];

  constructor(private elementRef: ElementRef) { }

  /**
   * Key board action
   * @param event
   */
 @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
  if (this.specialKeys.indexOf(event.key) !== -1) {
    return;
  }
  const inputValue: string = this.elementRef.nativeElement.value.concat(event.key);
  if (inputValue && !String(inputValue).match(this.regex)) {
    event.preventDefault();
  }
  return;
}


/**
   * Copy Paste action
   * @param event
   */
  @HostListener('paste', ['$event']) onPaste(event) {
    const clipboardData = (event.originalEvent || event).clipboardData.getData('text/plain');
    if (clipboardData) {
      const regEx = new RegExp('^[a-zA-Z ]+$');
      if (!regEx.test(clipboardData)) {
        event.preventDefault();
      }
    }
    return;
  }


}
