import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DragHandleDirective } from './dragg-handle.directive';
import { ESignService } from '../service/e-sign/e-sign.service';
import { Position } from '../interface/common.interface';

@Directive({
  selector: '[appDraggable]'
})
export class DraggableDirective implements AfterViewInit, OnDestroy {
  @Input() currentX = 0;
  @Input() currentY = 0;
  @Input() boundaryQuery = '.pdf-viewer-wrapper';
  @Input() moveWithMouse = false; // To check if the element should move with mouse
  @Output() dragEnd = new EventEmitter<Position>();
  @ContentChild(DragHandleDirective)
  handle: DragHandleDirective;
  private element: HTMLElement;
  private subscriptions: Subscription[] = [];
  private handleElement: HTMLElement;

  draggingBoundaryElement: HTMLElement | HTMLBodyElement;

  constructor(
    private elementRef: ElementRef,
    private _eSign: ESignService,
    @Inject(DOCUMENT) private document: any
  ) { }

  ngAfterViewInit(): void {
    this.draggingBoundaryElement = (this.document as Document).querySelector(
      this.boundaryQuery
    );
    if (!this.draggingBoundaryElement) {
      throw new Error(
        'Couldn\'t find any element with query: ' + this.boundaryQuery
      );
    } else {
      this.element = this.elementRef.nativeElement as HTMLElement;
      this.handleElement =
        this.handle?.elementRef?.nativeElement || this.element;
      this.element.style.cursor = 'move';
      this.initDrag();
    }
  }

  initDrag(): void {
    const dragStart$ = fromEvent<MouseEvent>(this.handleElement, 'mousedown');
    const dragEnd$ = fromEvent<MouseEvent>(this.document, 'mouseup');
    const drag$ = fromEvent<MouseEvent>(this.document, 'mousemove').pipe(
      takeUntil(dragEnd$)
    );

    let initialX: number,
      initialY: number,
      currentX = this.currentX,
      currentY = this.currentY;

    let dragSub: Subscription;
    const minBoundX = this.draggingBoundaryElement.offsetLeft;
    const minBoundY = this.draggingBoundaryElement.offsetTop;

    const maxBoundX =
      minBoundX +
      this.draggingBoundaryElement.offsetWidth -
      this.element.offsetWidth;
    const maxBoundY =
      minBoundY +
      this.draggingBoundaryElement.offsetHeight -
      this.element.offsetHeight;

    const dragStartSub = dragStart$.subscribe((event: MouseEvent) => {
      initialX = event.clientX - currentX;
      initialY = event.clientY - currentY;
      this.element.classList.add('free-dragging');

      dragSub = drag$.subscribe((dragEvent: MouseEvent) => {
        // dragEvent.preventDefault();

        const x = dragEvent.clientX - initialX;
        const y = dragEvent.clientY - initialY;

        currentX = Math.max(minBoundX, Math.min(x, maxBoundX));
        currentY = Math.max(minBoundY, Math.min(y, maxBoundY));
        this.element.style.transform =
          'translate3d(' + currentX + 'px, ' + currentY + 'px, 0)';
      });
    });

    const dragEndSub = dragEnd$.subscribe((ev) => {
      initialX = currentX;
      initialY = currentY;
      this.dragEnd.emit({
        x: currentX,
        y: currentY,
      });
      this.element.classList.remove('free-dragging');
      if (dragSub) {
        dragSub.unsubscribe();
      }
    });
    this.subscriptions.push.apply(this.subscriptions, [
      dragStartSub,
      dragSub,
      dragEndSub,
    ]);
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach((s) => {
        if (s) {
          s.unsubscribe();
        }
      });
    }
  }
}
