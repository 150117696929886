import { Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appNumberfield]'
})
export class NumberfieldDirective {
  private regex: RegExp = new RegExp('^[0-9+\\-%$<>\\.]*$');
  private specialKeys: string[] = ['Backspace', 'ArrowLeft', 'ArrowRight'];
  constructor(private elementRef: ElementRef) {

  }

  /**
   * Key board action
   * @param event
   */
  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys?.includes(event?.key)) {
      return;
    }
    const inputValue: string = this.elementRef?.nativeElement?.value?.concat(event?.key);
    if (inputValue && !String(inputValue).match(this.regex)) {
      event?.preventDefault();
    }
    return;
  }

  /**
   * Copy Paste action
   * @param event
   */
  @HostListener('paste', ['$event']) onPaste(event: { originalEvent: any; preventDefault: () => void; }) {
    const clipboardData = (event?.originalEvent || event)?.clipboardData?.getData('text/plain');
    if (clipboardData) {
      const regEx = this.regex;
      if (!regEx?.test(clipboardData)) {
        event.preventDefault();
      }
    }
    return;
  }
}
