export const riskArabicTranslation = {
  all_strategies: 'All Strategies',
  create_strategy: 'Create Strategy',
  business_strategies: 'Business Strategies',
  strategy_plan: 'Strategy Plan',
  edit_business_strategy: 'Edit Business Strategy',
  strategy_overview: 'Strategy Overview',
  additional_information: 'Additional Information',
  strategy: 'Strategy',
  inherent_likelihood: 'Inherent Likelihood',
  residual_likelihood: 'Residual Likelihood',
  inherent_impact: 'Inherent Impact',
  residual_impact: 'Residual Impact',
  inherent: 'Inherent',
  residual: 'Residual',
  overall_rating: 'Overall Rating',
  all_strategy_plans: 'All Plans',
  create_plan: 'Create Plan',
  create_strategy_plan: 'Create Strategy Plan',
  edit_strategy_plan: 'Edit Plan',
  clone_strategy: 'Clone Strategy',
  continue_ahead: 'Continue Ahead',
  delete_strategy: 'Delete Strategy',
  to_edit_strategy: 'To Edit Strategy',
  delete_strategy_plan: 'Delete Plan',
  to_edit_strategy_plan: 'To Edit Plan',
  risks: 'Risks',
  riskMap: 'Risk Map',
  history: 'History',
  erm: 'ERM',
  enterprise_risks: 'Enterprise Risks',
  erm_register: 'ERM Register',
  assessment: 'Assessment',
  categories: 'Categories',
  risk_rating: 'Risk Rating',
  overall_risk: 'Overall Risk',
  all_erm: 'All ERM',
  file_format: 'File Format',
  create_erm: 'Create ERM',
  edit_erm: 'Edit ERM',
  erm_overview: 'ERM Overview',
  erm_details: 'ERM Details',
  erm_score: 'ERM Score',
  value_between: 'Value between',
  mitigation_date: 'Mitigation Date',
  controls: 'Controls',
  category: 'Category',
  edit_category: 'Edit Category',
  all_categories: 'All Categories',
  create_category: 'Create Category',
  all_registers: 'All Registers',
  create_erm_register: 'Create ERM Register',
  edit_register: 'Edit Register',
  create_register: 'Create Register',
  assessments_by_status: 'Assessments Status',
  assessment_score: 'Assessment Score',
  likelihood: 'Likelihood',
  impact: 'Impact',
  overall_score: 'Overall Score',
  assessments: 'Assessments',
  assessment_plan: 'Assessment Plan',
  questionnaire: 'Questionnaire',
  edit_assessment_plan: 'Edit Assessment Plan',
  Create_assessment_plan: 'Create Assessment Plan',
  take_assessment: 'Take Assessment',
  view_details: 'View Details',
  Assign_ERM_Assessment: 'Assign Assessment',
  Configure_Assessment: 'Configure Assessment',
  Review_Questions: 'Review Questions',
  Select_Assessment_Plan: 'Select Assessment Plan',
  Assessment_Title: 'Assessment Title',
  Question: 'Question',
  Instructions: 'Instructions',
  Attended_on: 'Attended on',
  Hex_Code: 'Hex Code',
  To_Delete_ERM: 'To Delete ERM',
  Continue_Editing_ERM: 'Continue Editing ERM',
  To_Edit_ERM: 'To Edit ERM',
  Create_New_ERM: 'Create New ERM',
  Delete_ERM_Register: 'Delete ERM Register',
  To_Edit_ERM_Register: 'To Edit ERM Register',
  Risk_Universe: 'Risk Universe',
  Risk_Program_MR: 'Risk Program MR',
  Risk_Overview: 'Risk Overview',
  Risk_Aggregation: 'Risk Aggregation',
  Risk_Register: 'Risk Register',
  KRI: 'KRI',
  Risk_Appetite: 'Risk Appetite',
  Inherent: 'Inherent',
  Residual: 'Residual',
  Owners: 'Owners',
  All_Risks: 'All Risks',
  Risk: 'Risk',
  Inherent_Risk_Score: 'Inherent Risk Score',
  Residual_Risk_Score: 'Residual Risk Score',
  Inherent_Risk_Rating: 'Inherent Risk Rating',
  Residual_Risk_Rating: 'Residual Risk Rating',
  Risk_Mitigation_Details: 'Risk Mitigation Details',
  Scoring: 'Scoring',
  Update_Residual_Risk_Rating: 'Update Residual Risk Rating',
  incidenttab: 'Incidents',
  analyticAutomation: 'Analytics Automation',
  complianceObservation: 'Compliance Observations',
  actions: 'Actions',
  issues: 'Issues',
  request: 'Requests',
  riskMovement: 'Risk Movements',
  auditRisk: 'Audit and Risk Program Observations',
  Create_Risk: 'Create Risk',
  Edit_Risk: 'Edit Risk',
  Definitions: 'Definitions',
  Summary: 'Summary',
  Risk_Assessment: 'Risk Assessment',
  Perform_Assessment_Later: 'Perform Assessment Later',
  Add_Assessments: 'Add Assessments',
  Skip_the_Step: 'Skip Step',
  Risk_Scoring: 'Risk Scoring',
  Scoring_Instructions: 'Scoring Instructions',
  Risk_Impact_Scoring: 'Risk Impact Scoring',
  Risk_Likelihood_Scoring: 'Risk Likelihood Scoring',
  Velocity: 'Velocity',
  Vulnerability: 'Vulnerability',
  All_Risk_Registers: 'All Risk Registers',
  Edit_Risk_Register: 'Edit Risk Register',
  Create_Risk_Register: 'Create Risk Register',
  Delete_Risk_Register: 'Delete  Risk Register',
  undo_action: 'Undo Action',
  To_Edit_Risk_Register: 'To Edit Risk Register',
  Risk_Program: 'Risk Program',
  Risk_Program_Overview: 'Risk Program Overview',
  Risk_Plan: 'Risk Plan',
  Risk_Program_Calendar: 'Risk Program Calendar',
  All_Risk_Programs: 'All Risk Programs',
  Observations_by: 'Observations by',
  Create_Risk_Program: 'Create Risk Program',
  field_exist: 'This field is already exist',
  maximum_length_characters: 'The maximum length of this field is 70 characters',
  Risk_Program_Team: 'Risk Program Team',
  Ongoing_Risk_Programs: 'Ongoing Risk Programs',
  risk_control_program: 'The Risks/Controls you have selected are already part of other risk Programs. Please confirm if you want to proceed.',
  Management_Responses: 'Management Responses',
  Risk_Program_ID: 'Risk Program ID',
  Add_a_Risk: 'Add a Risk',
  Add_a_Control: 'Add a Control',
  Issues_by_Status: 'Issues by Status',
  Issues_by_Rating: 'Issues by Rating',
  Issues_by_Owners: 'Issues by Owners',
  Risk_Program_Observations: 'Risk Program Observations',
  Risks_Controls: 'Risks and Controls',
  Create_Observation: 'Create Observation',
  Observation_Overview: 'Observation Overview',
  Add_Issues: 'Add Issues',
  Add_Controls: 'Add Controls',
  Add_Risks: 'Add Risks',
  Add_Working_Papers: 'Add Working Papers',
  Select_Observations: 'Select Observations',
  Submit_response: 'Submit for response',
  Request_Management_Response: 'Proceed to Request Management Response?',
  proceed: 'Yes, proceed',
  No_dont_proceed: 'No dont proceed',
  Edit_Management_Response: 'Edit Management Response',
  Management_Response: 'Management Response',
  Auditor_Comment: 'Auditor Comment',
  Time_Spent_Users: 'Time Users',
  Time_Risk_Program_Stage: 'Time Program Stage',
  All_Risk_Plan: 'All Risk Plan',
  Create_Risk_Plan: 'Create Risk Plan',
  Risk_Controls: 'Risk Controls',
  Controls_Rating: 'Controls Rating',
  Frequency: 'Frequency',
  All_Controls: 'All Controls',
  Create_Control: 'Create Control',
  Edit_Control: 'Edit Control',
  Control_Overview: 'Control Overview',
  Control_Action: 'Control Action',
  control: 'Control',
  Control_Ownership: 'Control Ownership',
  Control_Duration: 'Control Duration',
  Control_Information: 'Control Information',
  Control_Actions: 'Control Actions',
  controlMovement: 'Control Movements',
  Action_Overview: 'Action Overview',
  Control_ID: 'Control ID',
  Observation_ID: 'Observation ID',
  Observation_Rating: 'Observation Rating',
  create_observation_information: 'Create Observation Info',
  Create_an_Observation: 'Create an Observation',
  Observation_Status: 'Observation Status',
  Risk_ID: 'Risk ID',
  Risk_Universe_Object_Editor: 'Risk Editor',
  Risk_Scoring_Models: 'Risk Scoring Models',
  Scoring_Factors: 'Scoring Factors',
  Residual_Rating: 'Residual Rating',
  Inherent_Rating: 'Inherent Rating',
  Create_Scoring_Model: 'Create Scoring Model',
  Scoring_Model: 'Scoring Model',
  Select_Scoring_Weightage: 'Select Scoring Weightage',
  Scores_and_Ratings: 'Scores and Ratings',
  Show_Inherent_Risk_Rating: 'Show Risk Rating',
  Show_Residual_Risk_Score: 'Show Risk Score',
  edit_scoring_factors: 'Edit Scoring Factors',
  Scoring_Model_Details: 'Scoring Model Details',
  Show_Inherent_Risk_Score: 'Show Inherent Score',
  Inherent_Risk_Scoring_Factors: 'Inherent Risk Factors',
  Show_Residual_Risk_Rating: 'Show Residual Rating',
  Scoring_Calculation: 'Scoring Calculation',
  Rating_Calculation: 'Rating Calculation',
  Risk_Range: 'Risk Range',
  Edit_Scoring_Model: 'Edit Scoring Model',
  Inherent_Risk_Scoring_Method: 'Inherent Risk Method',
  Configure_Risk_Rating_Range: 'Configure Risk Range',
  Update_inherent_rating: 'Update Calc Inherent',
  click_Risk_Range: 'Update Risk Range',
  Tolerance_range: 'Tolerance Range',
  Residual_Risk_Scoring_Method: 'Residual Scoring Method',
  Update_residual_rating_values: 'Update Calc Residual',
  Business_Strategy_Object_Editor: 'Strategy Editor',
  Risk_Control_Object_Editor: 'Control Editor',
  Business_Strategy_Overview: 'Business Strategy Overview',
  Risk_Program_Object_Editor: 'Program Editor',
  Risk_Program_Mr_Object_Editor: 'Risk Program Management Response - Object Editor',
  Risk_Program_Management_Response: 'Risk Program Management Response',
  ERM_Object_Editor: 'ERM Editor',
  Enterprise_Risk: 'Enterprise Risk',
  Enterprise_Risk_Scoring_Models: 'Enterprise Risk Scoring Models',
  editing_scoring_factors: 'Scoring Weightage Severity',
  Inherent_Enterprise_Risk_Scoring_Factors: 'Inherent Enterprise Factors',
  Inherent_Enterprise_Risk_Score: 'Inherent Enterprise Score',
  Inherent_Enterprise_Risk_Rating: 'Inherent Enterprise Rating',
  Enterprise_Risk_Range: 'Enterprise Risk Range',
  Inherent_Enterprise_Risk_Scoring_Method: 'Inherent Enterprise Method',
  Configure_Enterprise_Risk_Rating_Range: 'Configure Enterprise Range',
  auditRetalClient: 'Audit Observations',
  Delete_an_Assessment: 'Delete an Assessment',
  Delete_an_Assessment_Plan: 'Delete an Assessment Plan',
  Edit_an_Assessment_Plan: 'Edit an Assessment Plan',
  delete_question: 'Delete a Question',
  clone_ERM: 'Clone ERM',
  delete_category: 'Delete the Category',
  edit_the_category: 'Edit the Category',
  defaultgridView: 'Default Grid View',
  downloadTemplate: 'Download Template',
  Filter_by_Risk_Plan: 'FILTER BY RISK PLAN',
  browse_program: 'Browse Program',
  browse_record: 'Browse Record',
  to_edit_Risk_Program: 'Are you sure you want to edit this Risk Program?',
  Edit_Risk_Program: 'Edit Risk Program',
  to_remove_risk: 'You are About to Remove a Risk!',
  not_tag_risk_program: 'You are not authorized to Tag in Risk Program',
  not_untag_risk_program: 'You are not authorized to UnTag in Risk Program',
  select_one_riskOrControl: 'Please select at least one risk/control',
  risk_program_saved_draft_editing: 'The risk program has been saved as a draft. Do you want to continue editing?',
  proceed_risk_assessment: 'You are proceeding to risk assessment. The risk will be saved as draft.',
  risk__saved_draft_editing: 'The risk has been saved as a draft. Do you want to continue editing?',
  to_edit_risk: 'Are you sure you want to edit this risk?',
  Create_new_Control: 'Create a new Control',
  to_create_new_risk_control: 'Are you sure you want to create a new risk control?',
  to_edit_risk_control: 'Are you sure you want to edit this risk control?',
  risk_tagged_to_audit: 'This risk is not tagged to any audit. Please tag an audit to continue.',
  control_tagged_to_audit: 'This control is not tagged to any audit. Please tag an audit to continue.',
  to_remove_control: 'You are About to Remove a Control!',
  to_delete_control: 'You are About to Delete Control!',
  copy_of_control: 'You are About to Create a Copy of the Control.',
  to_edit_the_risk_control: 'You are About to Edit the Risk Control!',
  create_a_new_risk_control: 'You are About to Create a New Risk Control!',
  to_remove_the_risk: 'You are About to Remove the Risk!',
  to_create_new_risk: 'You are About to Create a New Risk!',
  clone_risk: 'You are About to Create a Copy of the Risk.',
  to_delete_risk: 'You are About to Delete a Risk!',
  about_to_edit_risk: 'You are About to Edit a Risk!',
  about_to_remove_recommendation: 'You are About to Remove a Recommendation!',
  ongoing_rp_selected_risks_controls: 'Ongoing RP assoc RC',
  to_edit_residual_risk_rating: 'You are About to Edit the Residual Risk Rating!',
  to_edit_rp_recommendation: 'You are About to Edit a Risk Program Recommendation!',
  to_delete_rp_recommendation: 'You are About to Delete  a Risk Program Recommendation!',
  to_delete_MR: 'You are About to Delete the Management Response!',
  change_inherent_risk_rating_values: 'You are about to change inherent risk rating values. If you modify, please review inherent rating calculation in scoring models.',
  change_residual_risk_rating_values: 'You are about to change residual risk rating values. If you modify, please review residual rating calculation in scoring models.',
  there_are_currently: 'There are currently ',
  tagged_risks_associated_rating: ' tagged risks associated with this rating. Would you like to update these risks with the new value?',
  to_remove_the_control: 'You are About to Remove the Control!',
  to_add_dynamic_rating_factor: 'You are about to add Dynamic Rating Factor. If you modify, please review control rating calculation in Dynamic Rating.',
  to_change_dynamic_rating_factor: 'You are about to change Dynamic Rating Factor. If you modify, please review control rating calculation in Dynamic Rating.',
  risks_and_controls_associated_entity: 'Only those risks and controls associated with entity will be listed for selection in future changes to the audit program.',
  update_entity: 'You Are About to Update the Entity.',
  risk_program_issue_tracker: 'Risk Program Issue Tracker',
  risk_issue_tracker: 'Risk Issue Tracker',
  recommendations: 'Recommendations',
  observation_created: 'Observation created.',
  want_create_recommendation: 'Do you want to create recommendation?',
  create_recommendation: 'Create recommendation',
  MR_selected_observation: 'Management response for the selected observation will be requested from',
  MR_selected_observations: 'Management response for the selected observation’s will be requested from',
  select_any_plan: 'Select any plan',
  issue_status: 'Issues Status',
  issue_rating: 'Issues Rating',
  all_issues: 'All Issues',
  auditprogramobservation: 'Audit Observations',
  riskprogramobservation: 'Risk Program Observations',
  impact_risk_rating: 'Does it impact risk rating?',
  control_name: 'Control Name',
  control_rating: 'Control Rating',
  control_type: 'Control Type',
  current_rating: 'Current Rating',
  new_control_rating: 'New Control Rating',
  risk_dashboard: 'Risk Dashboard',
  top_risks: 'Top Risks',
  select_categories: 'Select Categories',
  choose_atleast_one_category: 'Choose atleast one category',
  risk_appetite_statements: 'Risk Appetite Statements',
  impact_level_consequence: 'Impact is the level of consequence that the potential risk can have on the achievement of business / functional objectives',
  Likelihood_possibility_risk_event: 'Likelihood is the possibility that a given risk event will occur',
  risk_definitions: 'Risk Definitions',
  risk_category_definition: 'Risk Category Definition',
  rating_definition: 'Rating Definition',
  financial: 'Financial',
  financial_risk_performance: 'Financial risk is any uncertainty that could lead to an adverse financial impact on SAL financial performance. High Financial risks could have severe impact that SAL will not be able to meet its financial obligations.',
  operational: 'Operational',
  Risk_loss_resulting: 'Risk of loss resulting from inadequate or failed internal processes, people, systems commercial and/or external events impacting Company’s daily Station operations. The risk to attract the best talent in the market and retain its existing best performing talent. Risk of high attrition.',
  Reputational: 'Reputational',
  Risks_impacting_Company: 'Risks impacting Company’s image and the loss of value to the SAL’s brand and negative impact in our ability to satisfy customers, attract investors and/or professional capabilities and impacting Company’s reputation in the region and outside.',
  Risk_company_mission_goal: 'Risks Impacting the company’s mission, goals and objectives that might lead to a deviation from Company objectives.',
  Health_Safety_Environment: 'Health, Safety & Environment',
  Risks_impacting_health_safety: 'Risks impacting the health and safety of employees and external contractors and affecting the ecosystem and environment in which Company operates.',
  Regulatory_Legal_Compliance: 'Regulatory, Legal & Compliance',
  Risk_loss_arising_noncompliance: 'Risk of loss arising from non-compliance of regulatory requirements, legal actions and level of regulations adherence impacting the governance and legislative framework of the Company.',
  Security: 'Security',
  Risks_security_infrastructure: 'Risks impacting the security of Station, aircraft, Cargo and airport infrastructure.',
  Information_Technology: 'Information Technology',
  Risks_impacting_security_Company: 'Risks impacting the Digitalization and IT security within Company.',
  Risks_impacting_cybersecurity_environment: 'Risks impacting cybersecurity environment within Company. The protection of privacy, integrity, and accessibility of data information in the cyberspace. Cyberspace is acknowledged as an interaction of persons, software and worldwide technological services.',
  Cybersecurity: 'Cybersecurity',
  risk_program_observations_dashboard: 'Risk Program Observations Dashboard',
  risk_program_by_status: 'Risk Program By Status',
  control_manager: 'Control Manager',
  control_owner: 'Control Owner',
  control_title: 'Control Title',
  control_description: 'Control Description',
  risk_manager: 'Risk Manager',
  risk_owner: 'Risk Owner',
  risk_title: 'Risk Title',
  risk_category: 'Risk Category',
  risk_description: 'Risk Description',
  value_below_min_threshold: 'This value is below the minimum threshold',
  edit_question: 'Edit Question',
  add_questions: 'Add Questions',
  Add_Question: 'Add Question',
  risk_program_schedule: 'Risk Program Schedule',
  all_esg_plans: 'All ESG Plans',
  create_esg_plan: 'Create ESG Plan',
  edit_esg_plan: 'Edit ESG Plan',
  control_effective: 'Is this control dynamically affecting the risk',
  mark_key_control: 'Mark as key control',
  control_effectiveness: 'Control Effectiveness',
  control_effectiveness_likelihood: 'Control Effectiveness (Likelihood Reduction)',
  control_effectiveness_impact: 'Control Effectiveness (Impact Reduction)',
  program_plan: 'Program Plan',
  configure_conditions: 'Configure the conditions for how the control rating affects likelihood and impact',
  residual_check: 'The residual rating you have selected is higher than the inherent rating.',
  configure_effectiveness: 'Configure control effectiveness',
  Risk_Taxonomy: 'Risk Taxonomy',
  Risk_negatively_impact: 'Risks that could negatively impact an organization’s ability to achieve its objectives',
  Strategic: 'Strategic',
  Core_Operations: 'Core Operations',
  Customer: 'Customer',
  Legal_Regulatory_Compliance: 'Legal, Regulatory and Compliance',
  Risk_Assessment_Criteria: 'Risk Assessment Criteria',
  Risk_Scoring_Criteria_Likelihood: 'Risk Scoring Criteria (Likelihood)',
  Risk_Scoring_Criteria_Impact: 'Risk Scoring Criteria (Impact)',
  risk_custom_dashboard: 'Risk Custom Dashboard',
  risk_by_status: 'Risk by Status',
  risk_by_source: 'Risk by Source',
  risk_by_control_effectiveness: 'Risk by Control Effectiveness',
  audit_coverage: 'Audit Coverage',
  control_coverage: 'Control Coverage',
  type_of_risk: 'Type of Risk',
  risk_by_rating: 'Risk by Rating',
  risk_by_category: 'Risk by Category',
  configure_dashboard: 'Configure Dashboard',
  total_audits: 'Total Audits',
  total_controls: 'Total Controls',
  risk_control_effectiveness: 'Control Effectiveness Score',
  overall_residual_rating: 'Overall Residual Risk Rating',
  calculation: 'Calculation',
  effectiveness_score: 'effectiveness scores of controls',
  of: 'of',
  risk_control_effectiveness_method: 'Control Effectiveness Scoring Method',
  dynamic_control_rating: 'Dynamic Control Rating',
  control_rating_calculation: 'Control Rating Calculation',
  Configure_Overall_Rating: 'Configure Overall Rating',
  risk_summary_dashboard: 'Risk Summary Dashboard',
  customize_your_dashboard_view: 'Customize your dashboard view. The changes are only visible to you.',
  risk_outcome: 'Risk Outcome',
  risk_outcome_score: 'Risk Outcome Score',
  audit_outcome: 'Audit Outcome',
  audit_outcome_score: 'Audit Outcome Score',
  effectiveness_weightage: 'Effectiveness Weightage',
  max_weightage: 'Maximum weightage limited to 1000',
  weightage_sum_exceed: 'Input exceeds the total weightage limit of 1000',
  Issue_by_Status: 'Issue by Status',
  Activity_by_Status: 'Activity by Status',
  scoring_factors: 'Scoring Factors',
  values: 'Values',
  causes: 'Causes',
  impacts: 'Impacts',
  Organization_Details: 'Organization Details',
  currently_inactive: 'is currently inactive',
  inactive_tab_approval: 'Please check the inactive tab and initiate approval',
  cause: 'Cause',
  all_divisions: 'All Divisions',
  create_division: 'Create Division',
  Sort: 'Sort',
  Division: 'Division',
  enterprise_scoring_factor: 'Enterprise Risk Scoring Factors',
  scoring_method: 'Scoring Method',
  control_by_type: 'Controls by Type',
  control_by_rating: 'Controls by Rating',
  risk_with_appetite: 'Risk within Appetite',
  calendar: 'Calendar',
  incident: 'Incident',
  rp_recommendation: 'Risk program recommendation',
  issueTracker: 'Issue Tracker',
  allMR: 'All Management Responses',
  all_observ: 'All Observations',
  all_actions: 'All Actions',
  action_id: 'Action ID',
  action_title: 'Action Title',
  risk_program_name: 'Risk Program Name',
  action_status: 'Action Status',
  action_priority: 'Action Priority',
  due_date: 'Due Date',
  owner: 'Owner',
  department: 'Department',
  created_by: 'Cost Saving',
  cost_saving: 'Created by',
  Documents: 'Documents',
  modified_date: 'Updated Date',
  updated_by: 'Updated by',
  risk_program_id: 'PROGRAM ID',
  issue_id: 'Issue Id',
  issue_title: 'Issue Title',
  response_id: 'Response ID',
  modified_last_date: 'Last Modified On',
  assigned_date: 'Assigned Date',
  assigned_by: 'Assigned by',
  criticality: 'Criticality',
  observation_rating: 'Observation Rating',
  observation_description: 'Observation Description',
  observation_name: 'Observation Title',
  observation_id: 'Observation ID',
  status_risk_program: 'Status Of Risk Program',
  response_expected_by: 'Response Expected By',
  mr_reviewer: 'MR Reviewer',
  response_from: 'Response From',
  fifth_revised_date: '5th Implementation Due Date',
  fourth_revised_date: '4th Implementation Due Date',
  third_revised_date: '3rd Implementation Due Date',
  second_revised_date: '2nd Implementation Due Date',
  first_revised_date: '1st Implementation Due Date',
  mr_edit_count: 'No. of revisions',
  overdue_days: 'Overdue Days',
  initial_due_date: 'Initial Due Date',
  response_overdue: 'Response Overdue Days',
  status: 'Status',
  mr_description: 'MR Description',
  configure_tabs: 'Configure tabs',
  Default_Scoring_model_updated: 'Default scoring model updated',
  fetch_users_from_RiskRegister: 'Fetch users from the Risk Register',
  automatically_allow_newRisk: 'Automatically allow to new Risk',
  Risk_Categories: 'Risk Categories',
  likelihood_score: 'Likelihood Score',
  impact_score: 'Impact Score',
  modified_Date: 'Modified date',
  Risk_Prgrm: 'RISK PROGRAM',
  risk_program_date: 'Risk Program Date',
  as_on_date: 'As on Date',
  new_field: 'New Field',
  addCompliance: 'Add Compliance',
  ERM_Scoring_Model: 'ERM Scoring Model',
  request_management_response: 'Request Management Response',
  Tag_Reccommendations: 'Tag Reccommendations',
  Risk_program_department_rating: 'Risk program department rating',
  add_observ: 'Add Observation',
  refresh: 'Refresh',
  QUESTIONS: 'QUESTIONS',
  Last_Audited: 'Last Audited',
  Add_comments_if_any: 'Add comments if any',
  STATUS_OF_RISK_PROGRAM: 'STATUS OF RISK PROGRAM',
  RISK_PROGRAM_NAME: 'RISK PROGRAM NAME',
  Show_Risk_Assessment_Criteria: 'Show risk assessment criteria in risk scoring section',
  Scoring_Assessment_Details: 'Scoring Assessment Details',
  all_causes: 'All Causes',
  all_impacts: 'All Impacts',
  tagged_to_risk: 'tagged to risk',
  Causes_tagged_to_risk: 'Causes tagged to risk',
  Impacts_tagged_to_risk: 'Impacts tagged to risk',
  untagged_from_risk: 'untagged from risk',
  Control_Assessment_Criteria: 'Control Assessment Criteria',
  Control_Scoring_Section: 'Show control assessment criteria in control scoring section',
  Enter_title: 'Enter Title',
  risk_scoring_definitions: 'Risk Scoring Definitions',
  Show_Risk_scoring_defenition: 'Show risk scoring definitions in risk scoring section',
  modify_default_criteria: 'Allow users to modify the rating criteria',
  Configure_control_rating_criteria: 'Configure control rating criteria',
  Show_the_criterias: 'Show criteria',
  hide_the_criterias: 'Hide criteria',
  update_criteria: 'Please update the control rating criteria to ensure effective scoring values',
  Criteria_Control_Impact_Residual_Risk: 'Criteria for Control Impact on Residual Risk',
  let_user_modify_crtieria: 'Let users modify residual risk rating criteria while tagging controls to risks.',
  Press_Enter_populate_values: 'Press Enter key to populate values',
  CREATE_RISK_PLAN: 'CREATE A RISK PLAN',
  Edit_Risk_Plan: 'Edit a Risk Plan',
  notify_risk: 'Notify on control rating changes?',
  save_risk_to_draft: 'This will save the risk as a draft. You must submit it for approval before it can be published. Once submitted, the risk will be locked and cannot be edited until the approval process is complete.',
  time_entries: 'Time Entries',
  control_duplicate_from_risk_action: 'Create a new control based on the selected action. This control will be automatically linked to the associated risks.',
  Duplicate_action_control: 'Duplicate action as control'
};
