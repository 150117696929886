import { NotfoundComponent } from 'src/app/authentication/404/not-found.component';
import { Routes } from '@angular/router';
import { ResolveGuard } from 'src/app/core/guard/resolve.guard';
import { ResolveAdminGuard } from 'src/app/core/guard/resolveadmin.guard';
import { inject } from '@angular/core';
import { ResolveContributorGuard } from 'src/app/core/guard/resolve-contributor.guard';

const routes: Routes = [
  { path: 'dashboard/home', redirectTo: 'home', pathMatch: 'full' },
  {
    path: '',
    loadChildren:
    () => import('./layouts/blank/blank.module').then((m:typeof import('./layouts/blank/blank.module')) => m.BlankModule)
  },
  {
    path: '',
    loadChildren:
    () => import('./layouts/full/full.module').then((m:typeof import('./layouts/full/full.module')) => m.FullModule),
    resolve: {
      data: () => inject(ResolveGuard).resolve(),
      dataadmin: () => inject(ResolveAdminGuard).resolve(),
      dataContributor: () => inject(ResolveContributorGuard).resolve()
    }
  },
  {
    path: '404',
    component: NotfoundComponent
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];

export const appRoutes: Routes = routes;
