
import {
  Directive,
  ElementRef,
  HostListener,
  forwardRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[appAuContentEditable][formControlName],[appAuContentEditable][formControl],[appAuContentEditable][ngModel]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AuContentEditableDirective),
      multi: true
    }
  ]
})
export class AuContentEditableDirective  implements ControlValueAccessor  {

  private onChange: (value: string) => void;
  private onTouched: () => void;
  constructor(private elementRef: ElementRef) { }
  writeValue(value: any): void {
    this.elementRef.nativeElement.textContent = value;

  }

  /**
   * Registers a callback function that should be called when
   * the control's value changes in the UI.
   *
   * This is called by the forms API on initialization so it can update
   * the form model when values propagate from the view (view -> model).
   */
  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  /**
   * Registers a callback function that should be called when the control receives a blur event.
   * This is called by the forms API on initialization so it can update the form model on blur.
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }


 /**
   * Key board action
   * @param event
   */
 @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {

    if ( event.key === 'Enter') {
      event.preventDefault();
    }

    return;
  }

  @HostListener('keyup', ['$event']) onKeyUp(event: KeyboardEvent) {
   if ( event.key?.length === 1) {
     const inputValue: string = this.elementRef.nativeElement.textContent;
     this.onChange(inputValue);
     this.onTouched();
   } else if (event.key === 'Backspace'){
     const inputValue: string = this.elementRef.nativeElement.textContent;
     this.onChange(inputValue);
     this.onTouched();
   }


   return;
 }

}
