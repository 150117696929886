import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeUnderScoreCapitalize'
})
export class RemoveUnderScoreCapitalizePipe implements PipeTransform {

  transform(value: string, capitalizeStatus: boolean = true): string {
    try {
      value = value.replace(/_/g, ' ');
      if (capitalizeStatus) {
        return value.replace(/\w\S*/g, (txt: string) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase() );
      }
      return value;
    } catch (error) {
      return value;
    }
  }
}
