import { Directive, Input, HostBinding, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appImagePreload]'
})
export class ImagePreloadDirective {
  @Input() appImg = null;
  @Input() appErrorImage = 'assets/images/placeholder.jpg';
  constructor(private ref: ElementRef) {  }
  @HostListener('error')
  loadFallBackOnError() {
    const element: HTMLImageElement = <HTMLImageElement>this.ref.nativeElement;
    element.src =  this.appErrorImage;
  }

}
