import { Directive } from '@angular/core';
import { Validator, NG_VALIDATORS, ValidatorFn, UntypedFormControl } from '@angular/forms';

@Directive({
  selector: '[appTextAreavalidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useClass: TextAreavalidatorDirective,
      multi: true
    }
  ]
})
export class TextAreavalidatorDirective implements Validator {

  validator: ValidatorFn;
  constructor() {
    this.validator = this.textareavalidator();
  }

  validate(c: UntypedFormControl) {
    return this.validator(c);
  }

  textareavalidator(): ValidatorFn {
    return (control: UntypedFormControl) => {
      if (control.value != null && control.value !== '') {
        const testPattern = /<(?:(?:(?:(script|style|object|embed|applet|noframes|noscript|noembed)(?:\s+(?:"[\S\s]*?"|'[\S\s]*?'|(?:(?!\/>)[^>])?)+)?\s*>)[\S\s]*?<\/\1\s*(?=>))|(?:\/?[\w:]+\s*\/?)|(?:[\w:]+\s+(?:"[\S\s]*?"|'[\S\s]*?'|[^>]?)+\s*\/?)|\?[\S\s]*?\?|(?:!(?:(?:DOCTYPE[\S\s]*?)|(?:\[CDATA\[[\S\s]*?\]\])|(?:--[\S\s]*?--)|(?:ATTLIST[\S\s]*?)|(?:ENTITY[\S\s]*?)|(?:ELEMENT[\S\s]*?))))>/g;
        const validTags = testPattern.test(control.value);
        const isValid = !validTags;
        if (isValid) {
          return null;
        } else {
          return {
            invalidFormat: { message: "Invalid input" }
          };
        }
      } else {
        return null;
      }
    };
  }
}
