import { CommonModule, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DirectiveModule } from 'src/app/core/directive/directive.module';
import { FunctionService } from 'src/app/core/helper/function.helper.service';
import { JwtTokenService } from 'src/app/core/helper/jwt-token.service';
import { StorageService } from 'src/app/core/helper/storage.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  standalone: true,
  imports: [CommonModule, DirectiveModule, TranslateModule, RouterModule]
})

export class NotfoundComponent implements OnInit {
  public adminPermission: boolean=false;
  public redirectUrl: string;

  constructor(
    private _location: Location,
    private _localStorage: StorageService,
    public _helper: FunctionService,
    private  _jwtService : JwtTokenService) {}

  ngOnInit(): void {
    this.adminPermission = this._jwtService.getTokenData?.['admin_permission_only'];
    this.redirectUrl = this._localStorage.getLocalStorage('redirectURLforAdmin');
  }

  previousPage(): void {
    this._location.back();
  }

}
