import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appFloatingpointNumber]'
})
export class FloatingpointNumberDirective {

  // Allow decimal numbers having 12 whole nuber and 6 fraction points
  private regex: RegExp = new RegExp(/^\d{0,12}(\.\d{0,6})?$/);
  // Allow key codes for special events. Reflect : Backspace, tab, end, home etc
  private specialKeys: string[] = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'ArrowLeft',
    'ArrowRight',
    'Del',
    'Delete'
  ];

  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.includes(event.key)) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [
      current.slice(0, position),
      event.key == 'Decimal' ? '.' : event.key,
      current.slice(position)
    ].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }

}
