import { Injectable } from '@angular/core';
import { FunctionService } from 'src/app/core/helper/function.helper.service';
import { AdminUserService } from 'src/app/core/service/control-panel/admin-user.service';
import { tap } from 'rxjs';
import { SidebarPermission } from 'src/app/core/interface/common.interface';

@Injectable({
  providedIn: 'root'
})

export class ResolveContributorGuard {
  constructor(public _admin: AdminUserService,
    public _helper: FunctionService) {}

  resolve() {
    const userRole = this._helper?.getUser?.role;
    if (userRole !== 'PROFESSIONAL') {
      return this._admin.getContributorSidebar()?.pipe(tap((v: SidebarPermission)=>{
        this._admin.contributoriSideBarSubject?.next(v);
      }));
    }
  }
}
