import { CopyToClipboardDirective } from './copy-to-clipboard.directive';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { AlphabetsOnlyDirective } from './alphabets-only.directive';
import { AuPreventEnterDirective } from './au-prevent-enter.directive';
import { AuSuggestionListDirective } from './au-suggestion-list.directive';
import { DisableButtonApiDirective } from './disable-button-api.directive';
import { DragHandleDirective } from './drag-handle.directive';
import { DragNDropDirective } from './drag-ndrop.directive';
import { DraggableDirective } from './draggable.directive';
import { DynamicRenderDirective } from './dynamic-component.directive';
import { ImagePreloadDirective } from './image-preload.directive';
import { InputMaxLengthDirective } from './input-max-length.directive';
import { NumberonlyDirective } from './numberonly.directive';
import { TextAreavalidatorDirective } from './textareavalidator.directive';
import { WorkFlowClickEventDirective } from './work-flow-click-event.directive';
import { FontColorDirective } from './au-font-color.directive';
import { AuContentEditableDirective } from './au-contentEditable.directive';
import { AuPreventENumberDirective } from './au-Enumber-prevent.directive';
import { NumberWithNegativeDirective } from './number-with-negative.directive';
import { AuPreventENumberOnlyDirective } from './au-EnumberOnly-prevent.directive';
import { FloatingpointNumberDirective } from 'src/app/core/directive/floatingpoint-number.directive';
import { TemplateDirective } from './template-ref.directive';
import { NoWhitespaceStartDirective } from 'src/app/core/directive/no-white-spacevalidator.directive';
import { TextInputvalidatorDirective } from 'src/app/core/directive/textinputvalidator.directive';
import { AuCalendarDateDirective } from 'src/app/core/directive/calendar-date.directive';
import { AxTooltipDirective } from './ax-tooltip.directive';
import { VisiblityObserverDirective } from 'src/app/core/directive/visibility-observer.directive';
import { NumberfieldDirective } from 'src/app/core/directive/numberfield.directive';
import { SetDirectionDirective } from 'src/app/core/directive/ax-setDirection.directive';
import { ScrollSpyDirective } from 'src/app/core/directive/scroll-spy.directive';

@NgModule({
  declarations: [
    DragNDropDirective,
    NumberonlyDirective,
    ImagePreloadDirective,
    DraggableDirective,
    DragHandleDirective,
    DynamicRenderDirective,
    TextAreavalidatorDirective,
    NoWhitespaceStartDirective,
    AlphabetsOnlyDirective,
    AuSuggestionListDirective,
    AuPreventEnterDirective,
    InputMaxLengthDirective,
    WorkFlowClickEventDirective,
    FontColorDirective,
    AuContentEditableDirective,
    AuPreventENumberDirective,
    NumberWithNegativeDirective,
    AuPreventENumberOnlyDirective,
    FloatingpointNumberDirective,
    TemplateDirective,
    TextInputvalidatorDirective,
    AuCalendarDateDirective,
    CopyToClipboardDirective,
    NumberfieldDirective
  ],
  imports: [
    TranslateModule,
    AxTooltipDirective,
    VisiblityObserverDirective,
    DisableButtonApiDirective,
    SetDirectionDirective,
    ScrollSpyDirective
  ],
  exports: [
    DragNDropDirective,
    DisableButtonApiDirective,
    NumberonlyDirective,
    ImagePreloadDirective,
    DraggableDirective,
    DragHandleDirective,
    DynamicRenderDirective,
    TextAreavalidatorDirective,
    NoWhitespaceStartDirective,
    AlphabetsOnlyDirective,
    AuSuggestionListDirective,
    AuPreventEnterDirective,
    InputMaxLengthDirective,
    WorkFlowClickEventDirective,
    FontColorDirective,
    AuContentEditableDirective,
    AuPreventENumberDirective,
    NumberWithNegativeDirective,
    AuPreventENumberOnlyDirective,
    FloatingpointNumberDirective,
    TemplateDirective,
    TextInputvalidatorDirective,
    AuCalendarDateDirective,
    VisiblityObserverDirective,
    CopyToClipboardDirective,
    NumberfieldDirective,
    AxTooltipDirective,
    SetDirectionDirective,
    ScrollSpyDirective
  ]

})
export class DirectiveModule { }
