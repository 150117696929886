import { ChangeDetectorRef, Directive, ElementRef, HostListener, Inject, Input, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { AxActionService } from 'src/app/core/service/ax-action/ax-action.service';
import { DOCUMENT } from '@angular/common';
import { FunctionService } from 'src/app/core/helper/function.helper.service';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
  selector: '[appAxTooltip]',
  standalone: true
})
export class AxTooltipDirective {

  /** Input for showing tooltip text */
  @Input() tooltipText: string = '';

  /** Input for removing tooltip on click */
  @Input() disableOnClick: boolean = false;

  /** Input for removing tooltip */
  @Input() disableTooltip: boolean = false;

  /** Input for showing template */
  // any is used because will be having different content templates in tooltipTemplate
  @Input() tooltipTemplate: TemplateRef<any>;

  /** Input for adding custom class */
  @Input() customClass!: string;

  /** Input for showing tooltip text only on overflow ellipsis*/
  @Input() showToolTipOnlyOnEllipsis: boolean = false;

  /** Input for showing tooltip text only on overflow ellipsis based on height*/
  @Input() showToolTipEllipsisOnHeight: boolean = false;

  /** Input for content exact height*/
  @Input() contentScrollHeight: number = 0;

  /** Input for when body scroll will hidden using css need to add custom right value*/
  @Input() widthPostionValue: number = 30;

  public tooltipElement!: HTMLElement;
  public isContentString: boolean = false;
  public isTemplateContent: boolean = false;
  public mouseEvent!: MouseEvent;
  public maxWordsToShowTooltip: number = 100;

  constructor(private _elementRef: ElementRef, private _renderer: Renderer2, private _cdr: ChangeDetectorRef,
    public _selectedDropdown: AxActionService, private viewContainerRef: ViewContainerRef, public _function: FunctionService, @Inject(DOCUMENT) private _document: Document, protected _sanitizer: DomSanitizer) { }

  // Event triggered on mouseover
  @HostListener('mouseover', ['$event']) onMouseEnter(event: MouseEvent) {
    this.mouseEvent = event;
    const element = this._elementRef.nativeElement;
    if (!this.showToolTipEllipsisOnHeight) {
      if (this.showToolTipOnlyOnEllipsis ? (element?.offsetWidth < element?.scrollWidth) : true) {
        this.createTooltip();
        this.setTooltipPosition();
      }
    } else if (element?.offsetHeight < this.contentScrollHeight) {
      this.createTooltip();
      this.setTooltipPosition();
    }
  }

  // Event triggered on mouse out
  @HostListener('mouseleave', ['$event']) onMouseLeave(event: MouseEvent) {
    if (this.tooltipElement && !this.tooltipElement?.contains(event?.target as Node)) {
      this.isContentString = false;
      this.isTemplateContent = false;
      this.removeDivElement();
    }
  }

  // Event triggered on mouse click
  @HostListener('click', ['$event']) clickEvent(event: MouseEvent) {
    if ((this.disableOnClick && this.tooltipElement && !this.tooltipElement?.contains(event?.target as Node)) || (this._selectedDropdown?.selectDropdownExpansion && this.disableOnClick)) {
      this.isContentString = false;
      this.isTemplateContent = false;
      this.removeDivElement();
    }
  }

  // Method for setting tooltip
  createTooltip() {
    if (this.disableTooltip) {
      return;
    }
    this.removeDivElement();
    this.tooltipElement = this._renderer.createElement('div');
    this._renderer.addClass(this.tooltipElement, 'ax-tooltipvalue');

    if (this.customClass) {
      this._renderer.addClass(this.tooltipElement, this.customClass);
    }

    if (this.tooltipTemplate) {
      const viewRef = this.tooltipTemplate.createEmbeddedView({});
      this.viewContainerRef.insert(viewRef);
      viewRef.rootNodes.forEach((i: number) => {
        this._renderer.appendChild(this.tooltipElement, viewRef.rootNodes[i]);
      });
      this.isTemplateContent = true;
    } else if (this.tooltipText) {
      this.tooltipText = this.getToolTipContent(this.tooltipText);
      if (this.tooltipText.split(' ').length > this.maxWordsToShowTooltip) {
        const truncatedText = this.truncateText(this.tooltipText, this.maxWordsToShowTooltip);
        this.tooltipText = truncatedText;
      }
      const textNode = this._renderer.createText(this.tooltipText);
      this._renderer.appendChild(this.tooltipElement, textNode);
      this.isContentString=true;
    } else {
      this.isContentString=false;
      this.isTemplateContent=false;
    }
    if (this.tooltipTemplate || this.tooltipText) {
      this._renderer.appendChild(this._document.body, this.tooltipElement);
      this._renderer.setAttribute(this._document.body, 'dir', this._function.layoutOptions.dir);
      this._selectedDropdown.selectDropdownExpansion = this.tooltipElement;
    }
  }

  /** Function to remove htmltags from tooltip */
  getToolTipContent(tooltip: string) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = this._sanitizer.sanitize(1, tooltip);
    return tempDiv.textContent || tempDiv.innerText || '';
  }

  // Method for setting tooltip template
  setTooltipPosition() {
    if (this.isTemplateContent &&  !this.isContentString) {
      const buttonRef = this._elementRef?.nativeElement.getBoundingClientRect();
      const windowHeight = window.outerHeight;
      const windowWidth = window.innerWidth;
      if (buttonRef?.top-53  > this.tooltipElement.offsetHeight +30 && (windowWidth-buttonRef?.right  > this.tooltipElement.offsetWidth) && (buttonRef?.left > this.tooltipElement.offsetWidth) ) {
        this._renderer.setAttribute(this.tooltipElement, 'style', `left:${buttonRef?.left+10}px;bottom:${this.mouseEvent?.y+10}px;`);
        this._renderer.addClass(this.tooltipElement, 'ax-tooltipvalue__details--bottom-right');
      } else if (windowHeight-buttonRef?.bottom >this.tooltipElement.offsetHeight  && (windowWidth-buttonRef?.right  > this.tooltipElement.offsetWidth) && (buttonRef?.left > this.tooltipElement.offsetWidth)  ) {
        this._renderer.setAttribute(this.tooltipElement, 'style', `left:${windowWidth-this.mouseEvent?.x}px;top:${this.mouseEvent?.y}px;`);
        this._renderer.addClass(this.tooltipElement, 'ax-tooltipvalue__details--top-center');
      } else if ((buttonRef?.left > this.tooltipElement.offsetWidth) && (windowWidth-buttonRef?.right  < this.tooltipElement.offsetWidth)) {
        this._renderer.setAttribute(this.tooltipElement, 'style', `right:${windowWidth-this.mouseEvent?.x}px;top:${this.mouseEvent?.y+10}px;`);
        this._renderer.addClass(this.tooltipElement, 'ax-tooltipvalue__details--bottom-left');
      } else if ((windowWidth-buttonRef?.right  > this.tooltipElement.offsetWidth) && (buttonRef?.left < this.tooltipElement.offsetWidth) ) {
        this._renderer.setAttribute(this.tooltipElement, 'style', `left:${buttonRef?.left + (this.mouseEvent?.x/2)}px;top:${this.mouseEvent?.y+10}px;`);
        this._renderer.addClass(this.tooltipElement, 'ax-tooltipvalue__details--bottom-left');
      } else {
        this._renderer.setAttribute(this.tooltipElement, 'style', `left:${windowWidth-this.mouseEvent?.x}px;top:${this.mouseEvent?.y+10}px;`);
        this._renderer.addClass(this.tooltipElement, 'ax-tooltipvalue__details--bottom-left');
      }
    }
    if (!this.isTemplateContent &&  this.isContentString) {
      this.setTooltipTextPoition();
    }
  }

  // Method for setting tooltip text position
  setTooltipTextPoition() {
    if ( this.tooltipElement){
      const buttonRef = this._elementRef.nativeElement.getBoundingClientRect();
      const windowHeight = window.outerHeight;
      const windowWidth = window.innerWidth;
      const spanElement = this._renderer.createElement('span');
      this._cdr.detectChanges();
      if (((buttonRef?.top - 53 > this.tooltipElement?.offsetHeight + 30) && (windowWidth - buttonRef?.right > this.tooltipElement?.offsetWidth) && (buttonRef?.left > this.tooltipElement.offsetWidth))) {
        this._renderer.setAttribute(this.tooltipElement, 'style', `left:${this.tooltipElement?.offsetWidth === buttonRef.width ? buttonRef?.left : this.tooltipElement.offsetWidth > buttonRef.width ? buttonRef.left - 10 : buttonRef.left + 10}px;top:${buttonRef?.top - this.tooltipElement.offsetHeight - 10}px;`);
        this._renderer.addClass(this.tooltipElement, 'ax-tooltipvalue__details--top-center');
        this._renderer.addClass(spanElement, 'ax-tooltipvalue__details--arrow-position-top');
        if (buttonRef?.width < 15) {
          this._renderer.setAttribute(spanElement, 'style', `left:${this.tooltipElement?.offsetWidth <= buttonRef.width ? (this.tooltipElement?.offsetWidth / 2) - 4 : buttonRef.width}px`);
        } else {
          this._renderer.setAttribute(spanElement, 'style', `left:${this.tooltipElement.offsetWidth <= buttonRef.width ? (this.tooltipElement.offsetWidth / 2) - 4 : buttonRef.width / 2}px`);
        }
      } else if ((buttonRef?.top - 53 > this.tooltipElement.offsetHeight + 30) && (windowWidth - buttonRef?.right > this.tooltipElement.offsetWidth) && (buttonRef?.left < this.tooltipElement.offsetWidth)) {
        this._renderer.setAttribute(this.tooltipElement, 'style', `left:${this.mouseEvent.x +10 < buttonRef.right ? this.mouseEvent?.x-10 : this.mouseEvent?.x-20}px;top:${buttonRef?.top - this.tooltipElement.offsetHeight - 10}px;`);
        this._renderer.addClass(this.tooltipElement, 'ax-tooltipvalue__details--top-right');
        this._renderer.addClass(spanElement, 'ax-tooltipvalue__details--arrow-position-top-right');
        this._renderer.setAttribute(spanElement, 'style', `left:${4}px`);
      } else if (buttonRef?.top - 53 > this.tooltipElement.offsetHeight + 30 && (buttonRef?.left > this.tooltipElement.offsetWidth) && (windowWidth - buttonRef?.right < this.tooltipElement.offsetWidth)) {
        this._renderer.setAttribute(this.tooltipElement, 'style', `right:${this.mouseEvent.x+10 < buttonRef.left  ? (window.innerWidth)- (this.mouseEvent?.x)-10 : (window.innerWidth)- (this.mouseEvent?.x)- this.widthPostionValue}px;top:${buttonRef?.top - this.tooltipElement.offsetHeight - 10}px;`);
        this._renderer.addClass(this.tooltipElement, 'ax-tooltipvalue__details--top-left');
        this._renderer.addClass(spanElement, 'ax-tooltipvalue__details--arrow-position-top-left');
        this._renderer.setAttribute(spanElement, 'style', `right:${3}px`);
      } else if ((windowHeight - buttonRef?.bottom > this.tooltipElement.offsetHeight && (windowWidth - buttonRef?.right > this.tooltipElement.offsetWidth) && (buttonRef?.left > this.tooltipElement.offsetWidth))) {
        this._renderer.setAttribute(this.tooltipElement, 'style', `left:${this.tooltipElement.offsetWidth === buttonRef.width ? buttonRef?.left : this.tooltipElement.offsetWidth > buttonRef.width ? buttonRef.left - 10 : buttonRef.left + 10}px;top:${buttonRef?.top + buttonRef?.height + 8}px;`);
        this._renderer.addClass(this.tooltipElement, 'ax-tooltipvalue__details--bottom-center');
        this._renderer.addClass(spanElement, 'ax-tooltipvalue__details--arrow-position-bottom');
        this._renderer.setAttribute(spanElement, 'style', `left:${this.tooltipElement.offsetWidth <= buttonRef.width ? (this.tooltipElement.offsetWidth / 2) - 4 : buttonRef.width / 2}px`);
      } else if (windowHeight - buttonRef?.bottom > this.tooltipElement.offsetHeight && (windowWidth - buttonRef?.right > this.tooltipElement.offsetWidth) && (buttonRef?.left < this.tooltipElement.offsetWidth)) {
        this._renderer.setAttribute(this.tooltipElement, 'style', `left:${this.mouseEvent.x +10 < buttonRef.right ? this.mouseEvent?.x-10 : this.mouseEvent?.x-20}px;;top:${buttonRef?.top + buttonRef?.height + 8}px;`);
        this._renderer.addClass(this.tooltipElement, 'ax-tooltipvalue__details--bottom-right');
        this._renderer.addClass(spanElement, 'ax-tooltipvalue__details--arrow-position-bottom-right');
        this._renderer.setAttribute(spanElement, 'style', `left:${4}px`);
      } else if (windowHeight - buttonRef?.bottom > this.tooltipElement.offsetHeight && (buttonRef?.left > this.tooltipElement.offsetWidth) && (windowWidth - buttonRef?.right < this.tooltipElement.offsetWidth)) {
        this._renderer.setAttribute(this.tooltipElement, 'style', `right:${((window.innerWidth)- (this.mouseEvent?.x)-20)}px;top:${buttonRef?.top + buttonRef?.height + 8}px;`);
        this._renderer.addClass(this.tooltipElement, 'ax-tooltipvalue__details--bottom-left');
        this._renderer.addClass(spanElement, 'ax-tooltipvalue__details--arrow-position-bottom-left');
        this._renderer.setAttribute(spanElement, 'style', `right:${3}px`);
      } else if (buttonRef?.top > (windowHeight / 2) ) {
        this._renderer.setAttribute(this.tooltipElement, 'style', `left:${this.tooltipElement?.offsetWidth === buttonRef.width ? buttonRef?.left : this.tooltipElement.offsetWidth > buttonRef.width ? buttonRef.left - 10 : buttonRef.left + 10}px;top:${buttonRef?.top - this.tooltipElement.offsetHeight - 10}px;`);
        this._renderer.addClass(this.tooltipElement, 'ax-tooltipvalue__details--top-center');
        this._renderer.addClass(spanElement, 'ax-tooltipvalue__details--arrow-position-top');
        if (buttonRef?.width < 15) {
          this._renderer.setAttribute(spanElement, 'style', `left:${this.tooltipElement?.offsetWidth <= buttonRef.width ? (this.tooltipElement?.offsetWidth / 2) - 4 : buttonRef.width}px`);
        } else {
          this._renderer.setAttribute(spanElement, 'style', `left:${this.tooltipElement.offsetWidth <= buttonRef.width ? (this.tooltipElement.offsetWidth / 2) - 4 : buttonRef.width / 2}px`);
        }
      } else {
        this._renderer.setAttribute(this.tooltipElement, 'style', `left:${this.tooltipElement?.offsetWidth === buttonRef?.width ? buttonRef?.left : this.tooltipElement?.offsetWidth > buttonRef?.width ? buttonRef?.left - 10 : buttonRef?.left + 10}px;top:${buttonRef?.top + buttonRef?.height + 8}px;`);
        this._renderer.addClass(this.tooltipElement, 'ax-tooltipvalue__details--bottom-center');
        this._renderer.addClass(spanElement, 'ax-tooltipvalue__details--arrow-position-bottom');
        this._renderer.setAttribute(spanElement, 'style', `left:${this.tooltipElement?.offsetWidth <= buttonRef?.width ? (this.tooltipElement?.offsetWidth / 2) - 4 : buttonRef?.width / 2}px`);
      }
      this._renderer.appendChild(this.tooltipElement, spanElement);
    }
  }

  // Method for transferring long tooltiptext to short
  truncateText(text: string, maxWords: number): string {
    const words = text.split(' ');
    if (words.length > maxWords) {
      return `${words.slice(0, maxWords).join(' ')}...`;
    }
    return text;
  }

  // Method for removing tooltip from body
  removeDivElement() {
    if (this._selectedDropdown?.selectDropdownExpansion) {
      this.isContentString = false;
      this.isTemplateContent = false;
      this._renderer.removeChild(this._document.body, this._selectedDropdown.selectDropdownExpansion);
      this._selectedDropdown.selectDropdownExpansion = null;
    }
  }

  // This HostListener is used for the when window:resize
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setTooltipPosition();
  }
}
