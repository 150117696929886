import { Directive, ElementRef, Input, HostListener, OnChanges } from '@angular/core';

@Directive({
  selector: '[appNumberWithNegative]'
})
export class NumberWithNegativeDirective implements OnChanges {
  @Input() min!: number;
  @Input() decimal!: boolean;
  private regex: RegExp = new RegExp('^-?[0-9.]*$');
  private specialKeys: string[] = ['Backspace', 'ArrowLeft', 'ArrowRight'];
  constructor(private elementRef: ElementRef) {

  }

  /**
   * Key board action
   * @param event
   */
  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent& {
    target: HTMLInputElement
  }) {
    const start = event.target?.selectionStart;
    const end = event.target?.selectionEnd;
    if (this.specialKeys.includes(event.key)) {
      return;
    }
    const inputValue: string = this.elementRef.nativeElement.value.slice(0, start) + event.key+ this.elementRef.nativeElement.value.slice(end, this.elementRef.nativeElement.value?.length);
    if (inputValue && !String(inputValue).match(this.regex)) {
      event.preventDefault();
    }
    if (this.min && inputValue) {
      if (+inputValue < this.min) {
        event.preventDefault();
      }
    }
    return;
  }

  /**
   * Copy Paste action
   * @param event
   */
  @HostListener('paste', ['$event']) onPaste(event: { originalEvent: any; preventDefault: () => void; }) {
    const clipboardData = (event.originalEvent || event).clipboardData.getData('text/plain');
    if (clipboardData) {
      const regEx = this.regex;
      if (!regEx.test(clipboardData)) {
        event.preventDefault();
      }
    }
    return;
  }

  ngOnChanges(): void {
    if (this.decimal) {
      this.regex = new RegExp('^-?[0-9.]*$');
    }
  }
}
