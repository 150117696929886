export const customModuleApiEndpoints = {
  customModule: 'custom-module/',
  customModuleFieldsList: 'module-fields-list/',
  customModuleFieldsListDelete: 'module-data-delete/',
  customModuleDropDown: 'custom-module-dropdown-list/',
  customModuleCreateForm: 'module-data-create/',
  customModuleCreate: 'custom-module/create/',
  customModuleFieldList: 'custom-module/field-list/',
  customModuleDetails: 'custom-module/records/',
  customModuleFormUpdate: 'module-data-update/',
  customModuleUpdate: '/update/',
  customModuleEditDetails: '/fields/',
  contributor: 'contributor/',
  contributorComments: 'contributer-comments/',
  comment: 'comment/',
  customModuleDocument: 'custom-module/media/',
  customModuleDocumentList: '/list',
  customModuleDocumentUpload: '/upload',
  customModuleDocumentEdit: '/edit',
  customModuleDocumentDelete: '/delete',
  customModuleFieldListDependencies: 'custom-module/dependencies/config-list',
  customModuleFieldDependencies: 'custom-module/dependencies/',
  customModuleDependencyInfo: 'custom-module/dependency/',
  customModuleDependencyInfoDetails: '/details',
  customModuleAction: 'custom-record-actions/',
  customModuleActionList: '/list/',
  customModuleActionDropdown: 'custom-record-action-dropdown/',
  customModuleActionDropdownList: '/list/',
  customModuleActionOwnerDropdownList: 'stake-holder-list/',
  customModuleActionCreate: '/create/',
  customModuleActionEdit: '/edit/',
  customModuleActionDelete: '/delete/',
  customModuleDocumentAction: 'custom-record-actions-media/',
  customModuleDocumentActionFile: 'file-uploader/',
  customModuleDocumentListAction: '/list/',
  customModuleDocumentUploadAction: '?request_from=app&request_for=observation_action__custom_module',
  customModuleDocumentEditAction: '/edit/?request_from=app&request_for=observation_action__custom_module',
  customModuleDocumentDeleteAction: '/delete/?request_for=observation_action__custom_module',
  customModuleAddPermission: 'custom-module/add-permission/',
  customModuleDeleteUserPermission: 'custom-module/remove-permission/',
  customModuleEffectivePermission: 'custom-module/effective-permissions/',
  customModuleList: 'custom-modules/list/',
  untagBcmCustomModule: 'business-process/#object_id#/untag/custom_records/',
  assetTagDelete: 'bussinessprocess/tagged-asset/',
  customModulePermission: 'all-custom-module/current-user-permission/',
  strategydelete: 'bia/strategy/',
  processTagDelete: 'business-process/up-down-stream/'
};
