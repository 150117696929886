import { Component, Input, OnDestroy, Inject } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-spinner',
  template: `<div class="preloader" *ngIf="isSpinnerVisible">
        <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
        </div>
    </div>`
})
export class SpinnerComponent implements OnDestroy {
  public isSpinnerVisible: boolean = true;

  @Input() public backgroundColor: string ='rgba(0, 115, 170, 0.69)';

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.router.events.subscribe({
      next: (event: any) => {
        if (event instanceof NavigationStart) {
          this.isSpinnerVisible = true;
        } else if ( event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError ){
          this.isSpinnerVisible = false;
        }
      },
      complete: () => {
        this.isSpinnerVisible = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.isSpinnerVisible = false;
  }
}
