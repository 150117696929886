import { FunctionService } from 'src/app/core/helper/function.helper.service';
import { StorageService } from 'src/app/core/helper/storage.service';
import { HttpService } from './../http/http.service';
import { Injectable } from '@angular/core';
import { ApiEndpoints } from '../../constants';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { mfaAPI } from 'src/app/core/constant/mfa.constant';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpService,
    private _httpclient: HttpClient,
    private router: Router,
    private _storage: StorageService,
    private _helper: FunctionService,
    private _cookie: CookieService,
    private _activatedRoute: ActivatedRoute) { }

  login(data: string | any): Observable<LoginObject> {
    return this.http.post(ApiEndpoints.Login, data);
  }

  verifyMfaToken(data: {

    token: string,
    key:string,
  }){
    return this.http.post( mfaAPI.mfaVerifyToken, data);
  }

  resendMfaToken(data:string){
    return this.http.post( mfaAPI. emailOtpResend, {token: data});
  }

  getSamlLoginInfo() {
    return this.http.get(ApiEndpoints.samlLoginInfo);
  }

  userProfile() {
    return this.http.get(ApiEndpoints.userProfile);
  }

  userList() {
    return this.http.get(`${ApiEndpoints.adminUserGrid}?exclude_client=false`);
  }

  getProfessionalUsers() {
    return this.http.get(ApiEndpoints.professionalUsers);
  }

  logOut() {
    return this.http.post(ApiEndpoints.Logout);
  }

  loginSaml() {
    return this.http.get(ApiEndpoints.loginSaml);
  }

  // get login information
  // token value changes
  getLoginInfo(userId : string, token: string | any, clientId: string = '') {
    const header = new HttpHeaders().set(
      'Authorization', `JWT ${token}`
    );
    if (clientId !== '' && clientId !== null){
      return this._httpclient.get(`${environment.protocol+clientId}/${ApiEndpoints.loginInfo}${userId}`, {headers: header});
    }
    return this._httpclient.get(`${ApiEndpoints.loginInfo}${userId}`, {headers: header});
  }

  resetPassword(data: FormData) {
    return this.http.post(ApiEndpoints.passwordReset, data);
  }

  reactivateClientPassword(data: FormData) {
    return this.http.post(ApiEndpoints.reactivateClientPassword, data);
  }

  activateClientPassword(data: FormData) {
    return this.http.post(ApiEndpoints.activateClientPassword, data);
  }

  resetUserPassword(data: FormData) {
    return this.http.post(ApiEndpoints.resetUserPassword, data);
  }

  activateUserPassword(data: FormData) {
    return this.http.post(ApiEndpoints.activateUserPassword, data);
  }

  tokenVerification(token: string) {
    return this.http.get(ApiEndpoints.tokenValidation + token);
  }

  resetTokenVerification(token: string) {
    return this.http.get(ApiEndpoints.resetTokenValidation + token);
  }

  forceLogout() {
    this._helper.deleteCookie().then(() => {
      this._storage.clearLocalStorage();
      window.location.href = `/?returnUrl=${this._activatedRoute.snapshot.queryParamMap.get('returnUrl') || this.router.url}`;
    });
  }

  forceLogoutPasswordCreate() {
    this._helper.deleteCookie().then(() => {
      this._storage.clearLocalStorage();
      this.router.navigate(['/']);
    });
  }
}
export interface LoginObject {
  key: string;
  client_url: string;
  domain: string;
  role: string;
  status: number;
  is_client: boolean;
  is_response : boolean;
}
