import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appEnumberWithDecimalPrevent]'
})
export class AuPreventENumberOnlyDirective {
  private regex: RegExp = new RegExp(/^-?\d*\.?\d{0,2}$/g);
  private specialKeys: string[] = ['Backspace', '-', '.'];
  constructor(private el: ElementRef) { }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys?.includes(event?.key)) {
      return;
    }

    const current: string = this.el?.nativeElement?.value;
    const next: string = current?.concat(event?.key);

    if ((/^(-)?0+$/)?.test(next) && next !== '0' && next !== '-0' && !(/^0+\.\d+$/)?.test(next)) {
      event?.preventDefault();
      return;
    }

    if (next && !String(next)?.match(this.regex)) {
      event?.preventDefault();
    }

  }
}
