import { Injectable } from '@angular/core';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SocketService } from 'src/app/core/service/socket/socket.service';
import { ApiEndpoints } from 'src/app/core/constants';

@Injectable({
  providedIn: 'root'
})
export class PermissionSocketService {

  // Base url for socket
  private socketBase: string = `${environment.socketProtocol + environment.domain}/`;
  // socket url
  private socketUrl: string = this.socketBase + ApiEndpoints.socketUrl;
  // subject for subscription
  private socket!: AnonymousSubject<object>;
  constructor(private _socket: SocketService) { }

  // used to create connection
  connection(token: string, permissionSocketUrl: string) {
    // one socket connection at a time other will remove
    if (this.socket) {
      this.closeConnection();
    }
    const sub = new Subject();
    this.socket = this._socket.connect(`${this.socketUrl}${permissionSocketUrl}/?token=${token}`);
    this.socket.subscribe((response: MessageEvent) => {
      sub.next(JSON.parse(response?.data));
    });
    return sub.asObservable();
  }

  // used to close socket connection
  closeConnection() {
    this.socket?.complete();
  }
}
