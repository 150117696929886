import { TranslateService } from '@ngx-translate/core';
import { browserDataAlert, browserVersion } from './../../constants';
import { FunctionService } from 'src/app/core/helper/function.helper.service';
import { SweetAlertService } from './../../helper/sweet-alert.service';
import { Injectable } from '@angular/core';
import { browserData } from '../../constants';

@Injectable({
 providedIn: 'root'
})
export class BrowserCheckService {

  private browser: string = '';
  private version: string = '';
  private browserCheck: boolean = this._helperService.getCookie(browserData.browserCheck);
  constructor( private _sweetAlert: SweetAlertService,
    private _helperService: FunctionService, private translate: TranslateService
  ) {

  }

  async checkForUpdate() {
   const size = '600px';
   const className = {
    container: 'au-browser-alert__wrapper'
   };
   const buttonsStyling = true;
   const html = `
           <div class="au-browser-alert">
              <p class="au-browser-alert__content">${this.translate.instant(browserDataAlert.subText)}</p>
                  <div class="au-browser-alert__img">
                      <a href="https://www.google.com/chrome/?brand=YTUH&gclid=Cj0KCQjwz96WBhC8ARIsAATR251dHMCDA1inK9E17Wd_ZpVQhfNZ8EwaMkTK2H4Xb1wSAxiCFuiND4MaApEeEALw_wcB&gclsrc=aw.ds" target="_blank" class="au-browser-alert__item"> <img src="assets/images/svg/google-chrome-browser.svg"/>
                      <span class="au-browser-alert__item-text">
                      ${this.translate.instant('Chrome')}
                      </span>
                      </a>
                      <a href="https://www.microsoft.com/en-us/edge?r=1" target="_blank" class="au-browser-alert__item"> <img src="assets/images/svg/edge-browser.svg"/> <span class="au-browser-alert__item-text">
                      ${this.translate.instant('Edge')}
                      </span></a>
                      <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank" class="au-browser-alert__item"> <img src="assets/images/svg/firefox-browser.svg"/>
                      <span class="au-browser-alert__item-text">
                      ${this.translate.instant('Firefox')}
                      </span>
                      </a>
                      <a  href="https://support.apple.com/downloads/safari" target="_blank" class="au-browser-alert__item"> <img src="assets/images/svg/safari.svg"/>
                      <span class="au-browser-alert__item-text">
                      ${this.translate.instant('Safari')}
                      </span>
                      </a>
                  </div>
             </div>`;
   if (!this.browserCheck) {
    this.browser = this._helperService.detectBrowserName();
    this.version =  this._helperService.detectBrowserVersion();
    if ((browserVersion.chrome > + this.version && this.browser === browserData.chrome) || (browserVersion.opera > + this.version && this.browser === browserData.opera) ||     (browserVersion.firefox > + this.version && this.browser === browserData.firefox) ||  (browserVersion.safari > + this.version && this.browser === browserData.safari) ||     (browserVersion.edge > + this.version && this.browser === browserData.edge) ||  (  this.browser === browserData.ie || this.browser ===  browserData.other)
    ) {
     await this._sweetAlert.customAlert(browserDataAlert, size, className, buttonsStyling, html );
    }
    const cookieTime: Date =  this._helperService.getDateWithRespectToDay(15);
    this._helperService.addCookie( browserData.browserCheck, 'true', cookieTime);
   }
  }

}
