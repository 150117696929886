import { Injectable } from '@angular/core';
import Swal, { SweetAlertCustomClass, SweetAlertResult } from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { AuditSuccessDraftCancelSettings, AuditSuccessDraftConfirmSettings, clientNames } from 'src/app/core/constants';
import { commonLanguage } from 'src/app/core/constant/language-translation/common-language';
import { environment } from 'src/environments/environment';
import { riskArabicTranslation } from 'src/app/core/constant/language-translation/risk/risk-language';

@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {
  private envAccess: boolean = !environment?.clientEnv ? true : false;
  private miahonaAccess:boolean = environment?.clientName === clientNames?.miahona;

  constructor(
    private translate: TranslateService
  ) { }

  confirm(title: string = 'Are you sure?', text: string = 'This can\'t be undone!', confirmButtonText: string = 'Delete', cancelButtonText: string = 'No, keep it', confirmButtonAutomation:string = '', cancelButtonAutomation:string = '', isWarring: boolean = false) {
    return new Promise((resolve: (value: string | boolean) => void) => {
      Swal.fire({
        title: this.translate.instant(title),
        text: this.translate.instant(text),
        showCancelButton: true,
        allowOutsideClick: false,
        cancelButtonText: this.translate.instant(cancelButtonText),
        confirmButtonText: this.translate.instant(confirmButtonText),
        reverseButtons: true,
        customClass: {
          container: isWarring ? 'ax-swal ax-swal--inverse ax-swal--warning' : 'ax-swal ax-swal--inverse',
          confirmButton: 'au-btn au-btn--primary au-m--1',
          cancelButton: 'au-btn au-btn--secondary au-m--1'
        },
        buttonsStyling: false,
        onOpen: () => {
          const cancelButton = Swal.getCancelButton();
          if (cancelButton && cancelButtonAutomation !== '') {
            cancelButton?.setAttribute('name', cancelButtonAutomation);
          }
          const confirmButton = Swal.getConfirmButton();
          if (confirmButton && confirmButtonAutomation !== '') {
            confirmButton?.setAttribute('name', confirmButtonAutomation);
          }

        }
      }).then((result: SweetAlertResult) => {
        if (result?.value) {
          resolve(true); return;

        } else if (result.dismiss === Swal.DismissReason.cancel) {
          resolve(false); return;
        }
      });

    });
  }

  confirmWithThreeButton(title: string = 'Are you sure?', text: string = 'This can\'t be undone!', text2: string = 'This can\'t be undone!',  text3: string = 'This can\'t be undone!',
    confirmButtonText: string = 'Delete', cancelButtonText: string = 'No, keep it', denyButtonText: string = 'Not sure') {
    return new Promise((resolve: any) => {
      const htmlcontent = `<p>${text}<br /> There are currently <strong>${text2}</strong>${text3}</p>`;
      Swal.fire({
        title: this.translate.instant(title),
        text: this.translate.instant(text),
        html: htmlcontent,
        icon: 'warning',
        showCancelButton: true,
        showDenyButton: true,
        allowOutsideClick: false,
        confirmButtonText: this.translate.instant(confirmButtonText),
        cancelButtonText: this.translate.instant(cancelButtonText),
        denyButtonText: this.translate.instant(denyButtonText),
        customClass: {
          confirmButton: 'au-btn au-btn--primary au-m--1',
          cancelButton: 'au-btn au-btn--secondary au-m--1',
          denyButton: 'au-btn au-btn--warning au-m--1'
        },
        buttonsStyling: false
      }).then((result: SweetAlertResult) => {
        if (result.value) {
          resolve(true, 'NEWVALUE'); return;
        } else if (result.isDenied) {
          resolve('notSure'); return;
        } else if (result.isDismissed) {
          resolve(false); return;
        }
      });
    });
  }

  // confirmation pop-up with three buttons
  confirmWithThreeButtons(title: string = 'Are you sure?', text: string = 'This can\'t be undone!',
    confirmButtonText: string = 'Delete', cancelButtonText: string = 'No, keep it', denyButtonText: string = 'Not sure') {
    return new Promise((resolve: (value:string | boolean) =>void) => {
      Swal.fire({
        title: this.translate.instant(title),
        text: this.translate.instant(text),
        icon: 'warning',
        showCancelButton: true,
        showDenyButton: true,
        allowOutsideClick: false,
        confirmButtonText: this.translate.instant(confirmButtonText),
        cancelButtonText: this.translate.instant(cancelButtonText),
        denyButtonText: this.translate.instant(denyButtonText),
        customClass: {
          confirmButton: 'au-btn au-btn--primary au-m--1',
          cancelButton: 'au-btn au-btn--secondary au-m--1',
          denyButton: 'au-btn au-btn--warning au-m--1'
        },
        buttonsStyling: false
      }).then((result: SweetAlertResult) => {
        if (result?.value) {
          resolve(true); return;
        } else if (result?.isDenied) {
          resolve('notSure'); return;
        } else if (result?.isDismissed) {
          resolve(false); return;
        }
      });
    });
  }

  confirmSuccess(title: string = 'Are you sure?', text: string = 'This can\'t be undone!',
    confirmButtonText: string = 'Delete') {
    return new Promise((resolve: any) => {
      Swal.fire({
        title: this.translate.instant(title),
        text: this.translate.instant(text),
        icon: 'success',
        allowOutsideClick: false,
        confirmButtonText: this.translate.instant(confirmButtonText),
        customClass: {
          confirmButton: 'au-btn au-btn--primary au-m--1',
          cancelButton: 'au-btn au-btn--secondary au-m--1'
        },
        buttonsStyling: false
      }).then((result: SweetAlertResult) => {
        if (result.value) {
          resolve(true); return;

        } else if (result.dismiss === Swal.DismissReason.cancel) {
          resolve(false); return;
        }
      });
    });
  }

  // confirmation pop-up with two buttons
  confirmSuccesssWithTwoButton(title: string = 'Are you sure?', text: string = 'This can\'t be undone!',
    confirmButtonText: string = 'Delete', cancelButtonText: string = 'No, keep it') {
    return new Promise((resolve: any) => {
      Swal.fire({
        title: this.translate?.instant(title),
        text: this.translate?.instant(text),
        icon: 'success',
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonText: this.translate?.instant(confirmButtonText),
        cancelButtonText: this.translate?.instant(cancelButtonText),
        customClass: {
          confirmButton: 'au-btn au-btn--primary au-m--1',
          cancelButton: 'au-btn au-btn--secondary au-m--1'
        },
        buttonsStyling: false
      }).then((result: SweetAlertResult) => {
        if (result?.value) {
          resolve(true); return;

        } else if (result?.dismiss === Swal.DismissReason.cancel) {
          resolve(false); return;
        }
      });

    });
  }

  customConfirmSuccess(title: string = 'Are you sure?', text: string = 'This can\'t be undone!', customSettingsForCancel: AuditSuccessDraftCancelSettings = {
    cancelButtonText: '',
    customClassForCancel: '',
    cancelButtonRouterLink: '',
    enableCancelButtonRouterLink: false
  }, customSettingsForConfirm: AuditSuccessDraftConfirmSettings = {
    confirmButtonText: '',
    customClassForConfirm: '',
    confirmButtonRouterLink: '',
    enableConfirmButtonRouterLink: false
  }, innerHtml: any = '') {
    return new Promise((resolve: any) => {
      Swal.fire({
        title: this.translate?.instant(title),
        text: this.translate?.instant(text),
        icon: 'success',
        html: innerHtml !== '' ? innerHtml : '',
        showCancelButton: true,
        showConfirmButton: true,
        allowOutsideClick: false,
        confirmButtonText: customSettingsForConfirm?.enableConfirmButtonRouterLink ? `<a href="${customSettingsForConfirm?.confirmButtonRouterLink}" class="au-btn au-btn--primary au-m--1">${customSettingsForConfirm?.confirmButtonText}</a>` : this.translate?.instant(customSettingsForConfirm?.confirmButtonText),
        cancelButtonText: customSettingsForCancel?.enableCancelButtonRouterLink ? `<a href="${customSettingsForCancel?.cancelButtonRouterLink}" class="au-btn au-btn--secondary au-m--1">${customSettingsForCancel?.cancelButtonText}</a>` : this.translate?.instant(customSettingsForCancel?.cancelButtonText),
        customClass: {
          confirmButton: 'au-btn au-btn--primary au-m--1',
          denyButton: '',
          cancelButton: customSettingsForCancel?.customClassForCancel ? customSettingsForCancel?.customClassForCancel : ''
        },
        buttonsStyling: false,
        willOpen(popup: HTMLElement) {
          const confirmButton: any = popup.childNodes?.[2]?.childNodes?.[1];
          const cancelButton: any = popup.childNodes?.[2]?.childNodes?.[3];
          if (customSettingsForConfirm?.customClassForConfirm !== '') {
            // override sweet alert custom button display styles
            confirmButton.style.display = 'contents';
            confirmButton.className = customSettingsForConfirm?.customClassForConfirm;
          }
          if (customSettingsForCancel?.customClassForCancel !== '') {
            // override sweet alert custom button display styles
            cancelButton.style.display = 'contents';
            cancelButton.className = customSettingsForCancel?.customClassForCancel;
          }
        }
      }).then((result: SweetAlertResult) => {
        if (result?.value) {
          resolve(true); return;

        } else if (result?.dismiss === Swal.DismissReason.cancel) {
          resolve(false); return;
        }
      });

    });
  }

  confirmObject(object: {
    headText: string,
    subText: string,
    buttonOpt_1: string,
    buttonOpt_2: string
  }) {
    return new Promise((resolve: any) => {
      Swal.fire({
        title: this.translate.instant(object.headText),
        text: this.translate.instant(object.subText),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.translate.instant(object.buttonOpt_1),
        cancelButtonText: this.translate.instant(object.buttonOpt_2),
        customClass: {
          confirmButton: 'au-btn au-btn--primary au-m--1',
          cancelButton: 'au-btn au-btn--secondary au-m--1'
        },
        buttonsStyling: false
      }).then((result: SweetAlertResult) => {
        if (result.value) {
          resolve(true); return;

        } else if (result.dismiss === Swal.DismissReason.cancel) {
          resolve(false); return;
        }
      });
    });
  }

  /* confirm  popup for arx */
  confirmObjectArx(object: {
    headText: string,
    subText: string,
    buttonOpt_1: string,
    buttonOpt_2: string
  }) {

    /* data is variable */
    return new Promise((resolve: any) => {
      Swal.fire({
        title: this.translate.instant(object.headText),
        text: this.translate.instant(object.subText),
        icon: 'warning',
        iconHtml: '<svg width="4" height="22" viewBox="0 0 4 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 20.3333V11" stroke="var(--au-color-black)" stroke-width="2.17" stroke-linecap="round" stroke-linejoin="round"/><path d="M2 1.66669H2.02232" stroke="var(--au-color-black)" stroke-width="2.17" stroke-linecap="round" stroke-linejoin="round"/></svg>',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.translate.instant(object.buttonOpt_1),
        cancelButtonText: this.translate.instant(object.buttonOpt_2),
        customClass: {
          container: 'arx-swal-container',
          confirmButton: 'au-btn au-btn--primary au-m--1',
          cancelButton: 'au-btn au-btn--secondary au-m--1'
        },
        buttonsStyling: false
      }).then((result: SweetAlertResult) => {
        if (result.value) {
          resolve(true); return;

        } else if (result.dismiss === Swal.DismissReason.cancel) {
          resolve(false); return;
        }
      });
    });
  }

  customAlert(object: {
    headText: string,
    subText: string,
    buttonOpt_1: string,
    buttonOpt_2: string,
    showCancelButton: boolean,
    icon: any,
    automationButton_1?: string,
    automationButton_2?: string,
    allowOutsideClick?: boolean
  }, width: string = '400px', className: SweetAlertCustomClass = { confirmButton: 'au-btn au-btn--primary au-m--1', cancelButton: 'au-btn au-btn--secondary au-m--1' }, buttonsStyling: boolean = false, html: string = '') {
    return new Promise((resolve: (value: string | boolean) => void) => {
      Swal.fire({
        title: this.translate.instant(object.headText),
        text: this.translate.instant(object.subText),
        icon: object.icon,
        showCancelButton: object.showCancelButton,
        allowOutsideClick: !object?.allowOutsideClick ? object?.allowOutsideClick : true,
        confirmButtonText: this.translate.instant(object.buttonOpt_1),
        cancelButtonText: this.translate.instant(object.buttonOpt_2),
        width: width,
        html: html,
        customClass: className,
        buttonsStyling: buttonsStyling
      }).then((result: SweetAlertResult) => {
        if (result.value) {
          resolve(true); return;
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          resolve(false); return;
        }
      });
      const confirmButton = document.querySelector('.swal2-confirm') as HTMLElement;
      const cancelButton = document.querySelector('.swal2-cancel') as HTMLElement;
      if (confirmButton && object?.automationButton_1) {
        confirmButton?.setAttribute('name', object?.automationButton_1);
      }
      if (cancelButton && object.automationButton_2) {
        cancelButton?.setAttribute('name', object?.automationButton_2);
      }
    });
  }

  // discard create pop-up
  discard(module?: string, lowerCase: boolean = false,
    confirmButtonText: string = commonLanguage.keep_it, cancelButtonText: string = commonLanguage.discard, confirmButtonAutomation:string = '', cancelButtonAutomation:string = '', subText:string = '') {
    let x = '';
    if (lowerCase) {
      x = module?.toLowerCase();
    } else {
      x = module;
    }
    const headText = x ? `${this.translate.instant(commonLanguage.discard_new)} ${this.translate.instant(x)}?` : `${this.translate.instant(commonLanguage.discard)}`;
    if (subText === '') {
      subText =`${this.translate.instant(commonLanguage.discard_info)} ${this.translate.instant(x)}. `;
    }
    return new Promise((resolve: (value: string | boolean) => void) => {
      Swal.fire({
        title: this.translate.instant(headText),
        text: this.translate.instant(subText),
        showCancelButton: true,
        showCloseButton: true,
        allowOutsideClick: false,
        confirmButtonText: this.translate.instant(cancelButtonText),
        cancelButtonText: this.translate.instant(confirmButtonText),
        reverseButtons: true,
        customClass: {
          container: 'ax-swal ax-swal--warning',
          confirmButton: 'au-btn au-btn--primary au-m--1',
          cancelButton: 'au-btn au-btn--secondary au-m--1'
        },
        buttonsStyling: false,
        onOpen: () => {
          const confirmButton = Swal.getConfirmButton();
          if (confirmButton && confirmButtonAutomation !== '') {
            confirmButton?.setAttribute('name', confirmButtonAutomation);
          }
          const cancelButton = Swal.getCancelButton();
          if (cancelButton && cancelButtonAutomation !== '') {
            cancelButton?.setAttribute('name', cancelButtonAutomation);
          }
        }
      }).then((result: SweetAlertResult) => {
        if (result?.value) {
          resolve(true); return;

        } else if (result?.dismiss === Swal.DismissReason?.cancel) {
          resolve(false); return;
        }
      });

    });
  }

  // discard edit pop-up
  discardEdit(title: string = commonLanguage.discard_changes, text: string = commonLanguage.unsaved_changes,
    confirmButtonText: string = commonLanguage.keep_editing, cancelButtonText: string = commonLanguage.discard, confirmButtonAutomation:string = '', cancelButtonAutomation:string = '') {
    return new Promise((resolve: (value: string | boolean) => void) => {
      Swal.fire({
        title: this.translate.instant(title),
        text: this.translate.instant(text),
        showCancelButton: true,
        showCloseButton: true,
        allowOutsideClick: false,
        reverseButtons: true,
        confirmButtonText: this.translate.instant(cancelButtonText),
        cancelButtonText: this.translate.instant(confirmButtonText),
        customClass: {
          container: 'ax-swal ax-swal--warning',
          confirmButton: 'au-btn au-btn--primary au-m--1',
          cancelButton: 'au-btn au-btn--secondary au-m--1'
        },
        buttonsStyling: false,
        onOpen: () => {
          const confirmButton = Swal.getConfirmButton();
          if (confirmButton && confirmButtonAutomation !== '') {
            confirmButton?.setAttribute('name', confirmButtonAutomation);
          }
          const cancelButton = Swal.getCancelButton();
          if (cancelButton && cancelButtonAutomation !== '') {
            cancelButton?.setAttribute('name', cancelButtonAutomation);
          }
        }
      }).then((result: SweetAlertResult) => {
        if (result?.value) {
          resolve(true); return;

        } else if (result?.dismiss === Swal.DismissReason?.cancel) {
          resolve(false); return;
        }
      });

    });
  }

  // delete pop-up
  delete(module?: string, lowerCase: boolean = false,
    confirmButtonText: string = commonLanguage.delete, cancelButtonText: string = commonLanguage.cancel, confirmButtonAutomation:string = '', cancelButtonAutomation:string = '', headText: string = '', subText:string = '') {
    let x = '';
    if (lowerCase) {
      x = module?.toLowerCase();
    } else {
      x = module;
    }
    if (headText === '') {
      headText = x ? `${this.translate.instant(commonLanguage.delete)} ${this.translate.instant(x)}?` : `${this.translate.instant(commonLanguage.delete)}`;
    }
    if (subText === '') {
      subText =`${this.translate.instant(commonLanguage.info_lost)} ${this.translate.instant(x)}. `;
    }
    return new Promise((resolve: (value: string | boolean) => void) => {
      Swal.fire({
        title: this.translate.instant(headText),
        text: this.translate.instant(subText),
        showCancelButton: true,
        showCloseButton: true,
        allowOutsideClick: false,
        confirmButtonText: this.translate.instant(confirmButtonText),
        cancelButtonText: this.translate.instant(cancelButtonText),
        reverseButtons: true,
        customClass: {
          container: 'ax-swal ax-swal--warning ax-swal--inverse',
          confirmButton: 'au-btn au-btn--primary au-m--1',
          cancelButton: 'au-btn au-btn--secondary au-m--1'
        },
        buttonsStyling: false,
        onOpen: () => {
          const confirmButton = Swal.getConfirmButton();
          if (confirmButton && confirmButtonAutomation !== '') {
            confirmButton?.setAttribute('name', confirmButtonAutomation);
          }
          const cancelButton = Swal.getCancelButton();
          if (cancelButton && cancelButtonAutomation !== '') {
            cancelButton?.setAttribute('name', cancelButtonAutomation);
          }
        }
      }).then((result: SweetAlertResult) => {
        if (result?.value) {
          resolve(true); return;

        } else if (result?.dismiss === Swal.DismissReason?.cancel) {
          resolve(false); return;
        }
      });

    });
  }

  // remove pop-up
  remove(module?: string, lowerCase: boolean = false,
    confirmButtonText: string = commonLanguage.remove, cancelButtonText: string = commonLanguage.cancel, confirmButtonAutomation:string = '', cancelButtonAutomation:string = '', subText:string = '') {
    let x = '';
    if (lowerCase) {
      x = module?.toLowerCase();
    } else {
      x = module;
    }
    const headText = x ? `${this.translate.instant(commonLanguage.remove)} ${this.translate.instant(x)}?` : `${this.translate.instant(commonLanguage.remove)}`;
    if (subText === '') {
      subText =`${this.translate.instant(commonLanguage.to_remove)} ${this.translate.instant(x)}. `;

    }
    return new Promise((resolve: (value: string | boolean) => void) => {
      Swal.fire({
        title: this.translate.instant(headText),
        text: this.translate.instant(subText),
        showCancelButton: true,
        showCloseButton: true,
        allowOutsideClick: false,
        confirmButtonText: this.translate.instant(confirmButtonText),
        cancelButtonText: this.translate.instant(cancelButtonText),
        reverseButtons: true,
        customClass: {
          container: 'ax-swal ax-swal--warning',
          confirmButton: 'au-btn au-btn--primary au-m--1',
          cancelButton: 'au-btn au-btn--secondary au-m--1'
        },
        buttonsStyling: false,
        onOpen: () => {
          const confirmButton = Swal.getConfirmButton();
          if (confirmButton && confirmButtonAutomation !== '') {
            confirmButton?.setAttribute('name', confirmButtonAutomation);
          }
          const cancelButton = Swal.getCancelButton();
          if (cancelButton && cancelButtonAutomation !== '') {
            cancelButton?.setAttribute('name', cancelButtonAutomation);
          }
        }
      }).then((result: SweetAlertResult) => {
        if (result?.value) {
          resolve(true); return;

        } else if (result?.dismiss === Swal.DismissReason?.cancel) {
          resolve(false); return;
        }
      });

    });
  }

  // confirmation pop-up with two buttons for recommendation popup after observation submit
  confirmWithTwoButton(module?: string, lowerCase: boolean = false,
    confirmButtonText: string = commonLanguage.addRecmnd, cancelButtonText: string = commonLanguage.close, confirmButtonAutomation:string = '', cancelButtonAutomation:string = '', subText:string = '', headText:string= '') {
    let x = this.translate.instant(module);
    if (lowerCase) {
      x = module?.toLowerCase();
    }
    if (headText === '') {
      headText = x ? `${this.translate?.instant(commonLanguage.add)} ${this.translate?.instant(x)}?` : `${this.translate?.instant(commonLanguage.add)}`;
    }
    if (subText === '') {
      subText =`${this.translate.instant(commonLanguage.obs_msg)}. `;
    }
    return new Promise((resolve: (value: string | boolean) => void) => {
      Swal.fire({
        title: this.translate?.instant(headText),
        text: this.translate?.instant(subText),
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonText: this.translate?.instant(confirmButtonText),
        cancelButtonText: this.translate?.instant(cancelButtonText),
        reverseButtons: true,
        customClass: {
          container: 'ax-swal',
          confirmButton: 'au-btn au-btn--primary au-m--1',
          cancelButton: 'au-btn au-btn--secondary au-m--1'
        },
        buttonsStyling: false,
        onOpen: () => {
          const confirmButton = Swal.getConfirmButton();
          if (confirmButton && confirmButtonAutomation !== '') {
            confirmButton?.setAttribute('name', confirmButtonAutomation);
          }
          const cancelButton = Swal.getCancelButton();
          if (cancelButton && cancelButtonAutomation !== '') {
            cancelButton?.setAttribute('name', cancelButtonAutomation);
          }
        }
      }).then((result: SweetAlertResult) => {
        if (result?.value) {
          resolve(true); return;

        } else if (result?.dismiss === Swal.DismissReason.cancel) {
          resolve(false); return;
        }
      });

    });
  }

  // confirmation pop-up with two buttons
  confirmTwoButton(module?: string, lowerCase: boolean = false,
    confirmButtonText: string = commonLanguage.delete, cancelButtonText: string = commonLanguage.cancel, confirmButtonAutomation:string = '', confirmButtonTwoAutomation:string = '', headText: string = '', subText:string = '') {
    let x = '';
    if (lowerCase) {
      x = module?.toLowerCase();
    } else {
      x = module;
    }
    if (headText === '') {
      headText = x ? `${this.translate.instant(commonLanguage.delete)} ${this.translate.instant(x)}?` : `${this.translate.instant(commonLanguage.delete)}`;
    }
    if (subText === '') {
      subText =`${this.translate.instant(commonLanguage.info_lost)} ${this.translate.instant(x)}. `;
    }
    return new Promise((resolve: (value: string | boolean) => void) => {
      Swal.fire({
        title: this.translate.instant(headText),
        text: this.translate.instant(subText),
        showCancelButton: true,
        showCloseButton: true,
        allowOutsideClick: false,
        confirmButtonText: this.translate.instant(confirmButtonText),
        cancelButtonText: this.translate.instant(cancelButtonText),
        reverseButtons: true,
        customClass: {
          container: 'ax-swal ax-swal--warning',
          confirmButton: 'au-btn au-btn--primary au-m--1',
          cancelButton: 'au-btn au-btn--secondary au-m--1'
        },
        buttonsStyling: false,
        onOpen: () => {
          const confirmButton = Swal.getConfirmButton();
          if (confirmButton && confirmButtonAutomation !== '') {
            confirmButton?.setAttribute('name', confirmButtonAutomation);
          }
          const cancelButton = Swal.getCancelButton();
          if (cancelButton && confirmButtonTwoAutomation !== '') {
            cancelButton?.setAttribute('name', confirmButtonTwoAutomation);
          }
        }
      }).then((result: SweetAlertResult) => {
        if (result?.value) {
          resolve('notSure'); return;

        } else if (result?.dismiss === Swal.DismissReason?.cancel) {
          resolve(true); return;
        }
      });

    });
  }

  // popup for records with new dependencies
  deleteDependency(module?: string, lowerCase: boolean = false, content?:Record<string, string|number>[],
    confirmButtonText: string = commonLanguage.delete, cancelButtonText: string = commonLanguage.cancel, confirmButtonAutomation:string = '', cancelButtonAutomation:string = '', subText:string = '') {
    let x = this.translate?.instant(module);
    if (lowerCase) {
      x = x?.toLowerCase();
    }
    const headText = x ? `${(this.envAccess || this.miahonaAccess) && (riskArabicTranslation?.Risk_Universe === module)? this.translate.instant(commonLanguage?.submitForApproval):this.translate.instant(commonLanguage.delete)} ${(this.envAccess || this.miahonaAccess) && (riskArabicTranslation?.Risk_Universe === module) ? '' :x}?` : this.translate.instant(commonLanguage.delete);
    if (subText === '') {
      let listItems = '';
      if (content) {
        content?.forEach((item:{count:number, name:string}) => {
          listItems += `<li  style="color: #6a6a6a; line-height:14px;">${item?.count} ${this.translate.instant(item?.name)}</li>`;
        });
      }
      subText =`<p class="ax-swal-subtext"> ${(this.envAccess || this.miahonaAccess) && (riskArabicTranslation?.Risk_Universe === module)? this.translate.instant(commonLanguage?.submitApprovalLockConfirmDeleteDependancy):this.translate.instant(commonLanguage.deleteDependency)} ${x}:</p>
      <ul class="ax-swal-list" style="list-style-type:disc;">
        ${listItems}
      </ul>`;
    }
    return new Promise((resolve: (value: string | boolean) => void) => {
      Swal.fire({
        title: headText,
        html: subText,
        showCancelButton: true,
        showCloseButton: true,
        allowOutsideClick: false,
        confirmButtonText: this.translate?.instant((this.envAccess || this.miahonaAccess) && (riskArabicTranslation?.Risk_Universe === module)? commonLanguage?.submitForApproval:confirmButtonText),
        cancelButtonText: this.translate.instant(cancelButtonText),
        reverseButtons: true,
        customClass: {
          container: 'ax-swal ax-swal--warning ax-swal--inverse',
          confirmButton: 'au-btn au-btn--primary au-m--1',
          cancelButton: 'au-btn au-btn--secondary au-m--1'
        },
        buttonsStyling: false,
        onOpen: () => {
          const confirmButton = Swal.getConfirmButton();
          if (confirmButton && confirmButtonAutomation !== '') {
            confirmButton?.setAttribute('name', confirmButtonAutomation);
          }
          const cancelButton = Swal.getCancelButton();
          if (cancelButton && cancelButtonAutomation !== '') {
            cancelButton?.setAttribute('name', cancelButtonAutomation);
          }
        }
      }).then((result: SweetAlertResult) => {
        if (result?.value) {
          resolve(true);
          return;

        } else if (result?.dismiss === Swal.DismissReason?.cancel) {
          resolve(false);
          return;
        }
      });
    });
  }

  close() {
    Swal.close();
  }

  // Exit-tool confirmation popup
  exitConfirmation(headText: string = '', subText: string = '', cancelButtonText: string, exitButtonText: string, confirmButtonText: string,
    confirmButtonAutomation: string = '', cancelButtonAutomation: string = '') {
    return new Promise((resolve: (value: string | boolean) => void) => {
      Swal.fire({
        title: this.translate?.instant(headText),
        text: this.translate?.instant(subText),
        showCancelButton: true,
        showCloseButton: true,
        showDenyButton: true,
        allowOutsideClick: false,
        confirmButtonText: this.translate?.instant(confirmButtonText),
        cancelButtonText: this.translate?.instant(cancelButtonText),
        denyButtonText: this.translate?.instant(exitButtonText),
        reverseButtons: true,
        customClass: {
          container: 'ax-swal ax-swal--inverse',
          cancelButton: 'au-btn au-btn--secondary au-m--1',
          confirmButton: 'au-btn au-btn--primary au-m--1',
          denyButton: 'au-btn au-btn--warning-red au-ml--1'
        },
        buttonsStyling: false,
        onOpen: () => {
          const confirmButton = Swal?.getConfirmButton();
          if (confirmButton && confirmButtonAutomation !== '') {
            confirmButton?.setAttribute('name', confirmButtonAutomation);
          }
          const cancelButton = Swal?.getCancelButton();
          if (cancelButton && cancelButtonAutomation !== '') {
            cancelButton?.setAttribute('name', cancelButtonAutomation);
          }
        }
      }).then((result: SweetAlertResult) => {
        if (result?.value) {
          resolve(true); return;
        } else if (result?.isDenied) {
          resolve(false); return;
        } else if (result?.isDismissed) {
          resolve('Cancel'); return;
        }
      });

    });
  }

  // Cancel pop-up
  cancel(module?: string, lowerCase: boolean = false,
    confirmButtonText: string = commonLanguage.cancel, cancelButtonText: string = commonLanguage.keep_it, confirmButtonAutomation:string = '', cancelButtonAutomation:string = '', headText: string = '', subText:string = '') {
    let x = '';
    if (lowerCase) {
      x = module?.toLowerCase();
    } else {
      x = module;
    }
    if (headText === '') {
      headText = x ? `${this.translate.instant(commonLanguage.cancel)} ${this.translate.instant(x)}?` : `${this.translate.instant(commonLanguage.cancel)}`;
    }
    if (subText === '') {
      subText =`${this.translate.instant(commonLanguage.meeting_lost)} ${this.translate.instant(x)}. `;
    }
    return new Promise((resolve: (value: string | boolean) => void) => {
      Swal.fire({
        title: this.translate.instant(headText),
        text: this.translate.instant(subText),
        showCancelButton: true,
        showCloseButton: true,
        allowOutsideClick: false,
        confirmButtonText: this.translate.instant(confirmButtonText),
        cancelButtonText: this.translate.instant(cancelButtonText),
        reverseButtons: true,
        customClass: {
          container: 'ax-swal ax-swal--warning ax-swal--inverse',
          confirmButton: 'au-btn au-btn--primary au-m--1',
          cancelButton: 'au-btn au-btn--secondary au-m--1'
        },
        buttonsStyling: false,
        onOpen: () => {
          const confirmButton = Swal.getConfirmButton();
          if (confirmButton && confirmButtonAutomation !== '') {
            confirmButton?.setAttribute('name', confirmButtonAutomation);
          }
          const cancelButton = Swal.getCancelButton();
          if (cancelButton && cancelButtonAutomation !== '') {
            cancelButton?.setAttribute('name', cancelButtonAutomation);
          }
        }
      }).then((result: SweetAlertResult) => {
        if (result?.value) {
          resolve(true); return;

        } else if (result?.dismiss === Swal.DismissReason?.cancel) {
          resolve(false); return;
        }
      });
    });
  }
}
