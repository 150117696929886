import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root'
})
export class MfaValidateService {
  public mfaAuth:boolean = false;
  constructor(    private _cookie: CookieService) { }
  public setRedirection(data:  boolean) {
    if (environment.production && environment.protocol === 'https://') {
      document.cookie = `redirect=${data};path=/;Domain=.${environment.cookieDomain};secure`;
    } else if (environment.production && environment.protocol === 'http://') {
      document.cookie = `redirect=${data};path=/;Domain=.${environment.cookieDomain}`;
    } else {
      document.cookie =  `redirect=${data};path=/;`;
    }
  }

  public removeRedirection() {
    document.cookie = 'redirect=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  public get getRedirectionStatus(): any {
    if (this._cookie.get('redirect')) {
      return   this._cookie.get('redirect');
    }
    return '';
  }
}
