export const commonLanguage = {
  create: 'Create',
  duplicate: 'Duplicate',
  viewRecord: 'View Any Record',
  viewOwnRecord: 'View Own Record',
  edit: 'Edit',
  action: 'Action',
  createAssessment: 'Create Assessment',
  add: 'Add',
  yes: 'Yes',
  delete: 'Delete',
  overview: 'Overview',
  save_changes: 'Save Changes',
  csv: 'csv',
  xls: 'xls',
  categories: 'Categories',
  category: 'Category',
  assessment: 'Assessment',
  qName: 'Question Name',
  next: 'Next',
  previous: 'Previous',
  cancel: 'Cancel',
  headText: 'Successful',
  subText: 'Reminder has been scheduled',
  apply: 'Apply',
  proceed: 'Proceed',
  close: 'Close',
  finish: 'Finish',
  status: 'Status',
  rejected: 'Rejected',
  moreAbtRisk: 'More About Risks',
  rejected_by: 'Rejected by',
  approved_by: 'Approved by',
  withdrawn_by: 'Withdrawn by',
  approval: 'Approval',
  actions: 'Actions',
  field_req: 'This field is required',
  reviewer: 'Reviewer',
  license_configure: 'License Configure',
  approvers: 'Approvers',
  for: 'For',
  reviewers: 'Reviewers',
  reviews: 'Review',
  add_observ: 'Add Observation',
  create_activity: 'Create Activity',
  edit_activity: 'Edit Activity',
  activity_log: 'Activity Log',
  resolved: 'Resolved',
  unresolved: 'Unresolved',
  relatedDependencies: 'Related dependencies',
  upload_doc: 'Upload Documents',
  issues: 'Issues',
  export: 'Export',
  expense: 'Expense',
  expense_management: 'Expense Management',
  choose_format: 'Choose the file format you want to download.',
  download: 'Download',
  exp: 'Export',
  draft: 'Drafts',
  save: 'Save',
  no: 'No',
  delete_item: 'Delete item?',
  this_will_permanently_delete_the_item: 'This will permanently delete the item.',
  ownership: 'Ownership',
  documents: 'Documents',
  attachment: 'Attachment',
  browse: 'Browse',
  schedule: 'Schedule',
  dependencies: 'Dependencies',
  save_as_draft: 'Save as Draft',
  req: 'Requests',
  addItem: 'Add Item',
  loadingText: 'Loading...',
  notFoundText: 'No items found',
  no_data_avail: 'No data available',
  doDataProcessing: 'Process data to generate auto insights',
  createdBy: 'Created by',
  modifyBy: 'Modified by',
  publishedBy: 'Published by',
  createDate: 'Created Date',
  modifyDate: 'Modified Date',
  to: 'To',
  cC: 'Cc',
  attachFile: 'Attach Files',
  sndNotf: 'Send Notification',
  recentMail: 'Recent Emails',
  sndMail: 'send an email',
  emailSub: 'Email Subject',
  emailCont: 'Email Content',
  noMail: 'No Emails',
  competion: 'Completion',
  more_info: 'More Information',
  configMail: 'Configure Email',
  fieldReq: 'This field is required',
  validEmail: 'Enter Valid Email Address',
  validTime: 'Enter Valid Time',
  maxField: 'Maximum field length exceeded',
  minField: 'Minimum field length required',
  choose: 'Choose the file format you want to download.',
  csvD: 'Download CSV',
  xlsD: 'Download XLS',
  do_you_want_to_create_request: 'Do you want to create a request now?',
  workflow: 'Workflow',
  drag_your_files_here: 'Drag your files here',
  selectDateField: 'select a date field',
  selectFieldToUpdate: 'Select the fields to update',
  recurringType: 'Recurring Type',
  chooseAnOption: 'Choose An Option',
  no_of_days: 'Number of days',
  dateField: 'Date Field',
  drag_your_file_here: 'Drag your file here',
  new_value_for: 'New Value For',
  editValueFor: 'Edit Value For',
  cannot_start_with_whitespace: 'Input cannot start with special character or white space',
  please_select_date: 'Please select the correct Start and End Date',
  check_all_field_entered: 'Please check all fields are entered',
  totalNo: 'Total number',
  ofAssmnt: 'of Assessments',
  remove: 'Remove',
  addnInfo: 'Additional Information',
  updateDate: 'Updated date',
  updateBy: 'Updated by',
  addAction: 'Add action',
  organizationSettings: 'Organization Settings',
  editAnAction: 'Edit an Action',
  createAction: 'Create Action',
  createActionBia: 'Create action',
  addAnAction: 'Add an Action',
  assesmentDet: 'Assessment Details',
  uploadDoc: 'Upload Documents',
  assments: 'Assessments',
  assesmntTemp: 'Add Assessments Template',
  recmnd: 'Recommendation',
  currentStatus: 'Current Status',
  fetchControl: 'Fetch All Controls',
  allSubprocesses: 'All Subprocesses',
  fetchRisk: 'Fetch All Risks',
  editRemnd: 'Edit Recommendation',
  update: 'Update',
  riskId: 'Risk ID',
  riskName: 'Risk Name',
  allRisks: 'All Risks',
  allcntrl: 'All Controls',
  allpolicy: 'All Policies',
  alldocs: 'All Documents',
  allActions: 'All Actions',
  allRecmnd: 'All Recommendations',
  clone: 'Clone',
  max_length_field: 'The maximum length of this field is 500 characters',
  max_length_seven_hundred: 'The maximum length of this field is 700 characters',
  maxField_thirty: 'The maximum length of this field is 30 characters',
  maxField_ten: 'The maximum length of this field is 10 characters',
  maxField_hundred: 'The maximum length of this field is 100 characters',
  Description: 'Description',
  drafts: 'Drafts',
  team: 'Team',
  duration: 'Duration',
  working_paper: 'Working Paper',
  not_applicable: 'Not Applicable',
  submit: 'Submit',
  and: 'and',
  andTitle: 'And',
  import: 'Import',
  importFromLibrary: 'Import from library',
  send_for_approval: 'send approval',
  createBp: 'Create Business Process',
  bia: 'Business Impact Analysis',
  workaround: 'Workaround',
  impactAsst: 'Impact Assessment',
  prsOveview: 'Process Overview',
  back: 'Back',
  upload: 'Upload',
  docUpload: 'Document Uploader',
  docTitle: 'Document Title',
  desc: 'DESCRIPTION',
  noWhiteSpace: 'Blank or white spaces not allowed',
  or: 'or',
  supportedDoc: 'Supported Documents',
  upstream: 'Upstream Dependencies',
  downStream: 'Downstream Dependencies',
  assets: 'Assets',
  policy: 'Policy',
  sndApproval: 'Send for Approval',
  prs: 'PROCESS  ',
  incidents: 'Incidents',
  changeView: 'Change View',
  allAssets: 'All Assets',
  createAssets: 'Create Asset',
  type: 'TYPE',
  criticality: 'Criticality',
  addAsset: 'Add Asset',
  editAsset: 'Edit Asset',
  owner: 'Owner',
  name: 'Name',
  move: 'Move',
  processBy: 'Process by',
  allProcesses: 'All Processes',
  saveDraftExit: 'Save as Draft and Exit',
  saveExit: 'Save & Exit',
  publish: 'Publish',
  generatePdf: 'Generate PDF',
  viewDraft: 'View All Drafts',
  noData: 'No Data',
  filter: 'Filter',
  exportAutomaticallyDownload: 'When the export is complete, it will be automatically downloaded. Additionally, a copy of the export will be sent to your inbox for your convenience.',
  fields: 'Fields',
  dropdown: 'Dropdown',
  build: 'Build',
  setting: 'Settings',
  tooltip: 'Tooltip',
  legend: 'Legend',
  folder: 'Folder',
  color: 'Color',
  noSplCharAllowed: 'No special characters are allowed in the input',
  open: 'Open',
  closed: 'Closed',
  totalRecords: 'Total records',
  modelInsights: 'Model Insights',
  totalCount: 'Total Count',
  history: 'History',
  mr: 'MR',
  management_response: 'Management Response',
  mr_log: 'Observation MR Log',
  is: 'is',
  are: 'are',
  selected: 'selected',
  showing: 'Showing',
  outOf: 'out of',
  record: 'Record',
  records: 'Records',
  allItemsSelected: 'All items are selected',
  selectedData: 'Selected Data',
  bulkRecordEditor: 'Bulk Record Editor',
  bulkEditDescription: 'Edit multiple records simultaneously. Select the fields to edit and apply changes to all the selected records instantly.',
  uploadDocument: 'Upload Document',
  sendReminder: 'Send Reminder',
  publishedDate: 'Published Date',
  auditorsComment: 'Auditors Comment',
  removeAndReplace: 'Remove and replace existing values',
  kriKpi: 'KRI/KPI',
  entity: 'Entity',
  auditee: 'Auditee',
  stakeholder: 'Stakeholder',
  editProcess: 'Edit Process',
  subProcess: 'Sub-Process',
  sentForMR: 'Sent for MR',
  addProcess: 'Add Process',
  percentage: 'Percentage',
  operator: 'Operator',
  low: 'Low',
  reset: 'Reset',
  high: 'High',
  effective: 'Effective',
  notClassified: 'Not Classified',
  userName: 'Username',
  password: 'Password',
  port: 'Port',
  analytics: 'Analytics',
  usersPassRqt: 'The user\'s password. Will be requested later if its not set',
  setupNewConnection: 'Setup a new connection',
  connections: 'Connections',
  mysqlConnection: 'MySQL Connections',
  oracleConnection: 'Oracle Connections',
  microsoftSqlConnection: 'Microsoft SQL Server',
  daily: 'Daily',
  weekly: 'Weekly',
  monthly: 'Monthly',
  time: 'Time',
  date: 'Date',
  utilisation: 'Utilisation',
  day: 'Day',
  withinBudget: 'Within Budget',
  overBudget: 'Over Budget',
  saveSettings: 'Save Settings',
  scheduleLoad: 'Schedule and load',
  pleaseSetTime: 'Please set the time',
  selectDay: 'Select Day',
  selectDate: 'Select Date',
  calculatedField: 'Calculated Field',
  pointOfFailur: 'Is this a single point of failure?',
  assetType: 'Asset Type',
  doesAsset: 'Does this asset has an alternate process level workaround?',
  assetTitle: 'Asset Title',
  noDefaultAct: 'No default Action.',
  recoveryStrategy: 'Recovery Strategy',
  scenarioAndSummary: 'Scenario and Event Summary',
  MaxFieldLen: 'The maximum length of the field exceeds',
  process: 'Process',
  exportToaster: 'The export has been downloaded',
  imageToaster: 'The image has been downloaded',
  hideNonConfgObj: 'Hide non-configurable objects',
  editor: 'Editor',
  createProject: 'Create Project',
  search: 'Search',
  procurement: 'Procurement',
  financialSummary: 'Financial Summary',
  payroll: 'Payroll',
  profitAndLoss: 'Profit & Loss',
  balanceStatement: 'Balance Statement',
  accReceivables: 'Account Receivables',
  procurementSummary: 'Procurement Summary',
  poSummary: 'PO Summary',
  finance: 'Finance',
  uploading: 'Uploading',
  viewAll: 'View All',
  preview: 'Preview',
  exitPreview: 'Exit Preview',
  edit_issue: 'Edit Issue',
  create_act: 'Create an Action',
  create_issue: 'Create an Issue',
  advanced: 'Advanced',
  already_exist: 'This field is already exist',
  needs_to_be_saved: 'This field need to be saved',
  maximum_characters: 'The maximum length of this field is 70 characters',
  choose_temp: 'CHOOSE TEMPLATE',
  temp: 'TEMPLATE',
  default: 'Default',
  selectRecipientFields: 'Select recipient fields',
  addMoreRecipients: 'Add more recipients',
  reminderType: 'Reminder Type',
  no_temp: 'No Template Selected',
  end_date_after_start: 'The end date must be after the start date.',
  max_length: 'The maximum length of this field is 255',
  add_activity_assignees: 'Add activity assignees to the list of auditors',
  empty_temp: 'Select Template is empty.',
  no_default_temp: 'No Default Template Please Choose Template.',
  both: 'Both',
  risks_by: 'Risks by',
  Add_Risk: 'Add Risk',
  Change_View: 'Change View',
  Top_to_Bottom: 'Top to Bottom',
  Left_to_Right: 'Left to Right',
  markDefault: 'Mark as Default',
  scoring: 'Scoring',
  summary: 'Summary',
  topRisk: 'Top Risks',
  sendForApproval: 'Send for Approval',
  refresh: 'Refresh',
  share: 'share',
  asTheCompliancePlanIsCurrentlyPendingApprovalWith: 'As the Compliance plan is currently pending approval with',
  itCannotBeModifiedAtThisTime: 'it cannot be modified at this time',
  risk_rating: 'Risk Rating',
  complianceScore: 'Compliance Score',
  risk_outcome: 'Risk Outcome',
  inherent: 'Inherent',
  residual: 'Residual',
  inherent_likelihood: 'Inherent Likelihood',
  residual_likelihood: 'Residual Likelihood',
  average_likelihood: 'Average Likelihood',
  average_impact: 'Average Impact',
  control_by_rating: 'Controls by Rating',
  inherent_impact: 'Inherent Impact',
  residual_impact: 'Residual Impact',
  Add_Field: 'Add Field',
  field_exist: 'This field is already exist',
  maximum_length_characters: 'The maximum length of this field is 70 characters',
  Overall_Conclusion: 'Overall Conclusion',
  choose_template: 'Choose TEMPLATE',
  template: 'TEMPLATE',
  end_after_start: 'The end date must be after the start date.',
  maximum_length_255: 'The maximum length of this field is 255',
  activity_assignees_auditors: 'Add activity assignees to the list of auditors',
  template_empty: 'Select Template is empty.',
  default_template: 'No default Template Please Choose Template.',
  risk_control_program: 'The Risks/Controls you have selected are already part of other risk Programs. Please confirm if you want to proceed.',
  observations: 'Observations',
  relObs: 'Related Observations',
  recommendations: 'Recommendations',
  risks: 'Risks',
  TO: 'TO',
  CC: 'CC',
  Overall_Completion: 'Overall Completion',
  No_Schedule_Available: 'No Schedule Available',
  Add_Working_Paper: 'Add a Working Paper',
  activities: 'Activities',
  Observed_by: 'Observed by',
  Upload_File: 'Upload File',
  Assigned_date: 'Assigned date',
  add_eecommendation: 'Add Recommendation',
  time_entries: 'Time Entries',
  Owner: 'Owner',
  Edit_Action: 'Edit Action',
  Delete_Action: 'Delete Action',
  Add_Controls: 'Add Controls',
  Edit_Controls: 'Edit Controls',
  Action_Overview: 'Action Overview',
  issue: 'Issue',
  Working_Paper_Overview: 'Working Paper Overview',
  Working_Paper_ID: 'Working Paper ID',
  Stakeholders: 'Stakeholders',
  Issue_Overview: 'Issue Overview',
  Recommendation_Overview: 'Recommendation Overview',
  Issue_ID: 'Issue ID',
  language: 'Language',
  Issue: 'Issue',
  Values: 'Values',
  objects: 'Objects',
  order: 'Order',
  Type_here: 'Type here...',
  Reset: 'Reset',
  Measures: 'Measures',
  range: 'Range',
  value: 'Value',
  Tolerance: 'Tolerance',
  Hide_objects: 'Hide non-configurable objects',
  activity: 'Activity',
  Add_Range: 'Add Range',
  reminder: 'reminders',
  export_prcocessed: 'The export is currently being processed',
  export_completed: 'The export has been downloaded',
  export_loading: 'When the export is complete, it will be automatically downloaded. Additionally, a copy of the export will be sent to your inbox for your convenience.',
  project: 'Project',
  leave: 'Leave',
  docs: 'Document',
  noDataAvailable: 'No Data Available',
  thisFielfRequired: 'This field is required',
  uploadData: 'Upload Data',
  uploadedDocumentPreview: 'Uploaded Document Preview',
  recmnds: 'Recommendations',
  rec_Id: 'Recommendation Id',
  itemAttention: 'There are items that require your attention',
  review: 'Reviews',
  createPolicy: 'Create Policy',
  expProceed: 'The export is currently being processed',
  expCmpleteDowndload: 'When the export is complete, it will be automatically downloaded. Additionally, a copy of the export will be sent to your inbox for your convenience.',
  expDwnld: 'The export has been downloaded',
  createReq: 'Create a request',
  reqNow: 'Do you want to create a request now?',
  policyApproveReq: 'Is there an approved policy request?',
  reqst: 'Request',
  ctrl: 'Control',
  rsk: 'Risk',
  tagPolicy: 'Tag Policy',
  getReview: 'Get Review',
  publishedUrl: 'Click here to access the published URL',
  reviewFrm: 'Review From',
  confirm: 'Confirm',
  addCmnt: 'Add comment if any',
  observation: 'Observation',
  rAndC: 'Risks and Controls',
  obsDate: 'Observed Date',
  obsBy: 'OBSERVED BY',
  uploadFile: 'Upload File',
  addRecmnd: 'Add Recommendation',
  assignStakeholder: 'Assign a Stakeholder',
  selctObs: 'Select Observations',
  submitResp: 'Submit for response',
  proceedMng: 'Proceed to Request Management Response?',
  yesProceed: 'Yes, proceed',
  noProceed: 'No, do not proceed',
  grpBy: 'Group by',
  rem: 'Reminders',
  reports: 'Reports',
  signoff: 'Sign Off',
  genRep: 'Generate Report',
  docFormat: 'Select a document format',
  selctReport: 'Select Reports',
  selctRating: 'Select Rating',
  selctPriority: 'Select Priority',
  reportDoc: 'Upload Report Document',
  supportedReport: 'Supported Report Documents',
  generate: 'Generate',
  noWorkflow: 'No Workflow Available',
  supportedDocImg: 'Supported Documents: \'.jpg\', \'.png\', \'.jpeg\'',
  lang: 'Language',
  createTemp: 'Create Template',
  sort: 'Sort',
  report: 'Report',
  createRep: 'Create Report',
  createRoles: 'Create Roles and Responsibilities ',
  createAudit: 'Create Audit',
  values: 'Values',
  riskAndCtrl: 'Risk and Control',
  riskMetric: 'Risk Control Metrics',
  lastPage: 'Last Page',
  intro: 'Introduction',
  coverPage: 'Cover Page',
  hideObj: 'Hide non-configurable objects',
  drag_and_drop: 'Drag and Drop or',
  browse_files: 'Browse files',
  browse_file: 'Browse file',
  add_issue: 'Add Issue',
  add_assessments: 'Add Assessments',
  change_assignee: 'Change Assignee',
  new_assignee: 'New Assignee',
  res_date: 'Responded Date',
  res_by: 'Responded by',
  due_date: 'Due Date',
  disable_taking_assessment: 'Disable taking of the assessment post due date',
  add_incidents: 'Add Incidents',
  timeline: 'Timeline',
  comments: 'Comments',
  score: 'Score',
  gridtemplate: 'Grid Templates',
  scheduletemplate: 'Schedule Templates',
  gridTemplateEditor: 'Grid Template Editor',
  gridTemplateFields: 'Select Fields for Template',
  gridTemplateDisplayMessage: 'Choose Field Grid',
  details: 'Details',
  auditorComments: 'Auditor\'\s Comments',
  selectAll: 'Select All',
  filter_year: 'Filter by Year',
  req_review: 'Does it require review?',
  disable_due_date: 'Disable taking of the assessment post due date',
  question: 'Question',
  create_improve: 'Create Improvement',
  createIssue: 'Create Issue',
  edit_improve: 'Edit Improvement',
  lockDep: 'Lock Dependency',
  remDep: 'Remove Dependency',
  timeFrame: 'Timeframe',
  editTimeFrame: 'Edit Timeframes',
  browseRecmnd: 'Browse Recommendation',
  rejectedOn: 'Rejected On',
  approvedOn: 'Approved On',
  overallCompletion: 'Overall Completion',
  noScheduleAvail: 'No Schedule Available',
  all: 'All',
  assigned: 'Assigned',
  frequency: 'Frequency',
  on: 'on',
  of: 'of',
  configure: 'Configure',
  please_add_proper_value: 'Please add proper value',
  rating: 'Rating',
  the: 'The',
  threshold: 'Threshold',
  select_item: 'Select item',
  add_option: 'Add Option',
  controls: 'Controls',
  business_process: 'Business Process',
  Reminder: 'Reminder',
  risk: 'Risk',
  control: 'Control',
  risk_appetite: 'Risk Appetite',
  kri: 'KRI',
  add_business_process: 'Add Business Process',
  create_seperate_mr: 'Create separate management responses for each user selected.',
  request_mr: 'Request for Management Response',
  improvements: 'Improvements',
  projectLeave: 'Project/Leave',
  requestedBy: 'Requested by',
  allObs: 'All Observations',
  addTemplate: 'Add Template',
  managementRes: 'Management Response',
  impact: 'Impact',
  likelihood: 'Likelihood',
  overAllRating: 'Overall Rating',
  add_rqst: 'Add Request',
  allResReq: 'All Response Requests',
  allPendingReq: 'All Pending Requests',
  allClosedReq: 'All Closed Requests',
  entities: 'Entities',
  rec: 'records',
  mrLockBadge: 'The management response is locked as the status is',
  mrLockBadgeUpdate: 'You can still update the',
  mrBadgeField: 'MR Badge Field',
  createSepMr: 'Create separate management responses for each user selected.',
  update_entity: 'You Are About to Update the Entity.',
  undo: 'Undo',
  are_you_sure: 'Are you sure?',
  no_cancel: 'No, cancel',
  object_editor: 'Object Editor',
  goal: 'Goal',
  metrics: 'Metrics',
  max_weightage: 'Max',
  lock: 'Lock',
  unlock: 'Unlock',
  unlocksubtext: 'This will unlock the user and allow access to the account',
  max_value_Weightage: 'The maximum value for this field is 1000000000',
  column_name_table: 'Columns must have unique name',
  read_more: 'Read more',
  read_less: 'Read less',
  exit: 'Exit',
  weightage: 'Weightage',
  newValue: 'New Value',
  field: 'fields',
  hexCode: 'Hex Code e.g. \'#000 or #000000\'',
  exportDownloaded: 'The export has been downloaded.',
  exportDownloadNotification: 'You can also download it from the notifications.',
  controlRatingMessage: 'You can edit the label here. Please make the necessary configurations in the dynamic rating editor.',
  completed: 'Completed',
  in_progress: 'In Progress',
  not_commenced: 'Not Commenced',
  change_status: 'Change Status',
  set_current_stage: 'Set as Current Stage',
  yes_confirm: 'Yes, confirm',
  continue_editing: 'Continue editing',
  proceed_to_assessment: 'Proceed to Assessment?',
  save_draft: 'Save as Draft?',
  please_confirm: 'Please confirm',
  no_dont_delete: 'No, don’t delete',
  yes_delete_forever: 'Yes, delete forever',
  yes_edit: 'Yes, Edit',
  cancel_changes: 'Cancel changes',
  to_go_ahead: 'Are you sure you want to go ahead?',
  yes_create: 'Yes, create',
  undo_this_action: 'Are you sure? You cannot undo this action.',
  yes_remove: 'Yes, remove',
  draft_is_saved: 'Your Draft is Saved',
  continue: 'Continue',
  want_to_proceed: 'Do You Want to Proceed?',
  yes_update: 'Yes, Update',
  no_dont_update: 'No, Do not update',
  ok: 'Ok',
  print: 'Print',
  uploadedDocuments: 'Uploaded Documents',
  downloadTemplate: 'Download Template',
  NotificationSend: 'Send an email',
  addInput: 'Add Input Field',
  stage: 'Stage',
  stages: 'Stages',
  choose_a_date: 'Choose a date',
  user: 'user',
  fiscalyear: 'Fiscal year',
  users: 'users',
  cp_users: 'Users',
  start_date: 'Start Date',
  end_date: 'End Date',
  choose_start_date: 'Choose the start date',
  choose_end_date: 'Choose the end date',
  not_same_date: 'The start and end dates cannot be the same',
  max_length_of: 'The maximum length of',
  field_is_1000: 'field is 1000',
  clear: 'Clear',
  isNow: 'is now',
  audit_outcome: 'Audit Outcome',
  audit_planning: 'Audit Planning',
  audit_rating: 'Audit Rating',
  audit_name: 'Audit Name',
  control_id: 'Control ID',
  control_name: 'Control Name',
  control_type: 'Control Type',
  control_rating: 'Control Rating',
  created_by: 'Created By',
  control_manager: 'Risk Manager',
  risk_movmt: 'Risk Movement',
  risk_reported_year: 'Risk by Reported Year',
  risk_by_category: 'Risk by Category',
  risk_by: 'Risk by',
  risk_type: 'Type',
  risk_control: 'Control Effectiveness',
  risk_strategy: 'Business Strategy ',
  risk_sector: 'Risk by Sector',
  risk_status: 'Risk by Status',
  risk_entity: 'Risk by Entity',
  of_three: 'of 3',
  risk_mitigation: 'Risk Mitigation Timeline ',
  isValid: 'Entry date valid from',
  approvalStatus: 'Approval status',
  approvalOn: 'Approval on',
  approvalBy: 'Approval by',
  Sheduled_date: 'Sheduled the date',
  annexureDetails: 'Annexure Details',
  procedureDetails: 'Procedure Details',
  conclusionDetails: 'Conclusion Details',
  documentName: 'Document Name',
  docDetails: 'Document Details',
  advancedFilter: 'Advanced Filter',
  hideHeaders: 'Hide Headers',
  where: 'Where',
  selectColumn: 'Select Column',
  selectCondition: 'Select Condition',
  clearAll: 'Clear All',
  Show_non_config_objects: 'Show non-configurable objects',
  send_for_signoff: 'Send for Sign off',
  send_as_email: 'Send as email',
  report_details: 'Report Details',
  document_details: 'Document Details',
  home: 'Home',
  format: 'Format',
  generated_date: 'Generated Date',
  generated_by: 'Generated By',
  last_modified_date: 'Last modified date',
  last_modified_by: 'Last modified by',
  upload_report: 'Upload Report',
  id: 'ID',
  title: 'Title',
  attachments: 'Attachments',
  assign_limited_time: 'Assign for a limited time period',
  undo_action: 'Are you sure? You cannot undo this action',
  delete_forever: 'Yes, delete forever',
  no_delete: 'No, don’t delete',
  assigned_by: 'Assigned by',
  last_update_date: 'Last Updated Date',
  last_update_by: 'Last Updated By',
  remove_assessment: 'You are About to Remove the Assessment!',
  remove_wp: 'You are About to Remove a Working Paper!',
  cause: 'cause',
  create_observation: 'Create an Observation',
  issue_textarea_v1: 'Issue Textarea V1',
  issue_textarea_v2: 'Issue Textarea V2',
  issue_description: 'Issue Description',
  extra_info: 'Extra Information',
  observ_status: 'Observation Status',
  observ_dashboard: 'Observation Dashboard',
  observ_sal_dashboard: 'QM NC Dashboard',
  auditprogramobservation: 'Audit Observations',
  riskprogramobservation: 'Risk Program Observations',
  takassesment: 'Take Assesment',
  createEngagement: 'Create Engagement',
  editEngagement: 'Edit Engagement',
  entryDateFrom: 'entry date from',
  entryDateWithin: 'entry date within',
  engagements: 'Engagements',
  view_details: 'View Details',
  configure_dropdown_values: 'Configure Dropdown Values',
  show: 'Show',
  mandatory: 'Mandatory',
  show_description: 'Show Description',
  admin: 'Admin',
  today: 'Today',
  viewDetails: 'View Details',
  viewTemp: 'View Template',
  addBusinessProcess: 'Add Business Process',
  issueTitle: 'Issue Title',
  issueDesc: 'Issue Description',
  lastUpdated: 'Last Updated Date',
  lastUpdatedBy: 'Last Updated By',
  permissions: 'Permissions',
  improve: 'Improvement',
  recordDetails: 'Record Details',
  olderValue: 'Older Value',
  conditionApplyToDependencies: 'Apply to dependencies following the condition',
  applyToDependencies: 'Apply to all dependencies',
  invalid_color_code: 'Invalid color code',
  severity: 'Severity',
  please_fill_value_other_than_zero: 'Please fill value other than 0',
  risk_category_severity_vs_risk_appetite_range: 'Risk Category Severity Vs Risk Appetite Range',
  parent_value: 'Parent Value',
  mark_as_equivalent_to_closed: 'Mark as Equivalent to Closed',
  event_type: 'Event Type',
  numberField: 'Number Field',
  number_and_related_special_characters: 'The field accepts numbers and related special characters',
  choose_different_user: 'Choose a different replacement user',
  removeTab: 'Remove Tab',
  removeSection: 'Remove Section',
  inputField: 'Input Field',
  fieldName: 'Field Name',
  inputType: 'Input Type',
  choice: 'Choice',
  column: 'Column',
  addChoice: 'Add Choice',
  addColumn: 'Add Column',
  addSection: 'Add Section',
  addTab: 'Add Tab',
  addNewType: 'Add new Types',
  selectTemplate: 'Select Template',
  upload_diagram: 'Upload Diagram',
  no_diagram: 'No Diagrams Available',
  policies_by_status: 'Policies by Status',
  policies_by_rating: 'Policies by Rating',
  policies_by_type: 'Policies by Type',
  policies_by_department: 'Policies by Department',
  complianceType: 'Compliance Type',
  Recommendations_by_Priority: 'Recommendations by Priority',
  Recommendations_by_Department: 'Recommendations by Department',
  Recommendations_by_Status: 'Recommendations by Status',
  actionId: 'Action ID',
  lastAssmentDate: 'Last Assessment Date',
  respByStaus: 'Response by Status',
  respByOwner: 'Response by Owner',
  auditPgm: 'Audit Program',
  auditPlaning: 'Audit Planning',
  compliancebyType: 'Compliance by Type',
  add_audit_observations: 'Add Audit Observations',
  definitions: 'Definitions',
  condition: 'Condition',
  browse_issues: 'Browse Issues',
  all_management_responses: 'All Management Responses',
  all_MR: 'All MR',
  edit_management_response: 'Edit Management Response',
  add_risk_program_observations: 'Add Risk Program Observations',
  add_compliance_observations: 'Add Compliance Observations',
  associated_entity_strategy: 'Only those risks, controls and metrics associated with entity will be listed for selection in future changes to the strategy.',
  associated_entity_objective: 'Only those risks, controls and metrics associated with entity will be listed for selection in future changes to the objective.',
  associated_entity_goal: 'Only those risks, controls and metrics associated with entity will be listed for selection in future changes to the goal.',
  associated_entity_sub_goal: 'Only those risks, controls and metrics associated with entity will be listed for selection in future changes to the sub goal.',
  add_control: 'Add Control',
  add_policy: 'Add Policy',
  exception_rating: 'Exception Rating',
  possible_exception_rating: 'Possible Exception Rating',
  exception: 'Exceptions',
  fetch_tagged_risks: 'Fetch tagged risks',
  observation_type: 'Observation Type',
  Department: 'Department',
  mega_process: 'Mega Process',
  management_status: 'Management Status',
  observation_metrics: 'Observation Metrics',
  activity_completion: 'Activity Completion',
  testing_conclusion: 'Testing Conclusion',
  Source: 'Source',
  created_date: 'Created date',
  audit_overview: 'Audit Overview',
  Audit_ID: 'Audit ID',
  activity_overview: 'Activity Overview',
  activity_ID: 'Activity ID',
  action_title: 'Action Title',
  cost_saving: 'Cost Saving',
  action_priority: 'Action Priority',
  action_status: 'Action Status',
  action_type: 'Action Type',
  trend: 'Trend',
  add_working_paper: 'Add Working Paper',
  edit_working_paper: 'Edit Working Paper',
  admin_users: 'Users',
  allUserGroups: 'All User Groups',
  createUserGroup: 'Create User Group',
  createNewUserGroup: 'Create New User Group',
  editUserGroup: 'Edit User Group',
  members: 'Members',
  createNewUser: 'Create New User',
  editUser: 'Edit User',
  firstName: 'First Name',
  lastName: 'Last Name',
  emailAddress: 'Email Address',
  designation: 'Designation',
  license: 'License',
  statusOfTheAccount: 'Status of the account',
  active: 'Active',
  userGroup: 'User Group',
  reportingTo: 'Reporting To',
  userType: 'User Type',
  userCategory: 'User Category',
  newValueForDesignation: 'New Value for Designation',
  myProfile: 'My Profile',
  invalidInput: 'Invalid input',
  company: 'Company',
  linkedInUrl: 'LinkedIn URL',
  invalidFormat: 'Invalid format',
  contactNumber: 'Contact Number',
  contact_validation: 'Please enter a valid contact number',
  alternativeContactNumber: 'Alternative Contact Number',
  alt_contact_validation: 'Please enter a valid alternate number',
  proceed_to_assign_assessment: 'Proceed to Assign Assessment?',
  about_to_assign_the_assessment: 'You are about to assign the assessment to',
  to_proceed: 'want to proceed',
  controlPanel: 'Control Panel',
  all_Branches: 'All Branches',
  all_topics: 'All Topics',
  all_procedures: 'All Procedures',
  select_user: 'Select User',
  Division: 'Division',
  add_user: 'Add User',
  show_more: 'Show more',
  show_less: 'Show less',
  massReassign: 'New Reassign',
  copyToClipboard: 'Copied to clipboard',
  error_to_copy: 'Error in copying URL',
  participants: 'Participants',
  unable_fetch: 'Unable to fetch data',
  try_later: 'Please try again later.',
  sentMail: 'Sent an email',
  by: 'by',
  approve: 'Approve',
  reject: 'Reject',
  finalReport: 'Final Report',
  finalDocument: 'Final Document',
  finalizeDoc: 'Finalize Document',
  notAvailable: 'Not Available',
  signers: 'Signatories',
  tier: 'Tier',
  addSigner: 'Add Signatories',
  viewReport: 'View Report',
  confirmAction: 'Confirm Your Action',
  addComments: 'Add comments if any',
  saveAs: 'Save as Draft ?',
  save_as: 'Save as',
  riskProgramId: 'Risk Program ID',
  plzCnfm: 'Please confirm',
  reviewAndPublish: 'Review and Publish',
  placeHolder: 'Insert Citation Here ...',
  areYouSure: 'Please confirm that you want to save the changes',
  sure: 'Are you sure?',
  approvalstatus: 'Approval Status',
  byStakeholder: 'by Stakeholder',
  insertTextHere: 'Insert Text Here ...',
  minAndMaxvalueBasedOnCalc: 'Minimum and maximum value of the overall rating is based on the calculation.',
  overallRatingCalculation: 'Overall Rating Calculation',
  dynamicObservationRating: 'Dynamic Observation Rating',
  dynamicRating: 'Dynamic Rating',
  dynamicRatingFactors: 'Dynamic Rating Factors',
  ratingFactor: 'Rating Factors',
  observationRatingCalculation: 'Observation Rating Calculation',
  likelyhoodWeightage: 'Likelihood Weightage',
  impactWeightage: 'Impact Weightage',
  editObservation: 'Edit Observation',
  createObservation: 'Create Observation',
  processID: 'PROCESS',
  asset: 'ASSET',
  rolesAndResponsibilities: 'Roles and Responsibilities',
  qstn: 'Question',
  program: 'Program',
  addAnewDependency: 'Add a new dependency',
  areYouSureUwantToAddDependency: 'Are you sure you want to add a new dependency',
  Mark_as_current: 'Mark as Current',
  copy: 'Copy',
  key_control: 'Key Control',
  editObserv: 'You are About to Edit an Observation!',
  editObservSave: 'You are about to edit this observation',
  deleteObserv: 'You are About to Delete an Observation!',
  undoAction: 'Are you sure? You cannot undo this action',
  editImprov: 'You are About to Edit an Improvement!',
  deleteImprov: 'You are About to Delete an Improvement!',
  deleteReport: 'You are About to Delete the Report!',
  copyOfAudit: 'You are about to create a copy of the audit',
  duplicateObserv: 'You are about to duplicate the observation.',
  newlyCreatedObserv: 'Newly created observation will be listed in the observations tab.',
  editExistingScoring: 'You will be Redirected to Edit the Existing Scoring Model',
  adequate: 'Adequate',
  needImprovement: 'Needs Improvement',
  immediateAction: 'Immediate Action Required',
  moderate: 'Moderate',
  untitled: 'Untitled',
  assigned_to_me: 'Assigned to me',
  requested_by_me: 'Requested by me',
  slNo: 'SL NO.',
  notification: 'Notification',
  module: 'Module',
  reminderSelectDateField: 'Select date field For Reminder',
  conditions: 'Conditions',
  fieldsRequired: 'These fields are required',
  numberOfDays: 'Number of Days',
  send_reminder_to_user_fields: 'Send Reminder to User Fields',
  add_more_recipients: 'Add more Recipients',
  excludeTaggedUsers: 'Exclude tagged users',
  message: 'Message',
  addNotification: 'Add Notification',
  subject: 'Subject',
  months: 'Months',
  weeks: 'Weeks',
  years: 'Years',
  page: 'Page',
  budgetHours: 'Budget Hours',
  additional_recipients: 'Additional recipients',
  scheduleDate: 'Schedule Date',
  scheduleTime: 'Schedule Time',
  choose_fields_schedule: 'Choose fields to display on each activity in the schedule.',
  select_fields_template: 'Select Fields for Template',
  select_fields: 'Select Fields',
  configure_activities: 'Configure Activities',
  email_valid: 'Please enter a valid email address',
  startingAndEndingDate: 'Starting Date & Ending Date',
  subActivity: 'Sub Activity',
  additional_fields: 'Additional Fields',
  withdraw: 'Withdraw',
  excluded: 'Excluded',
  not_excluded: 'Not excluded',
  sub_module: 'Sub module',
  recipients: 'Recipients',
  excludeInfo: 'If this option is chosen, the question will be excluded from the scoring',
  mandatoryQuestion: 'This is a mandatory question',
  add_information: 'Add information',
  description: 'Description',
  please_select_plan: 'Please select a plan',
  terms: 'Terms',
  objectManager: 'Object Manager',
  priority: 'Priority',
  lastReminderDate: 'Last Reminder Date',
  section_title: 'Section Title',
  field_required: 'This field is required',
  section_description: 'Section Description',
  add_question: 'Add Question',
  add_section: 'Add Section',
  functions: 'functions',
  rcm: 'RCM',
  max_length_text: 'The maximum length of the text field is 255',
  schedule_temp_editor: 'Schedule Template Editor',
  viewDocument: 'View Document',
  approval_unanimous_header: 'Please select the approver(s) from the dropdown menu and submit the request for approval. Note that in the case of multiple approvers, unanimous approval is required.',
  approval_multi: 'Please select the users or user fields and submit the request for approval. In case of multiple approvers, the first response received will determine the approval or rejection of the request. All subsequent requests will be automatically cancelled.',
  versions: 'Versions',
  Version: 'Version',
  enterValidColor: 'Enter a valid color code.',
  position: 'Position',
  send_signed_doc: 'Send Signed Document',
  send_signed_report: 'Send Signed Report',
  previousVersions: 'Previous versions',
  restore: 'Restore',
  xlS: 'XLS',
  csV: 'CSV',
  filters: 'Filters',
  pageto: 'to',
  entries: 'entries',
  sector: 'Sector',
  explorerLicenses: 'Explorer Licenses',
  licenses: 'Licenses',
  professionalAdminLicenses: 'Professional Administrator Licenses',
  displayByColumns: 'Display by Column',
  assessmentTable: 'Assessment Table',
  assessmentView: 'Assessment view',
  planId: 'Plan ID',
  planName: 'Plan Name',
  auditUniverse: 'Audit Universe',
  period: 'Period',
  universeId: 'Universe ID',
  universeName: 'Universe Name',
  Create_Issue: 'Create an Issue',
  create_working_paper: 'Create working paper',
  add_observations: 'Add Observations',
  add_requests: 'Add Requests',
  reassign: 'Reassign',
  view: 'View',
  policies: 'Policies',
  recordType: 'Record Type',
  maxQuestions: 'The recommended number of questions under each section is 150',
  industries: 'Industries',
  attend_survey: 'Attend Survey',
  newCategory: 'New Value for Template Category',
  remove_template: 'You are about to remove the template.',
  additonalQuestion: 'You will lose additional questions and other modifications made to the template.',
  removeTemplate: 'Yes, remove',
  template_removal: 'No, don’t remove',
  manageNotifications: 'Manage Notifications',
  addScore: 'Add Score',
  editAction: 'Edit action',
  improvementId: 'Improvement Id',
  observationId: 'Observation ID',
  observationTitle: 'Observation Title',
  managementResponses: 'Management Responses',
  implementationOwner: 'Implementation Owner',
  observationBusinessUsers: 'Observation Business Users',
  managementUsers: 'Management Users',
  acOwner: 'Action Owner',
  departmentLevel1: 'Department Level 1',
  departmentLevel2: 'Department Level 2',
  age: 'Age',
  tagRisk: 'Tag Risk',
  tagControl: 'Tag Control',
  signOut: 'Sign out',
  hideSection: 'Hide Section',
  showHidePolicyCitation: 'Hide or show entire section from policy',
  showSectionHeader: 'Show Section Header',
  hideShowSectionHeader: 'Hide or show section header & description in policy detail page',
  activateAccount: 'Activate account',
  changePassword: 'Change Password',
  previousContent: 'Previous Content',
  newContent: 'New Content',
  activityBy: 'Activity by',
  modifiedOn: 'Modified on',
  recordId: 'Record ID',
  info: 'Info',
  configureTab: 'Customize the tab view. The changes will apply to all users.',
  configureChart: 'Customize the charts view. The changes will apply to all users.',
  passwordMatch: 'This password is not matched',
  passwordValidation: 'Password must be at least 8 characters long, 1 uppercase with 1 lowercase, 1 numeric, and 1 special character',
  switchToCalendar: 'Switch to calendar view',
  switchToTable: 'Switch to table view',
  sure_to_delete_template: 'Are you sure to delete this template? You can’t undo this action.',
  sure_to_delete_survey: 'Are you sure to delete this survey? You can’t undo this action.',
  addFields: 'Add fields',
  editField: 'Edit Field',
  configureFieldAvailability: 'Configure Field Availability',
  unmarkDefault: 'Unmark Default',
  displayStyle: 'Display Style',
  end_time_after_start: 'The end time must be later than the start time.',
  addMore: 'Add more',
  AddMore: 'Add More',
  sure_to_delete_participant: 'Are you sure to delete this participant? You can’t undo this action.',
  policySectionDelete: 'Are you sure you want to delete this section? You can’t undo this action',
  approvalType: 'Approval Type',
  initialResponderApproval: 'Initial Responder Approval',
  unanimousApproval: 'Unanimous Approval',
  withdrawApproval: 'Withdraw approval request',
  rejectRequest: 'Reject Request',
  approveRequest: 'Approve Request',
  effectivePermissions: 'Effective Permissions',
  unique_choice: 'Answer choices must be unique',
  overallBy: 'Overall by',
  progress: 'Progress',
  actionBy: 'Action by',
  action_by_priority: 'Action by Priority',
  action_by_status: 'Action by Status',
  observation_by_rating: 'Observation by Rating',
  single_choice: 'Choices should be unique',
  approval_alert: 'No users found in the field(s) selected. These records cannot be submitted for approval. Are you sure you want to proceed?',
  approvalAlertSubText_1: 'No users were found in the selected fields for ',
  approvalAlertSubText_2: ' out of ',
  approvalAlertSubText_3: ' records, which cannot be submitted for approval. Are you sure you want to proceed?',
  customLabelTitle: 'Customize the labels for modules, records, and abbreviations to represent them in navigations, page titles, breadcrumbs, messages, and notifications.',
  component: 'component',
  rules: 'Rules',
  components: 'components',
  createComponent: 'Create Component',
  actionByType: 'Actions by Type',
  recmByStatus: 'Recommendation by Status',
  incidentByStatus: 'Incidents by Status',
  testingStatus: 'Testing Status',
  general: 'General',
  controlScore: 'Control Score',
  manager: 'Manager',
  doc_uploader: 'Document uploader',
  warning_msg_obsrvtn_create: 'You are about to create a new observation!',
  flow_builder: 'Flow Builder',
  set_us_default: 'Set as Default',
  openNewTab: 'Open in new tab',
  noDashboard: 'No Dashboards',
  createCustomLink: 'Create Custom Link',
  linkType: 'Link Type',
  mainMenu: 'Main Menu',
  url: 'URL',
  reportBuilder: 'Report Builder',
  reportPreview: 'Report Preview',
  all_pages: 'All Pages',
  no_pages: 'No Pages',
  addLink: 'Add Link',
  customLinks: 'Custom Links',
  noCustomLinks: 'No Custom Links',
  dashboards: 'Dashboards',
  arrangeMenus: 'Arrange Menus',
  mainMenuEempty: 'This main menu is empty',
  organizationHierarchy: 'Organization hierarchy',
  hierarchyRepresentation: 'Hierarchy representation',
  organizationalElements: 'Organizational elements',
  organizationalElementsDesc: 'Below are the key elements that compose the organization’s hierarchy.',
  configOrganizationHierarchy: 'Configure organization hierarchy',
  configOrganizationHierarchyDesc: 'Customize the hierarchy of organizational elements. This will be automatically reflected in the forms.',
  complianceAssessment: 'Compliance Assessment',
  list: 'List',
  gantt: 'Gantt',
  board: 'Board',
  successful: 'Successful!',
  reassignJobs: 'All Reassign Jobs',
  add_risks: 'Add risks',
  add_controls: 'Add controls',
  addAssessments: 'Add assessments',
  sourceID: 'Source ID',
  assID: 'Assesment ID',
  surveyName: 'Survey Name',
  cmTitle: 'Compliance Title',
  cmStatus: 'Status Of Compliance',
  cmId: 'Compliance ID',
  updatedDate: 'Updated Date',
  uploaded_by: 'Uploaded by',
  uploaded_on: 'Uploaded on',
  fieldAlreadySelected: 'This field is already selected',
  itemAlreadyChosen: 'This item has already been chosen',
  riskMap: 'Risk Map',
  autoSelected: 'Automated reminders are triggered on, before, or after the dates recorded in specific fields.',
  instantSelected: 'Find records and send reminders instantly.',
  scheduleSelected: 'Set up a one-time reminder for a specific date and time.',
  recurringSelected: 'Configure reminders to repeat at regular intervals such as daily, weekly, or monthly.',
  newAssessment: 'Create New Assessment Template',
  Templates: 'Templates',
  color_code: 'Color code',
  lockIconApprovalText: 'The record is currently locked for approval. You can still browse the record.',
  approval_lock_no_browse: 'The record is currently locked for approval',
  max_length_is: 'Maximum length of this field is',
  min_length_is: 'Minimum length of this field is',
  characters: 'characters',
  modelId: 'Model ID',
  sure_to_delete_survey_response: 'Are you sure to delete this survey response? You can’t undo this action.',
  Inactive: 'Inactive',
  overDueExclude: 'Exclude from overdue calculation',
  obsOverdue: 'Observation Overdue',
  approvalRequests: 'Approval Requests',
  confirm_changes: 'Confirm changes',
  bulkEditOngoing: 'Bulk edit in progress',
  mr_exclude_change: 'Existing overdue days will not be modified. The changes will be reflected only for the upcoming overdue calculations. Are you sure?',
  updateDataAndLock: 'This will update all the selected records and lock them until the process is complete.',
  bulkEditInProgress: 'A bulk operation initiated by another user is still in progress. Submitting now will exclude records that are currently locked by this ongoing process. Do you want to proceed?',
  configureDependencies: 'Configure Dependencies',
  editDependency: 'Edit Dependency',
  configureDependencyActions: 'Configure Dependency Actions',
  configureDirectionInputs: 'Configure Direction Inputs',
  templateDetails: 'Template Details',
  fileType: 'File Type',
  uploadedBy: 'Uploaded by',
  uploadedOn: 'Uploaded on',
  createAuditUniverseModel: 'Create Audit Universe Model',
  scheduler: 'Scheduler',
  startOfSchedule: 'Start of Schedule',
  endOfSchedule: 'End of Schedule',
  temp_details: 'Template Details',
  file_type: 'File Type',
  user_id: 'User ID',
  clients: 'Clients',
  create_new_client: 'Create New Client',
  department: 'Department',
  role: 'Role',
  types: 'Type',
  control_by_type: 'Controls by Type',
  average_spent: 'Average Daily Spent',
  abbreviation: 'Abbreviation',
  userId: 'User ID',
  managementTeam: 'Management Team',
  auditors: 'Auditors',
  custom: 'custom',
  residualRiskRating: 'Residual Risk Rating',
  tagCauses: 'Tag Causes',
  tagImpacts: 'Tag Impacts',
  editDirection: 'Edit Direction',
  directionalDescription: 'You can change or adjust the directions from here.',
  resp_overdue_perc: 'Response Overdue Percentage',
  none: 'None',
  comments_optional: 'Comment Optional',
  comments_mandatory: 'Comment Mandatory',
  attachment_optional: 'Document Optional',
  attachment_mandatory: 'Document Mandatory',
  docs_uploaded: 'Documents Uploaded',
  audit_type: 'Audit Type',
  objective: 'Audit Objective',
  justification: 'Justification',
  planned_start_date: 'Planned Start Date',
  actual_start_date: 'Actual Start Date',
  scope: 'Scope',
  feature: 'Feature',
  features: 'Features',
  security_control: 'Security Control',
  holiday_types: 'Holiday Types',
  organization_calendar: 'Organization Calendar',
  fiscal_year: 'Fiscal Year',
  setup_calendars: 'Setup Calendars',
  create_calender: 'Create Calendar',
  edit_calendar: 'Edit Calendar',
  logo_configuration: 'Logo Configuration',
  dataTypes: 'Data Types',
  current_stage: 'Current Stage',
  auditStatus: 'Status of Audit',
  docs_req: 'Document upload is required',
  createUser: 'Create User',
  massReassignUser: 'Mass Reassign User',
  allUsers: 'All Users',
  viewEffectivePermission: 'View effective permission',
  business_user: 'Business User',
  business_users: 'Business Users',
  implementation_owners: 'Implementation Owners',
  edit_direction: 'Edit Direction',
  change_direction: 'Change Direction',
  addClient: 'Add Client',
  add_recommendation: 'Add Recommendation',
  fileInformation: 'File information',
  MaximumFileSize: 'Maximum file size',
  add_group: 'Add Group',
  schedule_temp: 'Schedule Templates',
  grid_temp: 'Grid Templates',
  unlockRecord: 'Unlock Record',
  update_mr_due_date: 'Modifications to the due date have reached the count of',
  this_is_the: 'This is the',
  riskCategory: 'Risk Category',
  skipForNow: 'Skip for now',
  getStarted: 'Get started',
  addTime: 'Add Time',
  addTimeDetails: 'Add Time Details',
  hello: 'Hello',
  configureHierarchy: 'Configure hierarchy',
  configureOrganizationHierarchy: 'Configure organization hierarchy',
  hierarchySetup: 'Configure organization hierarchy to define the structure of your organisation.',
  addHierarchyElements: 'Click the (+) icon to add objects to the hierarchy.',
  hierarchyKeyElements: 'Available organization objects:',
  configureLayout: 'Configure layout',
  configureLabels: 'Configure labels',
  quickSettings: 'Quick Settings',
  deleteIssue: 'Delete Issue',
  section_temp: 'Section Templates',
  policy_management: 'Policy Management',
  changeImplementationDate: 'You are about to change the implementation due date',
  totalFileSizeIncludeVideo: 'The total upload file size should be less than 2 GB.',
  totalFileSizeToast: 'The total upload file size should be less than',
  keep_user: 'Keep existing user',
  single_user_desclaimer: 'Records with user fields restricted to a single user will be replaced with the new user. All other user fields will keep both users.',
  addNewAssessment: 'Add assessment',
  addNewControlAssessment: 'Add control testing',
  riskEvaluation: 'Risk Evaluation',
  appliedFilters: 'Applied Filters',
  risksSendAssessment: 'Select risks and send for assessment',
  scheduleTemplateEditor: 'Schedule Template Editor',
  colorCode: 'Color code',
  overall: 'overall',
  more: 'more',
  dragDropItemsHere: 'Drag and drop items here',
  section_temp_name: 'Section Template Name',
  section_temp_desc: 'Section Templates Description',
  emailNotificationTempEditor: 'Email Notification Template Editor',
  object_management: 'Object Management',
  supportedFiles: 'Supported Files',
  commentsAdd: 'Add comments',
  documentsAdd: 'Attach Documents',
  scenarios: 'Scenarios',
  scenario_rating: 'Scenario Rating',
  controlsSendAssessment: 'Select controls and send for assessment',
  Controloverview: 'Control Overview',
  compareVersion: 'Compare Version',
  changeComments: 'Change comments',
  Configure_Dependencies: 'Configure Dependencies',
  disableAssessmentPost: 'Assessments can be taken after the due date. Click the lock icon to restrict.',
  enableAssessmentPost: 'Assessments cannot be taken after the due date. Click the lock icon to allow.',
  youWantToProceed: 'Do you want to proceed?',
  record_s: 'record',
  records_s: 'records',
  hide: 'Hide',
  asmntScore: 'Assessment Score',
  week: 'Week',
  month: 'Month',
  quarterly: 'Quarterly',
  year: 'Year',
  approvedDate: 'Approved Date',
  ageing: 'Ageing',
  issues_by: 'Issues by',
  actions_by: 'Actions by',
  observations_by: 'Observations by',
  issue_status: 'Issues by Status',
  issue_rating: 'Issues by Rating',
  issue_department: 'Issues by Department',
  issue_time: 'Issues by Time',
  issue_age: 'Issues by Ageing',
  issue_owners: 'Issues by Owners',
  issue_due_date: 'Issues by Due Date',
  mr_status: 'Management Responses by Status',
  mr_department: 'Management Responses by Department',
  mr_time: 'Management Responses by Time',
  mr_age: 'Management Responses by Ageing',
  mr_owners: 'Management Responses by Owners',
  mr_due_date: 'Management Responses by Due Date',
  actions_status: 'Actions by Status',
  actions_department: 'Actions by Department',
  actions_time: 'Actions by Time',
  actions_age: 'Actions by Ageing',
  actions_owners: 'Actions by Owners',
  actions_due_date: 'Actions by Due Date',
  observations_by_status: 'Observations by Status',
  observations_by_department: 'Observations by Department',
  observations_by_time: 'Observations by Time',
  observations_by_age: 'Observations by Ageing',
  observations_by_owners: 'Observations by Owners',
  observations_by_due_date: 'Observations by Due Date',
  responses: 'Responses',
  risk_overview: 'Risk Overview',
  asset_overview: 'Asset Overview',
  addRiskEvaluation: 'Add risk evaluation',
  riskSendAssessment: 'Select risks and send for assessment',
  group: 'Group',
  grouping: 'Grouping',
  copyOfComlpliance: 'You are About to Create a Copy of the Compliance Program.',
  copyOfComlpliancePlan: 'You are About to Create a Copy of the Compliance Plan',
  cloudConnection: 'Cloud Connections',
  awsConnect: 'Amazon S3',
  sftpConnect: 'SFTP',
  annexure: 'Annexure',
  conclusion: 'Conclusion',
  annexure_toast: 'annexure',
  conclusion_toast: 'conclusion',
  procedure_toast: 'procedure',
  uploadedfiles: 'Uploaded File',
  noFilesAvailable: 'No files available',
  reminderId: 'Reminder ID',
  searchData: 'Search Data',
  incident_reporting_forms: 'Incident Reporting Forms',
  request_form: 'Request Forms',
  notification_template_edit: 'Email Notification Template Editor',
  notification_templates: 'Notification Templates',
  time_management: 'Time Log Management',
  view_changes: 'View Changes',
  urlValidation: 'Enter a valid URL.',
  responses_By_Aging: 'Responses by Aging',
  requested_by: 'Requested By',
  notifications: 'Notifications',
  notificatio_detail: 'New Email',
  departmentlevel: 'Department Level',
  findDatWithPathSearch: 'Please enter the path and find your data',
  path: 'File Path',
  subCategory: 'Sub Category',
  billableTest: 'Billable Test',
  lockUnlock: 'Lock/Unlock',
  lockField: 'Click the lock to prevent changes',
  unlockField: 'Click the lock to make changes',
  lockedEditing: 'Locked for editing',
  deptL1: 'Department Level1',
  deptL2: 'Department Level2',
  timeLogId: 'Time Log ID',
  riskStatus: 'Risk Status',
  riskPgmId: 'Risk Program ID',
  riskPgmName: 'Risk Program Name',
  aboutToEdit: 'You are about to edit',
  aboutToDelete: 'You are about to delete',
  keepEditing: 'Keep editing',
  discard: 'Discard',
  major_process: 'Major Process',
  riskVelocity: 'Risk Velocity',
  assetRiskValue: 'Asset Risk Value',
  doc_Control: 'Document Control',
  covepage: 'Section CoverPage',
  version_history: 'Version History',
  authorization: 'Authorization',
  distribution_List: 'Distribution List',
  all_issues: 'All Issues',
  exitWithoutSaving: 'Exit without saving',
  saveAndProceed: 'Save and Proceed',
  return: 'Return',
  quarter: 'Quarter',
  overdue: 'Overdue',
  timeStampQuarter: 'Time Stamp Quarter',
  noOfDays: 'No of Days',
  trackChanges: 'Track changes',
  assessment_req: 'Assessment Requests',
  assessment_temp: 'Assessment Templates',
  conditional_docs: 'Conditional Document Upload',
  font_size: 'Font Size',
  change_theme: 'Change Theme',
  comment: 'Comment',
  NoFileFound: 'No data available in this path',
  AM: 'AM',
  PM: 'PM',
  an: 'an',
  editIssue: 'Edit an Issue',
  no_data_found: 'No data found',
  item: 'item',
  sameGroupExist: 'Group with the same name already exist.',
  inciednt: 'Incident',
  auditObs: 'Audit Observation',
  riskprogramobs: 'Risk Program Observation',
  managementResp: 'Management Response',
  relatedAudit: 'Related Audit',
  cmpPgm: 'Compliance Program',
  cmpName: 'Compliance Name',
  cmpAudit: 'Compliance Audit',
  basedOnThe: 'Based on the',
  selectedRiskText: 'risks you have selected the impact and the likelihood recommended for the observation are respectively',
  updateScore: 'Update the score to proceed with the recommendation.',
  exitFullScreen: 'Exit Full Screen',
  fullScreen: 'Full Screen',
  related: 'Related',
  businessContinuity: 'BCM Audit',
  bcmAuditPLan: 'BCM Audit Plan',
  bcmAuditProgram: 'BCM Working Paper',
  strategy: 'Strategy',
  complianceAudit: 'Compliance Program',
  bcmAuditAbbreviation: 'BA',
  bcmAuditUniverseAbbreviation: 'BP',
  bcmWPAbbreviation: 'WP',
  bcmIssueAbbreviation: 'IU',
  actionAbbreviation: 'AC',
  riskAbbreviation: 'RK',
  strategyAbbreviation: 'ST',
  complianceAbbreviation: 'CP',
  total_observations: 'Total Observations',
  total_issues: 'Total Issues',
  total_actions: 'Total Actions',
  total_mrs: 'Total Management Responses',
  theSmallCase: 'the',
  no_access: 'You do not have access to the records. Please contact the administrator',
  keep_it: 'Keep it',
  discard_new: 'Discard new',
  info_lost: 'This will permanently delete the',
  discard_changes: 'Discard unsaved changes ?',
  loose_unsaved_changes: 'You will loose all unsaved changes.',
  keep_editing: 'Keep editing',
  to_remove: 'This will remove the',
  info_lost_discard: 'You will loose all the information entered for this',
  moreCapitalCase: 'More',
  respOverdue: 'Response Overdue',
  initialDueDate: 'Initial Due Date',
  slider: 'Slider',
  submitForApproval: 'Submit for Approval',
  submitForApprovalQuestion: 'Submit for approval?',
  submitApprovalLockConfirm: 'This will create and submit the risk for approval and lock it for further editing.',
  submitApprovalLockConfirmEdit: 'This will submit the risk for approval and lock it for further editing. Changes will be reflected once the approval process is complete.',
  submitApprovalLockConfirmDelete: 'This will submit a request for approval to delete the risk and lock it for further editing. Once approved, the risk will be permanently deleted.',
  submitApprovalLockConfirmDeleteDependancy: 'This will request approval to delete the risk and lock it for further editing. Once approved, the risk and the following items will be permanently deleted:',
  organization_Settings: 'Organization settings',
  organizationDetails: 'Organization Details',
  dashboard: 'dashboard',
  takeAction: 'Take Action',
  deactivate: 'This will permanently deactivate the user and revoke all access to the account.',
  client: 'Client',
  model: 'Model',
  reminder_v2: 'Reminder',
  dependency: 'Dependency',
  event: 'Event',
  scenario: 'Scenario',
  deactivateConstant: 'Deactivate',
  deactivateUser: 'Deactivate User',
  fieldConstant: 'field',
  activate: 'Activate',
  activatesubtext: 'This will activate the user and allow access to the account.',
  activateClient: 'Activate client?',
  activateClientSubText: 'This will send the client an email with instructions on activating the account.',
  revertReassignedJob: 'Revert reassigned job?',
  revertReassignedSubText: 'This will revert the scheduled job and assign the records back to their original user.',
  revert: 'Revert',
  improvements_by_status: 'Improvements by Status',
  noAccessForDependency: 'You do not have access to this dependency. Please contact the administrator.',
  template_name: 'Template name',
  respId: 'Response ID',
  respForm: 'Response Form',
  mrDesc: 'Management Description',
  impStatus: 'Implementation Status',
  impDueDate: 'Implementation Due Date',
  impOverDue: 'Implementation Overdue',
  assId: 'Assessment ID',
  respOverdueDays: 'Response Overdue Days',
  overDueDays: 'Overdue Days',
  repPerson: 'Responsible Person',
  mrRev: 'Management Reviewer',
  obsDesc: 'Observation Description',
  resExp: 'Response expected by',
  obsRating: 'Observation Rating',
  remCount: 'Reminder Count',
  assignedBy: 'Assigned by',
  assignedDate: 'Assigned date',
  lastDate: 'Last Modified Date',
  projects: 'Projects',
  obs_msg: 'Proceed to recommendations?',
  rec_msg: 'Proceed to more recommendations?',
  compliance: 'Compliance',
  riskProgram: 'Risk Program',
  riskProgramMR: 'Risk Program Management Response',
  riskManagement: 'Risk Management',
  businessProcess: 'Business Process',
  auditDraft: 'Audit Draft',
  upstreamDependency: 'Upstream Dependency',
  downstreamDependency: 'Downstream Dependency',
  userMass: 'User Mass Reassign',
  scoringModel: 'Scoring model',
  timeEntry: 'Time Entry',
  select_table_view: 'Select Table View',
  create_view: 'Create view',
  edit_view: 'Edit view',
  fields_available: 'Fields available',
  fields_selected: 'Fields selected',
  template_selected_view: 'Template is currently selected for view',
  no_fields_available: 'No fields available',
  weekTimeStamp: 'Weekly Time Stamp',
  monthTimeStamp: 'Monthly Time Stamp',
  quarterTimeStamp: 'Quarterly Time Stamp',
  yearTimeStamp: 'Yearly Time Stamp',
  moduleLabelConfig: 'Module Label Configuration',
  customizeLabels: 'Customize the labels',
  objectiveLevel: 'Objective Level',
  enableLevel: 'Enable Level',
  moduleTitle: 'Module title ',
  recordAbbreviation: 'Record abbreviation',
  breadcrumbTitle: 'This is the page and breadcrumb title',
  recordidPrefix: 'A record ID will start with this as prefix, e.g:',
  RecordLabelSentenceCase: 'Record label - Sentence case',
  individualRecord: 'An individual record in the module is called',
  messageStrating: 'Messages starting with the label use this text.',
  recorLabellowerCase: 'Record label - Lower case',
  messagesInBetween: 'Messages with the label in between a sentence use this text.',
  pluralRecordSentenceCase: 'Plural of the record - Sentence case',
  messageStartWithPlural: 'Messages starting with plural of the label use this text.',
  pluralLabelLowerCase: 'Plural of the label - Lower case',
  messageWithPluralBetweenSentence: 'Messages with plural of the label in between a sentence use this text.',
  observation_age: 'Observation Age',
  management_response_status: 'Management Response Status',
  management_response_age: 'Management Response Age',
  iss_status: 'Issue Status',
  iss_age: 'Issue Age',
  action_age: 'Action Age',
  selectValue: 'Select Value',
  submitMR: 'Submit management response?',
  lockFieldWarning: 'This will lock the field',
  fromFurtherEditing: 'from further editing',
  removeGroupDependencies: 'This will remove the group and its dependencies.',
  removeDependencies: 'This will remove the dependency and its configurations.',
  groupRemoved: 'Group removed.',
  dependencyRemoved: 'Dependency removed.',
  level: 'Level',
  withdrawn: 'Withdrawn',
  first_response_approval: 'First-response approval',
  unanimous_approval: 'Unanimous approval',
  assignee: 'Assignee',
  subprocess: 'Sub Process',
  incidentEditor: 'Incident Reporting - Object Editor',
  timeManageEditor: 'Time Management - Object Editor',
  timeProjectEditor: 'Project - Object Editor',
  requestEditor: 'Request - Object Editor',
  unsaved_changes: 'You will lose all unsaved changes',
  discard_info: 'You will lose all the information entered for this',
  you_can_upload_maximum: 'You can upload maximum',
  approval_of: 'Approval of',
  working_papers_in_the_audit: 'working papers in the audit',
  showMore: 'Show More',
  showLess: 'Show Less',
  tag: 'Tag',
  sync: 'Sync',
  deleteDependency: 'This will permanently delete the following items created under this',
  display_label: 'Display Label',
  tagRecords: 'Tag Records',
  untagRecords: 'Untag Records',
  markDependencyDirection: 'Mark Dependency Direction',
  hideInPublic: 'Hide in Public form',
  majorDependency: 'Major Dependency',
  bidirectional: 'Bidirectional',
  downstream: 'Downstream',
  Upstream: 'Upstream',
  createNewRecords: 'Create New Records',
  deleteRecords: 'Delete Records',
  editRecords: 'Edit Records',
  dataSource: 'Data Source',
  calcField: 'Calculated Field Logic',
  analyticsEffectiveness: 'Analytics Effectiveness',
  analyticsManager: 'Analytics Manager',
  analytics_criticality_rating: 'Analytics Criticality Rating',
  exception_legend: 'Exception',
  respText: 'Response Text',
  exceptionStatus: 'Exception Status',
  exceptionFlag: 'Exception flag',
  q1: 'Q1',
  q2: 'Q2',
  q3: 'Q3',
  q4: 'Q4',
  scenarioCode: 'Scenario ID',
  effectiveness: 'Scenarios by Effectiveness',
  controlRating: 'Control By Rating',
  typeHere: 'Type here',
  ProcessByEffectiveness: 'Processes by Scenario Effectiveness',
  overallException: 'Overall Exceptions',
  overallExceptionTrend: 'Overall Exceptions Trend',
  group_approval_of_working_papers_under_audit: 'Group approval of working papers under audit',
  qstnName: 'Question Name',
  disasterRecoveryPlan: 'Disaster Recovery Plan',
  SignAllTheFieldsProvided: 'Sign all the fields provided',
  uncheck_all: 'Uncheck All',
  audit_plans: 'Audit Plan(s)',
  lockIconApprovedText: 'This audit plan has been approved and is locked. You can still browse the record.',
  all_records_are_in_lock_state: 'All records are in lock state',
  select_all: 'Select all',
  oneAlphabetNeeded: 'Input must contain at least one alphabet',
  addDoc: 'Add Document(s)',
  policyId: 'Policies ID',
  docId: 'Document ID',
  met: 'Metrics',
  created: 'Created',
  byDept: 'by Department',
  byTime: 'by Time',
  byStatus: 'by Status',
  wantToCreate: 'WANT TO CREATE AN',
  withInfo: 'WITH THIS INFORMATION?',
  select: 'Select',
  total: 'Total',
  Assessment_added_to_risk: 'Assessment is added to risk',
  delete_section_associated_fields: 'This will delete the section and associated fields.',
  Section_deleted: 'Section deleted',
  Delegate: 'Delegate',
  cannot_delete_id: 'Unable to delete this section as the ID field is mandatory.',
  Groups_cannot_be_empty: 'Groups cannot be empty',
  This_will_withdraw_the_following_approval_requests: 'This will withdraw the following approval requests.',
  Delete_section: 'Delete section?',
  typeOfPolicy: 'Type Of Document',
  generatedOn: 'Generated On',
  unsprtFormat: 'unsupported document format...try uploading docx,pptx,pdf formats',
  analytics_automation: 'Analytics Automation',
  mr_review_status: 'MR Review Status',
  submit_mr_for_approval: 'Submit management response for approval?',
  approvalWarning: 'This will submit the management response for approval and lock it for further editing. Changes will be reflected once the approval process is completed',
  the_maximum_length_of_this_field_is_seventy_five_characters: 'The maximum length of this field is 75 characters',
  analytics_scenarios: 'Analytics Scenarios',
  mr_completion_status: 'MR Completion Status',
  add_scenarios: 'Add Scenarios',
  generate_observations: 'Generate Observations',
  select_import_obs: 'Select & Import Observations',
  generating_observations: 'Generating Observations',
  consolidate_obs: 'Consolidate observations',
  tl_obs: 'Transactional level observations',
  obs_selected: 'observations selected',
  select_one_observation: 'Please select atleast one observation',
  response_text: 'Response Text',
  noOfDaysDue: 'Number of Days Due',
  review_status: 'Review Status',
  obs_generated: 'Observations generated',
  generate_sub_message: 'This process may take some time. You can minimize this window and continue with other tasks.',
  tagged_scenario_delele_warning: 'This scenario is automatically fetched from the control in this audit.',
  scenario_generating_delete_warning: 'This scenario is currently generating observations',
  perm_remove: 'This will permanently remove the',
  manual: 'Manual',
  obs_failed: 'Observations generation failed',
  assignTo: 'ASSIGN TO',
  generatedBy: 'Generated By',
  any_field: 'Any field',
  step: 'Step',
  exceptions_selected: 'exceptions selected',
  ai_generated: 'AI Generated',
  generate_help_text: 'Select exceptions and relevant fields to generate observations.',
  discard_obs: 'This will permanently remove all observations generated for this scenario.',
  Risk_Appetites: 'Risk Appetites',
  create_from_scratch: 'Create from scratch',
  quickView: 'Quick view',
  show_in_drawer_quick_view: 'Show in Drawer Quick View',
  exportAll: 'Export all',
  exportByView: 'Export by view',
  progress_line_legends: 'Progress line legends',
  progress_line_legends_placeholder: 'Progress line legends placeholder',
  cmpDashboard: 'Compliance Dashboard',
  ovrRating: 'Overall rating is shown in this',
  cmpProcess: 'Compliance by process',
  cmpOverall: 'Overall',
  cmpImplemented: 'Implemented',
  cmEntityScore: 'Entities by Score',
  cmObsRating: 'Observation by rating',
  obsName: 'Observation Name',
  drawer_quick_view: 'Drawer Quick View',
  major_field: 'Major Field',
  email: 'Email',
  notAccepted: 'Not Accepted',
  accepted: 'Accepted',
  expired: 'Expired',
  latest: 'Latest',
  allResponses: 'All Responses',
  free_on_dates: 'Free on Dates',
  engaged_audits: 'Engaged Audits',
  Pie_chart: 'Pie chart',
  Pie_chart_placeholder: 'Pie chart placeholder',
  Progress_Slider_chart: 'Progress Slider Chart',
  Progress_Slider_chart_placeholder: 'Progress Slider Chart Placeholder',
  KRIs: 'KRIs',
  specification: 'Specification',
  createSepRecommendation: 'Create separate management responses for each recommendation selected',
  addMoreResponses: 'Add more management responses',
  selectRecommendationWarning: 'Select at least one recommendation as you have checked to create separate management responses for each recommendation.',
  allRelObs: 'All Related Observations',
  add_user_grp: 'Add user group',
  approval_pending: 'Pending for approval',
  approved: 'Approved',
  passwordTaken: 'This password is already taken',
  filter_by: 'Filter by',
  select_quarter: 'Select quarter',
  coverageValidation: 'Selection not matching the coverage criteria',
  lastModifiedOn: 'Last modified on',
  addUserGroup: 'Add user group',
  small: 'Small',
  large: 'Large',
  medium: 'Medium',
  totalExpense: 'Total Expense',
  expenseBalance: 'Expense Balance',
  options: 'Options',
  points: 'Points',
  date_range: 'Date Range',
  current: 'Current',
  published: 'Published',
  direction: 'Direction',
  addsection: 'Add section',
  questionId: 'Question ID',
  Parent_Value_Level_1: 'Parent Value Level 1',
  Parent_Value_Level_2: 'Parent Value Level 2',
  Parent_Value_Level_3: 'Parent Value Level 3',
  Configurable: 'Configurable',
  workflow_history: 'Workflow history',
  record_name: 'Record Name',
  workflow_status: 'Workflow Status',
  untitled_workflow: 'Untitled workflow',
  blank: 'Blank',
  user_groups: 'User groups',
  user_field: 'User field',
  execution_date: 'Execution Date',
  Action_cannot_performed_change: 'Action cannot be performed as there is no change',
  plan: 'Plan',
  alertSubText: 'You will be directed to editing wizard where you can modify the observation details and the dependencies. Do you want to proceed?',
  createNew: 'Create New',
  domain: 'Domain',
  noPermissionToConfigureTabView: 'You do not have permission to configure this tab view.',
  noPermissionToConfigureChartView: 'You do not have permission to configure this chart view.',
  loading: 'Loading',
  testOfDesign: 'Test of design',
  Min: 'Min',
  newValueAssessmentTemplate: 'New Value for Template Category',
  edit_report: 'Edit Report',
  template_Title: 'Template Title',
  display_text: 'Display Text',
  add_items: 'Add item',
  replacementUser: 'Replacement User',
  existingUser: 'Existing User',
  selectRatingFactor: 'Select Rating Factors',
  editRatingFactor: 'Edit Rating Factor',
  newName: 'New Name',
  severityorweight: 'Severity | Weight',
  wightpercent: 'Weight %',
  setDaysLeft: 'Set Days_Left',
  setcontent: 'Set Content',
  setSubject: 'Set Subject',
  setupCalenderModel: 'Setup Calendar Model',
  selectDefaultfieldSetup: 'Select a default field for calendar setup',
  createType: 'Create Type',
  calenderTitle: 'CALENDAR TITLE',
  calenderOverview: 'Calender Overview',
  setWorkingHrs: 'Set working hours',
  SetupWorkingdysWorkinghrs: 'Setup working days and working hours',
  holiday: 'Holiday',
  markHolidays: 'Mark Holidays',
  markHolidaysCalendar: 'Mark Holidays in Calendar',
  january: 'January',
  february: 'February',
  march: 'March',
  april: 'April',
  may: 'May',
  june: 'June',
  july: 'July',
  august: 'August',
  september: 'September',
  october: 'October',
  november: 'November',
  december: 'December',
  workingHrs: 'working hours',
  workingDaysworkingHrs: 'Working days and working hours',
  departments: 'Departments',
  sections: 'Sections',
  region: 'Region',
  branch: 'Branch',
  topic: 'Topic',
  procedure: 'Procedure',
  browse_designation: 'Browse Designation',
  user_fields: 'User Fields',
  create_type: 'Create Type',
  new_user_category: 'New Value For User Category',
  new_user_type: 'New Value For User Type',
  edit_mega_process: 'Edit Mega Process',
  user_dept: 'User Department',
  publishCalendar: 'Publish Calendar',
  calendar: 'Calendar',
  new_notification: 'New Notification',
  inbox: 'Inbox',
  sent_items: 'Sent Items',
  maxSingleFileUpload: 'Maximum upload file size',
  from: 'From',
  risk_program_department_rating: 'Risk program department rating',
  documentDetails: 'Document Details',
  documentExtension: 'Document Extension',
  retired: 'Retired',
  docGenerated: 'Document Generated',
  pending: 'Pending',
  pendingSignOff: 'Pending for Sign Off',
  docSignOff: 'Document is waiting for Sign Off approval',
  drawField: 'draw',
  typeField: 'type',
  uploadField: 'upload',
  delegate: 'Delegate',
  maxUpload: 'Maximum upload file size 25 MB',
  compliances: 'Compliances',
  ascending: 'Ascending',
  descending: 'Descending',
  timeline_view: 'Timeline View',
  mark_as_read: 'Mark as Read',
  mark_as_unread: 'Mark as Unread',
  flag: 'Flag',
  unflag: 'Unflag',
  upload_in_progress: 'Upload in progress',
  inactive: 'inactive',
  scheduleactive: 'active',
  template_title: 'TEMPLATE TITLE',
  field_updated: 'Field Updated',
  Create_Form: 'Create Form',
  Edit_Form: 'Edit Form',
  Details_Page: 'Details Page',
  Page_Filter: 'Page Filter',
  Related_Grid: 'Related Grid',
  Notification: 'Notification',
  Field_deleted: 'Field deleted',
  Assessments_are_added_to_risk: 'Assessments are added to risk',
  gettingStarted: 'Getting started',
  templateTitle: 'TEMPLATE TITLE',
  followUpTableHeading: 'Follow-up Report on Outstanding Audit Observations',
  Note: 'Note',
  NoHighRiskPending: 'No High-Risk audit observation is outstanding as of reporting date',
  auditReports: 'Audit Reports',
  AuditYear: 'Audit Year',
  Aging: 'Aging',
  Implemented: 'Implemented',
  due: 'Due',
  InternalAuditPlan: 'Internal Audit Plan ',
  ProgresstoDate: 'Progress to Date',
  PlannedExecutionMonth: 'Planned Execution Month',
  AuditStages: 'Audit Stages',
  AuditCompletion: 'Audit Completion',
  Kickoff: 'Kick-off',
  ProcessUnderstanding: 'Process Understanding',
  rcmApm: 'RCM/APM',
  Fieldwork: 'Fieldwork',
  DraftReport: 'Draft Report',
  FinalReport: 'Final Report',
  ApprovedAuditPlanExecutionStatus: 'Approved Audit Plan Execution Status',
  pending_for_first_time_login: 'Pending for first time login',
  resourceAllocation: 'Resource Allocation',
  assessment_desc: 'Assessment Description',
  configure_impact_effectiveness: 'Configure Impact Analysis',
  time_frame_based_assessment: 'Time frame based assessment',
  factors_based_scoring: 'Factors based scoring',
  scoring_configurtion: 'Scoring Configuration',
  scoring_Factors: 'Scoring Factors',
  scoringCalculation: 'Scoring Calculation',
  rangeAnalysis: 'Range Analysis',
  addSeverityValues: 'Add Severity Values',
  available_inscoring_factors: 'Available in Scoring Factors',
  configure_Severity_Values: 'Configure Severity Values',
  factors: 'Factors',
  severityValue: 'Severity Value',
  severityValues: 'Severity Values',
  controlCategory: 'Control Category',
  cmpare_years: 'Compare two years',
  cmparison_years: 'Compliance comparison of 2 years',
  typeHereCalc: 'Type here',
  drag_drop_files: 'Drag and Drop files here or',
  file_formats: 'Multiple file formats are supported',
  groups: 'group',
  newTab: 'New Tab',
  ADDITIONAL_FIELDS: 'ADDITIONAL FIELDS',
  response: 'Response',
  risksbyentity: 'Risks by Entity',
  risksbysector: 'Risks by Sector',
  risksbymegaprocess: 'Risks by Mega Process',
  riskbymajorprocess: 'Risks by Major Process',
  programid: 'PROGRAM ID',
  risksbycategory: 'Risks by Category',
  risksbystatus: 'Risks by Status',
  riskbydepartment: 'Risks by Department Level1',
  riskbydepartmentlevel: 'Risks by Department Level2',
  reportingTab: 'Reporting Tab',
  byResidual: 'by Residual Risk Rating',
  entity_residual: 'Entity ESR',
  sector_residual: 'SectorMaster',
  region_residual: 'Region',
  functions_residual: 'Functions',
  department_residual: 'Department',
  section_residual: 'Section',
  departmentl3_residual: 'Department L3',
  departmentl4_residual: 'Department L4 ',
  exportBy: 'Exported By',
  exportOn: 'Exported On',
  copy_of: 'Copy of',
  attended_by: 'Attended By',
  text: 'Text',
  mr_by: 'Management Responses by',
  editHoliday: 'Edit Holiday',
  addHoliday: 'Add Holiday',
  factor_field: 'Factors available in scoring',
  expressionCalc: 'Expressions containing factors and functions',
  evaluateCategories: 'Evaluate the impact based on the duration of impact categories.',
  evaluateFactor: 'Evaluate the impact based on predefined impact scoring factors.',
  triggerType: 'Trigger Type',
  workflowName: 'Workflow Name',
  workflowId: 'Workflow ID',
  selectDuration: 'Select duration',
  startOf: 'Start of',
  endOf: 'End of',
  halfYear: 'Half Year',
  show_all_fields: 'Show all fields',
  notaccepted: 'Not accepted',
  reqOn: 'Requested On',
  select_version: 'Select at least one version.',
  annexure_object_editor: 'Annexure - Object Editor',
  create_annexure: 'Create annexure',
  edit_annexure: 'Edit annexure',
  doc_deleted: 'Document deleted successfully',
  annexure_templates: 'Annexure Templates',
  annexure_overview: 'Annexure Overview',
  procedure_overview: 'Procedure Overview',
  conclusion_overview: 'Conclusion Overview',
  add_templates: 'Add templates',
  create_procedure: 'Create procedure',
  edit_procedure: 'Edit procedure',
  create_conclusion: 'Create conclusion',
  edit_conclusion: 'Edit conclusion',
  compare: 'COMPARE',
  in: 'in',
  all_fields: 'All fields',
  approvals: 'Approvals',
  approverComment: 'Approver Comment',
  requestorComment: 'Requestor Comment',
  requestApprovedOn: 'requested approval on',
  no_quick_actions_found: 'No Quick Actions Added',
  addCause: 'Add Cause',
  addImpact: 'Add Impact',
  selectBaseYr: 'Select Base Year',
  selectComparison: 'Select Comparison Year',
  required_fields_only: 'Required fields only',
  selectedSheet: 'Select Sheet to Import',
  sheetIdentifier: 'Select Identifier from Sheet',
  dependancyModule: 'Dependency module',
  selectSheetTo: 'Select Sheet To Import',
  referenceFrom: 'Reference field from',
  deleteConfirmationDependency: 'Are yo sure you want to delete this dependency',
  selectField: 'Select Field',
  endInfoFieldMapping: 'Map each field to its corresponding pair for accurate data consistency',
  fieldsFromMod: 'Fields from module',
  fieldsFromDoc: 'Fields from Document',
  importGeneric: 'Generic Import',
  zoomIn: 'Zoom In',
  zoomOut: 'Zoom Out',
  lock_record: 'Lock record',
  approval_request: 'Request approval',
  notify_users: 'Alert someone',
  assign_people: 'Assign people',
  generate_document: 'Generate document',
  approve_node: 'If approved',
  reject_node: 'If declined',
  select_action: 'Select an action from this list',
  add_condition: 'Add condition',
  customize_message: 'Customize message',
  type_of_approval: 'Type of approval',
  edit_workflow: 'Edit workflow',
  lock_record_description: 'Prevent making changes from users',
  unlock_record_description: 'Allow users to make changes',
  publish_record_description: 'Save the records when specified conditions are met',
  unpublish_record_description: 'Save the records as draft when specified conditions are met',
  unlock_condition_title: 'Authorize the users/user group to unlock this record',
  lock_dependencies: 'Lock dependencies',
  select_field: 'Select field',
  select_user_group: 'Select user group',
  select_users: 'Select users',
  trigger: 'Trigger',
  select_trigger: 'Select trigger',
  select_module: 'Select module',
  is_changed: 'is changed',
  trigger_descripion: 'A trigger is an event or condition that starts a workflow.',
  initial_responder_desc: 'The approval or rejection is determined by the initial response from any of the designated approvers.',
  unanimous_desc: 'Consensus among all parties is required for approval. Otherwise, the request will be rejected.',
  send_specific_users: 'Send to specific users',
  send_tagged_in_record: 'All users tagged in the record',
  insert_field_placeholder: 'Type # to insert field.',
  otherwise: 'Otherwise',
  create_from_template: 'Create a workflow from a template',
  use_template: 'Use template',
  bulkEdit: 'Bulk Edit',
  relatedFieldsAdded: 'Related fields added to the form',
  relatedFieldsRemoved: 'Related fields removed',
  selectOneRow: 'Please select atleast one row from the selected data list',
  publish_record: 'Publish record',
  un_publish_record: 'Unpublish record',
  update_a_field: 'Update fields',
  factor: 'Factor',
  auditControlRatingFromWp: 'Audit control rating from working paper',
  enableAuditControlRating: 'Enable audit control rating',
  auditControlRatingSubtext: 'Control rating evaluates the effectiveness of the control from working papers. Select the Dynamic Rating or Static Rating options for evaluation.',
  staticRating: 'Static Rating',
  staticRatingSubtext: 'Control effectiveness is directly evaluated from the working paper.',
  dynamicRatingSubtext: 'Control effectiveness is assessed using various factors. Configure these factors and the calculation method using the link below.',
  configureAuditControlRatingFactors: 'Configure Audit Control Rating Factors',
  create_control: 'Create Control',
  createCompliance: 'Create Compliance',
  isNot: 'is not',
  contains: 'contains',
  doesntContains: 'doesn\'t contains',
  Draft: 'Draft',
  minimumLimitExceeded: 'Minimum  limit exceeded',
  annexure_id: 'Annexure ID',
  conclusion_id: 'Conclusion ID',
  youCanUpload: 'You can upload files with the following extensions',
  procedure_id: 'Procedure ID',
  tag_annexure: 'Tag annexure',
  select_at_least_one_annexure: 'Please select at least one annexure.',
  all_annexures_are_tagged: 'All annexures are already tagged.',
  expandView: 'Expanded View',
  fieldMapping: 'Field Mapping',
  controlRatingFactor: 'Control Rating Factors',
  useOneTimePassword: 'Use a one-time password authenticator on your mobile device or computer to enable two-factor authentication (2FA).',
  weRecommendCloudBased: 'We recommend cloud-based mobile authenticator apps such as Microsoft Authenticator, Google Authenticator and LastPass. They can restore access if you lose your hardware device.',
  scanTheCode: 'Can\'t scan the code? To add the entry manually, provide the following details to the application on your phone.',
  account: 'Account',
  key: 'Key',
  registerTwoFactorAuth: 'Register Two-Factor Authenticator',
  select_an_option: 'Select an Option',
  templateSmall: 'template',
  create_new_observation: 'Create New Observation',
  tag_items_from_wp: 'Tag items from working paper',
  create_new_issue: 'Create New Issue',
  no_required_fields: 'No required fields',
  want_to_create_observation: 'Want to create observation with this information ?',
  create_recommendation: 'Create recommendation',
  add_desc: 'Add Description',
  expand: 'Expand',
  auto_lock: 'Auto-Lock',
  field_upon_mr_submission: 'Field Upon MR Submission',
  setDays: 'Set Days',
  setTimeOut: 'Set Time-Out',
  auditControlRatingFactors: 'Audit control rating factors',
  departmentDetails: 'Department Details',
  processDetails: 'Process Details',
  gridView: 'Grid view',
  listView: 'List view',
  uploadTextInfo: 'You can upload files with the following extensions',
  avtivitylog: 'Activity Log',
  formatText: 'Format Text',
  minimum_threashold: 'This value is below the minimum threshold',
  valueLimit: 'The value provided is not within the allowed limits',
  splCharLimit: 'special character not allowed',
  addStrategy: 'Add Strategy',
  addAn: 'Add an',
  editAn: 'Edit an',
  resetPosition: 'Reset position',
  addComment: 'Add Comments',
  strategyManagement: 'Strategy Management',
  auditManagement: 'Audit Management',
  complianceManagement: 'Compliance Management',
  assetManagement: 'Asset Management',
  riskUniverse: 'Risk Universe',
  compliancePrograms: 'Compliance Programs',
  workflowNotAdded: 'Workflow not added',
  imageFormats: 'jpg,png,jpeg',
  createdOn: 'Created On',
  fitToScreen: 'Fit to screen',
  resetZoom: 'Reset Zoom',
  enableAutoFit: 'Enable Auto Fit',
  addIcon: 'Add Icon',
  Show_in_grid: 'Show in Grid',
  hide_blank_fields: 'Hide Blank Fields',
  show_mandatory_fields: 'Show Mandatory Fields',
  genericImport: 'Generic Import',
  selectSheetImport: 'Select sheet to import',
  selectSheet: 'Select sheet',
  selectIdentifierFromSheet: 'Select Identifier from Sheet',
  dependencyModule: 'Dependency module',
  addDependency: 'Add dependency',
  referenceField: 'Reference field',
  selectReferenceField: 'Select reference field',
  referenceTo: 'Reference to',
  infoFieldMapping: 'Map fields to the module to import data from the document',
  editable: 'Editable',
  attachDocument: 'Attach Document',
  addCommentStatus: 'Add Comment',
  cofigure_charts: 'Configure Charts',
  bookmark: 'Bookmark',
  selectTemplates: 'Select Templates',
  assignAssesments: 'Assign Assessments',
  manageWorkflows: 'Manage Workflows',
  userRoleContributor: 'CONTRIBUTOR',
  print_report: 'Print Report',
  related_dep: 'Related dependencies',
  last_updated_on: 'Last updated on',
  replace: 'Replace',
  improvement_mr_log: 'Improvement MR Log',
  select_one_dependency: 'Please select atleast one dependency',
  bulk_mr: 'Request Management Response',
  next_stage: 'Next stage',
  max_stages: 'Maximum stages reached',
  meeting_lost: 'This will permanently cancel the',
  Organizational_Levels: 'Organizational Levels',
  resource: 'Resource',
  rename: 'Rename',
  set_as_target: 'Set as Target',
  set_as_baseline: 'Set as Baseline',
  analysis: 'Analysis',
  survey_template: 'Template',
  signed: 'Signed',
  viewInNewTab: 'View in new tab',
  fileUpload: 'File Upload',
  draw: 'Draw',
  fetch_users_from_RiskRegister: 'Fetch users from the Risk Register',
  time_spend_by_audit_stage: 'Time Spend By Audit Stage',
  time_spend_by_user: 'Time Spend By User',
  overflow_progress: 'Overall Progress',
  budget: 'Budget',
  hr: 'Hr',
  min: 'Min',
  time_spend: 'Time Spent',
  budgeted_hours: 'Budgeted Hours',
  module_name: 'Module Name',
  show_dependency_in_quick_view: 'Show Dependency in Quick View',
  recoveryResource: 'Recovery Resource',
  maxFieldLengthIs: 'The maximum field length is',
  tagAssessment: 'Add Assessment',
  tagControlTesting: 'Add Control Testing',
  link: 'Link',
  linkControls: 'Link Controls',
  linkRisks: 'Link Risks',
  signOption: 'Sign options',
  dragSignature: 'Drag the signature or date into the section where significant data is required.',
  acceptAndSign: 'Accept & Sign',
  processing: 'Processing',
  uploadSignature: 'Upload an image or a scan of your signature.',
  documentUpload: 'Document Upload',
  ratingCalculation: 'Rating Calculation',
  scoring_Range: 'Scoring Range',
  controlCoverage: 'Control Coverage',
  riskByRating: 'Risk by Rating',
  riskCoverage: 'Risk Coverage',
  auditByStatus: 'Audit by Status',
  byRating: 'by Rating',
  hierarchy: 'Hierarchy',
  velocity: 'Velocity',
  parent: 'Parent',
  addSignatories: 'Please add atleast one signatories',
  addFile: 'Please add the proper file',
  signatoriesNotAdded: 'Signatories not added',
  last_Modified_by: 'Last Modified By',
  last_Modified_date: 'Last Modified Date',
  selectSignatory: 'Select Signatory',
  collapse: 'Collapse',
  descriptiontext: 'Description:',
  markAsIncomplete: 'Mark as incomplete',
  markAsCompleted: 'Mark as completed',
  assign: 'Assign',
  assignTO: 'Assign to',
  submitControlWithoutRating: 'This will create and tag the control without dynamically affecting the risk.',
  complianceRecommendation: 'Compliance Recommendation',
  setupRiskManagementTeam: 'Setup Risk Management Team',
  teamEnableText: 'Do you have multiple team managing the risk module ?',
  teamDescription: 'Selecting \'Yes\' enables the system to customize the user experience according to the organization\'s structure. This ensures that dedicated teams for Compliance, ERM, or Internal Audit can access and manage their specific areas effectively.',
  teamLabel: 'Team Label',
  Duplicate_as_control: 'Duplicate as control',
  noDependancyToshow: 'No dependencies to show.',
  configureDependancy: 'Configure dependencies',
  attach_template: 'Attach template',
  compliance_obs: 'Compliance Observation',
  noEditableFields: 'No editable fields. Enable the editable option in the object editor to allow modifications'

};
