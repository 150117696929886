import { Injectable } from '@angular/core';
import { SetupWizardService } from '../service/setup-wizard/setup-wizard.service';

@Injectable({
  providedIn: 'root'
})

export class ResolveSetupWizardGuard {
  constructor(private _setupwizard: SetupWizardService) {}
  resolve() {
    return this._setupwizard.checkSetupWizardStatus();
  }
}
