export const stractArabicTranslation = {
  refresh: 'Refresh',
  back_to_set_performance_plan: 'Back to Set Performance  Plan',
  manage_goals: 'Manage Goals',
  added_from_library: 'Added from Library',
  choose_logo: 'Choose an Icon',
  change: 'Change',
  same_as_performance_plan: 'Same as performance plan',
  perspective_name: 'Perspective Name',
  perspective_logo: 'Perspective Logo',
  role_description: 'The Perspective Admin is the individual responsible for managing access, configurations and permissions within a specific perspective.',
  perpsective_admin: 'Perspective Admin',
  perspective_visibility_rules_description: 'Identify the users, user groups, or the organisational departments who can access this Perspective. The Perspective Admin will be automatically granted visibility.',
  save_and_exit: 'Save and Exit',
  next_set_goal: 'Next: Set Goal',
  create_new_perspective: 'Create New Perspective',
  roledesignation: 'Role Designation',
  visibility_rules: 'Visibility Rules',
  set_kpi: 'Set KPI',
  assign_role_to: 'ASSIGN ROLE TO',
  recipient: 'Recipient',
  goal: 'Goal',
  goalID: 'Goal ID',
  more_details: 'MORE DETAILS',
  add_more: 'Add More',
  goals: 'Goals',
  frequency: 'Frequency',
  performance_create_page_title: 'Performance Planning Tool',
  performance_create_page_description: 'The Performance Plan ensures clear tracking of organizational progress through time- or theme-bound goals. It is organized into multiple levels, each aligned with the parent level, and includes KPIs to provide actionable insights for strategic decision-making.',
  customise_layout: 'Customise Layout',
  show_pins: 'Show Pins',
  edit_performance_plan: 'Edit Performance Plan',
  trackingDetails: 'Tracking Details',
  editKpi: 'Edit KPI',
  goal_visibility_rules_description: 'Identify the users, user groups, or the organisational departments who can access this Goal. The Goal Owner and the Goal Reviewer will be automatically granted visibility.',
  perspective_details: 'Perspective Details',
  description: 'Description',
  perspective_owners: 'Perspective Owners',
  role_designation_description: 'Specify the key stakeholder for this KPI',
  role_designation_description_goal: 'Specify the key stakeholder for this Goal',
  myTask: 'My Tasks',
  addKPI: 'Add KPIs',
  setKPI: 'Set KPIs',
  previousMonth: 'Previous month',
  readmore: 'Read more',
  checkIn: 'Check-in',
  currentScore: 'Current score under review',
  thisMonth: 'This Month',
  lastUpdate: 'Last updated on',
  score: 'Score',
  threshold1: 'Threshold 1',
  threshold2: 'Threshold 2',
  threshold3: 'Threshold 3',
  threshold4: 'Threshold 4',
  threshold5: 'Threshold 5',
  preview: 'Preview',
  details: 'Details',
  prespective: 'Perspective',
  goalName: 'Goal Name',
  goalOwner: 'Goal Owners',
  goalRev: 'Goal Reviewer',
  pastPerf: 'Past Performance Data',
  kpis: 'KPI',
  manageKpi: 'Manage KPIs',
  addNewCollection: 'Add New Collection',
  expand: 'Expand',
  collapse: 'Collapse',
  prgmEnhancement: 'Engagement Program Enhancement',
  workforceTurnover: 'Workforce turnover Stability',
  selectExistingKPI: 'Select existing KPIs or create new KPIs to add under the Goal.',
  previewSubmittedScore: 'This toggle allows you to preview your submitted score even while it\'s under review.',
  perspective_form_input: 'Perspective :',
  goal_form_input: 'Goal :',
  kpi_name_form_input: 'KPI Name',
  objective_form_input: 'Objective :',
  more_details_form_input: 'MORE DETAILS :',
  create_new_kpi: 'Create New KPI',
  plan_delete_modal_head: 'You are about to delete this Performance Plan',
  plan_delete_modal_subtitle: 'Are you sure? You cannot undo this action.',
  set_perspective: 'Set Perspectives',
  exit_tool: 'Exit Tool',
  edit_plans: 'Edit Performance Plan',
  set_goals: 'Set Goal',
  edit_perspective: 'Edit Perspective',
  edit_goal: 'Edit Goal',
  saveandExit: 'Save and Exit',
  target: 'Target',
  kpiDetails: 'KPI Details',
  goalDetails: 'Goal Details',
  relatedItems: 'Related Items',
  createNewGoal: 'Create New Goal',
  setGoal: 'Set Goal',
  setPerspectiveDesc: 'A Perspective is a focused viewpoint for assessing alignment with strategic goals. Add, Edit or Remove Perspectives under the Performance Plan here.',
  set_goal_desc: 'Define the highest level of the performance plan that guides strategic efforts and decision-making processes, aligning with the organizational vision. Creating high-level specifications in the performance planning tool helps clarify the key aims of your team or organization.',
  measurement_method: 'Measurement Method',
  detailOveriew: 'Detailed Overview',
  moreDetails: 'More Details',
  perf: 'Performance',
  previousValue: 'Previous Value',
  currentValue: 'Current Value',
  minValue: 'Minimum Value',
  maxValue: 'Maximum Value',
  kpi_owner: 'KPI Owners',
  kpiReviewer: 'KPI Reviewer',
  goal_owner: 'Goal Owners',
  goalReviewer: 'Goal Reviewer',
  kpiName: 'KPI Name',
  objective: 'Objective',
  objectives: 'Objectives',
  min: 'Min',
  max: 'Max',
  filter: 'Filter',
  perspectiveDesc: 'The Strategic Perspective identifies key focus areas that help make sure that Goals and Key Performance Indicators (KPIs) are in line with the overall mission and objectives of the organization.',
  log_added_commnet: 'A new comment has been added by',
  kpi_created_by: 'KPI Created by',
  goalCreatedBy: 'Goal Created by',
  lower_is_better_tooltip_message:
    'Inverts the performance indicator for the KPI, reflecting that lower scores are more desirable. In case of Balanced KPis (2 color and 3 color balanced), this setting is not applicable.',
  kpi_owner_tooltip_template:
    'Responsible for regular score updates and receives automated reminders when a check-in date is set.',
  kpi_reviewer_tooltip_template:
    'Responsible for approving scores submitted by owners.',
  goal_owner_tooltip_template:
    'Responsible for regular score updates and receives automated reminders when a check-in date is set.',
  goal_reviewer_tooltip_template: 'Responsible for approving scores submitted by owners.',
  goal_admin_tooltip_template: 'Responsible for managing access, configurations, and permissions.',
  kpi_admin_tooltip_template: 'Responsible for managing access, configurations, and permissions.',
  enable_score_tooltip: 'The normalized score for the KPI is displayed within the range of 0 to 10. This value can be used in Goal scoring.',
  kpi_reviewer_tooltip: 'KPI Reviewers review check-ins made by KPI Owners, offering insights and approvals',
  same_as_performace_tooltip: 'Match the duration of the associated Performance Plan.',
  grace_period_tooltip: 'Grace Period allows additional time beyond the check-in date for KPI reporting',
  automaticallyIncludeGoalAdmin: 'Include the Goal Admin',
  automaticallyIncludeKPIAdmin: 'Include the KPI Admin',
  goal_lower_is_better_tooltip_message:
    'Inverts the performance indicator for the goal, reflecting that lower scores are more desirable. In case of Balanced goals (2 color and 3 color balanced), this setting is not applicable.',
  noGoalsToShow: 'No Goals to show',
  noKPIsToShow: 'No KPIs to show',
  onDay: 'ON DAY',
  onThe: 'ON THE',
  on: 'ON',
  of: 'OF',
  total_admin_users: 'Total Admin Users ',
  goalAdmin: 'GOAL ADMIN',
  total_users_designated: 'Total Users Designated',
  users: 'Users',
  department: 'Department',
  equation: 'Equation',
  create_plan: 'Create Plan',
  performance_plan: 'Performance Plan',
  kpi_check_in_open_popup_message: 'By confirming your action, your Check-in score will be recorded in the system. If a reviewer is assigned, the details will be sent to them for further review.',
  kpi_check_in_review_popup_message: 'By confirming your action, the review details will be returned to the assigned owner for further corrections.',
  kpi_check_in_closed_popup_message: 'By confirming your action, the Check-in score will be updated in the system and the task will be closed.',
  perspective: 'Perspective',
  perspective_icon: 'PERSPECTIVE ICON',
  checkinPreview: 'Preview unreviewed score',
  checkInTask: 'Check-in Task',
  checkInHistory: 'Check-in History',
  manualCheckIn: 'Manual Check-in',
  updatedDate: 'Update Date',
  attachment: 'Attachment',
  chooseFile: 'Choose Files',
  noFiles: 'No files chosen',
  reviewTask: 'Review Task',
  backCheckIN: 'Back to Check-In',
  historyCheckin: 'History of Check-In',
  checkinDetails: 'This view enables goal Owners and Reviewers to access their task list. Goal Owners have the capability to check-in, as well as to view a history of check-ins. Goal Reviewers can review and approve check-in values submitted by the goal Owners.',
  kpi: 'KPI',
  fallback_checkbox: 'Enable Fallback Value?',
  fallback_value: 'FALLBACK VALUE',
  custom_value: 'CUSTOM VALUE',
  performance_plan_owner_tooltop: 'The Performance Plan Owner is the individual responsible for overseeing and managing a specific performance plan within an organization',
  inclusive_tooltip: 'Specify which users have visibility into the perspective. Only the selected users will be able to access and view the perspective, ensuring that sensitive or confidential information is restricted to authorized individuals',
  exlusive_tooltip: 'Exclude specific users form having visibility into the perspective. Any users selected under this rule will be restricted from accessing or viewing the perspective, providing a means to control access and protect sensitive information from unauthorized individuals.',
  inclusive_tooltip_goal: 'Specify which users have visibility into the goal. Only the selected users will be able to access and view the goal, ensuring that sensitive or confidential information is restricted to authorized individuals',
  exlusive_tooltip_goal: 'Exclude specific users form having visibility into the goal. Any users selected under this rule will be restricted from accessing or viewing the goal, providing a means to control access and protect sensitive information from unauthorized individuals.',
  inclusive_tooltip_kpi: 'Specify which users have visibility into the KPI. Only the selected users will be able to access and view the KPI, ensuring that sensitive or confidential information is restricted to authorized individuals',
  exlusive_tooltip_kpi: 'Exclude specific users form having visibility into the KPI. Any users selected under this rule will be restricted from accessing or viewing the KPI, providing a means to control access and protect sensitive information from unauthorized individuals.',
  save_layout: 'Save layout',
  kpi_Insights: 'KPI Insights And Feedbacks',
  goal_Insights: 'Goal Insights And Feedbacks',
  alert: 'Alerts',
  recentUpdate: 'Recent Updates',
  basic_details: 'Basic Details',
  startEndDateCheckforStract: 'End date should be greater than or equal to start date.',
  modified_on: 'Modified on',
  modified_by: 'Modified by',
  exit_tool_confirmation_header: 'Exit Tool Confirmation',
  exit_tool_confirmation_sub: 'Are you sure you want to exit the tool? Any unsaved progress will be lost.',
  exit_tool_confirmation_cancel: 'Cancel',
  exit_tool_confirmation_exit: 'Exit without Saving',
  exit_tool_confirmation_save: 'Save and Exit',
  goal_score: 'Goal Score',
  kpi_score: 'KPI Score',
  kpi_admin: 'KPI ADMIN',
  dayStartingfrom: 'day, starting from',
  scoringType: 'Scoring Type',
  obj: 'Objective',
  prespective_admin_tooltip: 'The Perspective Admin is the Individual responsible for managing Access, Configurations and Permissions for the specific Perspective',
  plan_admin_tooltip: 'The Performance Plan Admin is the Individual responsible for managing Access, Configurations and Permissions for the specific Perspective',
  performance_plan_owner: 'Performance Plan Owner',
  data_entry: 'Measurement Type',
  select_data_source: 'Select Data Source',
  addandExit: 'Add and Exit',
  addandContinue: 'Add and Continue',
  selectDataColoumn: 'Select Data Column',
  identifyLatestValueBy: 'Identify Latest Value by',
  selectTimePeriodColoumn: 'Select Time Period Column',
  selectTimeStampFormat: 'Select Time Stamp Format',
  duplicationLogic: 'Deduplication Logic incase of Same Time Stamp',
  updateHistoricalData: 'Sync Historical Data',
  syncOption: 'Sync Option',
  indexRange: 'Index Range',
  indexRangeInfo: 'Specify the range of rows to consider for synchronization based on the chosen frequency. For example, if the frequency is monthly and the range is 0-12, it will sync the first 12 rows, treating them as monthly data points',
  index_coloumn: 'Index Column',
  indexColoumnInfo: 'Select the column to use for identifying the row index for syncing.',
  synTimeStampInfo: 'Choose the column containing timestamps for synchronization.',
  sync_timeStamp_coloumn: 'Sync TimeStamp Column',
  syncEndDateInfo: 'Ensure the sync end date does not exceed the KPI start date.',
  fallBackInfo: 'This value is used in case you miss the check-in deadline.',
  syncOptionInfo: 'Choose the method for historical data synchronization: by time range or index range',
  duplicationLogicInfo: 'Specify the logic to handle duplicate records',
  selectTimePeriodColoumnInfo: 'Choose the timestamp column to use for identifying the time period for syncing.',
  identifyLatestValueByInfo: 'Select the method to identify the latest value: first row, last row, or timestamp column.',
  selectedDataInfo: 'Choose the column to be used as the Value.',
  sync_start_date: 'Sync Start Date',
  sync_end_date: 'Sync End Date',
  same_as_KPI_Check_in_Time_Range: 'Same as KPI Check-in Time Range',
  riskAllocation: 'Risk Allocation',
  aggregationMethod: 'Aggregation Method',
  aggregrationMethodInfo: ' Specify how risk is allocated and aggregated, with options such as proportional weightage, custom weightage, and critical risk configured to your risk assessment needs.',
  associatedRisk: 'Associated Risks',
  riskScore: 'Risk Score',
  riskOverview: 'Risk Overview',
  riskScorinMethod: 'Risk Scoring Method',
  totalRiskAllocated: 'Total Risks Associated',
  avgRiskScore: 'Average Risk Score',
  avgResidualScore: 'Average Residual Rating',
  avgInherentRating: 'Average Inherent Rating',
  prvsRiskScore: 'Previous Risk Score',
  addedToKPI: 'added to KPI',
  removedFromKPI: 'has been removed from KPI',
  addedTo: 'added to',
  removedFrom: 'has been removed from',
  riskTitle: 'Risk Title',
  goalAggregationMethodInfo: 'Specify how to allocate and aggregate risk for this Layer, with options such as roll-up, proportional weightage, custom weightage, and critical risk configured to your risk assessment needs',
  residualRating: 'Residual Rating',
  inherentRating: 'Inherent Rating',
  riskOwner: 'Risk owner',
  riskManager: 'Risk Manager',
  linkedOn: 'Linked on',
  Weightage: 'Weightage',
  keyRisk: 'Key Risk',
  maxRiskScore: 'Max Risk Score',
  library_selected: 'Selected items will appear here',
  add_from_library: 'Add from Library',
  grace_period_tooltip_goal: 'Grace Period allows additional time beyond the check-in date for goal reporting',
  grace_period: 'Grace Period',
  plan_owner_validation_error: 'Plan Creator cannot be removed',
  draft_tooltip: 'This item was recently added from the library. Please fill in additional details to proceed to the next step',
  dataSource: 'Data Source',
  dataName: 'Data Name',
  dataOwner: 'Data Source Owner',
  sourceType: 'Source Type',
  lastExtraction: 'Last Extraction On',
  dataColumn: 'Data Column',
  timePeriodColum: 'Time Period Column',
  syncFrequency: 'Sync Frequency',
  syncTime: 'Sync Time',
  show_only_pinned: 'Show only pinned',
  draft: 'Draft',
  add_from_data_source: 'Add From Data Source',
  deduplication_logic_for_historical_data: 'Deduplication logic For historical data',
  dataSourceAdded: 'Data Source Added',
  choose: 'Choose',
  records: 'Records',
  liveOrLastExtract: 'Live/Last Extract',
  scenario_count: 'Scenario Count',
  date_format_validation_txt: 'The selected column\'s format does not match the supported format, Please select a format from the drop-down that matches the detected format of your selected column :',
  date_format_validation_txt_2: 'The selected column\'s format does not match any of the supported formats',
  goal_name: 'Goal name',
  owner_goal: 'Goal Owner',
  plans: 'Plans',
  duration_days: 'Duration(Days)',
  perfomancePlanwillRunfor: 'The performance plan will run for',
  time_period: 'Time Period',
  start_date_input: 'Start Date',
  end_date_opt: 'End date',
  goal_reviewers: 'GOAL REVIEWERS (OPTIONAL)',
  goal_owner_opt: 'GOAL OWNER',
  score_type: 'Score Type',
  data_type: 'Data Type',
  decimal_precision: 'Decimal precision',
  score_goal: 'Score Goal',
  minimum_value: 'Minimum value',
  maximum_value: 'Maximum value',
  due_on_every_day_starting_from: 'Due on every day starting from ',
  due_on_every_day: 'Due on every day',
  due_on_every_week_on: 'Due on every week on',
  starting_from: 'starting from',
  due_on_every: 'Due on every',
  due_on_every_month_on: 'Due on every month on',
  due_on_every_month_on_the: 'Due on every month on the',
  week_on: 'week on',
  th: 'th',
  month: 'month',
  year_on_the: 'year on the',
  visualization: 'Vizualisation',
  sample_socre: 'SAMPLE SCORE',
  kpi_id: 'KPI-ID',
  time_frame: 'Time frame',
  actions: 'Actions',
  value: 'Value',
  Risk_id: 'Risk ID',
  risk_name: 'Risk Name',
  review_comment: 'Review Comment',
  check_in_comment: 'Check-In Comment',
  updated_value: 'Updated Value',
  deadline: 'Deadline',
  check_in_by: 'Check-in By',
  check_in_period: 'Check-in Period',
  updated_on: 'Updated On',
  request_date: 'Request Date',
  all: 'All',
  owned: 'Owned',
  performancePlan: 'Performance Plans',
  measurement: 'Measurement',
  tracking_details: 'Tracking Details',
  tracking_method: 'Tracking Method',
  tracking_method_dec: 'Determine how to measure and track progress of this level, whether through normalized scoring or progress tracking, to accurately reflect performance.',
  additional_fields: 'Additional Field',
  mega_process: 'Mega Process',
  major_process: 'Major Process',
  department_level1: 'Department Level 1',
  department_level2: 'Department Level 2',
  department_level3: 'Department Level 3',
  department_level4: 'Department Level 4',
  region: 'Region',
  criticality: 'Criticality',
  entity: 'Entity',
  sector: 'Sector',
  new_value_for: 'New Value For',
  layer_measurement_type: 'Layer Measurement Type',
  layer_measurement_type_dec: 'Specify how to measure this Layer: Check-in for periodic updates, Classification for categorization, Roll-up for aggregated scores, or Equation for calculated values.',

  allow_subitems: 'Allow Sub-Items',
  topic: 'Topic',
  sub_topic: 'Sub Topic',
  topic_field: 'esg_topic',
  sub_topic_field: 'esg_sub_topic',
  priority: 'Priority',
  fiscal_year: 'Fiscal Year',
  plan_description: 'The Strategic Goal identifies key focus areas that help make sure that Initiatives, Goal KPIs and Indicators are in line with the overall mission and objectives of the organization.',
  back: 'Back',
  next_step: 'Next Step',
  subgoals: 'Sub Goals',
  subgoal: 'Sub Goal',
  complete_plan: 'Complete Plan',
  subgoal_set_page: 'Set Sub Goal and KPI',
  set_subgoal_desc: 'Break down the higher level into smaller, more specific, manageable sub-categories to achieve organizational specifications effectively, or tag specific metrics to measure their success.',
  set_kra_desc: 'Specify critical focus areas for specific sub-categories or tag specific metrics to measure their success.',
  set_obj_desc: 'Specify detailed steps toward the critical focus areas or tag specific metrics to measure their success.',
  set_kpi_desc: 'Configure specific metrics to measure the success of the highest level in the performance plan.',
  goalTitle: 'Goal Title',
  sub_goal_title: 'Sub Goal Title',
  create_new_subgoal: 'Create New Sub Goal',
  kra_set_page: 'Set KRA and KPI',
  kra: 'KRA',
  editGoal: 'Edit Goal',
  editSubGoal: 'Edit Sub Goal',
  create_new_kra: 'Create New KRA',
  objective_set_page: 'Set Objective and KPI',
  create_new_objective: 'Create New Objective',
  save_and_return: 'Save and Return',
  endDate: 'End Date',
  calculate_end_date_based_on: 'Calculate end date based on ',
  default_period_label: 'Set default value for missing period',
  set: 'Set',
  add_data_source: 'Add From Data Source',
  subItems: 'Sub Items',
  reviewer: 'Reviewer',
  visibility_rules_description: 'Identify the users, user groups, or the organisational departments who can access this KPI. The KPI Owner and the KPI Reviewer will be automatically granted visibility.',
  visibility: 'Visibility',
  create_new_performance_plan: 'Create Performance Plan',
  enter_title: 'Enter Title',
  enter_here: 'Enter Here',
  user: 'User',
  user_group: 'User Group',
  select_item: 'Select item',
  page_title: 'Performance Planning Tool',
  data_entry_desc: 'Specify how to measure this metric: Check-in for periodic updates; Add from Data Source, which lets you connect to a Data Source and automates the check-in process.',
  grace_period_dec: 'Set an additional time frame after the deadline for submitting updates without penalty.',
  balance_scorecard: 'Balanced Scorecard',
  scorecard_classification_tooltip: 'Classification layers does not support scoring mechanics. Therefore, score gauges and related metrics will not be displayed for this layer.',
  actual: 'Actual',
  endDateCheckforDataSource: 'End date should be less than or equal to current date.',
  startDateCheckforDataSource: 'start date should be less than or equal to current date.',
  dataConfiguration: 'Data Configuration',
  owners: 'Owners',
  pin: 'pin',
  unpin: 'Unpin',
  expandAll: 'Expand All',
  collapseAll: 'Collapse All',
  toggle_view: 'Toggle View',
  drag_drop_view: 'Drag and Drop to Rearrange the view',
  quick_brief: 'Quick Brief',
  classification_Layer: 'Classification Layer',
  kpi_min_value_validation: 'Minimum value should be less than Threshold 1',
  kpi_threshold1_validation: 'Threshold 1 should be greater than Minimum value',
  kpi_threshold2_validation: 'Threshold 2 should be greater than Threshold 1',
  kpi_threshold3_validation: 'Threshold 3 should be greater than Threshold 2',
  kpi_threshold4_validation: 'Threshold 4 should be greater than Threshold 3',
  kpi_threshold5_validation: 'Threshold 5 should be greater than Threshold 4',
  kpi_max_value_validation: 'Maximum value should be greater than last Threshold value',
  list_items: 'List Items',
  list_validation: 'Value must be or in between Minimum and Maximum value',
  bookmarks: 'Bookmarks',
  visibilityTooltip: 'Determines who can view and access the current level.',
  dragnDrop: 'Drag & Drop to rearrange the view',
  smart_insights: 'Smart Insights',
  additionalDetails: 'Additional Details',
  feedbackInsights: 'Feedback and Insights',
  taggedRisk: 'Tagged Risks',
  activityLog: 'Activity Log',
  dataSourceConnection: 'Data Source Connection',
  detailViewClassificationLayerMsg: 'Classification layers does not support scoring mechanics. Therefore, score gauges and related metrics will not be available for this layer.',
  overview: 'Overview',
  role_visibility_description: 'Assign roles and designations assigned to users within the system. Each role carries specific responsibilities and permissions to ensure effective management and accountability at every level.',
  organizationalDetails: 'Organizational Details',
  insights_and_feedbacks: 'Insights And Feedbacks',
  save: 'Save',
  performance_planner: 'Open in Performance Planner',
  create_kpi: 'Create KPI',
  triggered_date: 'Triggered Date',
  create_alert: 'Create Alert',
  alert_name: 'Alert Name',
  add_condition: 'Add Condition',
  field: 'Field',
  comparator: 'Comparator',
  channel_delivery: 'Channel Delivery',
  system_notif: 'System Notification',
  email_notif: 'Email Notification',
  targeted_delivery: 'Targeted Delivery',
  noRelatedItemAvl: 'No Related Items Available',
  Tree_view: 'Tree view',
  card_view: 'Card View',
  perfCenter: 'Performance Center',
  uptrend: 'Uptrend',
  downtrend: 'Downtrend',
  assigned_to_me: 'Assigned to me',
  add_attachments: 'Add attachments',
  send: 'Send',
  targetted_user: 'Targeted Delivery User',
  targetted_user_group: 'Targeted Delivery User Group',
  conditions: 'Conditions',
  edit_alert: 'Edit Alert',
  sub_goal: 'Sub Goal',
  single_alert: 'Alert'

};
