import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { AnonymousSubject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})

export class SocketService {
  // rxjs subject to subscribe the value
  private subject: AnonymousSubject<MessageEvent>;
  // rxjs subject to subscribe the value for set to send message
  public senMessageSubject!: AnonymousSubject<MessageEvent>;
  // when websocket connection store to variable for to check the connection readystate,  data type is veriying here
  public wsAnalytics!: any;
  // used to create a connection to the socket
  public connect(url: string, roomName?: string):  AnonymousSubject<MessageEvent> {
    if (roomName && url?.includes(roomName)){
      this.senMessageSubject = this.create(url, roomName);
      return this.senMessageSubject;
    }
    this.subject = this.create(url);
    return this.subject;
  }

  // function used to send msg in socket , here any used message will varying against the connection
  public sendMsg(message: any): void {
    if (this.senMessageSubject) {
      // use the senMessageSubject for send msg on socket
      this.senMessageSubject.next(message);
    }
  }

  // after connection function it trigger to  creating particular socket and return a subject for subscribe
  private create(url: string, roomName?: string):  AnonymousSubject<MessageEvent> {
    // provides the API for creating and managing a WebSocket connection to a server, as well as for sending and receiving data on the connection
    const ws = new WebSocket(url);
    if (roomName && url?.includes(roomName)){
      // the socket assign variable for used to send message
      this.wsAnalytics = ws;
    }
    const observable = new Observable((observers: Observer<MessageEvent>) => {
      // Called whenever there is a message from the server.
      ws.onmessage = observers.next.bind(observers);
      // Called if at any point WebSocket API signals some kind of error.
      ws.onerror = observers.error.bind(observers);
      // Called when connection is closed (for whatever reason).
      ws.onclose = observers.complete.bind(observers);
      return ws.close.bind(ws);
    });
    // Successful operation / regular socket shutdown status
    const status = 1000;
    const observer = {
      next: (data: MessageEvent) => {
        if (ws.readyState === WebSocket.OPEN) {
          // used to send data to server
          ws.send(JSON.stringify(data));
        }
      },
      // used get observer error
      error: () => {
        // console.log(error)
      },
      //  used get event when observer closed use the connection
      complete: () => {
        ws.close(status);
      }
    };
    // used to create connection between 2 observable
    return new  AnonymousSubject(observer, observable);
  }


}
