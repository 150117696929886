<div class="error-box">
    <div class="error-body text-center">
        <h1 class="error-title text-danger">404</h1>
        <h3 class="error-subtitle text-muted">Page Not Found</h3>
        <p class="text-muted m-t-30 m-b-30">The page you were looking for could not be found.<br>To return, please click the button below.</p>
        <div class="au-flex au-gap--2 au-flex--center">
          <button  appDisableButtonApi (click)="previousPage()" class="au-btn au-btn--primary">
            {{ "Go Back" | translate }}
          </button>
          <button [routerLink]="[(!redirectUrl && adminPermission) ? '/logout' : '/home']" appDisableButtonApi class="au-btn au-btn--secondary">
            {{ (!redirectUrl && adminPermission) ? ("Back to Login" | translate) : ("Back to Home" | translate) }}
          </button>
        </div>
    </div>
</div>
