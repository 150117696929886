import { NgbTooltip, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgControl } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Renderer2, Inject, HostListener, Input, OnDestroy, Output, EventEmitter, Component, ViewContainerRef, ViewChild, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appAuSuggestionList]'
})
export class AuSuggestionListDirective implements OnChanges, OnDestroy {

  // class for suggestion list
  @Input() listClass: string = '';
  // input list
  @Input() auSuggestionList: string[] | any = [];
  // reference element as output
  @Output() element: EventEmitter<any> = new EventEmitter;

  public childNode !: ChildNode;
  public clickEvent !: PointerEvent;
  private filterList: string[] = [];
  public childElement: HTMLElement;
  public clicked: boolean = false;

  constructor(private elementRef: ElementRef, private _renderer: Renderer2, private _control: NgControl,
    @Inject(DOCUMENT) private _document: Document, private _vr: ViewContainerRef) { }

  ngOnChanges(changes: SimpleChanges) {
    if ((this.auSuggestionList.length !== 0) && (this.clicked)) {
      this.clicked = false;
      this.createSuggestionList(this.auSuggestionList);
    } else {
      if (this.childElement) {
        this._renderer.removeChild(this._document.body, this.childElement);
      }
      this.auSuggestionList = [];
    }
  }

  @HostListener('click', ['$event'])
  onClick(eventPointer: PointerEvent) {
    if (this.childElement) {
      this._renderer.removeChild(this._document.body, this.childElement);
    }
    this.clickEvent = eventPointer;
    this.childElement = this._renderer.createElement('div');
    const clintRec = this.elementRef?.nativeElement?.getBoundingClientRect();
    const halfHeight = clintRec.height;
    const yHeight = clintRec.y + 240 + halfHeight;
    const windowHeight = window.innerHeight;
    const bodyHeight = this._document?.body?.offsetHeight;
    if (windowHeight < yHeight) {
      this._renderer.setAttribute(this.childElement, 'style', `left:${this.clickEvent?.pageX - this.clickEvent?.offsetX}px;bottom:${bodyHeight - (this.clickEvent?.pageY - this.clickEvent?.offsetY)}px; width:${clintRec?.width}px;position:absolute`);
    } else {
      this._renderer.setAttribute(this.childElement, 'style', `left:${this.clickEvent?.pageX - this.clickEvent?.offsetX}px;top:${(this.clickEvent?.pageY - this.clickEvent?.offsetY) + halfHeight}px; width:${clintRec?.width}px;position:absolute`);
    }
    this._renderer.setAttribute(this.childElement, 'class', 'au-suggestion-list au-suggestion-list__opacity--0');
    this._renderer.appendChild(this._document.body, this.childElement);
    this.clicked = true;
    this.createSuggestionList(this.auSuggestionList);
  }

  @HostListener('window:scroll', ['$event'])
  scrollHandler() {
    if (this.childElement) {
      this._renderer.removeChild(this._document.body, this.childElement);
    }
  }

  @HostListener('window:touchdown', ['$event.target'])
  @HostListener('window:mousedown', ['$event.target'])
  onOutsideClick(target: HTMLElement) {
    if (this.childNode) {
      if (!this.childNode?.contains(target) && !target?.classList?.contains('au-suggestion-list')) {
        this._renderer.removeChild(this._document.body, this.childElement);
      }
    }
  }

  @HostListener('keyup', ['$event']) onKeyDown() {
    const value = this.elementRef?.nativeElement?.value;
    this.filterList = this.auSuggestionList.filter((s: string) => (s.toLowerCase()).includes(value.toLowerCase()));
    this.createSuggestionList(this.filterList);
  }

  createSuggestionList(suggestionListItems: string[]) {
    if (this.childNode) {
      this._renderer.removeChild(this.childElement, this.childNode);
    }
    this._renderer.setAttribute(this.childElement, 'class', 'au-suggestion-list au-suggestion-list__opacity--0');
    if (suggestionListItems.length !== 0) {
      this._renderer.setAttribute(this.childElement, 'class', 'au-suggestion-list au-suggestion-list__opacity--1');
      const child = this._renderer.createElement('div');
      this._renderer.setAttribute(child, 'class', `au-suggestion-list__main ${this.listClass}`);
      suggestionListItems.forEach((suggestionList: string) => {
        const div = this._renderer.createElement('div');
        this._renderer.setAttribute(div, 'class', 'au-suggestion-list__items');
        const ref = this._vr.createComponent(AuSuggestionComponent);
        (ref.instance as any).value = suggestionList;
        this._renderer.appendChild(div, ref.location.nativeElement);
        this._renderer.appendChild(child, div);
        this._renderer.appendChild(this.childElement, child);
      });
      this.element.emit(child);
      this.childNode = child;
      const listItems: ChildNode[] = child.childNodes;

      listItems.forEach((listItem: ChildNode, j: number) => {
        this._renderer.listen(
          listItem,
          'click',
          () => {
            this.spanClick(j, suggestionListItems);
          }
        );
      });
    }
  }

  spanClick(index: number, suggestionListItems: string[]) {
    if (this.childNode) {
      this._renderer.removeChild(this.childElement, this.childNode);
    }
    if (this.childElement) {
      this._renderer.setAttribute(this.childElement, 'class', 'au-suggestion-list au-suggestion-list__opacity--0');
    }
    if (this.elementRef?.nativeElement) {
      this._control?.control?.setValue(suggestionListItems[index]);
    }
  }

  ngOnDestroy(): void {
    if (this.childNode || this.childElement) {
      this._renderer.removeChild(this._document.body, this.childElement);
    }
  }
}

@Component({
  selector: 'app-au-suggestion',
  template: '<div #tooltip [ngbTooltip]="value" tooltipClass="bs-tooltip-zindex" [placement]="[\'top-left\',\'bottom-left\',\'left\', \'right\' ,]" class="au-suggestion-list__text" container="body" [animation]="false">{{value}}</div>',
  providers: [NgbTooltipConfig]
})

export class AuSuggestionComponent {
  @Input() value: string = '';
  @ViewChild('tooltip') tooltip: NgbTooltip;
  constructor() { }

  OnDestroy() {
    this.tooltip.close(false);
  }

}
