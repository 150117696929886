import { Injectable } from '@angular/core';
import { AdminUserService } from 'src/app/core/service/control-panel/admin-user.service';
import { tap } from 'rxjs';
import { SidebarPermission } from 'src/app/core/interface/common.interface';

@Injectable({
  providedIn: 'root'
})

export class ResolveGuard {
  constructor(private _admin: AdminUserService) {}
  resolve() {
    return this._admin.getSidebarPermission('', '')?.pipe(tap((v: SidebarPermission)=>{
      this._admin.sideBarSubject?.next(v);
    }));
  }
}
