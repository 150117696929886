import { Directive, ElementRef, Renderer2, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { CommonApiService } from 'src/app/core/service/common/common-api.service';
import { SubSink } from 'subsink';

@Directive({
  selector: '[appSetDirection]',
  standalone: true
})
export class SetDirectionDirective implements AfterViewInit, OnDestroy, OnInit {

  private observer!: MutationObserver;
  private subs: SubSink = new SubSink();

  constructor(private el: ElementRef, private renderer: Renderer2, private _commonApi: CommonApiService) {}

  ngOnInit(): void {
    this.applyDirection();
    this.subs.add(
      this._commonApi.getSelectedLanguage.subscribe(() => {
        this.applyDirection();
      })
    );
  }

  ngAfterViewInit() {
    this.applyDirection();
    this.observer = new MutationObserver(() => {
      this.applyDirection();
    });

    this.observer.observe(this.el?.nativeElement, { childList: true, subtree: true });
  }

  /**
  * @description To set the direction of english contents to ltr in arabic mode
   * @date 08/07/2024
   */

  private applyDirection() {
    const englishPattern = /[a-zA-Z]/;
    const content = this.el?.nativeElement.textContent.trim();
    const parentDirection = getComputedStyle(this.el.nativeElement.parentElement).direction;
    if (parentDirection === 'rtl' && englishPattern.test(content)) {
      this.renderer.setStyle(this.el?.nativeElement, 'direction', 'ltr');
    }
  }


  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
    this.subs.unsubscribe();
  }
}
