export const translateKeys = {
  audit_plans: 'All Audit Plans',
  audit_universe: 'All Audit Universe',
  create_universe: 'Create Universe',
  generate_plan: 'Generate Plan',
  create_audit_universe: 'Create an Audit Universe',
  edit_audit: 'Edit an Audit Universe',
  configure_model: 'Configure Audit Plan Model',
  select_model: 'Select the Model and Identify the Parameters',
  review_model: 'Review and Create',
  finalise: 'Finalise Audit Universe',
  generate_audit: 'Generate Audit',
  populate_audit: 'Populate Audit',
  select_audit: 'Select Audits',
  generate_universe: 'Generate Audit Universe',
  audit_summary: 'Audits Summary',
  select_dept: 'Select Departments',
  select_param: 'Selection Parameters',
  generate_steps: 'Generate Audit Plan in 4 Steps',
  modify_audit: 'Modify Audits',
  select_audit_model: 'Select Audit Plan Model',
  invalid: 'Invalid details',
  select_dimensions: 'Select Dimensions',
  select_msg1: 'Please select at least one item from the list of',
  audit_preview: 'Audit Universe Preview',
  no_data: 'No Data Available',
  select_msg2: 'Select Audits and Add to Your Plan',
  previous: 'Previous',
  more_info: 'More Information',
  select_msg3: 'Start and End Date',
  decimal_range: 'Decimal out of range',
  proceed_1: 'Proceed to create Audit Universe ?',
  generate_msg_1: 'Are you planning to prepare your audits for a year or more in advance? It can be done in a few steps that follow: First, select fields for your audit plan and choose one field on which engagements will be generated in the audit universe. Then, you can create multiple models and use them according to the audit types.',
  generate_msg_2: 'Select the audit plan model to generate the audit plan. Then choose values for each field from the sidebar, which will be under the scope of your audit plan. You can see the preview of your selection and proceed to the next step.',
  generate_msg_3: 'Based on your model selection and the values you specify, Aurex can generate audits. Before the last stage, you can review, filter, and make changes.',
  generate_msg_4: 'Now with the audits planned, you can create the audit plan. Afterwards, the audit plan and the audits are published.',
  audit_planning: 'Audit Planning',
  audit_overview: 'Audit Overview',
  audit_plan: 'Audit Plan',
  audit_uni: 'Audit Universe',
  multi_plan: 'Multi Year Audit Plan',
  send_approval: 'Send for Approval',
  risk_coverage: 'Risk Coverage',
  high_risks: 'Coverage on High Risks',
  risk_rating: 'Risks by Rating',
  control_cov: 'Control Coverage',
  key_controls: 'Coverage on Key Controls',
  control_rating: 'Controls by Rating',
  auditUniverseManagerTitle: 'AuditUniverseManagerTitle',
  all_audits: 'All Audits',
  rcm: 'RCM',
  audit_create: 'Create Audit',
  audit: 'Audit',
  update_concl: 'Update Audit Conclusion',
  audit_observ: 'Audit Observation',
  manage_response: 'Management Responses',
  recommendations: 'Recommendations',
  controls: 'Controls',
  risks: 'Risks',
  assessments: 'Assessments',
  add_observ: 'Add Observation',
  observation: 'Observation',
  observ_date: 'Observed Date',
  observ_descrip: 'Observation Description',
  department: 'Department',
  audit_area: 'Audit Area',
  rating: 'Rating',
  manage_status: 'Management Status',
  manage_feedback: 'Management Feedback',
  implement_owners: 'Implementation Owners',
  stakeholders: 'Stakeholders',
  require_review: 'Does it require review ?',
  pending_approval: 'Audit plan is currently pending approval',
  cannot_modify: 'It cannot be modified',
  audit_id: 'Audit ID',
  current_stage: 'Current Stage',
  audit_start: 'Audit start date',
  team: 'Team',
  overall_conclu: 'Overall Conclusion',
  risk_control_rate: 'RISK AND CONTROL RATINGS FOR',
  audit_status: 'Audit Program by Status',
  issue_status: 'Issue by Status',
  activity_status: 'Activity by Status',
  audit_program: 'Audit Program',
  add_risk: 'Add a Risk',
  add_control: 'Add a Control',
  add_working_paper: 'Add a Working Paper',
  unlock: 'Unlock Record',
  control: 'Add Control',
  risk: 'Add Risk',
  wp: 'Working Paper',
  activity_comp: 'Activity Completion',
  dependencies: 'Dependencies',
  create_observ: 'Create Observation',
  scoring: 'Scoring',
  summary: 'Summary',
  observ_overview: 'Observation Overview',
  add_wp: 'Add Working Papers',
  risk_control_proceed: 'Risks/Controls you have selected are already part of other audits.',
  ongoing: 'Ongoing Audits',
  add_issues: 'Add Issues',
  add_controls: 'Add Controls',
  add_risks: 'Add Risks',
  add_assessments: 'Add Assessments',
  impact: 'Impact',
  likelihood: 'Likelihood',
  overall_rating: 'Overall Rating',
  add_recommendation: 'Add Recommendation',
  view_observ: 'View observation',
  add_field: 'Add Field',
  au_plans: 'Audit Plans',
  calender: 'Calendar',
  ongoingAuditWithoutRisk: 'The selected risks are not part of any ongoing audits.',
  ongoingAuditWithoutControl: 'The selected controls are not part of any ongoing audits.',
  ongoingTitleWithAudit: 'The risks or controls you have selected are already part of other audits. Please confirm if you want to proceed.',
  audits_by: 'Audits by',
  observ_by: 'Observations by',
  observ_rating: 'Observations by Rating',
  audits_requestor: 'Audits by Requestor',
  audits_scoring: 'Audits by Sourcing',
  audits_mega: 'Audits by Mega Process',
  pending: 'Pending Approvals',
  approv_history: 'Approval History',
  audits_overview: 'Audits Overview',
  create_an_audit: 'Create an Audit',
  audit_team: 'Audit Team',
  audit_schedule: 'Audit Schedule',
  tag_risks_controls: 'Tag risks and controls',
  tagged_risks: 'Fetch tagged risks',
  group_wp: 'Group Working Papers',
  wp_generated: 'Select an option based on which working papers will be generated.',
  create_group_wp: 'Create group working paper',
  create_seperate_wp: 'Create separate working papers',
  audit_method: 'Audit Program Method',
  add_risk_parameters: 'Add Risk Parameters',
  select_parameters: 'Select Risk Parameters',
  risks_identified: 'Risks are identified',
  more_risks: 'Add More Risks',
  audits: 'Audits',
  audit_consist: 'Audits consist',
  risk_proceed_to_create: 'Risks. Proceed to create a',
  residual_likelihood: 'Residual Likelihood Risks',
  residual_impact: 'Residual Impact Risks',
  actions_type: 'Actions by Type',
  actions_aging: 'Actions by Aging',
  actions_priority: 'Actions by Priority',
  action_overview: 'Action Overview',
  issue_overview: 'Issue Overview',
  issue_id: 'Issue ID',
  risk_id: 'Risk ID',
  control_id: 'Control ID',
  activity_overview: 'Activity Overview',
  activity_id: 'Activity ID',
  activity: 'Activity',
  wp_overview: 'Working Paper Overview',
  wp_id: 'Working Paper ID',
  all_issues: 'All Issues',
  issue: 'Issue',
  source: 'Source',
  ob_id: 'Observation ID',
  ob_rating: 'Observation Rating',
  control_overview: 'Control Overview',
  control_mngr: 'Control Manager',
  control_owner: 'Control Owner',
  control_title: 'Control Title',
  control_desc: 'Control Description',
  risk_overview: 'Risk Overview',
  risk_mngr: 'Risk Manager',
  risk_owner: 'Risk Owner',
  risk_title: 'Risk Title',
  risk_ctgry: 'Risk Category',
  risk_desc: 'Risk Description',
  working_paper_add: 'Add Working Paper',
  edit_wp: 'Edit Working Paper',
  edit_observ: 'Edit Observation',
  change_assignee: 'Change Assignee',
  new_assignee: 'New Assignee',
  all_observ: 'All Observations',
  all_actions: 'All Actions',
  all_rel_obs: 'All Related Observations',
  add_issue: 'Add Issue',
  obsrv_id: 'Observation ID',
  observation_rating: 'Observation Rating',
  ctrlOverview: 'Control Overview',
  control_manager: 'Control Manager',
  control_descrip: 'Control Description',
  riskMngr: 'Risk Manager',
  riskOwner: 'Risk Owner',
  riskTitle: 'Risk Title',
  riskCat: 'Risk Category',
  riskDesc: 'Risk Description',
  risk_program_observations: 'Risk Program Observations',
  add_risk_observ: 'Add Risk Program Observations',
  incident: 'Incident',
  add_incident: 'Add Incident',
  actions_status: 'Actions by Status',
  observ_metrics: 'Observation Metrics',
  mr: 'Management Response',
  editMngResp: 'Edit Management Response',
  compliance_observ: 'Compliance Observations',
  add_complaince_observ: 'Add Compliance Observations',
  obsByRating: 'Observation by Rating',
  resByAging: 'Responses by Aging',
  reqForMR: 'Request for Management Response',
  TagRecommandation: 'Tag Recommendations',
  editMR: 'Edit Management Response',
  mrLockBadge: 'The management response is locked as the status is',
  mrLockBadgeUpdate: 'You can still update the',
  impOverview: 'Improvement overview',
  manage_comments: 'Management comments',
  send_report: 'Send Report as Email',
  risk_control_metrics: 'Risk and Control Metrics',
  benchmark: 'Benchmark',
  target: 'Target',
  survey_status: 'Survey Status',
  response_summary: 'Response Summary',
  survey_score: 'Survey Score',
  network: 'Network',
  change_view: 'Change View',
  userTime: 'Time Spent by Users',
  time_entries: 'Time Entries',
  audit_time_spent: 'Time Spent by Audit Stage',
  total_time_spent: 'Total Spent in hours',
  management_team: 'Management Team',
  auditors: 'Auditors',
  owning_group: 'Owning Group',
  analytics_automation: 'Analytics Automation',
  improvement: 'Improvements',
  allImprovements: 'All Improvements',
  auditImprovement: 'Improvement',
  obsMr: 'Observation MR',
  impMr: 'Improvement MR',
  issueTracker: 'Issue Tracker',
  auditIssueTracker: 'Audit Issue Tracker',
  issuesByStatus: 'Issues by Status',
  issuesByOwners: 'Issues by Owners',
  issuesByRating: 'Issues by Rating',
  allMR: 'All Management Responses',
  enter_more: 'Enter More Information',
  generate_audit_plan: 'Generate Audit Plan',
  all_audit_universes: 'All Audit Universes',
  setup_audit_plan_model: 'Set up Audit Plan Model',
  review_audit: 'Review Audit Plan',
  select_audit_fields: 'Select Audit Fields',
  generate_msg_5: 'The selected departments below are recommended for the upcoming year\'s audits. You can still modify the selection and generate an audit plan.',
  ongoingTitleWithOnlyRisks: 'The risks you have selected are already part of other audits. Please confirm if you want to proceed.',
  ongoingTitleWithOnlyControls: 'The controls you have selected are already part of other audits. Please confirm if you want to proceed.',
  ongoingTitleWithoutControlAndRisk: 'The selected risks or controls are not part of any ongoing audits.',
  audit_program_by: 'Audit Program by',
  issue_by: 'Issue by',
  activity_by: 'Activity by',
  editor: 'Editor',
  action_id: 'Action ID',
  title: 'Title',
  audit_name: 'Audit Name',
  status: 'Status',
  priority: 'Priority',
  cost_saving: 'Cost Saving',
  browse_program: 'Browse Program',
  browse_record: 'Browse Record',
  create_with_this_info: 'WANT TO CREATE AN OBSERVATION WITH THIS INFORMATION?',
  audit_likelihood: 'Audit Likelihood',
  audit_impact: 'Audit Impact',
  audit_rating_for_control: 'Audit Rating for control',
  update_audit_control_rating: 'Update audit control rating',
  to_edit_audit_control_rating: 'You are about to edit the audit control rating!',
  generate_audits: 'Generate Audits',
  edit_plan: 'Edit Audit Plan ',
  create_plan: 'Create Plan',
  download_report: 'Download report',
  basedOnThe: 'Based on the',
  riskBasedRating: 'risks you have selected the rating recommended for the observation is',
  updateTheScoreToProceed: 'Update the score to proceed with the recommendation.',
  audit_plan_model: 'Audit Plan Model',
  audit_notification_templates: 'Audit Notification Templates',
  schedule_templates: 'Schedule Templates',
  approval_list: 'Approval',
  audit_object_manager: 'Audit Management - Object Manager',
  audit_object_editor: 'Audit Management - Object Editor',
  audit_program_object_editor: 'Audit Program - Object Editor',
  activity_object_editor: 'Activity - Object Editor',
  audit_issue: 'Audit Issue',
  audit_observation: 'Audit Observation',
  audit_improvement: 'Audit Improvement',
  audit_recommendation: 'Audit Recommendation - Object Editor',
  object_editor: 'Object Editor',
  observ_MR: 'Observation Management Response',
  improve_MR: 'Improvement Management Response',
  activities_by: 'Activities by',
  issues_by: 'Issues by',
  selectPlan: 'Please select a plan',
  audit_plan_criteria: 'Audit Plan Criteria',
  scoring_criteria: 'Scoring Criteria',
  generate_step: 'Generate Audit Plan in 2 Steps',
  report_rating_criteria: 'Report Rating Criteria',
  audit_review: 'Audit Review',
  status_by_requester: 'Status by Requester',
  confirm_action: 'Confirm Your Action',
  actions_request: 'Actions Requested by',
  add_comments: 'Add comments if any',
  controlRating: 'Control Rating',
  controlScore: 'Control Score',
  add_departments: 'Add More Departments',
  entitiesProceed: 'Entities. Proceed to create a',
  tiers: 'Tiers',
  entities: 'Entities',
  departments: 'Departments',
  selectDepartment: 'Select Department Parameters',
  departmentsIdentified: 'Departments are Identified',
  filterByAuditPlan: 'Filter by Audit Plan',
  default_value_audit_schedule: 'You can set default values in the template which will be further editable during audit schedule.',
  default_value_compliance_audit_schedule: 'You can set default values in the template which will be further editable during compliance audit schedule.',
  default_value_risk_program_schedule: 'You can set default values in the template which will be further editable during risk program schedule.',
  default_value_bcm_audit_schedule: 'You can set default values in the template which will be further editable during BCM audit schedule.',
  select_audit_universe_model: 'Select Audit Universe Model',
  management_users: 'Management Users',
  action_owner: 'Action Owner',
  implementation_owner: 'Implementation Owner',
  observation_business_user: 'Observation Business User',
  complianceId: 'Compliance ID',
  statusOfCompliance: 'Status Of Compliance',
  obsId: 'Observation ID',
  obsTitle: 'Observation Title',
  businessUser: 'Business User',
  statusOfRP: 'Status Of Risk program',
  Risk_Program_ID: 'Risk Program ID',
  audit_criteria_msg: 'The glossary below defines the terms used to recommend departments for audit plan. Review and select the departments on the next page to generate the audit plan.',
  high_risk: 'High Risks',
  risk_score: 'Risk Score',
  moderate_risk: 'Moderate Risks',
  low_risk: 'Low Risks',
  total_risks: 'Total Risks',
  weighted_avg_score: 'Weighted Average Scores',
  last_audited: 'Last Audited',
  last_multiplier: 'Last Audited Multiplier%',
  total_risk_score: 'Total Risk Score',
  weighted_risk_category: 'Weighted Average Risk Category',
  total_inherent_risk: 'Total number of risks tagged to each department with inherent rating high.',
  sum_of_risk_scores: 'Sum of inherent scores of risks tagged to each department.',
  weightage_to_calculate_inherent_risks_rated_high: 'Weightage assigned to high risks to calculate the weighted average score. \n Calculation: Sum of risk scores/Total number of high risks * 50%',
  total_risks_rated_moderate: 'Total number of risks tagged to each department with inherent rating moderate.',
  weightage_to_calculate_inherent_risks_rated_moderate: 'Weightage assigned to moderate risks to calculate the weighted average score. \n Calculation: Sum of risk scores/Total number of moderate risks * 30%',
  total_risks_rated_low: 'Total number of risks tagged to each department with inherent rating low.',
  weightage_to_calculate_inherent_risks_rated_low: 'Weightage assigned to low risks to calculate the weighted average score. \n Calculation: Sum of risk scores/Total number of low risks * 20%',
  risks_rated_total: 'Total number of risks tagged to each department.',
  weighted_average_sum: 'Sum of the weighted averages of high (50%), moderate (30% ), and low (20%) risks.',
  recent_audit: 'The year when the department was last audited.',
  last_audit_year: 'Weightage based on the year the department was last audited. \n\n 80% - If the department is being audited in the current year. \n 85% - If the department has been audited in the previous year. \n 90% - If the department was not audited in the last 2 years. \n 100% - If the department was not audited in the past 3 consecutive years.',
  total_risk_scores: 'Each department\'s total risk score is calculated by multiplying the weighted average score by the percentage of the last audit multiplier.',
  risk_category: 'The risk category of each department is calculated based on the total risk score. \n\n  High - If the total risk score is greater than 11. \n Moderate - If the total risk score is between 6 and 11. \n Low - If the total risk score is less than 6.',
  audit_plan_glossary: 'Audit Plan Glossary',
  incident_id: 'Incident ID',
  incident_name: 'Incident Name',
  tracking_id: 'Tracking Id',
  incident_rating: 'Incident Rating',
  type: 'Type',
  reported_on: 'Reported On',
  due_date: 'Due Date',
  age: 'Age',
  project_name: 'Project Name',
  task: 'Task',
  entry_date: 'Entry Date',
  hours: 'Hours',
  action_name: 'Action Name',
  user: 'User',
  start_date: 'Start Time',
  end_time: 'End Time',
  configure_tabs: 'Configure tabs',
  configure_Charts: 'Configure Charts',
  approval_status: 'Approval Status',
  approved_or_rejected_by: 'Approval By',
  approved_date: 'Approval On',
  audit_type: 'Audit Type',
  planed_start_date: 'Planned Start Date',
  end_date: 'End Date',
  actual_start_date: 'Actual Start Date',
  status_of_audit: 'Status of Audit',
  objective: 'Audit Objective',
  scope: 'Scope',
  department_level1: 'Department Level 1',
  department_level2: 'Department Level 2',
  observation_count: 'Observation Count',
  recommendation_count: 'Recommendation Count',
  management_response_count: 'Management Response Count',
  dependencyHeaderMsg: 'Select the associated records that require approval along with the audit.',
  approvalHeaderMsg: 'Please select the approver(s) from the dropdown menu and submit the request. Note that in the case of multiple approvers, all must approve the request. If anyone rejects, corresponding pending requests will be automatically cancelled.',
  recommendation_id: 'Recommendation Id',
  recommendation_title: 'Recommendation Title',
  recommendation_desc: 'Recommendation Description',
  response_id: 'Response ID',
  management_user: 'Management User',
  management_status: 'Management Status',
  response_text: 'Response Text',
  issue_rating: 'Issue Rating',
  approval_issue_status: 'Issue Status',
  recommendations_count: 'Total number of recommendation',
  control_count: 'Number of controls',
  risk_count: 'Number of Risk',
  assign_to: 'Assign To',
  duplicateIssue: 'Select working papers to duplicate the issue',
  moveIssue: 'Select a working paper to move the issue',
  actions_by: 'Actions by',
  recommendations_by: 'Recommendations by',
  responses_by: 'Responses by',
  sourceTitle: 'Source Title',
  source_of_audit: 'Source of Audit',
  audit_plan_msg1: 'Review audit universe and select auditable areas for the plan',
  createdBy: 'Created by',
  editPlan: 'Edit an audit plan',
  approver_level: 'Approver Level',
  no_revisions: 'No. of revisions',
  first_due_date: '1st Implementation Due Date',
  second_due_date: '2nd Implementation Due Date',
  third_due_date: '3rd Implementation Due Date',
  forth_due_date: '4th Implementation Due Date',
  fifth_due_date: '5th Implementation Due Date',
  response_expected: 'Response Expected By',
  improvemnet_id: 'Improvement ID',
  improvement_mr: 'Improvement',
  improvement_desc: 'Improvement Description',
  fiscal_year: 'Fiscal Year',
  management_desc: 'Management Description',
  implementation_status: 'Implementation Status',
  generateUniverse: 'Generate Audit Universe',
  audits_plans: 'Audit Plans',
  auditStatus: 'Audits by Status',
  plansOwner: 'Plans by Owner',
  universePreview: 'Audit Universe Model Preview',
  previewMsg: 'You have chosen the following fields for the audit universe model. Choose a field based on which engagements to be populated while generating audit universe.',
  universeModel: 'Audit Universe Models',
  allUniverseModels: 'All Audit Universe Models',
  createUniverseModel: 'Create Audit Universe Model',
  editUniverseModel: 'Edit Audit Universe Model',
  auditUniverseModel: 'Audit Universe Model',
  proceed_2: 'Proceed to create Audit Plan ?',
  fieldName: 'Field Name',
  configurable: 'Configurable',
  deleteUniverseModel: 'You are about to delete an audit universe model!',
  universeModelEdit: 'You are about to edit an audit Universe model!',
  departmentBasedModel: 'Department Based Audit Plan Model',
  add_risks_controls: 'Add risks and controls',
  quality_management_dashboard: 'QMS Dashboard',
  departmentAudit: 'Non Conformity Status by SAL Department Audit',
  salAudit: 'Non Conformity Status by Suppliers Audit',
  auditProgress: 'Audit Progress Status',
  ncrDepartment: '% of NCR Closures for SAL Department Audit',
  ncrSuppliers: '% of NCR Closures for Suppliers Audit',
  controlledDocument: 'Controlled Documents',
  createaplan: 'Create a plan',
  riskBased: 'Risk-based',
  deptBased: 'Department-based',
  methodOfAudit: 'Method Of Audit',
  id: 'ID',
  createdDate: 'Created date',
  updatedBy: 'Updated By',
  updatedDate: 'Updated Date',
  media_count: 'Documents',
  action_priority: 'Action Priority',
  action_status: 'Action Status',
  owner: 'Owner',
  observation_overview: 'Observation overview',
  more_information: 'More information',
  add_assessmnts: 'Add assessments',
  working_paper: 'Working paper',
  add_working_papers: 'Add working papers',
  response_from: 'Response From',
  managementReviewer: 'Management Reviewer',
  implementation: 'Implementation Due Date',
  respondedDate: 'Responded Date',
  respondedBy: 'Responded by',
  overdueDays: 'Overdue Days',
  responseOverdue: 'Response Overdue Days',
  initialDate: 'Initial Due Date',
  criticality: 'Criticality',
  lastModifiedOn: 'Last Modified On',
  auditors_agreed_date: 'Auditors Agreed Date',
  lastReminderDate: 'Last Reminder Date',
  edit_universe: 'Edit Universe',
  universeSubText: 'Now, generate an audit plan to schedule your upcoming audits.',
  create_working_paper: 'Create working paper',
  auditProgram: 'Audit program',
  working_paper_overview: 'Working paper overview',
  audit_program_method: 'Audit program method',
  every_assignee_will_receive_separate_assessment: 'Every assignee will receive a separate assessment for each template selected',
  choose_users_and_assign: 'Choose users and assign assessments to them',
  choose_entities_and_assign: 'Choose entities and assign assessments to entity managers',
  pending_approval_Universe: 'The audit universe is pending approval from',
  audit_plan_approval_audit: 'The audit plan is pending approval from',
  audit_plan_universe: 'The audit universe associated with the audit plan is pending approval from',
  and_cannot_modified: 'and cannot be modified',
  audit_management: 'Audit Management',
  audit_models: 'Audit Universe Models',
  maxSelectfields: 'Maximum selectable field is 52',
  selectLeast: 'Please select at least one field from each section',
  selectLeastUnder: 'Please select at least one field under',
  sal_safety_incident_dashboard: 'Safety Incident Dashboard',
  sal_security_incident_dashboard: 'Security Incident Dashboard',
  incidentByStations: 'Incidents by Stations',
  incidentByStatus: 'Incidents by Status',
  incidentByCategory: 'Incidents by Category',
  incidentByYearBasis: 'Incidents by YOY Basis',
  incidentByMonthBasis: 'Incidents by MOM Basis',
  incidentByClassification: 'Incidents by Classification',
  prev_year: 'Compared to previous year',
  prev_month: 'Compared to previous Month',
  incidents: 'Incidents in ',
  prev_audits: 'Related Audits',
  add_prev_audits: 'Add Related Audits',
  finish: 'Finish',
  basedOn: 'Auditable Field',
  audit_name_preview: 'Audit Name Preview',
  description: 'Description',
  active: 'Active',
  inactive: 'Inactive',
  mark_default: 'Mark as Default',
  selectAuditFields: 'Select Fields of Audit',
  uniModel: 'Universe Model',
  completionRate: 'Completion Rate',
  managementResponsesByStatus: 'Management Responses by Status',
  totalControlsReviewed: 'Total Controls Reviewed',
  byRating: 'by Rating',
  controlEffectiveness: 'Control Effectiveness',
  resourceUtilization: 'Resource Utilization',
  plannedVsActual: 'Actual vs Planned',
  delayedAudits: 'Delayed audits',
  averageImplementationTime: 'Average time for completion of audits',
  averageOverdueForResponses: 'Number of audits not commenced',
  ctrl: 'Control',
  days: 'Days',
  placeholderString: 'Type "Audit Model for #Entity-name"',
  basedOnText: 'Each audit engagement will be created according to the selected field below.',
  riskCovered: 'Risks Covered',
  controlsReviwed: 'Controls Reviewed',
  obsDetails: 'Observations Details',
  residual: 'Residual',
  inherent: 'Inherent',
  auditSummary: 'Audit Summary Dashboard',
  action_overdue: 'Action Overdue in Days',
  newObservation: 'New Observations',
  notDue: 'Not due',
  total: 'Total',
  range1: '0-30',
  range2: '31-60',
  range3: '>60',
  closed: 'Closed',
  opened: 'Opened',
  overview: 'Overview',
  risk_by_rating: 'Risk by Rating',
  observationOverview: 'Observations Overview',
  highPriorityIssues: 'High Priority Issues',
  closureRate: 'Closure Rate',
  averageMRage: 'Average management response age',
  implementationTime: 'Average implementation time',
  avgDaysOverdue: 'Average number of days overdue for responses',
  auditId: 'Audit ID',
  action_due_date: 'Actions by Due Date',
  digitalBoardroom: 'Boardroom Dashboard',
  riskAndControl: 'Risk & Controls',
  removeAuditWarningMsg: 'You are about to remove the audit',
  editIssue: 'You are about to edit the issue',
  timeStampQuarter: 'Time Stamp Quarter',
  auditsByTime: 'Audits by Time',
  observationsByStatus: 'Observations by Status',
  totalObservation: 'Total Observation',
  actual: 'Actual',
  planned: 'Planned',
  total_controls: 'Total Controls',
  controlCovered: 'Control Covered',
  totalRisk: 'Total Risk',
  overdue: 'Overdue',
  not_commenced: 'Not commenced',
  in_progress: 'In progress',
  completed: 'Completed',
  departmentLevel: 'Department Level',
  entity: 'Entity',
  fiscalYear: 'Financial Year',
  megaProcess: 'Mega Process',
  majorProcess: 'Major Process',
  analyticsDashboard: 'Analytics Dashboard',
  totalControls: 'Total Controls',
  auditNameFormat: 'Audit Title Format',
  nameDescription: 'Each audit engagement will be generated based on the format specified below.',
  risk_ratings: 'Risk Rating',
  control_ratings: 'Control Rating',
  auditsStatus: 'Audit Status',
  managementResponseOverview: 'Management Response Overview',
  observ_status: 'Observation Status',
  managementResponseStatus: 'Management Response Status',
  covered: 'Covered',
  risk_by: 'Risk by',
  sectors: 'Sectors',
  manulalPlacment: 'Manual Placement',
  manualOption1: 'Option for manual placement to personally',
  manualOption2: 'define audit positions in each fiscal year.',
  systemDriven: 'System-Driven Placement',
  systemOption1: 'Allow the system to intelligently place audits based on',
  systemOption2: 'criteria, providing you with editable suggestions',
  create_audit_plan: 'Create Audit Plan',
  analyticsDashboardDetail: 'Inaccurate or unreliable data can result in incorrect Fuel Efficiency Index calculations',
  organizationDetails: 'Organization Details',
  manualPlacement: 'Manual Placement',
  create_audit_universes: 'Create Audit Universe',
  select_auditable: 'Select Auditable Units',
  auditCoverage: 'Audit Coverage',
  auditDuration: 'Audit by Duration',
  auditCompletion: 'Audit Completion Rate',
  avearageManDays: 'Average Man-days per Audit',
  totalManDays: 'Total Man-days',
  day: 'Day',
  totalAudits: 'Total Audits',
  controls_covered: 'Controls Covered',
  audit_placed: 'Audit Placed in',
  edit_audit_universe: 'Edit Audit Universe',
  coverage_criteria: 'Coverage Criteria',
  frequeny_month: 'Frequency (in months )',
  limit_exceeds: 'The value provided is not within the allowed limits',
  scheduled_audit: 'Scheduled Audit',
  wrkingpaper_steps_editor: 'Working Paper Steps - Object Editor',
  wrkingpaper_steps: 'Working Paper Steps',
  steps: 'Steps',
  tag_items_audit: 'Tag items from Audit',
  addAdhoc: 'Add AD hoc Audits',
  generateadhoc: 'Generate AD hoc Audits',
  numbermandays: 'Number of Mandays',
  years: 'Years',
  adhocaudits: 'AD hoc Audits',
  auditExceed: 'Audits cannot exceed 12',
  show_in_audit_report: 'Show in audit report',
  annexure_will_be_shown: 'Annexure will be shown in the audit report.',
  annexure_will_be_hidden: 'Annexure will be hidden in the audit report.',
  analyticScenarios: 'Analytic Scenarios',
  auditState: 'Audit State',
  auditStage: 'Audit stage',
  step: 'Step',
  audit_state_changed_from: 'Audit State changed from ',
  lockStage_audit: 'Unable to update audit state as the audit is locked.',
  digitalAuditReport: 'Digital audit report',
  planStatus: 'Plan Status',
  custom_id: 'Custom ID',
  obs_custom_Id: 'Observation Custom ID',
  mrCustomid: 'Management Response Custom ID',
  recomendationCustomId: 'Recommendation Custom ID'
};
