import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor() { }

  async setLocalStorage(key: string, data: string | any) {
    data = JSON.stringify(data);
    await localStorage.setItem(key, data);
  }

  getLocalStorage(key) {
    return JSON.parse(localStorage.getItem(key));
  }

  async clearLocalStorage() {
    await localStorage.clear();
  }

  async removeLocalStorage(key: string) {
    await localStorage.removeItem(key);
  }
}
