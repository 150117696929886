import { Router } from '@angular/router';
import { LoginService } from './../service/login/login.service';
import { ApiStatus, Toasts, ApiEndpoints, autoStatusExcludedApiUrls } from './../constants';
import { ToastService } from 'src/app/core/helper/toast.helper.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpEvent
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CommonApiService } from '../service/common/common-api.service';
import { ErrorResponse } from '../interface/common.interface';
import { showToastrContext } from 'src/app/core/constants';
import { commonLanguage } from '../constant/language-translation/common-language';

@Injectable()
export class StatusCheckInterceptor implements HttpInterceptor {

  public commonTranslations:Record<string, string> = commonLanguage;
  constructor(private _toast: ToastService,
    private _loginApi: LoginService,
    private _toaster: ToastrService,
    private _translate: TranslateService,
    private _router: Router,
    private _commonAPI: CommonApiService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler) : Observable<HttpEvent<any>>  {
    this._toaster.clear();
    let excludeUrl = false;
    let progressToaster;
    autoStatusExcludedApiUrls.map((urls:string) => {
      if (request.url?.includes(urls)) {
        excludeUrl = true;
      }
    });
    return next.handle(request).pipe(
      tap((evt:any) => {
        if (evt instanceof HttpResponse) {
          if (!evt.url.includes('.json') || !evt.url.includes('selected_fields_list')) {
            if (evt?.headers.get('File-Name') !== null) {
              evt.body['file'] = evt?.body;
              evt.body['name'] = evt?.headers.get('File-Name');
            } else if (!evt.url.includes('/language-translation/')) {
              evt.body.status = evt?.status;
            }

          }
        // If the api request has setting reportProgress set to true then we will get the info about the transfer size updates
        // This condition is used to show the progress of transfer updates through toast message
        } else if (evt?.loaded && evt?.total) {
          const progress = Math.round(evt?.loaded / evt?.total * 100);
          if (progress === 100 && evt?.type !== 1) {
            this._toaster.remove(progressToaster?.toastId);
          } else if (progressToaster) {
            const toastComponent = progressToaster?.toastRef?.componentInstance;
            // toastComponent.message = this._translate.instant(`Upload in progress: ${progress === 100 ? 99 : progress}% Completed`);
            toastComponent.message = this._translate.instant(`${this.commonTranslations?.upload_in_progress} ${progress === 100 ? 99 : progress}% ${this.commonTranslations?.completed}`);
          } else {
            progressToaster = this._toaster.info(this._translate.instant(`${this.commonTranslations?.upload_in_progress} ${progress === 100 ? 99 : progress}% ${this.commonTranslations?.completed}`), '', {
              closeButton: true,
              disableTimeOut: true
            });
          }
        } else if (progressToaster?.toastId) {
          this._toaster.remove(progressToaster?.toastId);
        }
      }),
      catchError((err:ErrorResponse) => {
        if (progressToaster?.toastId) {
          this._toaster.remove(progressToaster?.toastId);
        }
        if (!request.context.get(showToastrContext)){

          return of(new HttpResponse({ body: err.error }));
        }
        err.requestMethod = request?.method;
        if (!excludeUrl) {
          const extension = request.url.substring(request.url.lastIndexOf('/') + 1);
          if (extension.includes(ApiEndpoints.suggest) || extension.includes(ApiEndpoints.filter)) {
            this._toast.error(Toasts.errorMessageAskAura);
          } else {
            this.showHttpErrorToast(err);
          }
        } else {
          return of(new HttpResponse({ body: err.error }));
        }
        if ((err?.url?.includes('work-flow-builder/run') || err?.url?.includes('work-flow-builder/publish') || err?.url?.includes('sharepoint/site-lists') || err?.url?.includes('analytics-builder/established/connection/list') || err?.url?.includes('report_builder/template/'))) {
          return of(new HttpResponse({ body: err.error }));
        }
        return of(err);
      }
      )
    );
  }

  showHttpErrorToast(err:any) {
    if (err instanceof HttpErrorResponse) {
      const status = err.status;
      switch (status) {
      case ApiStatus.serverDown:
        this.showMessage(err);
        break;
      case ApiStatus.unAuthorized: {
        this._loginApi.forceLogout();
        this.showMessage(err);
        break;
      }
      case ApiStatus.forbidden: {
        // this._loginApi.forceLogout();
        this.showMessage(err);
        break;
      }
      case ApiStatus.badRequest || ApiStatus.notAcceptable: {
        if (err.error instanceof Object) {
          const key = Object.keys(err.error);
          const errors = err.error;
          key.forEach((el:string)=> {
            if (errors[el] instanceof Array) {
              errors[el].forEach((element:any) => {
                this._toast.warning(`${el}  : ${element}`);
              });
            } else if (el !== 'status') {
              this._toast.warning(errors[el]);
            }
          });
        } else {
          this.showMessage(err);
        }
        break;
      }
      case ApiStatus.notAcceptable: {
        this.showMessage(err);
        break;
      }
      case ApiStatus.notFound: {
        this.showMessage(err);
        break;
      }
      case ApiStatus.timeOut: {
        this._toast.error(Toasts.gateWayTimeOutToast);
        break;
      }
      case ApiStatus.failedDependency: {
        this.showMessage(err);
        break;
      }
      case ApiStatus.conflict: {
        this.showMessage(err);
        break;
      }
      case ApiStatus.securityStatus: {
        this._router.navigate(['home']);
        break;
      }
      case ApiStatus.unprocessableEntity: {
        if (err.error instanceof Array) {
          const key = Object.keys(err.error);
          const errors = err.error;
          key.forEach((errValue:string) => {
            if (errors[errValue] instanceof Array) {
              errors[errValue].forEach((error:any) => {
                this._toast.warning(`${errValue}  : ${error}`);
              });
            }
          });
        } else if (err.error instanceof Object) {
          const key = Object.keys(err.error);
          const errors = err.error;
          key.forEach((errorVal:string) => {
            if (errors[errorVal] instanceof Object) {
              const subKey = Object.keys(errors[errorVal]);
              subKey.forEach((fieldName:string) => {
                this._toast.warning(`${fieldName}  : ${errors[errorVal][fieldName]}`);
              });
            }
          });
        } else {
          this.showMessage(err);
        }
        break;
      }
      default:
      {
        this.showMessage(err);
        break;
      }
      }
    }
  }

  showMessage(err:any) {
    if (err.error['detail']) {
      this._toast.warning(err.error['detail']);
    } else if (err.error['details']) {
      const errorData = err.error['details'];
      for (const errorItem in errorData) {
        if (errorData[errorItem] instanceof Array) {
          errorData?.[errorItem].forEach((errorItemText:any) => {
            this._toast.warning(`${errorItem} : ${errorItemText}`);
          });
        }
      }
    } else if (err.error['message']) {
      this._toast.warning(err.error['message']);
    } else if (err.error['msg']) {
      this._toast.warning(err.error['msg']);
    } else if (err.error['error']) {
      this._toast.warning(err.error['error']);
    } else if (err?.requestMethod === 'GET') {
      this._commonAPI.error.emit(err);
    } else {
      this._toast.networkError();
    }
  }
}
