export const environment = {
  production: true,
  version: require('../../package.json').version,
  domain: 'dev.beinex.in',
  cookieDomain: 'dev.beinex.in',
  baseUrl: 'https://dev.beinex.in',
  baseVersion: '/v1',
  apiVersion: '/api/v6/',
  protocol: 'https://',
  socketProtocol:  'wss://',
  clientName: '',
  akiAccess: false,
  console: true,
  amlakAccess: false,
  saml: true,
  clientEnv: false,
  retalAccess: false,
  boodaiAcess:false,
  alhabtoorAccess:false,
  galadariAccess:false,
  msalClientId: '',
  msalAuthority: '',
  msalScope: []
};
