import { Injectable } from '@angular/core';
import { BadgeData } from 'src/app/core/interface/common.interface';

@Injectable()
export class BadgeService {

  // * Used to limit the number of badges with full content displayed based on available space.
  limitBadgeContent(badgeData: BadgeData[], maxWidth: number, badgeElements: Element[]): { limitedBadges: BadgeData[], remainingBadges: BadgeData[], badgeLength: number } {
    const result = { limitedBadges: [], remainingBadges: [], badgeLength: 0 };

    if (badgeData?.length > 1) {
      const availableSpace = maxWidth - (maxWidth * 0.15);
      let totalWidth = 0;
      let badgeNo = 1;
      result.limitedBadges = badgeData?.slice(0, 1);

      for (const badge of badgeElements) {
        totalWidth += (badge as HTMLElement).offsetWidth;
        if (totalWidth <= availableSpace) {
          badgeNo++;
          result.limitedBadges = badgeData?.slice(0, badgeNo-1);
        } else {
          result.remainingBadges = badgeData?.slice(badgeNo-1, badgeData.length);
          break;
        }
      }
      result.badgeLength = result?.limitedBadges?.length;
    }
    return result;
  }
}