import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import Swal  from 'sweetalert2';

@Injectable()
export class AccessibilityService {
  private renderer: Renderer2;

  constructor(_rendererFactory: RendererFactory2) {
    this.renderer = _rendererFactory.createRenderer(null, null);
  }

  addClassOnTabPress() {
    document.addEventListener('keydown', (event: {key: string}) => {
      if (event?.key === 'Tab') {
        this.renderer.addClass(document.body, 'ax-focus-tab');
      }
    });

    document.addEventListener('keydown', (event: KeyboardEvent) => {
      if (event?.key === 'Enter') {
        const focusedElement = document.activeElement as HTMLElement;
        if (focusedElement) {
          focusedElement.click();
        }
      }
    });

    document.addEventListener('click', () => {
      this.renderer.removeClass(document.body, 'ax-focus-tab');
    });

    document.addEventListener('keyup', (event:KeyboardEvent) => {
      if (Swal.isVisible && event?.key === 'Tab') {
        this.renderer.addClass(document.body, 'ax-focus-tab');
      }
    });
  }
}
