import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showLoader: boolean= true;
  public hideLoader: boolean = false;

  hide() {
    this.isLoading.next(false);
  }

  show() {
    this.isLoading.next(true);

  }
}
