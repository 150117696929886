import { FieldList, Fields } from './../../interface/e-sign.interface';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Signer } from '../../interface/e-sign.interface';
import { filedTypes } from '../../constant/e-sign.constant';
@Injectable({
  providedIn: 'root'
})
export class ESignService {
  public selectedSigner: Signer = null; // to know which signer is selected -> sign-option
  public fieldList: FieldList[] = []; // to push the fields by click on the options
  public signList = [];
  public selectedSignIndex: number;
  public reportData: any;

  constructor(private http: HttpClient) { }

}
