import { Directive, HostListener, ElementRef, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appAuPreventEnter]'
})
export class AuPreventEnterDirective {

  @Output() keyEnter = new EventEmitter;
   /**
   * Key enter event
   */
  constructor(private _elementRef: ElementRef) { }
  /**
   * Key board action
   * @param event
   */
  @HostListener('keydown.enter', ['$event']) onKeyDown(event: KeyboardEvent) {
    this._elementRef?.nativeElement?.blur();
    this.keyEnter?.emit(event);
    return false;
  }
  @HostListener('keydown.shift.enter', ['$event']) onKeyPress(event: KeyboardEvent) {
    this._elementRef?.nativeElement?.blur();
    this.keyEnter?.emit(event);
    return false;
  }
}
