import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class HttpUrlInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let url = request?.url;
    if (!url?.includes('.s3.amazonaws.com') && !url?.includes('https://graph.microsoft.com') ) {
      const timestamp = new Date().getTime();
      // Check whether the url ends with &
      if (url?.endsWith('&')) {
        url = `${url}timeStamp=${timestamp}`;
      } else if (url?.includes('?')) {
        // Check if url already have query param
        url = `${url}&timeStamp=${timestamp}`;
      } else {
        // If no query params added
        url = `${url}?timeStamp=${timestamp}`;
      }
    }
    const cloned = request.clone({
      url: url,
      withCredentials: true
    });
    return next.handle(cloned);
  }
}
