import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'userDate',
  standalone: true
})
export class UserDatePipe implements PipeTransform {

  constructor(private date: DatePipe) {

  }

  transform(value: string | Date, format: string = 'dd MMM yyyy') {
    try {
      return this.date.transform(value, format);
    } catch (error) {
      return value;
    }
  }

}
