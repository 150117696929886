import { HttpClient } from '@angular/common/http';
import { CustomLinkData, IClientCreation, IClientEdit, MenuItemData, TemplateData } from 'src/app/core/interface/admin.interface';
import { Injectable } from '@angular/core';
import { ApiEndpoints, tableConfig, adminPanelUser, adminAuditPlanning, adminAuditPlanningQuery, clientNames, incidentSettingsQueryParams} from 'src/app/core/constants';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuditNotificationEmailEditData, AuditNotificationSignatureEditData, CategoryData, FieldEditorInputData, LicenceData, PermissionData, ControlPanelAuditDetails, FormConfigureDataForList, AdminPanelUser, Getusergridparams, AdminAuditPlanning, AdminAuditPlanningQuery, Getauditgridparams, EmailTemplateMainDetails, ImpEqClosedParams, Tags, PermissionStatusDataType, UserGroupModalGridParams, GridParams, ControlPanelAudit } from 'src/app/core/interface/common.interface';
import { HttpService } from 'src/app/core/service/http/http.service';
import { FunctionService } from 'src/app/core/helper/function.helper.service';
import { scoringModelEntityManagement } from 'src/app/core/constant/entity.constants';
import { Router } from '@angular/router';
import { apiEndpoints } from 'src/app/core/constant/risk-constant';
import { customModuleApiEndpoints } from 'src/app/core/constant/custom-module-api.constants';
import { AuditPermission } from 'src/app/core/interface/audit-management.interface';
import { ComplianceGridRow } from 'src/app/core/interface/compliance-management.interface';
import { NotificationResponse } from 'src/app/core/interface/bulk-reminder.interface';
import { environment } from 'src/environments/environment';
import { RiskProgramMR } from 'src/app/core/interface/risk.interface';
@Injectable({
  providedIn: 'root'
})
export class AdminUserService {
  public replacementUserValidation: boolean = true;
  public editUserData : string;
  public countryId: number;
  public redirectUri:string;
  public keepUser: boolean = false;
  public toggleShedule: boolean = false;
  public viewOwnKR: boolean = true;
  public viewOwnAP: boolean = true;
  public viewOwnRU: boolean = true;
  public viewOwnRG: boolean = true;
  public viewOwnPL: boolean = true;
  public viewOwnRP: boolean = true;
  public viewOwnEntities: boolean = true;
  public viewOwnfunctions: boolean = true;
  public viewOwnDepartments: boolean = true;
  public viewOwnSections: boolean = true;
  public viewOwnAudits: boolean = true;
  public permissionModules: string;
  public permissionIssueData:PermissionStatusDataType=[];
  public fieldEditorChange: boolean = false;
  public formConfigure: boolean = true;
  public formRecords: boolean = false;
  public menuArrange: boolean = true;
  public recordDataSet: any = {};
  public auditUniverseAccessList!:AuditPermission;
  public complianceAuditAccessList!:ComplianceGridRow;
  public bcmAccessList!:ComplianceGridRow;
  public assetAccessList!:ComplianceGridRow;
  public bcpAccessList!:ComplianceGridRow;
  public policyAccessList!:ComplianceGridRow;
  public kriAccessList!:ComplianceGridRow;
  public kriAccessScoreList!:ComplianceGridRow;
  public adminLicencePermission: boolean;
  public formConfigureData: any = {
    data_transfer_to_user_email: null,
    modules: [],
    tables: [],
    groups: [],
    objects_unselected: [],
    selected_id: [],
    justification: null,
    start_date: null,
    end_date: null
  };

  public formConfigureDataForList : FormConfigureDataForList = {
    groups: [],
    module: []
  };

  public auditEmailTemplateDetails: EmailTemplateMainDetails | any;

  public selectedUser : string | any;
  public dynamicRating: boolean = false;
  // value varies so any given
  public sideBarSubject: any = new BehaviorSubject('');
  // value varies so any given
  public contributoriSideBarSubject: any = new BehaviorSubject('');
  // value varies so any given
  public adminSideBarSubject: any = new BehaviorSubject('');
  public annexureGridDataWithNonConfig: ControlPanelAudit[] = [];
  public annexureGridDataConfig: ControlPanelAudit[] = [];
  public auditGridDataWithNonConfig:ControlPanelAudit[] = [];
  public auditGridDataConfig:ControlPanelAudit[] = [];
  public auditProgramDataConfig:ControlPanelAudit[] = [];
  public auditProgramDataWithNonConfig:ControlPanelAudit[] = [];
  public issueGridDataConfig:ControlPanelAudit[] = [];
  public issueGridDataWithNonConfig:ControlPanelAudit[] = [];
  public reportGridDataConfig:ControlPanelAudit[] = [];
  public reportGridDataWithNonConfig:ControlPanelAudit[] = [];
  public managementGridDataConfig:ControlPanelAudit[] = [];
  public managementGridDataWithNonConfig:ControlPanelAudit[] = [];
  public recommendationGridDataConfig:ControlPanelAudit[] = [];
  public recommendationGridDataWithNonConfig:ControlPanelAudit[] = [];
  public improvementGridDataConfig:ControlPanelAudit[] = [];
  public improvementGridDataWithNonConfig:ControlPanelAudit[] = [];
  public improvementmrGridDataConfig:ControlPanelAudit[] = [];
  public improvementmrGridDataWithNonConfig:ControlPanelAudit[] = [];

  constructor(
    private _http: HttpService,
    private _function: FunctionService,
    private router: Router,
    private http: HttpClient
  ) { }

  getUserGraph(params : AdminPanelUser = adminPanelUser) {
    const keys: any = Object.keys(params);
    const value: any = Object.values(params);
    let query = '&';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.adminUserGraph + query);
  }

  getUserGrid(params : AdminPanelUser|any = adminPanelUser, gridParams :Getusergridparams|any = {
    q: '',
    offset: 0,
    limit: tableConfig.tableLimit
  }) {
    let keys: any = Object.keys(params);
    let value: any = Object.values(params);
    let query = '?';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    keys = Object.keys(gridParams);
    value = Object.values(gridParams);

    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.adminUserGrid + query);
  }

  getLoginUserType() {
    return this._http.get(ApiEndpoints.adminLoginUser);
  }

  getUserDetails(id : string | any) {
    return this._http.get(`${ApiEndpoints.adminUserDetails}?id=${id}`);
  }

  getUserExperience(data : FormData | any) {
    return this._http.get(`${ApiEndpoints.adminExperience + data}/list`);
  }

  getUserEducation(data : FormData | any) {
    return this._http.get(`${ApiEndpoints.adminEducation + data}/list`);
  }

  getUserCertificate(data : FormData | any) {
    return this._http.get(`${ApiEndpoints.adminCertification + encodeURIComponent(data)}/list`);
  }

  getUserRoleList(params : AdminPanelUser = adminPanelUser, gridParams : Getusergridparams = {
    q: '',
    offset: 0,
    limit: tableConfig.tableLimit
  }) {
    let keys: any = Object.keys(params);
    let value: any = Object.values(params);
    let query = '?';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }

    keys = Object.keys(gridParams);
    value = Object.values(gridParams);

    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.adminRoleList + query);
  }

  // usergroup table
  getUserGroupGrid(gridParams: GridParams) {
    const params = this._function?.queryParamsToString(gridParams);
    return this._http.get(`${ApiEndpoints.adminUserGroup}?${params}`);
  }

  // to get user group data for admin user group modal
  getUserGroupModal(id:string, gridParams :UserGroupModalGridParams) {
    const params = this._function?.queryParamsToString(gridParams);
    return this._http.get(`${ApiEndpoints.adminUserGroupModal}${id}/list/?${params}`);
  }

  deleteRole(id : string | any) {
    return this._http.delete(`${ApiEndpoints.adminRoleDelete + id}/delete/`);
  }

  deleteUserGroup(id : string | any) {
    return this._http.delete(`${ApiEndpoints.adminUserGroupDelete + id}/delete`);
  }

  deleteAuditModel(id : string | any) {
    return this._http.delete(`${ApiEndpoints.adminAuditModeldelete + id}/delete`);
  }

  deletePolicies(id : string | any) {
    return this._http.delete(`${ApiEndpoints.adminPolicyDelete + id}/delete/`);
  }

  deleteUser(data : FormData | any, action : FormData | any) {
    return this._http.post(`${ApiEndpoints.adminUserDelete + data}/user-actions/?action=${action}`);
  }

  getUserGroupDropdown() {
    return this._http.get(ApiEndpoints.adminUserGroup);
  }

  getDesignationDropdown() {
    return this._http.get(ApiEndpoints.designationGrid);
  }

  getLicenseDropdown() {
    return this._http.get(ApiEndpoints.licenseDropdown);
  }

  getDepartmentManagerDropdown() {
    return this._http.get(ApiEndpoints.policyAllUsersList);
  }

  getReportingToDropdown() {
    return this._http.get(ApiEndpoints.professionalUsers);
  }

  getUserRoleDropdown() {
    return this._http.get(ApiEndpoints.adminRoleList);
  }

  userGroupCreate(data : FormData | any) {
    return this._http.post(ApiEndpoints.adminUsergroupCreate, data);
  }

  userGroupEdit(id : string | any, data : FormData | any) {
    return this._http.put(`${ApiEndpoints.adminUsergroupEdit + id}/edit/`, data);
  }

  getUserGroupDetails(id : string | any) {
    return this._http.get(`${ApiEndpoints.userGroupdetails + id}/detail/`);
  }

  getAuditPlanningList() {
    return this._http.get(ApiEndpoints.adminAuditPlanningList);
  }

  getAuditReportingList(_params : AdminAuditPlanning = adminAuditPlanning, _gridParams : Getusergridparams = {
    q: '',
    offset: 0,
    limit: tableConfig.tableLimit
  }) {
    return this._http.get(ApiEndpoints.adminAuditReportingList);
  }

  /**
   * @description to get configurable object list
   */
  getAuditReportingObsConfigurableList() {
    return this._http.get(ApiEndpoints.AuditReportingObsConfigurableList);
  }

  /**
   * @description to get non configurable object list
   */
  getAuditReportingObsNonConfigurableList() {
    return this._http.get(ApiEndpoints.AuditReportingObsNonConfigurableList);
  }

  getAuditRecommendationList(_params : AdminAuditPlanning = adminAuditPlanning, _gridParams : Getusergridparams = {
    q: '',
    offset: 0,
    limit: tableConfig.tableLimit
  }) {
    return this._http.get(ApiEndpoints.adminAuditRecommendationList);
  }

  getAuditImprovementList(_params : AdminAuditPlanning = adminAuditPlanning, _gridParams : Getusergridparams = {
    q: '',
    offset: 0,
    limit: tableConfig.tableLimit
  }) {
    return this._http.get(ApiEndpoints.adminAuditImprovementNonConfigList);
  }


  getAuditActionList(_params : AdminAuditPlanning = adminAuditPlanning, _gridParams : Getusergridparams = {
    q: '',
    offset: 0,
    limit: tableConfig.tableLimit
  }) {
    return this._http.get(ApiEndpoints.adminAuditActionList);
  }

  getAuditManagementResponseList(_params : AdminAuditPlanning = adminAuditPlanning, _gridParams : Getusergridparams = {
    q: '',
    offset: 0,
    limit: tableConfig.tableLimit
  }) {
    return this._http.get(ApiEndpoints.adminAuditManagementResponseList);
  }

  getAuditProgramList(_params : AdminAuditPlanning = adminAuditPlanning, _gridParams : Getusergridparams = {
    q: '',
    offset: 0,
    limit: tableConfig.tableLimit
  }) {
    return this._http.get(ApiEndpoints.adminAuditProgramList);
  }

  getAuditAreasList(params : AdminAuditPlanningQuery = adminAuditPlanningQuery, fieldName : FormData | any, search : string | any) {
    params['q'] = search;
    const keys: string[] = Object.keys(params);
    const value: (string | number)[] = Object.values(params);
    let query = '?';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.adminAuditAreasList + fieldName + query);
  }

  getAuditAreasListAll(fieldName : FormData | any) {
    return this._http.get(ApiEndpoints.adminAuditAreasList + fieldName);
  }

  getAuditReportingDataList(params : Getusergridparams | any = {}, _gridParams : Getusergridparams = { q: '', offset: 0, limit: tableConfig.tableLimit }, slug: string, fieldName : FormData | any, search : string | any) {
    params['q'] = search;
    const keys: string[] = Object.keys(params);
    const value: (string | number)[] = Object.values(params);
    let query = '?';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(`${ApiEndpoints.adminAuditReportingDataList + slug}/${fieldName}/${query}`);
  }

  getAuditReportingDataListAll(slug : string | any, fieldName : FormData | any) {
    return this._http.get(`${ApiEndpoints.adminAuditReportingDataList + slug}/${fieldName}/`);
  }

  getAuditRecommendationDataList(params : Getusergridparams | any = {}, _gridParams : Getusergridparams = { q: '', offset: 0, limit: tableConfig.tableLimit }, fieldName : FormData | any, search : string | any) {
    params['q'] = search;
    const keys: string[] = Object.keys(params);
    const value: (string | number)[] = Object.values(params);
    let query = '?';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.adminAuditRecommendationDataList + fieldName + query);
  }

  getAuditImprovementDataList(params : Getusergridparams | any = {}, _gridParams : Getusergridparams = { q: '', offset: 0, limit: tableConfig.tableLimit }, fieldName : FormData | any, search : string | any) {
    params['q'] = search;
    const keys: string[] = Object.keys(params);
    const value: (string | number)[] = Object.values(params);
    let query = '?';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.adminAuditImprovementDataList + fieldName + query);
  }

  // to get dynamic page header name
  getDynamicPageHeaderName(params:Record<string, string>) {
    let query = '?';
    query += this._function.queryParamsToString(params);
    return this._http.get(ApiEndpoints.adminAuditHeaderFieldName + query);
  }

  getAuditRecommendationDataListAll(fieldName : FormData | any) {
    return this._http.get(ApiEndpoints.adminAuditRecommendationDataList + fieldName );
  }

  getAuditImprovementDataListAll(fieldName : FormData | any) {
    return this._http.get(ApiEndpoints.adminAuditImprovementDataList + fieldName );
  }

  getAuditActionDataList(params : AdminAuditPlanningQuery = adminAuditPlanningQuery, _gridParams : Getusergridparams = {
    q: '',
    offset: 0,
    limit: 10
  }, fieldName : FormData | any, search : string | any) {
    params['q'] = search;
    const keys: string[] = Object.keys(params);
    const value: (string | number)[] = Object.values(params);
    let query = '?';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.adminAuditActionDataList + fieldName + query);
  }

  getAuditActionDataListAll(fieldName : FormData | any) {
    return this._http.get(ApiEndpoints.adminAuditActionDataList + fieldName);
  }

  getAuditProgramDataList(params : AdminAuditPlanningQuery = adminAuditPlanningQuery, _gridParams : Getusergridparams = {
    q: '',
    offset: 0,
    limit: 10
  }, fieldName : FormData | any, search : string | any) {
    params['q'] = search;
    const keys: string[] = Object.keys(params);
    const value: (string | number)[] = Object.values(params);
    let query = '?';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.adminAuditProgramDataList + fieldName + query);
  }

  getAuditprogramDataListAll(fieldName : FormData | any) {
    return this._http.get(ApiEndpoints.adminAuditProgramDataList + fieldName);
  }

  // analytics Mr SortData
  // need any here,becouse string and form data has no overlap
  getAnalyticsMrDataListAll(fieldName : FormData | any) {
    return this._http.get(ApiEndpoints.adminAnalyticsMrDataList + fieldName);
  }

  getManagementResponseDataList(params : AdminAuditPlanningQuery = adminAuditPlanningQuery, _gridParams : Getusergridparams = {
    q: '',
    offset: 0,
    limit: 10
  }, fieldName : FormData | any, search : string | any) {
    params['q'] = search;
    const keys: string[] = Object.keys(params);
    const value: (string | number)[] = Object.values(params);
    let query = '?';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.adminmanagementResponseDataList + fieldName + query);
  }

  /* get Risk Program Mr Data List **/
  /* need any here,becouse string and form data has no overlap **/
  getRiskProgramMRDataList(params : AdminAuditPlanningQuery = adminAuditPlanningQuery, _gridParams : Getusergridparams = {
    q: '',
    offset: 0,
    limit: 10
  }, fieldName : FormData | any, search : string | any) {
    params['q'] = search;
    let query = '?';
    query += this._function?.queryParamsToString(params);
    return this._http.get(ApiEndpoints.adminRiskProgramMRDataList + fieldName + query);
  }

  // to get analytics Mr dropdown values
  // need any here,becouse string and form data has no overlap
  getAnalyticsMrDataList(params : AdminAuditPlanningQuery = adminAuditPlanningQuery, _gridParams : Getusergridparams = {
    q: '',
    offset: 0,
    limit: 10,
    table_filters: ''
  }, fieldName : FormData | any, search : string) {
    params['q'] = search;
    let query = '?';
    query += this._function?.queryParamsToString(params);
    return this._http.get(ApiEndpoints.adminAnalyticsMrDataList + fieldName + query);
  }

  getManagementResponseDataListAll(fieldName : FormData | any) {
    return this._http.get(ApiEndpoints.adminmanagementResponseDataList + fieldName);
  }

  /* get Risk Program Mr data list all **/
  getRiskProgramMRDataListAll(fieldName : FormData | any) {
    return this._http.get(ApiEndpoints.adminRiskProgramMRDataList + fieldName);
  }

  getAuditPlanningConfigurable(dropDownValue: number) {
    return this._http.get(`${ApiEndpoints.AuditParentConfigurable + dropDownValue}/list/?configurable=Yes`);
  }

  getSubValueDropDown(slug: string, data: string) {
    return this._http.get(`${ApiEndpoints.auditAreasSubValue + slug}/${data}`);
  }

  getValuesDropdown(field: string) {
    return this._http.get(`${ApiEndpoints.getValue + field}/list/`);
  }

  createNewDropdownItem(data: ControlPanelAuditDetails) {
    return this._http.post(ApiEndpoints.controlPanelCreatedDropdown, data);
  }

  createNewDropdownIncidentTypeItem(data: ControlPanelAuditDetails) {
    return this._http.post(ApiEndpoints.incidentTypeCreate, data);
  }

  improvementStatusEqClosed(params: ImpEqClosedParams){
    const queryParam = this._function?.queryParamsToString(params);
    return this._http.put(`${ApiEndpoints.improvementStatusEqClosed}?${queryParam}`);
  }

  createNewDropdownRequestTypeItem(data: ControlPanelAuditDetails) {
    return this._http.post(ApiEndpoints.requestTypeCreate, data);
  }

  deleteArea(id: number) {
    return this._http.delete(`${ApiEndpoints.controlPanelAuditAreaDelete + id}/delete/`);
  }

  editDropdown(data: ControlPanelAuditDetails, id: number) {
    return this._http.put(`${ApiEndpoints.controlPanelPriorityDelete + id}/edit/`, data);
  }

  editDropdownRequest(data: ControlPanelAuditDetails, id: number) {
    return this._http.put(`${ApiEndpoints.requestControlPanelTypeEdit + id}/edit/`, data);
  }

  getAuditHideObjectGrid(_params : Getusergridparams | any = {}, _gridParams : Getauditgridparams = { configurable: '', offset: 0, limit: 10 }) {
    return this._http.get(ApiEndpoints.AuditPlanningNonConfigurable);
  }

  getAuditReportingHideObjectGrid(_params : Getusergridparams | any = {}, _gridParams : Getauditgridparams = { configurable: '', offset: 0, limit: 10 }) {
    return this._http.get(ApiEndpoints.AuditReportingNonConfigurable);
  }

  getAuditRecommendationHideObjectGrid() {
    return this._http.get(ApiEndpoints.AuditRecommendationConfigurable);
  }

  getAuditImprovementHideObjectGrid() {
    return this._http.get(ApiEndpoints.AuditImprovrementConfigurable);
  }

  getAllEntityGrid(gridParams : Getusergridparams | any = {}) {
    let keys: any = Object.keys(gridParams);
    let value: any = Object.values(gridParams);
    let query = '?';
    keys = Object.keys(gridParams);
    value = Object.values(gridParams);

    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.entityList + query);
  }

  getPolicyList(params : AdminPanelUser = adminPanelUser, gridParams : Getusergridparams = {
    q: '',
    offset: 0,
    limit: tableConfig.tableLimit
  }) {
    let keys: any = Object.keys(params);
    let value: any = Object.values(params);
    keys = Object.keys(gridParams);
    value = Object.values(gridParams);
    let query = '?';
    keys = Object.keys(gridParams);
    value = Object.values(gridParams);

    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.adminPolicyList + query);
  }

  getRiskUniverseList(params : Getusergridparams | any = {}, gridParams : Getusergridparams = { q: '', offset: 0, limit: 100 }, search : string | any = '') {
    gridParams.q = search;
    let keys: any = Object.keys(params);
    let value: any = Object.values(params);
    let query = '?';
    keys = Object.keys(gridParams);
    value = Object.values(gridParams);

    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.adminRiskUniverse + query);
  }

  getKriControlpanelList(params : Getusergridparams|{ field_name: string; configurable: string; data_type: string; } = {}, gridParams : Getusergridparams = { q: '', offset: 0, limit: 100 }, search : string ='', contentType:string) {
    gridParams.q = search;
    let keys: string[] = Object.keys(params);
    let value: (string | number)[] = Object.values(params);
    let query = '?';
    keys = Object.keys(gridParams);
    value = Object.values(gridParams);

    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(`${ApiEndpoints.adminKriList + contentType}/list/${query}`);
  }

  getRiskProgramList(params : Getusergridparams | any = {}, gridParams : Getusergridparams = { q: '', offset: 0, limit: 100 }, search : string | any = '') {
    gridParams.q = search;
    let keys: string[] = Object.keys(params);
    let value: (string | number)[] = Object.values(params);
    let query = '?';
    keys = Object.keys(gridParams);
    value = Object.values(gridParams);

    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.adminRiskProgram + query);
  }

  getAuditActionHideObjectGrid(_params : Getusergridparams | any = {}, _gridParams : Getauditgridparams = { configurable: '', offset: 0, limit: 10 }) {
    return this._http.get(ApiEndpoints.AuditActionConfigurable);
  }

  getAuditManagementResponseHideObjectGrid(_params : Getusergridparams | any = {}, _gridParams : Getauditgridparams = { configurable: '', offset: 0, limit: 10 }) {
    return this._http.get(ApiEndpoints.AuditManagementConfigurable);
  }

  getAuditProgramHideObjectGrid(_params : Getusergridparams | any = {}, _gridParams : Getauditgridparams = { configurable: '', offset: 0, limit: 10 }) {
    return this._http.get(ApiEndpoints.AuditProgramConfigurable);
  }

  /**
   * @description to get the audit activity's configurable based grid data
   */
  getAuditActivityHideObjectGrid() {
    return this._http.get(ApiEndpoints.AuditActivityConfigurable);
  }

  // To get plan object grid config
  getPlanObjectGrid(_params : Getusergridparams = {}, _gridParams : Getauditgridparams = { configurable: '', offset: 0, limit: 10 }) {
    return this._http.get(ApiEndpoints.planConfigurable);
  }

  getRegionGrid(gridParams : Getusergridparams | any = {}) {
    const keys: any = Object.keys(gridParams);
    const value: any = Object.values(gridParams);
    let query = '?';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.regionGrid + query);
  }

  getReportGrid() {
    return this._http.get(ApiEndpoints.reportGrid);
  }

  getReportGridRiskProgram() {
    return this._http.get(`${ApiEndpoints.reportGrid}?request_for=risk_program`);
  }

  getEntityDetail(id : string | any): Observable<any> {
    return this._http.get(`${ApiEndpoints.entityDetail + id}/list/`);
  }

  getEntityManagerDropDown() {
    return this._http.get(ApiEndpoints.RiskControlManagerDropDown);
  }

  getEntitySizeDropDown() {
    return this._http.get(ApiEndpoints.entitySizeDropdown);
  }

  getRegionDropdown() {
    return this._http.get(ApiEndpoints.regionDropdown);
  }

  getStateDropdown(id : string | any) {
    return this._http.get(`${ApiEndpoints.stateDropdown + id}/list/`);
  }

  deleteEntity(id : string | any) {
    return this._http.delete(`${ApiEndpoints.entity + id}/delete`);
  }

  createEntityData(data : FormData | any) {
    return this._http.post(`${ApiEndpoints.entity}create/`, data);
  }

  entityEdit(id : string | any, data : FormData | any) {
    return this._http.put(`${ApiEndpoints.entityEdit + id}/`, data);
  }

  getDepartmentGrid(gridParams : Getusergridparams | any = {}) {
    const keys: any = Object.keys(gridParams);
    const value: any = Object.values(gridParams);
    let query = '?';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.departmentGrid + query);
  }

  getDesignationGrid(gridParams : Getusergridparams | any = {}) {
    const keys: any = Object.keys(gridParams);
    const value: any = Object.values(gridParams);
    let query = '?';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.designationGrid + query);
  }

  // To List division in controlpanel
  getDivisionGrid(gridParams : Getusergridparams | any = {}) {
    const keys: any = Object.keys(gridParams);
    const value: any = Object.values(gridParams);
    let query = '?';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.divisionGrid + query);
  }

  getBudgetCategoryGrid(params : Getusergridparams | any = {}) {
    let query = '?';
    const keys: string[] = Object.keys(params);
    const value: (string | number)[] = Object.values(params);
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.budgetCategoryGrid + query);
  }

  getPolicyData() {
    return this._http.get(ApiEndpoints.adminPolicyList);
  }

  createRole(data : FormData | any) {
    return this._http.post(ApiEndpoints.adminRoleCreate, data);
  }

  updatePolicy(id : string | any, data : FormData | any) {
    return this._http.put(`${ApiEndpoints.policyEdit + id}/edit/`, data);
  }

  createPolicy(data : FormData | any) {
    return this._http.post(ApiEndpoints.adminPolicyCreate, data);
  }

  createUsers(data : FormData | any) {
    return this._http.post(ApiEndpoints.adminUserscreate, data);
  }

  recoverUser(email : FormData | any) {
    return this._http.post(`${ApiEndpoints.user + email}/user-actions/?action=RECOVER`);
  }

  updateUser(id : string | any, data : FormData | any) {
    return this._http.put(ApiEndpoints.adminUsersedit + id, data);
  }

  getRoleDetails(id : string | any) {
    return this._http.get(`${ApiEndpoints.roleDetails + id}/detail/`);
  }

  editRoles(id : string | any, data : FormData | any) {
    return this._http.put(`${ApiEndpoints.adminRolesEdit + id}/edit/`, data);
  }

  createDesignation(data : FormData | any) {
    return this._http.post(ApiEndpoints.addNewDesignation, data);
  }

  getPolicyDetails(id : string | any) {
    return this._http.get(`${ApiEndpoints.policyDetails + id}/detail/`);
  }

  getBudgetSubCategoryGrid(params : Getusergridparams | any = {}) {
    const keys: string[] = Object.keys(params);
    const value: (string | number)[] = Object.values(params);
    let query = '?';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.budgetSubCategoryGrid + query);
  }

  getEntityDropdown() {
    return this._http.get(ApiEndpoints.EntityDropDown);
  }

  getCategoryDropdown() {
    return this._http.get(ApiEndpoints.CategoryDropDown);
  }

  getBudgetBalance() {
    return this._http.get(ApiEndpoints.budgetBalance);
  }

  getSubcategoryBudgetBalance() {
    return this._http.get(ApiEndpoints.subcategoryBudgetBalance);
  }

  createBudgetCategory(data: CategoryData) {
    return this._http.post(ApiEndpoints.addBudgetCategory, data);
  }

  deleteCategory(id: number) {
    return this._http.delete(`${ApiEndpoints.budgetCategory + id}/delete/`);
  }

  deleteSubCategory(id: number) {
    return this._http.delete(`${ApiEndpoints.budgetSubCategory + id}/delete/`);
  }

  createBudgetSubCategory(data: CategoryData) {
    return this._http.post(ApiEndpoints.addBudgetSubCategory, data);
  }

  getCategoryDetailById(categoryId: number) {
    return this._http.get(`${ApiEndpoints.editCategory + categoryId}/detail/`);
  }

  getSubCategoryDetailById(subCategoryId: number) {
    return this._http.get(`${ApiEndpoints.editSubCategory + subCategoryId}/detail/`);
  }

  categoryEdit(id: number, data: CategoryData) {
    return this._http.put(`${ApiEndpoints.editCategory + id}/edit/`, data);
  }

  subCategoryEdit(id: number, data: FormData) {
    return this._http.put(`${ApiEndpoints.editSubCategory + id}/edit/`, data);
  }

  getCategoryDetails(id: number) {
    return this._http.get(`${ApiEndpoints.categoryDetails + id}/detail/`);
  }

  getSubCategoryDetails(id: number) {
    return this._http.get(`${ApiEndpoints.subCategoryDetails + id}/detail/`);
  }

  getHideControlObjectGrid(_params : Getusergridparams | any = {}, _gridParams : Getauditgridparams = { configurable: '', offset: 0, limit: 10 }) {
    return this._http.get(ApiEndpoints.controlHidenonConfigurable);

  }

  getRiskControlpanelTypeGrid(params : Getusergridparams | any = {}, _gridParams : Getusergridparams = { q: '', offset: 0, limit: 10 }, search : string | any = '', fieldName : FormData | any) {
    params['q'] = search;
    const keys: string[] = Object.keys(params);
    const value: (string | number)[] = Object.values(params);
    let query = '?';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.riskFieldGrid + fieldName + query);
  }

  /* get Risk Program Mr response list **/
  getRiskProgramManagementResponseList(_params : RiskProgramMR = incidentSettingsQueryParams, _gridParams : Getusergridparams = {
    q: '',
    offset: 0,
    limit: tableConfig.tableLimit
  }) {
    return this._http.get(ApiEndpoints.riskProgramManagementResponseList);
  }

  getRiskControlpanelBusinessStrategiesTypeGrid(params : Getusergridparams = {}, _gridParams : Getusergridparams = { q: '', offset: 0, limit: 10 }, search : string = '', fieldName : string) {
    params['q'] = search;
    let query = '?';
    query += this._function.queryParamsToString(_gridParams);
    return this._http.get(apiEndpoints.ExtraDropdownBS + fieldName + query);
  }

  getKriControlpanelTypeGrid(params : Getusergridparams = {}, _gridParams : Getusergridparams = { q: '', offset: 0, limit: 10 }, search : string = '', fieldName : string, parent:string) {
    params['q'] = search;
    const keys: string[] = Object.keys(params);
    const value: (string | number)[] = Object.values(params);
    let query = '?';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(`${`${ApiEndpoints.kriFieldGrid + parent}/${fieldName}`}/${query}`);
  }

  getRiskControlpanelRiskProgramTypeGrid(params : Getusergridparams | any = {}, _gridParams : Getusergridparams = { q: '', offset: 0, limit: 10 }, search : string | any = '', fieldName : FormData | any) {
    params['q'] = search;
    const keys: string[] = Object.keys(params);
    const value: (string | number)[] = Object.values(params);
    let query = '?';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.riskProgramFieldGrid + fieldName + query);
  }

  getRiskControlpanelTypeGridAll(fieldName : FormData | any) {
    return this._http.get(ApiEndpoints.riskFieldGrid + fieldName);
  }

  getRiskProgramControlpanelTypeGridAll(fieldName: string) {
    return this._http.get(ApiEndpoints.riskFieldGridRiskProgram + fieldName);
  }

  getBusinessStrategyControlpanelTypeGridAll(fieldName: string) {
    return this._http.get(apiEndpoints.ExtraDropdownBS + fieldName);
  }

  getKriControlpanelTypeGridAll(contentType: string, fieldName: string) {
    return this._http.get(`${ApiEndpoints.kriFieldGrid + contentType}/${fieldName}`);
  }

  getHideObjectGrid(_params : Getusergridparams | any = {}, _gridParams : Getauditgridparams = { configurable: '', offset: 0, limit: 10 }) {
    return this._http.get(ApiEndpoints.riskHidenonConfigurable);

  }

  getHideObjectGridKri(contentType:string) {

    return this._http.get(`${ApiEndpoints.kriHidenonConfigurable + contentType}/list/?configurable=Yes&pagination_config=all`);

  }

  getRiskProgramHideObjectGrid(_params : Getusergridparams | any = {}, _gridParams : Getauditgridparams = { configurable: '', offset: 0, limit: 10 }) {

    return this._http.get(ApiEndpoints.riskProgramHidenonConfigurable);

  }

  /* get Risk Program Mr Hide Object Grid **/
  getRiskProgramMrHideObjectGrid() {
    return this._http.get(ApiEndpoints.riskProgramMrHidenonConfigurable);
  }

  getAdminRiskControlList(params : Getusergridparams | any = {}, gridParams : Getusergridparams = { q: '', offset: 0, limit: 100 }, search : string | any = '') {
    gridParams.q = search;
    let keys: string[] = Object.keys(params);
    let value: (string | number)[] = Object.values(params);
    let query = '?';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    keys = Object.keys(gridParams);
    value = Object.values(gridParams);

    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.adminRiskControl + query);
  }

  getRiskControlTypeGrid(params : Getusergridparams | any = {}, _gridParams : Getusergridparams = { q: '', offset: 0, limit: 10 }, search : string | any = '', fieldName : FormData | any) {
    params['q'] = search;
    const keys: any = Object.keys(params);
    const value: any = Object.values(params);
    let query = '?';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.controlFieldGrid + fieldName + query);
  }

  getRiskControlTypeGridAll(fieldName : FormData | any) {
    return this._http.get(ApiEndpoints.controlFieldGrid + fieldName);
  }

  createNewItem(data : FormData | any) {
    return this._http.post(ApiEndpoints.riskFieldCreate, data);
  }

  editNewItem(id : string | any, data : FormData | any) {
    return this._http.put(`${ApiEndpoints.riskFieldDelete + id}/edit/`, data);
  }

  editNewItemValue(id : number, data : FormData) {
    return this._http.put(`${apiEndpoints.riskRatingNewValue + id}/edit-new-value/`, data);
  }

  riskRatingCount(id : number, data : FormData) {
    return this._http.put(`${apiEndpoints.riskRatingNewValue + id}/calculate-count/`, data);
  }

  deleteField(id : string | number) {
    return this._http.delete(`${ApiEndpoints.riskFieldDelete + id}/delete/`);
  }

  getParentDropDown(slug : string | any) {
    return this._http.get(`${ApiEndpoints.riskParentDropdown + slug}/list/?configurable=Yes`);
  }

  getValueDropdown(slug : string | any, field : FormData | any) {
    return this._http.get(`${ApiEndpoints.riskValueDropdown + slug}/${field}`);
  }

  getAuditValuesDropdown(field : FormData | any) {
    return this._http.get(`${ApiEndpoints.valueDropdown + field}/list/`);
  }

  getAdminRiskDetails(field : FormData | any, id : string | any) {
    return this._http.get(`${ApiEndpoints.adminRiskDetails + field}/?id=${id}`);
  }

  getAdminControlDetails(field : FormData | any, id : string | any) {
    return this._http.get(`${ApiEndpoints.adminControlDetails + field}/?id=${id}`);
  }

  getRegionDetails(id : string | any) {
    return this._http.get(ApiEndpoints.regionDetails + id);
  }

  getDepartmentDetails(id : string | any) {
    return this._http.get(`${ApiEndpoints.cpDepartmentDetail}${id}/detail/`);
  }

  getUserDepartmentDetails(id : string | any) {
    return this._http.get(`${ApiEndpoints.userdepartmentDetails}?id=${id}`);
  }

  getDesignationDetails(id : string | any) {
    return this._http.get(`${ApiEndpoints.designationDetail}?id=${id}`);
  }

  // To display division details in controlpanel
  getDivisionDetails(id : string | any) {
    return this._http.get(`${ApiEndpoints.divisionDetail}?id=${id}`);
  }

  getRegionManagerDropDown() {
    return this._http.get(ApiEndpoints.RiskControlManagerDropDown);
  }

  getCountryDropdown() {
    return this._http.get(ApiEndpoints.countryDropdown);
  }

  regionCreate(data : FormData | any) {
    return this._http.post(ApiEndpoints.regionCreate, data);
  }

  reportCreate(data : FormData | any) {
    return this._http.post(ApiEndpoints.reportCreate, data);
  }

  deleteRegion(id : string | any) {
    return this._http.delete(`${ApiEndpoints.regionDelete + id}/delete/`);
  }

  deleteReport(id : string | any) {
    return this._http.delete(`${ApiEndpoints.reportDelete + id}/delete/`);
  }

  regionEdit(id : string | any, data : FormData | any) {
    return this._http.put(`${ApiEndpoints.regionEdit + id}/edit/`, data);
  }

  reportEdit(id : string | any, data : FormData | any) {
    return this._http.put(`${ApiEndpoints.reportDelete + id}/edit`, data);
  }

  getLogoList() {
    return this._http.get(ApiEndpoints.logoList);
  }

  getLoginUsersType() {
    return this._http.get(ApiEndpoints.loginUserType);
  }

  uploadLogos(data : FormData | any) {
    return this._http.post(ApiEndpoints.uploadLogo, data);
  }

  departmentCreate(data : FormData | any) {
    return this._http.post(ApiEndpoints.departmentCreate, data);
  }

  userdepartmentCreate(data : FormData | any) {
    return this._http.post(ApiEndpoints.userdepartmentCreate, data);
  }

  designationCreate(data : FormData | any) {
    return this._http.post(ApiEndpoints.designationCreate, data);
  }

  // To create a new division in controlpanel
  divisionCreate(data : FormData | any) {
    return this._http.post(ApiEndpoints.divisionCreate, data);
  }

  deleteDepartment(id : string | any) {
    return this._http.delete(`${ApiEndpoints.departmentDelete + id}/delete/`);
  }

  userdeleteDepartment(id : string | any) {
    return this._http.delete(`${ApiEndpoints.userdepartmentDelete + id}/delete/`);
  }

  deleteDesignation(id : string | any) {
    return this._http.delete(`${ApiEndpoints.designationDelete + id}/delete`);
  }

  // To delete a division from controlpanel
  deleteDivision(id : string | any) {
    return this._http.delete(`${ApiEndpoints.divisionDelete + id}/delete`);
  }

  departmentEdit(id : string | any, data : FormData | any) {
    return this._http.put(`${ApiEndpoints.departmentDelete + id}/edit/`, data);
  }

  userdepartmentEdit(id : string | any, data : FormData | any) {
    return this._http.put(`${ApiEndpoints.userdepartmentEdit + id}/edit/`, data);
  }

  designationEdit(id : string | any, data : FormData | any) {
    return this._http.put(`${ApiEndpoints.designationEdit + id}/edit`, data);
  }

  // To edit division in controlpanel
  divisionEdit(id : string | any, data : FormData | any) {
    return this._http.put(`${ApiEndpoints.divisionEdit}${id}/edit/`, data);
  }

  getPlanningDetailById(itemId : FormData | any, slug : string | any) {
    return this._http.get(`${ApiEndpoints.planningDetails}${slug}/audit_areas/?id=${itemId}`);
  }

  featureList(gridParams :Getusergridparams | any = {}) {
    const keys: any = Object.keys(gridParams);
    const value: any = Object.values(gridParams);
    let query = '?';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.featureList + query);
  }

  approveList() {
    return this._http.get(ApiEndpoints.approvalList );
  }

  approveAudit(data : FormData | any) {
    return this._http.get(`${ApiEndpoints.approvalList}?request_for=${data}` );
  }

  departmentData(id: string, approvalId:string) {
    return this._http.get(`${ApiEndpoints.departmentData + id}?approval=${approvalId}`);
  }

  updateFeatureStatus(id : string | any, slug : string | any) {
    return this._http.put(`${ApiEndpoints.featureStatusEdit + id}/edit?eachId=${slug}`);
  }

  updateApprovalStatus(id : string) {
    return this._http.put(`${ApiEndpoints.approvalStatus + id}/edit/`);
  }

  updateMultipleApprovalStatus(id : string, status: boolean) {
    return this._http.put(`${ApiEndpoints.multipleApproval + id}/edit/?is_on=${status}`);
  }

  getCount(data: string) {
    return this._http.get(`${ApiEndpoints.getCount}?request_for=${data}`);
  }

  activateApprovalStatus(id : string) {
    return this._http.put(`${ApiEndpoints.approvalStatus + id}/edit/`);
  }

  approvalPendingStatus(data : FormData, params:string) {
    return this._http.put(`${ApiEndpoints.activateApproval}?request_for=${params}`, data);
  }

  approvalOntoOff(data: FormData|any) {
    return this._http.put(`${ApiEndpoints.approvalPendingStatus}?request_for=${data}`);
  }

  updateClientFeatureStatus(id : string | any, slug : string | any) {
    return this._http.put(`${ApiEndpoints.featureClientStatusEdit + id}/edit?eachId=${slug}`);
  }

  getFeatureDetails(slug : string | any) {
    return this._http.get(`${ApiEndpoints.featureList}?slug=${slug}`);
  }

  editFeatureSessionDetails(id : string | any, slug : string | any, timeOut : number | any) {
    return this._http.put(`${ApiEndpoints.featureUpdate + id}/edit?feature_slug=${encodeURIComponent(slug)}`, timeOut);
  }

  editFeatureFailedLoginCount(id: number, slug: string, failedCount: number) {
    return this._http.put(`${ApiEndpoints.featureUpdate + id}/edit?feature_slug=${encodeURIComponent(slug)}`, failedCount);
  }

  editFeatureRemainderDetails(id : string | any, slug : string | any, data : FormData | any) {
    return this._http.put(`${ApiEndpoints.featureUpdate + id}/edit?feature_slug=${encodeURIComponent(slug)}`, data);
  }

  editFeatureEscalationDetails(id : string | any, slug : string | any, data : FormData | any) {
    return this._http.put(`${ApiEndpoints.featureUpdate + id}/edit?feature_slug=${encodeURIComponent(slug)}`, data);
  }

  editFeatureAnalyticsEmailDetails(id : string | any, slug : string | any, data : FormData | any) {
    return this._http.put(`${ApiEndpoints.featureUpdate + id}/edit?feature_slug=${encodeURIComponent(slug)}`, data);
  }

  editFeaturePasswordDetails(id : string | any, slug : string | any, data : FormData | any) {
    return this._http.put(`${ApiEndpoints.featureUpdate + id}/edit?feature_slug=${encodeURIComponent(slug)}`, data);
  }

  getAdminSecurityGrid(gridParams : Getusergridparams | any = {}) {
    const keys: any = Object.keys(gridParams);
    const value: any = Object.values(gridParams);
    let query = '?';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.securityList + query);
  }

  deleteSecurity(id : string | any) {
    return this._http.delete(`${ApiEndpoints.securityDelete + id}/delete/`);
  }

  columnDataCreate(data : FormData | any) {
    return this._http.post(ApiEndpoints.addColumnSecurity, data);
  }

  columnDataUpdate(id : string | any, data : FormData | any) {
    return this._http.put(`${ApiEndpoints.editColumnSecuirty + id}/edit/`, data);
  }

  getColumnSecurityDetails(id : string | any) {
    return this._http.get(ApiEndpoints.columnSecurityDetails + id);
  }

  getModuleDropdown() {
    return this._http.get(ApiEndpoints.moduleDropdown);
  }

  getItemDropdown(data : FormData | any, type : string | any) {
    return this._http.post(`${ApiEndpoints.itemDropdown}?security_type=${type}`, data);
  }

  getUserDropdown() {
    return this._http.get(ApiEndpoints.UsersList);
  }

  /** *to get the all user list including explorer, professional and contributor*/
  getAllUserDropdown() {
    return this._http.get(ApiEndpoints.adminActiveUserGrid);
  }

  // To get users list for dropdown
  // Added 'any' type as the data is varying
  getPermissionUserDropdown(data : FormData | any, isCustomModule: boolean = false) {
    if (isCustomModule) {
      return this._http.get(`${customModuleApiEndpoints.customModule}${data}/users-list/`);
    }
    return this._http.get(`${ApiEndpoints.permissionUserList}?module=${data}`);
  }

  // To get group list for dropdown
  // Added 'any' type as the data is varying
  getUserGroup(data : FormData | any, isCustomModule: boolean = false) {
    if (isCustomModule) {
      return this._http.get(`${customModuleApiEndpoints.customModule}${data}/group-list/`);
    }
    return this._http.get(`${ApiEndpoints.UserGroupList}?module=${data}`);
  }

  getConditionDropdown() {
    return this._http.get(ApiEndpoints.conditionDropdown);
  }

  getPolicyModuleList() {
    return this._http.get(ApiEndpoints.PolicyModuleList);
  }

  // function used to get XLS list
  getAdminUserXLS(gridParams: GridParams) {
    const params = this._function?.queryParamsToString(gridParams);
    return this._http.fileDownload(`${ApiEndpoints.adminUserExporXLS}?${params}`);
  }

  // function used to get CSV list
  getAdminUserCSV(gridParams: GridParams) {
    const params = this._function?.queryParamsToString(gridParams);
    return this._http.fileDownload(`${ApiEndpoints.adminUserExportCSV}?${params}`);
  }

  getScenarioDropdown() {
    return this._http.get(ApiEndpoints.scenarioList);
  }

  editinherentRating(data: FormData, id: string) {
    return this._http.post(`${ApiEndpoints.editInherentScoring + id}/min_max_value`, data);
  }

  getScoringModelsLIst(search: string) {
    return this._http.get(`${ApiEndpoints.scoringModelsLIst}?q=${search}`);
  }

  getScoringModelById(id: string) {
    return this._http.get(`${ApiEndpoints.scoringModelsLIst}?object_id=${id}`);
  }

  deleteModel(id: string) {
    return this._http.delete(`${ApiEndpoints.scoringModel + id}/delete`);
  }

  createScoringModel(data: FormData |any) {
    return this._http.post(ApiEndpoints.scoringModelCreate, data);
  }

  editScoringModel(id: string, data: FormData | any) {
    return this._http.put(`${ApiEndpoints.scoringModel + id}/edit?edit_for=${data?.name}`, data);
  }

  getFactorsDropdown(id: string) {
    return this._http.get(`${ApiEndpoints.scoringModel + id}/factor-list`);
  }

  changePassword(data: FormData) {
    return this._http.post(ApiEndpoints.changeUserPassword, data);

  }

  activateUser(email : string, data: FormData) {
    return this._http.post(`${ApiEndpoints.activateUser + email}/user-actions/?action=SUPERUSER_ACTIVATE_USER`, data);

  }


  clientCreate(userData: IClientCreation) {
    return this._http.post(ApiEndpoints.clientCreate, userData);
  }

  clientEdit(userData: IClientEdit, id: number) {
    return this._http.put(`${ApiEndpoints.clientEdit + id}/edit/`, userData);
  }

  language() {
    return this._http.get(ApiEndpoints.languageDropdown);
  }

  clientList(gridParams : Getusergridparams = { q: '', offset: 0, limit: 10 }) {
    const keys: any = Object.keys(gridParams);
    const value: any = Object.values(gridParams);
    let query = '?';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(`${ApiEndpoints.clientList + query}client_list=true`);
  }

  clientDelete(formData: FormData) {
    return this._http.post(ApiEndpoints.clientDelete, formData);
  }

  clientDetail(id: number) {
    return this._http.get(`${ApiEndpoints.clientDetails + id}/details/`);
  }

  // client feature list
  getClientFeatures(gridParams: GridParams) {
    const params = this._function?.queryParamsToString(gridParams);
    return this._http.get(`${ApiEndpoints.clientFeaturesList}?${params}`);
  }

  clientInstance(formData: FormData) {
    return this._http.post(ApiEndpoints.clientInstance, formData);
  }

  clientAction(status: string, userId: number) {
    return this._http.post(`${ApiEndpoints.clientAction}?action=${status}&user_id=${userId}`);
  }

  clientStagesList() {
    return this._http.get(ApiEndpoints.clientStagesList);
  }

  deploymentModes() {
    return this._http.get(ApiEndpoints.deploymentModes);
  }

  clientType() {
    return this._http.get(ApiEndpoints.clientLicenseTypeList);
  }

  // license list
  getLicenseList(gridParams: GridParams) {
    const params = this._function?.queryParamsToString(gridParams);
    return this._http.get(`${ApiEndpoints.licenseList}?${params}`);
  }

  getLicenseDetails(id : string | any) {
    return this._http.get(`${ApiEndpoints.licenseDetails}?license=${id}`);
  }

  getAdminLicenseDetails(id: number) {
    return this._http.get(`${ApiEndpoints.adminLicenseDetails}?license=${id}`);
  }

  saveLicense(data : FormData | any) {
    return this._http.post(ApiEndpoints.saveLicense, data);
  }

  saveAdminLicense(data: LicenceData) {
    return this._http.post(ApiEndpoints.adminSaveLicense, data);
  }

  // To get list of submodules for the selected item
  moduleList(slug : string, isCustomModule: boolean = false, client:string) {
    if (isCustomModule) {
      return this._http.get(`${customModuleApiEndpoints.customModuleList}${client}`);
    }
    return this._http.get(`${ApiEndpoints.moduleList}?slug=${slug}${client}`);
  }

  // To get list of permissions available for the module
  // Added 'any' type as the data is varying
  modulePermissionList(data : FormData | any, isCustomModule: boolean = false, clientName: string= '') {
    if (isCustomModule) {
      return this._http.get(`${customModuleApiEndpoints.customModule}${data}/permissions-list/`);
    } else if (clientName !== '') {
      return this._http.get(`${ApiEndpoints.modulePermissionList}?module=${data}&client=${clientName}`);
    }
    return this._http.get(`${ApiEndpoints.modulePermissionList}?module=${data}`);
  }

  // To update permission for user
  // Added 'any' type as the data is varying
  saveUserPermission(data : FormData | any, type : string, isCustomModule: boolean = false) {
    if (isCustomModule) {
      return this._http.post(`${customModuleApiEndpoints.customModuleAddPermission}?&type=${type}`, data);
    }
    return this._http.post(ApiEndpoints.userPermissionAdd, data);
  }

  // To update permission for group
  // Added 'any' type as the data is varying
  saveUserGroupPermission(data : FormData | any, type : string, isCustomModule: boolean = false) {
    if (isCustomModule) {
      return this._http.post(`${customModuleApiEndpoints.customModuleAddPermission}?&type=${type}`, data);
    }
    return this._http.post(ApiEndpoints.userGroupPermissionAdd, data);
  }

  // To remove a permission for users and groups
  deleteUserPermission(id : string, type : string, module : string, isCustomModule : boolean = false) {
    if (isCustomModule) {
      return this._http.post(`${customModuleApiEndpoints.customModuleDeleteUserPermission}?type=${type}`, {module: module, id: id});
    }
    return this._http.get(`${ApiEndpoints.deleteUserPermission}?module=${module}&id=${id}&type=${type}`);
  }

  // To get already applied permissions list
  getList(module : string, isCustomModule : boolean = false) {
    if (isCustomModule) {
      return this._http.get(`${customModuleApiEndpoints.customModule}${module}/applied-permissions/`);
    }
    return this._http.get(`${ApiEndpoints.permissionList}?module=${module}`);
  }

  // To get effective permission of users and groups
  effectivePermission(id : string, type : string, module : string, isCustomModule: boolean = false) {
    if (isCustomModule) {
      return this._http.get(`${customModuleApiEndpoints.customModuleEffectivePermission}?module=${module}&id=${id}&type=${type}`);
    }
    return this._http.get(`${ApiEndpoints.effectivePermission}?module=${module}&id=${id}&type=${type}`);
  }

  // To get effective permission of all users
  effectivePermissionAllUser(type : string, module : string, isCustomModule: boolean = false) {
    if (isCustomModule) {
      return this._http.get(`${customModuleApiEndpoints.customModuleEffectivePermission}?module=${module}&type=${type}`);
    }
    return this._http.get(`${ApiEndpoints.effectivePermission}?module=${module}&type=${type}`);
  }

  // To update permission of all users
  // Added 'any' type as the data is varying
  saveAllUserPermission(data : FormData | any, isCustomModule: boolean = false) {
    if (isCustomModule) {
      return this._http.post(`${customModuleApiEndpoints.customModuleAddPermission}?&type=all_users`, data);
    }
    return this._http.post(ApiEndpoints.allUserPermission, data);
  }

  getPermissionAccess(data : FormData | any) {
    return this._http.get(`${ApiEndpoints.permissionAccess}?slug=${data}`);
  }

  // Function provide the permission of the requred modules
  getPermissionGroupAccess(data : string, reqFor: string) {
    return this._http.get(`${ApiEndpoints.permissionAccess}?slug=${data}${reqFor}`);
  }

  getAllActionPermissionAccess() {
    return this._http.get(`${ApiEndpoints.permissionforAllActions}`);
  }

  // function for get all compliance action permission
  getAllComplianceActionPermissionAccess() {
    return this._http.get(`${ApiEndpoints.permissionforAllComplianceActions}`);
  }

  getAdminPermissionAccess(data: string) {
    return this._http.get(`${ApiEndpoints.adminPermissionAccess}?module=${data}`);
  }

  getSidebarPermission(admin : string, sidebar : string, additionalParams:string='') {
    this.redirectUri = this.router.url;
    if (sidebar != '' && additionalParams != '') {
      return this._http.get(`${ApiEndpoints.sidebarPermission}?request_for=${sidebar}${additionalParams}`);
    } else if (sidebar != '') {
      return this._http.get(`${ApiEndpoints.sidebarPermission}?request_for=${sidebar}`);
    } else if (this.redirectUri.includes('/controlpanel')) {
      if (environment.clientName === clientNames.tabreed) {
        return this._http.get(`${ApiEndpoints.sidebarPermission}?request_for=${admin}&client=${clientNames.tabreed}`);
      }
      return this._http.get(`${ApiEndpoints.sidebarPermission}?request_for=${admin}`);
    }
    return this._http.get(`${ApiEndpoints.sidebarPermission}?version=v1`);
  }

  getContributorSidebar() {
    return this._http.get(ApiEndpoints.contributorSidebar);
  }

  notificationList(gridParams?: NotificationResponse) {
    const params = this._function?.queryParamsToString(gridParams);
    return this._http.get(`${ApiEndpoints.notificationList}?${params}`);
  }

  newNotificationList(gridParams?: NotificationResponse) {
    const params = this._function?.queryParamsToString(gridParams);
    return this._http.get(`${ApiEndpoints.newNotificationList}?${params}`);
  }

  notificationFieldList(gridParams?: NotificationResponse) {
    const params = this._function?.queryParamsToString(gridParams);
    return this._http.get(`${ApiEndpoints.notificationList}?${params}`);
  }

  updateNotification(gridParams: NotificationResponse, data : FormData) {
    const params = this._function?.queryParamsToString(gridParams);
    return this._http.put(`${ApiEndpoints.notificationList}?${params}`, data);
  }

  getFieldEditorList(requestFor: string) {
    return this._http.get(`${ApiEndpoints.dynamicFieldList}?request_for=${requestFor}`);
  }

  editFieldList(editedFieldData: FieldEditorInputData[]) {
    return this._http.put(ApiEndpoints.fieldListEdit, editedFieldData);
  }

  updateOrder(data : FormData | any, slug : string | any, field : string | any, requestFor : string | any) {
    return this._http.put(`${ApiEndpoints.updateOrder}?slug=${slug}&field=${field}&request_for=${requestFor}`, data);
  }

  getNotificationsModuleList() {
    return this._http.get(ApiEndpoints.getNotificationsModuleList);
  }

  getNotificationsUserFieldList(gridParams?: NotificationResponse) {
    const params = this._function?.queryParamsToString(gridParams);
    return this._http.get(`${ApiEndpoints.getNotificationsUserFieldList}?${params}`);
  }

  getNotificationsFieldList() {
    return this._http.get(ApiEndpoints.getNotificationsFieldList);
  }

  getreminderTypeChanges(){
    return this._http.get(ApiEndpoints.getReminderType);
  }

  getReminderDate(gridParams?: NotificationResponse){
    const params = this._function?.queryParamsToString(gridParams);
    return this._http.get(`${ApiEndpoints.getReminderDate}?${params}`);
  }

  getConditionMultiselect(gridParams?: NotificationResponse){
    const params = this._function?.queryParamsToString(gridParams);
    return this._http.get(`${ApiEndpoints.getConditionMultiselect}?${params}`);
  }

  getReminderModuleDropdown() {
    return this._http.get(ApiEndpoints.reminderModuleList);
  }

  getReminderDetail(id : string | any) {
    return this._http.get(`${ApiEndpoints.getReminderDetail + id}/detail`);
  }

  // update dynamic reminder list
  getDynamicRecordList(gridParams?: any) {
    const queryParams = this._function?.queryParamsToString(gridParams);
    return this._http.get(`${ApiEndpoints.getDynamicRecordList}?${queryParams}`);
  }

  addRecipients() {
    return this._http.get(ApiEndpoints.adminUserGrid);
  }

  createReminder(data : FormData | any) {
    return this._http.post(ApiEndpoints.reminderCreate, data);
  }

  sendBulkReminder(data : FormData | any) {
    return this._http.post(ApiEndpoints.notificationList, data);
  }

  getAllUsers(paramString : string | any = '') {
    return this._http.get(ApiEndpoints.adminUserGrid + paramString);
  }

  getAllModules(clientname:string ='', requestFor: {reassign_id : string, request_for: string}) {
    let query = '?';
    query += this._function.queryParamsToString(requestFor);
    if (requestFor?.reassign_id) {
      return this._http.get(ApiEndpoints.listModules+clientname+query);
    }
    return this._http.get(ApiEndpoints.listModules+clientname);
  }

  /* function to get module count in mass user */
  getAllListCounts(id : string, paramString : string, parent_module_slug: string) {
    return this._http.get(ApiEndpoints.recordsListCount + id + paramString + parent_module_slug);
  }

  getDataList(id : string | any, params : Getusergridparams | any) {
    const keys: any = Object.keys(params);
    const value: any = Object.values(params);
    let query = '/?';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.getRecordList + id + query);
  }

  /* function to get module record list in mass user */
  getRecordDataList(id : string, params : Getusergridparams, parent_module_slug: string) {
    const keys: any = Object.keys(params);
    const value: any = Object.values(params);
    let query = '/?';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.getRecordListByUser + id + query + parent_module_slug);
  }

  userMassReassign(data : FormData | any, email : string | any) {
    return this._http.post(`${ApiEndpoints.user + email}/user-actions/?action=REASSIGN`, data);
  }

  getMassReassignExport(additional_params:{request_type?:string}= {}) {
    let query = '?';
    query += this._function.queryParamsToString(additional_params);
    return this._http.get(ApiEndpoints?.massReassignExport + query);
  }

  getReminderSubModuleDropdown(module : string | any) {
    return this._http.get(`${ApiEndpoints.reminderModuleList}?slug=${module}`);
  }

  getReminderStatusList(subModule : string | any) {
    return this._http.get(`${ApiEndpoints.reminderStatusList}?slug=${subModule}`);
  }

  getBulkReminder(gridParams : Getusergridparams) {
    const keys: any = Object.keys(gridParams);
    const value: any = Object.values(gridParams);
    let query = '?';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.bulkReminderList + query);
  }

  getRecordList(gridParams : Getusergridparams, id : string | any) {
    const keys: any = Object.keys(gridParams);
    const value: any = Object.values(gridParams);
    let query = '&';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(`${ApiEndpoints.reminderRecordList}?object_id=${id}${query}`);
  }

  getReminderDetails(id : string | any) {
    return this._http.get(`${ApiEndpoints.bulkReminderDetails + id}/details/`);
  }

  getRecords(gridParams : Getusergridparams, sub : string | any, status : string | any, id : string | any) {
    const keys: any = Object.keys(gridParams);
    const value: any = Object.values(gridParams);
    let query = '&';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    if (id !== undefined) {
      return this._http.get(`${ApiEndpoints.reminderRecordList}?object_id=${id}&status=${status}${query}`);
    }
    return this._http.get(`${ApiEndpoints.reminderRecordList}?request_for=${sub}&status=${status}${query}`);
  }

  /* function get observation scoring model details */
  getObservationScoringModelDetails() {
    return this._http.get(`${ApiEndpoints.auditRpScoringModelList}?observation_rating=true`);
  }

  editObservationRating(data: FormData) {
    return this._http.post(ApiEndpoints.observationMinMaxValue, data);
  }

  editObservationScoringModel(data: any) {
    return this._http.put(ApiEndpoints.observationRatingEdit, data);
  }

  getObservationRatingStatus() {
    return this._http.get(ApiEndpoints.newObservationRatingStatus);
  }

  getProcessList(processType: string, params : Getusergridparams | any = {}, search : string | any = '' ) {
    params['q'] = search;
    const keys: string[] = Object.keys(params);
    const value: (string | number)[] = Object.values(params);
    let query = '&';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(`${ApiEndpoints.newProcessList}list/?request_for=${processType}${query}`);
  }

  getProcessDetails(_processType: string, process_object_id: string) {
    return this._http.get(`${ApiEndpoints.newProcessList}list/?process_id=${process_object_id}`);
  }

  createProcess(data: any) {
    return this._http.post(`${ApiEndpoints.newProcessList}create/`, data);
  }

  editProcess(process_object_id: string, data: any) {
    return this._http.put(`${ApiEndpoints.newProcessList + process_object_id}/edit/`, data);
  }

  deleteProcess(process_object_id : string | any) {
    return this._http.delete(`${ApiEndpoints.newProcessList + process_object_id}/delete/`);
  }

  getAllReassignJobs(params : Getusergridparams | any) {
    const keys: any = Object.keys(params);
    const value: any = Object.values(params);
    let query = '?';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.reassignJobsList + query);
  }

  // Delete Scheduled mass user reassign job
  deleteScheduledReassignJobs(id: number) {
    return this._http.delete(`${ApiEndpoints.deleteReassignedJob}${id}/delete/`);
  }

  getFiscalData() {
    return this._http.get(ApiEndpoints.fiscalYearList);
  }

  saveFiscalData(data : FormData | any) {
    return this._http.post(ApiEndpoints.fiscalYearSave, data);
  }

  getSectorsList(params : Getusergridparams | any) {
    const keys: any = Object.keys(params);
    const value: any = Object.values(params);
    let query = '?';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.sectorsList + query);
  }

  saveSectorData(data : FormData | any) {
    return this._http.post(ApiEndpoints.createSector, data);
  }

  editSector(id : string | any, data : FormData | any) {
    return this._http.put(`${ApiEndpoints.editSector + id}/`, data);
  }

  getSectorDetails(id : string | any) {
    return this._http.get(`${ApiEndpoints.sectorDetails + id}/list/`);
  }

  deleteSector(id : string | any) {
    return this._http.delete(`${ApiEndpoints.sectorDelete + id}/delete/`);
  }

  sortEntityOrder(data : FormData | any) {
    return this._http.put(ApiEndpoints.sortEntity, data);
  }

  sortRegionOrder(data : FormData | any) {
    return this._http.put(ApiEndpoints.sortRegion, data);
  }

  sortDepartmentOrder(data : FormData | any) {
    return this._http.put(ApiEndpoints.sortDepartment, data);
  }

  sortDesignationOrder(data : FormData | any) {
    return this._http.put(ApiEndpoints.sortDesignation, data);
  }

  // To sort division in controlpanel
  sortDivisionOrder(data : FormData | any) {
    return this._http.put(ApiEndpoints.sortDivision, data);
  }

  sortSectorOrder(data : FormData | any) {
    return this._http.put(ApiEndpoints.sortSector, data);
  }

  getReminderDurationDropdown() {
    return this._http.get(ApiEndpoints.reminderDurationList);
  }

  withdrawReminder(id : string | any) {
    return this._http.delete(`${ApiEndpoints.withdrawReminder + id}/delete`);
  }

  editBulkReminder(data : FormData | any, id : string | any) {
    return this._http.put(`${ApiEndpoints.withdrawReminder + id}/update`, data);
  }

  getEnterpriseRiskScoringModelsList(search: string) {
    return this._http.get(`${ApiEndpoints.scoringModelsLIst}?erm_rating=true&q=${search}`);
  }

  createEnterpriseRiskScoringModel(data: any) {
    return this._http.post(`${ApiEndpoints.scoringModelCreate}?request_for=erm_rating`, data);
  }

  getEnterpriseRiskControlPanelTypeGrid(params : Getusergridparams | any = {}, _gridParams : Getusergridparams = { q: '', offset: 0, limit: 10 }, search : string | any = '', fieldName : FormData | any) {
    params['q'] = search;
    const keys: string[] = Object.keys(params);
    const value: (string | number)[] = Object.values(params);
    let query = '?';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.enterpriseRiskFieldGrid + fieldName + query);
  }

  getBCMControlPanelTypeGrid(params : Getusergridparams | any = {}, _gridParams : Getusergridparams = { q: '', offset: 0, limit: 10 }, search : string | any = '', fieldName : FormData | any) {
    params['q'] = search;
    const keys: string[] = Object.keys(params);
    const value: (string | number)[] = Object.values(params);
    let query = '?';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.bcmControlPanel + fieldName + query);
  }

  getEnterpriseRiskScoringModelById(id: string) {
    return this._http.get(`${ApiEndpoints.scoringModelsLIst}?erm_rating=true&object_id=${id}`);
  }

  getErmUniverseList(params : Getusergridparams | any = {}, gridParams : Getusergridparams = { q: '', offset: 0, limit: 100 }, search : string | any = '') {
    gridParams.q = search;
    let keys: any = Object.keys(params);
    let value: any = Object.values(params);
    let query = '?';
    keys = Object.keys(gridParams);
    value = Object.values(gridParams);

    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.adminErmUniverse + query);
  }

  getErmControlpanelTypeGrid(params : Getusergridparams | any = {}, _gridParams : Getusergridparams = { q: '', offset: 0, limit: 10 }, search : string | any= '', fieldName : FormData | any) {
    params['q'] = search;
    const keys: string[] = Object.keys(params);
    const value: (string | number)[] = Object.values(params);
    let query = '?';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(ApiEndpoints.adminErmDetails + fieldName + query);
  }

  getErmControlpanelTypeGridAll(fieldName : FormData | any) {
    return this._http.get(ApiEndpoints.adminErmDetails + fieldName);
  }

  getErmHideObjectGrid(_params : Getusergridparams | any = {}, _gridParams : Getauditgridparams = { configurable: '', offset: 0, limit: 10 }) {
    return this._http.get(ApiEndpoints.ermHidenonConfigurable);
  }

  getAdminErmDetails(field : string | any, id : string | any) {
    return this._http.get(`${ApiEndpoints.adminErmDetails + field}/?id=${id}`);
  }

  getAdminKriDetails(field : string, id : string|number, slug:string) {
    return this._http.get(`${`${ApiEndpoints.kriFieldGrid +slug}/${field}`}/?id=${id}`);
  }

  editErmScoringModel(id: string, data: any) {
    return this._http.put(`${ApiEndpoints.scoringModel + id}/edit?request_for=erm_rating`, data);
  }

  getDesignationDropdownList() {
    return this._http.get(ApiEndpoints.designationDropdownList);
  }

  deleteScoringModel(id: string) {
    return this._http.delete(`${ApiEndpoints.scoringModel + id}/delete?request_for=erm_rating`);
  }

  getDepartmentDetailList(departmentLevel: string, params : Getusergridparams | any = {}, search : string | any = '' ) {
    params['q'] = search;
    const keys: string[] = Object.keys(params);
    const value: (string | number)[] = Object.values(params);
    let query = '&';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(`${ApiEndpoints.departmentLevelList}?department_level=${departmentLevel}${query}`);
  }

  getUserDepartmentDetailList(departmentLevel: string, params : Getusergridparams | any = {}, search : string | any = '' ) {
    params['q'] = search;
    const keys: string[] = Object.keys(params);
    const value: (string | number)[] = Object.values(params);
    let query = '&';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(`${ApiEndpoints.userDepartmentGrid}?department_level=${departmentLevel}${query}`);
  }

  getDepartmentParentDropdownList( level: string) {
    return this._http.get(`${ApiEndpoints.riskDepartmentDropdownList}?department_level=${level}` );
  }

  getDepartmentLevelDropdownList( level: string) {
    return this._http.get(`${ApiEndpoints.riskDepartmentLevelDropdownList}?department_level=${level}` );
  }

  getDepartmentParentDropdown(level: string) {
    return this._http.get(`${ApiEndpoints.riskDepartmentDropdownList}?department_level=${level}`);
  }

  getuserDepartmentParentDropdownList(level: string) {
    return this._http.get(`${ApiEndpoints.riskuserDepartmentDropdownList}?department_level=${level}`);
  }

  getAdminBusinessStrategyList() {
    return this._http.get(ApiEndpoints.adminBusinessStrategy);
  }

  getAdminBusinessStrategyListNonConfigurable() {
    return this._http.get(ApiEndpoints.adminBusinessStrategyNonConfigurable);
  }

  getDynamicField(fieldName: string, slug: string) {
    return this._http.get(`${ApiEndpoints.riskControlPanelDynamicField}?field_name=${fieldName}&slug=${slug}`);
  }

  getBcmDynamicist(fieldName: string) {
    return this._http.get(`${ApiEndpoints.bcmDynamicList}?slug=business-process&field_name=${fieldName}&model=businessprocess`);
  }

  getComplianceAuditDynamicist(fieldName: string) {
    return this._http.get(`${ApiEndpoints.complianceControlPanelDynamicField}?slug=compliance-audit&field_name=${fieldName}&model=complianceaudit`);
  }


  updateAuditNotificationTemplate(data: AuditNotificationEmailEditData) {
    return this._http.put(ApiEndpoints.auditNotificationTemplateEdit, data);
  }

  updateRiskProgramNotificationTemplate(data: AuditNotificationEmailEditData) {
    return this._http.put(ApiEndpoints.riskProgramNotificationTemplate, data);
  }

  getAuditNotificationTemplate() {
    return this._http.get(ApiEndpoints.auditNotificationTemplateEdit);
  }

  getRiskProgramNotificationTemplate() {
    return this._http.get(ApiEndpoints.riskProgramNotificationTemplate);
  }

  updateAuditNotificationSignature(data: AuditNotificationSignatureEditData) {
    return this._http.put(ApiEndpoints.auditNotificationSignatureEdit, data);
  }

  getAuditNotificationSignature() {
    return this._http.get(ApiEndpoints.auditNotificationSignatureEdit);
  }

  getFiscalYearDropdown() {
    return this._http.get(ApiEndpoints.fiscalYearList);
  }

  adminModuleList(slug: string) {
    return this._http.get(`${ApiEndpoints.adminModuleList}?slug=${slug}`);
  }

  adminGetList(module: string) {
    return this._http.get(`${ApiEndpoints.adminPermissionList}?module=${module}`);
  }

  adminSaveAllUserPermission(data: PermissionData) {
    return this._http.post(ApiEndpoints.adminAllUserPermission, data);
  }

  adminSaveUserPermission(data: PermissionData) {
    return this._http.post(ApiEndpoints.adminUserPermissionAdd, data);
  }

  adminSaveUserGroupPermission(data: PermissionData) {
    return this._http.post(ApiEndpoints.adminUserGroupPermissionAdd, data);
  }

  adminDeleteUserPermission(id: string, type: string, module: string) {
    return this._http.get(`${ApiEndpoints.adminDeleteUserPermission}?module=${module}&id=${id}&type=${type}`);
  }

  adminGetPermissionUserDropdown(data: string) {
    return this._http.get(`${ApiEndpoints.adminPermissionUserList}?module=${data}`);
  }

  adminGetUserGroup(data: string) {
    return this._http.get(`${ApiEndpoints.adminUserGroupList}?module=${data}`);
  }

  adminModulePermissionList(data: string) {
    return this._http.get(`${ApiEndpoints.adminModulePermissionList}?module=${data}`);
  }

  adminGetSidebarPermission() {
    return this._http.get(ApiEndpoints.adminSidebarPermission);
  }

  adminEffectivePermission(id: string, type: string, module: string) {
    return this._http.get(`${ApiEndpoints.adminEffectivePermission}?module=${module}&id=${id}&type=${type}`);
  }

  adminEffectivePermissionAllUser(type: string, module: string) {
    return this._http.get(`${ApiEndpoints.adminEffectivePermission}?module=${module}&type=${type}`);
  }

  deleteDepartmentLevel(id: string, level: string) {
    return this._http.delete(`${ApiEndpoints.departmentDelete + id}/delete/?level=${level}`);
  }

  deleteUserDepartmentLevel(id: string, level: string) {
    return this._http.delete(`${ApiEndpoints.userdepartmentDelete + id}/delete/?level=${level}`);
  }

  getAuditIssueList(_params : AdminAuditPlanning = adminAuditPlanning, _gridParams : Getusergridparams= {
    q: '',
    offset: 0,
    limit: tableConfig.tableLimit
  }) {
    return this._http.get(ApiEndpoints.adminAuditIssueList);
  }

  getAuditIssueHideObjectGrid(_params : Getusergridparams | any = {}, _gridParams : Getauditgridparams = { configurable: '', offset: 0, limit: 10 }) {
    return this._http.get(ApiEndpoints.auditIssueConfigurable);
  }

  getObjectiveManagerDropdown() {
    return this._http.get(ApiEndpoints.stakeholderUsers);
  }

  getObjectiveParentDropdownList(level: string) {
    return this._http.get(`${ApiEndpoints.objectiveDropdownList}?obj_level=${level}`);
  }

  getObjectiveGridData(level: string, params: any = {}, search: string = '' ) {
    params['q'] = search;
    const keys: string[] = Object.keys(params);
    const value: (string | number)[] = Object.values(params);
    let query = '&';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(`${ApiEndpoints.objectiveDropdownList}?obj_level=${level}${query}`);
  }

  getObjectiveDetail(id: string) {
    return this._http.get(`${ApiEndpoints.objectiveDropdownList}?object_id=${id}`);
  }

  createObjective(data: FormData) {
    return this._http.post(ApiEndpoints.objectiveCreate, data);
  }

  editObjective(id: string, data: FormData) {
    return this._http.put(`${ApiEndpoints.objectiveEditAndDelete + id}/edit`, data);
  }

  deleteObjective(id: string, level: string) {
    return this._http.delete(`${ApiEndpoints.objectiveEditAndDelete + id}/delete?level=${level}`);
  }

  getDepartmentPermissionStatus(): Observable<any> {
    return this._http.get(ApiEndpoints.CpEntityDepartmentStatus);
  }

  getDepartmentReportLists(data: string): Observable<any> {
    return this._http.get(`${ApiEndpoints.CpEntityDepartmentReportList}/${data}/list/?configurable=Yes&pagination_config=all`);
  }

  CPEntityDeptNonConfigurable(data: string): Observable<any> {
    return this._http.get(`${ApiEndpoints.CpEntityDepartmentReportList}/${data}/list/?pagination_config=all`);
  }

  // used to get scoring models of entity and department
  CPEntityDeptRatingList(scoringModel: string): Observable<any> {
    if (scoringModel ===  scoringModelEntityManagement.department) {
      return this._http.get(ApiEndpoints.departmentScoringList);
    }
    if (scoringModel ===  scoringModelEntityManagement.entity) {
      return this._http.get(ApiEndpoints.entityScoringList);
    }
    if (scoringModel ===  scoringModelEntityManagement.section) {
      return this._http.get(ApiEndpoints.sectionScoringList);
    }
  }

  // getting department and entity min and max value
  CPEntityObservationRating(data: FormData, scoringModel: string): Observable<any> {
    if (scoringModel ===  scoringModelEntityManagement.department) {
      return this._http.post(ApiEndpoints.CpEntityEditMinMaxValue, data);
    }
    if (scoringModel ===  scoringModelEntityManagement.entity) {
      return this._http.post(ApiEndpoints.entityMinAndMax, data);
    }
    if (scoringModel ===  scoringModelEntityManagement.section) {
      return this._http.post(ApiEndpoints.sectionMinAndMax, data);
    }
  }

  // edit scoring
  CPEntityEditObservationScoring(data: any, scoringModel: string): Observable<any> {
    if (scoringModel ===  scoringModelEntityManagement.department) {
      return this._http.put(ApiEndpoints.CpEntityEditRating, data);
    }
    if (scoringModel ===  scoringModelEntityManagement.entity) {
      return this._http.put(ApiEndpoints.entityModelEdit, data);
    }
    if (scoringModel ===  scoringModelEntityManagement.section) {
      return this._http.put(ApiEndpoints.cpSectionScoringModelEdit, data);
    }
  }

  CPEntityDeptDetails(slug: string, field: string): Observable<any> {
    return this._http.get(`${ApiEndpoints.CpEntityDeptDetails}${slug}/${field}/`);
  }

  CPEntityReportingList(params : Getusergridparams | any = {}, _gridParams : Getusergridparams = { q: '', offset: 0, limit: tableConfig.tableLimit }, slug: string, fieldName : FormData | any, search : string | any) {
    params['q'] = search;
    const keys: string[] = Object.keys(params);
    const value: (string | number)[] = Object.values(params);
    let query = '?';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(`${ApiEndpoints.CpEntityDeptDetails}${slug}/${fieldName}/${query}`);
  }

  // to get entity status
  getEntityPermissionStatus(): Observable<{name: string, status: boolean}[] | any> {
    return this._http.get(ApiEndpoints.CpEntityStatus);
  }

  getUserDepartmentParentDropdownList(level: string) {
    return this._http.get(`${ApiEndpoints.userDepartmentList}?department_level=${level}`);
  }

  getDepartmentFormParentDropdownList(level: string) {
    return this._http.get(`${ApiEndpoints.departmentFormList}?department_level=${level}`);
  }


  /** used to department dropdown, value change hence use any*/
  getDepartmentFormParentDropdownListForAKI(level: string, url: string): Observable<any | any[]> {
    return this.http.get(`${url + ApiEndpoints.departmentFormListAKI}?department_level=${level}`);
  }

  // department edit
  editDepartment(id : string | any, data : FormData | any) {
    return this._http.put(`${ApiEndpoints.department + id}/edit/`, data);
  }

  getClientLicenceDetails(): Observable<any> {
    return this._http.get(ApiEndpoints.clientLicenceDetails);
  }

  CPAddActivationKey(data: any) {
    return this._http.post(ApiEndpoints.addActivationKey, data);
  }

  getAllPageList(id: string) {
    return this._http.get(`${ApiEndpoints.allPageList}?license_type=${id}`);
  }

  getMenuOrderList(id: string) {
    return this._http.get(`${ApiEndpoints.menuOrderList}?license_type=${id}`);
  }

  editMenuItems(id : string | number, data: MenuItemData[]) {
    return this._http.post(`${ApiEndpoints.menuListUpdate + id}`, data);
  }

  deleteCustomLink(id:string){
    return this._http.delete(`${ApiEndpoints.customMenuDelete}${id}/delete/`);
  }

  getUserGridData(params: Getusergridparams | any){
    const queryParam = this._function?.queryParamsToString(params);
    return this._http.get(`${ApiEndpoints.menusCreatorUserList}?${queryParam}`);
  }

  createTemplate(data: TemplateData){
    return this._http.post(ApiEndpoints.menusCreateTemplate, data);
  }

  editTemplate(id:number, data: TemplateData){
    return this._http.put(`${ApiEndpoints.menusEditTemplate}${id}/`, data);
  }

  deleteTemplate(id:number){
    return this._http.delete(`${ApiEndpoints.menusDeleteTemplate}${id}/delete/`);
  }

  getAllPagesList(){
    return this._http.get(`${ApiEndpoints.allPagesAndCustomLinkList}?license_type=5`);
  }

  addCustomLink(data: CustomLinkData, licenseType: number | string = null){
    return this._http.post(`${ApiEndpoints.addCustomLink}?license_type=${licenseType}`, data);
  }

  editCustomLink(id: string, data: CustomLinkData, licenseType: number | string = null){
    return this._http.put(`${ApiEndpoints.editCustomLink}${id}/edit/?license_type=${licenseType}`, data);
  }

  getMenuCustomLinksDropdown(){
    return this._http.get(`${ApiEndpoints.menuCustomLinksDropdown}`);
  }

  getSidebarMenus(role: string){
    return this._http.get(`${ApiEndpoints.sidebarMenus}?license_type=${role}`);
  }

  // To get all custom modules permission
  getAllCustomModulePermission(){
    return this._http.get(`${customModuleApiEndpoints.customModulePermission}`);
  }

  getEmailTemplateList(params:Getusergridparams, search:string = '', slug: string = null): Observable<any> {
    const keys: string[] = Object.keys(params);
    const value: any = Object.values(params);
    let query = '';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '' && value[i] !== false) {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(`${ApiEndpoints.allEmailTemplate}?slug=${slug}&q=${encodeURIComponent(search)}&${query}`);
  }

  deleteEmailTemplate(id:number){
    return this._http.delete(`${ApiEndpoints.deleteEmailTemplate}${id}/delete/`);
  }

  getEmailTemplateDetails(templateId: number, _slug: string = null){
    return this._http.get(`${ApiEndpoints.getTemplateDetails}?template_id=${templateId}`);
  }

  getDocuments(templateId: number) {
    return this._http.get(`${ApiEndpoints.getEmailDocument}${templateId}`);
  }

  getAllFields(params: string) {
    return this._http.get(`${ApiEndpoints.getAllFields}${params}`);
  }

  publishTemplate(data: FormData | any){
    let formDataHasFile = false;
    for (const value of data?.values()) {
      if (value instanceof File) {
        formDataHasFile = true;
      }
    }
    if (formDataHasFile) {
      return this._http.postWithProgress(ApiEndpoints.publishTemplate, data);
    }
    return this._http.post(ApiEndpoints.publishTemplate, data);
  }

  deleteEmailDocuments(docId: number | string){
    return this._http.delete(`${ApiEndpoints.deleteEmailDocument}${docId}/delete/?request_for=audit_planning_email_template&controlpanel=true` );
  }

  getEmailTemplate(templateId: number, slug: string = null) {
    return this._http.get(`${ApiEndpoints.getEmailTemplateDetails}?slug=${slug}&dynamic_fields=true&templete_id=${templateId}`);
  }

  editPublishedTemplate(data: FormData | any){
    let formDataHasFile = false;
    for (const value of data?.values()) {
      if (value instanceof File) {
        formDataHasFile = true;
      }
    }
    if (formDataHasFile) {
      return this._http.putWithProgress(ApiEndpoints.publishTemplate, data);
    }
    return this._http.put(ApiEndpoints.publishTemplate, data);
  }

  getPlanDetail(id: string) {
    return this._http.get(`${ApiEndpoints.planDetail + id}/model-detail/`);
  }

  getCPScheduleTemplateList(requestFor:string, params?:Getusergridparams, search:string = ''): Observable<any> {
    params.q=search;
    const keys: string[] = Object.keys(params);
    const value: any = Object.values(params);
    let query = '';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '' && value[i] !== false) {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(`${ApiEndpoints.CPScheduleTemplateList}?${query}request_for=${requestFor}`);
  }

  getCPGridTemplateList(params?:Getusergridparams, search:string = '') {
    params.q=search;
    const query = this._function.queryParamsToString(params);
    return this._http.get(`${ApiEndpoints.CPGridTemplateList}?${query}`);
  }

  getAuditModelData(params :any= {
    q: '',
    offset: 0,
    limit: tableConfig.tableLimit
  }) {
    const keys: string[] = Object.keys(params);
    const value: (string | number)[] = Object.values(params);
    let query = '?';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(`${ApiEndpoints.auditPlanModelData}${query}`);
  }

  getCPScheduleTemplateListDelete(objectid: string, requestFor:string){
    return this._http.delete(`${ApiEndpoints.cpAuditschedule}/${objectid}/delete/?request_for=${requestFor}`);
  }

  getCPGridTemplateListDelete(objectid: string){
    return this._http.delete(`${ApiEndpoints.cpGriddelete}/${objectid}/delete`);
  }

  getControlScoringModelDetails() {
    return this._http.get(`${ApiEndpoints.scoringModelsLIst}?control_rating=true`);
  }

  getControlRatingDynamic(data: string | any) {
    return this._http.post(ApiEndpoints.dynamicRating, data);
  }

  editControlRating(data: FormData) {
    return this._http.post(ApiEndpoints.controlRatingMinMax, data);
  }

  editControlScoringModel(data: FormData | any) {
    return this._http.put(`${ApiEndpoints.controlRatingEdit}`, data);
  }

  getNotificationTemplateDetails(templateId: number){
    return this._http.get(`${ApiEndpoints.getNotificationTemplateDetails}?template_id=${templateId}`);
  }

  publishNotificationTemplate(data: FormData | any){
    return this._http.post(ApiEndpoints.getNotificationTemplateDetails, data);
  }

  editPublishNotificationTemplate(data: FormData | any){
    return this._http.put(ApiEndpoints.getNotificationTemplateDetails, data);
  }

  getNotificationTemplateList(params:Getusergridparams, search:string = ''): Observable<any> {
    const keys: string[] = Object.keys(params);
    const value: any = Object.values(params);
    let query = '';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '' && value[i] !== false) {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return this._http.get(`${ApiEndpoints.allNotificationEmailTemplate}?q=${encodeURIComponent(search)}&${query}`);
  }

  deleteNotificationEmailTemplate(id:number){
    return this._http.delete(`${ApiEndpoints.deleteEmailTemplate}${id}/delete/?controlpanel=true`);
  }

  getControlDynamicFieldData() {
    return this._http.get(ApiEndpoints.controlDynamicFields);
  }

  resetMfa(id: number) {
    return this._http.post(ApiEndpoints.userMfaReset, {user_id: id});
  }

  listDepartmentScoringModel(search: string) {
    return this._http.get(`${ApiEndpoints.departmentScoringModelList}?q=${search}`);
  }

  listDepartmentRatingFactor() {
    return this._http.get(ApiEndpoints.departmentRatingFactorList);
  }

  createDepartmentScoringModel(data: FormData |any) {
    return this._http.post(ApiEndpoints.departmentScoringModelCreate, data);
  }

  updateDepartmentScoringModel(id : string | any, data : FormData | any) {
    return this._http.put(`${ApiEndpoints.departmentScoringModel + id}/edit/`, data);
  }

  deleteDepartmentScoringModel(id: number | any) {
    return this._http.delete(`${ApiEndpoints.departmentScoringModel + id}/delete`);
  }

  getDepartmentScoringModelDetails(id: number | any) {
    return this._http.get(`${ApiEndpoints.departmentScoringModelDetail}?id=${id}`);
  }

  editIncident(data: ControlPanelAuditDetails, id: number) {
    return this._http.put(`${ApiEndpoints.controlpaneltypeedit + id}/edit/`, data);
  }

  getCusDepartmentLevelDropdownList(level: Tags) {
    return this._http.post(ApiEndpoints.riskDepartmentLevelDropdownList, level);
  }

  getFieldNameInside(query: {slug: string, field_name: string}) {
    const queryParam = this._function?.queryParamsToString(query);
    return this._http.get(`${ApiEndpoints.fieldNameGettingApi}?${queryParam}`);
  }

  getControlScoringModel() {
    return this._http.get(apiEndpoints.controlScoringModelsLIst);
  }

  getAdminStrategyDetails(field : string, id : number) {
    return this._http.get(`${apiEndpoints.adminStrategyDetails + field}/?id=${id}`);
  }

  // returning admin tab permision
  getAdminPermission(){
    if (environment.clientName === clientNames.tabreed) {
      return this._http.get(`${ApiEndpoints.sidebarPermission}?request_for=admin&client=${clientNames.tabreed}`);
    }
    return this._http.get(`${ApiEndpoints.sidebarPermission}?request_for=admin`);
  }

  // mass user table export
  getMassReassignTableExport(additional_params:{request_type?:string, reassign_id?: string}= {}) {
    let query = '?';
    query += this._function.queryParamsToString(additional_params);
    return this._http.get(ApiEndpoints?.massUserTableExport + query);
  }

  /* Function to get calculation dropdown in risk score */
  getCalculation() {
    return this._http.get(apiEndpoints.calculationList);
  }

  // function to get general issue field list
  getAdminGeneralIssueList(configurable:boolean) {
    if (configurable){
      return this._http.get(`${ApiEndpoints.auditIssueConfigurable}&module=general`);
    }
    return this._http.get(`${ApiEndpoints.adminAuditIssueList}&module=general`);
  }

  getOrganizationHierarchyList() {
    return this._http.get(ApiEndpoints.organizationHierarchyList);
  }

  getOrganizationComponentList() {
    return this._http.get(ApiEndpoints.organizationComponentList);
  }

  // Function to update organization hierarchy
  setOrganizationHierarchyList(data: Record<string, string>) {
    return this._http.put(`${ApiEndpoints.organizationHierarchyUpdate}`, data);
  }

  // Function to get count of hierarchy relations
  setHierarchyRelationCount(data: {parent: string, child: string}) {
    return this._http.post(ApiEndpoints.organizationHierarchyRelationCount, data);
  }

  // Function to update organization hierarchy
  skipOrganizationHierarchy() {
    return this._http.put(ApiEndpoints.organizationHierarchySkip);
  }

  /* To get industries values */
  checkLibraryInstance() {
    return this._http.get(ApiEndpoints.libraryInstance);
  }

  // to get module configuration details
  getModuleConfigurationdetails(contentType:string){
    return this._http.get(`${ApiEndpoints.moduleConfiguration}/${contentType}/detail/`);
  }

  // to update module configuration details
  // data varies here, so using any
  updatetModuleConfigurationdetails(query: {type?: string, content_type?: string}, data:Record<string, string>|any, contentType:string){
    let queryParam = '?';
    queryParam += this._function.queryParamsToString(query);
    return this._http.put(`${ApiEndpoints.moduleConfiguration}/${contentType}/edit/${queryParam}`, data);
  }

  // Function used to get the feature list based on module
  getQuickSettingFeatureList(module : string) {
    return this._http.get(`${ApiEndpoints.featureList}?module=${module}`);
  }

  // Function for edit for Risk program
  getAdminRiskProgramEdit(field : string, id : string|number, slug:string) {
    return this._http.get(`${`${ApiEndpoints.riskValueDropdown +slug}/${field}`}/?id=${id}`);
  }

  // function to get ms app credentials
  getMSconfig(){
    return this._http.get(ApiEndpoints.msConfig);
  }

  // function to get tag and type values
  getGenericFieldList(type: string, params?:Record<string, string|number|boolean>) {
    if (!params) {
      return this._http.get(`${ApiEndpoints.genericTagType}?field=${type}`);
    }
    let query = '&';
    query = this._function.queryParamsToString(params);
    return this._http.get(`${ApiEndpoints.genericTagType}?field=${type}&${query}`);
  }

  // function to create tag and type values
  createGenericTagType(params: Record<string, string>) {
    return this._http.post(ApiEndpoints.createGenericDropdown, params);
  }

  // To get generic field details
  getGenericFieldDetail(type:string, id:number){
    return this._http.get(`${ApiEndpoints.genericTagType}?field=${type}&id=${id}`);
  }

  // to edit generic field
  editGenericField(id:number, data: FormData) {
    return this._http.put(`${ApiEndpoints.genericEdit}/${id}/edit/`, data);
  }

  // to delete generic field
  deleteGenericField(id:number) {
    return this._http.delete(`${ApiEndpoints.genericEdit}/${id}/delete/`);
  }

  // To call entity dropdown
  getEntityDropDown() {
    return this._http.get(ApiEndpoints.deptEntityListDropdown);
  }

  // To call function dropdown
  getFunction() {
    return this._http.get(ApiEndpoints.deptFunctionListDropdown);
  }

  // To call UserDepartmentParentDropdown
  getUserDepartmentParentDropdown(level: Tags) {
    return this._http.post(ApiEndpoints.departmentFormList, level);
  }

  // To get sector dropdown data
  getSectorDropdown() {
    return this._http.get(ApiEndpoints.sectorDropdown);
  }

  // To get Scoring Factor List
  getScoringFactorList(params: GridParams) {
    let query = '?';
    query += this._function.queryParamsToString(params);
    return this._http.get(`${ApiEndpoints.bcmScoringFactor}${query}`);
  }

  // To get Range Analysis List
  getRangeAnalysisList() {
    return this._http.get(ApiEndpoints.bcmRangeData);
  }

  // To get bcmDetail Scoring
  getbcmDetailScoring(objectId:string) {
    return this._http.get(`${ApiEndpoints.bcmDetailScoring + objectId}/detail`);
  }

  // To get bcm Create Scoring
  getbcmCreateScoring(formData:ComplianceGridRow) {
    return this._http.post(ApiEndpoints.bcmScoringCreate, formData);
  }

  // To get bcm rating expression
  getRatingExpression() {
    return this._http.get(ApiEndpoints.bcmRatingExpression);
  }

  // to delete generic field
  getRiskCategoryData() {
    return this._http.get(`${apiEndpoints.riskCategoryList}`);
  }

  // to delete generic field
  getRiskCategoryParentData(data:Record<string, number[]>) {
    return this._http.post(`${apiEndpoints.categoryList}`, data);
  }

  // to get risk category parent level data
  getRiskCategoryTopData(data:string) {
    return this._http.get(`${apiEndpoints.dropdownList}${data}`);
  }

  // to get child category level data
  getRiskCategoryLevelParentData(id:number, level:string) {
    return this._http.get(`${apiEndpoints.categoryList}?level=${level}&id=${id}`);
  }

  /* Function used to get control panel annexure landing list**/
  getCPAnnexureList(apiUrl:string, params:{configurable?:string}) {
    let query = '?';
    query += this._function.queryParamsToString(params);
    return this._http.get(apiUrl + query);
  }
}
